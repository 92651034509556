import { FC, useEffect } from "react";
import { useSubscribeUnreadMessages } from "./hooks/use-subscribe-unread-messages";
import { useProperty } from "../../hooks/use-property";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";

export const GlobalSubscribe: FC = () => {
  const { selectedPropertyIds } = useProperty();

  const { subscribeAction, unsubscribeAction } = useSubscribeUnreadMessages();

  useEffect(() => {
    let promise: QueryActionCreatorResult<any> | undefined = undefined;

    if (selectedPropertyIds && selectedPropertyIds.length) {
      promise = subscribeAction(selectedPropertyIds);
    }

    return () => {
      if (promise) {
        // @ts-ignore
        unsubscribeAction(promise?.queryCacheKey);
      }
    };
  }, [selectedPropertyIds, subscribeAction, unsubscribeAction]);
  return <></>;
};
