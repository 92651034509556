import * as Types from "../generated/graphql";

import { messagingApi } from "../messaging.api";
export type UpdateConversationMutationVariables = Types.Exact<{
  conversation: Types.ConversationInput;
}>;

export type UpdateConversationMutation = {
  __typename?: "Mutation";
  UpdateConversation: { __typename?: "Conversation"; conversationId: string };
};

export const UpdateConversationDocument = `
    mutation UpdateConversation($conversation: ConversationInput!) {
  UpdateConversation(conversation: $conversation) {
    conversationId
  }
}
    `;

const injectedRtkApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({
    UpdateConversation: build.mutation<
      UpdateConversationMutation,
      UpdateConversationMutationVariables
    >({
      query: (variables) => ({ document: UpdateConversationDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useUpdateConversationMutation } = injectedRtkApi;
