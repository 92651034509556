import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { ReservationTableDto } from "src/domain/reservation-table-dto";
import { transformReservationTableOverviewV2ToCommonReservationOverview } from "src/graphql/transform/transform-reservation-table-overview";
import {
  transformReservationSortingFiltersCommonToV2ReservationSortingFilter,
  transformValueV2ByTheName
} from "src/graphql/transform/transform-reservation-overview-filters";
import { useGetReservationTableDTOQuery } from "src/store/endpoints/reservation-table.endpoints";
import { FilterObject } from "src/types/filters/filters";
import { Order, PageableResponse } from "src/types/pageable";
import { transformFiltersToGraphqlFilters } from "../utils/filters";
import { useOverviewTableQueryEnhanced } from "../graphql/queries/enhanced-queries/get-reservations-overview";

export const useReservationTableOverview = (
  propertyId: string,
  orderBy: keyof ReservationTableDto,
  order: Order,
  page: number,
  rowsPerPage: number,
  debouncedFilterParams: FilterObject[]
): PageableResponse<ReservationTableDto> | undefined => {
  const { isRESTVersion, isGraphQlVersion } = useApiVersion();

  const { data: dataV1 } = useGetReservationTableDTOQuery(
    {
      propertyId: propertyId,
      sortingRules: [{ id: orderBy, order }],
      pageable: { pageNumber: page, pageSize: rowsPerPage },
      filterParams: debouncedFilterParams
    },
    {
      skip: !propertyId || isGraphQlVersion,
      refetchOnMountOrArgChange: true
    }
  );

  const { data: dataV2 } = useOverviewTableQueryEnhanced(
    {
      filter: {
        pageable: {
          pageNumber: page,
          pageSize: rowsPerPage,
          sort: transformReservationSortingFiltersCommonToV2ReservationSortingFilter(orderBy, order)
        },
        pmsPropertyId: propertyId ?? "",
        filters: transformFiltersToGraphqlFilters(debouncedFilterParams, transformValueV2ByTheName)
      }
    },
    { skip: isRESTVersion, refetchOnMountOrArgChange: true }
  );

  return isRESTVersion
    ? dataV1
    : transformReservationTableOverviewV2ToCommonReservationOverview(dataV2?.OverviewTable);
};
