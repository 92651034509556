import { api } from "../GetHousekeepingCards.generated";

export const getHousekeepingCardsEnhanced = api.enhanceEndpoints({
  endpoints: {
    GetHousekeepingCards: {
      providesTags: ["HousekeepingCards"]
    }
  }
});

export const { useGetHousekeepingCardsQuery: useGetHousekeepingCardsQueryEnhanced } =
  getHousekeepingCardsEnhanced;
