import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useGetMembershipTypesQuery } from "../graphql/queries/GetMembershipTypes.generated";
import { MemberShipClassEnum } from "../graphql/generated/graphql";

export const useMembershipTypes = (propertyId: string, membershipClass: MemberShipClassEnum) => {
  const { isRESTVersion } = useApiVersion();

  const { data } = useGetMembershipTypesQuery(
    {
      pmsPropertyId: propertyId
    },
    { skip: isRESTVersion || !propertyId }
  );

  return {
    membershipTypes: data?.GetMembershipTypes.filter(
      (item) => item.membershipClass === membershipClass
    )
  };
};
