import { api } from "../CreateTask.generated";

type TasksRelatedToReservationType = "Tasks-Related-To-Reservations";
const getCreateTaskEnhanced = api.enhanceEndpoints({
  endpoints: {
    CreateTask: {
      // @ts-ignore
      invalidatesTags: (result, error, arg) => {
        return [
          ...(arg.request?.links?.pmsReservationIds?.map((reservationId) => ({
            id: reservationId,
            type: "Tasks-Related-To-Reservations" as TasksRelatedToReservationType
          })) ?? []),
          "Tasks",
          { id: arg.request.parentId ?? 0, type: "Tasks" },
          { id: result?.CreateTask.id, type: "Tasks" },
          "HousekeepingCards"
        ];
      }
    }
  }
});

export const { useCreateTaskMutation: useCreateTaskMutationEnhanced } = getCreateTaskEnhanced;
