import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useRemoveFromBlacklistMutationEnhanced } from "src/graphql/mutations/enhanced-mutations/remove-from-blacklist-enhanced";
import { useUpdateBlacklistProfileMutation } from "src/store/endpoints/user-profile.endpoints";

interface RemoveProfileToBlacklistProps {
  profileId: number;
  reservationId?: string;
}

export const useUnBlacklistProfile = () => {
  const { isRESTVersion } = useApiVersion();
  const [removeFromBlacklist, resultRemoveFromBlacklist] = useRemoveFromBlacklistMutationEnhanced();
  const [updateBlacklistProfile, resultV1] = useUpdateBlacklistProfileMutation();

  const removeFromBlacklistV1 = ({ profileId, reservationId }: RemoveProfileToBlacklistProps) => {
    return updateBlacklistProfile({
      profileId: profileId,
      patches: { patches: [{ op: "replace", path: "/blacklisted", value: false }] },
      reservationId
    });
  };

  const removeFromBlacklistV2 = ({ profileId }: RemoveProfileToBlacklistProps) => {
    return removeFromBlacklist({
      userProfileId: profileId
    });
  };

  const functionCall = isRESTVersion ? removeFromBlacklistV1 : removeFromBlacklistV2;
  const result = isRESTVersion ? resultV1 : resultRemoveFromBlacklist;

  return [functionCall, result] as
    | [typeof removeFromBlacklistV1, typeof resultV1]
    | [typeof removeFromBlacklistV2, typeof resultRemoveFromBlacklist];
};
