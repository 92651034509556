import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import { ForecastApi } from "src/api/forecast.api";
import { DeparturesAndCleaningsItem } from "src/domain/departures-and-cleanings-forecast";

export const departuresAndCleaningsForecastAdapter =
  createEntityAdapter<DeparturesAndCleaningsItem>({
    selectId: (model) => model.date
  });

const initialState = departuresAndCleaningsForecastAdapter.getInitialState<{
  status: EntityStateStatus;
}>({
  status: EntityStateStatus.IDLE
});

export const fetchDeparturesAndCleaningsForecast = createAsyncThunk<
  Array<DeparturesAndCleaningsItem>,
  { propertyId: string; startDate: string; endDate: string },
  { state: RootState; rejectValue: { reason: string } }
>("departuresAndCleaningsForecast/fetch", async (arg, thunkAPI) => {
  return ForecastApi.fetchDeparturesAndCleaningsForecast(
    arg.startDate,
    arg.endDate,
    arg.propertyId,
    {
      signal: thunkAPI.signal
    }
  );
});

const slice = createSlice({
  name: "departuresAndCleaningsForecast",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeparturesAndCleaningsForecast.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchDeparturesAndCleaningsForecast.fulfilled, (state, action) => {
        departuresAndCleaningsForecastAdapter.setAll(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchDeparturesAndCleaningsForecast.rejected, (state) => {
        state.status = EntityStateStatus.IDLE;
      });
  }
});

export const { reducer } = slice;

export const departuresAndCleaningsForecastSelectors =
  departuresAndCleaningsForecastAdapter.getSelectors<RootState>(
    (state) => state.departuresAndCleaningsForecast
  );
