import { api } from "../remove-from-blacklist.generated";

const getUserProfileByIdEnhanced = api.enhanceEndpoints({
  endpoints: {
    RemoveFromBlacklist: {
      invalidatesTags: (result, error, arg) => [
        { type: "UserProfile" as any, id: arg.userProfileId },
        "UserProfile"
      ]
    }
  }
});

export const { useRemoveFromBlacklistMutation: useRemoveFromBlacklistMutationEnhanced } =
  getUserProfileByIdEnhanced;
