import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type RefundPaymentMutationVariables = Types.Exact<{
  refundPaymentRequest?: Types.InputMaybe<Types.RefundPaymentRequest>;
}>;

export type RefundPaymentMutation = {
  __typename?: "Mutation";
  RefundPayment?: { __typename?: "Void"; None?: string | null } | null;
};

export const RefundPaymentDocument = `
    mutation refundPayment($refundPaymentRequest: RefundPaymentRequest) {
  RefundPayment(refundPaymentRequest: $refundPaymentRequest) {
    None
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    refundPayment: build.mutation<RefundPaymentMutation, RefundPaymentMutationVariables | void>({
      query: (variables) => ({ document: RefundPaymentDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useRefundPaymentMutation } = injectedRtkApi;
