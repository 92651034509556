import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import { Button, CommunicationChannel, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Grid, Tooltip } from "@mui/material";
import { FC } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import { useIsMobile } from "src/hooks/use-is-mobile";

export const displayNotificationIcons = (type: string) => {
  switch (type) {
    case CommunicationChannel.WHATSAPP:
      return <WhatsAppIcon fontSize="small" />;
    case CommunicationChannel.EMAIL:
      return <MailOutlineIcon fontSize="small" />;
    case CommunicationChannel.SMS:
      return <SmsOutlinedIcon fontSize="small" />;
  }
};

interface DisplayNotificationContactProps {
  channel: string;
  recipient: string;
}

export const DisplayNotificationContact: FC<DisplayNotificationContactProps> = ({
  channel,
  recipient
}) => {
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={isMobile ? 1 : 2} paddingTop={0.5}>
        <Tooltip title={t(`labels__guest__communication__channel__${channel}`)}>
          {displayNotificationIcons(channel) ?? <></>}
        </Tooltip>
      </Grid>
      <Grid item xs={isMobile ? 11 : 10}>
        <ParagraphSmall sx={{ wordBreak: "break-all" }}>{recipient}</ParagraphSmall>
      </Grid>
    </Grid>
  );
};

interface DisplayResendButtonProps {
  handleOpen: () => void;
}

export const DisplayResendButton: FC<DisplayResendButtonProps> = ({ handleOpen }) => {
  const { t } = useTranslationWrapper();
  return (
    <Grid container justifyContent="end">
      <Button
        onClick={handleOpen}
        variant="secondary"
        startIcon={<SyncOutlinedIcon fontSize="medium" />}
      >
        {t("labels__notification__resend")}
      </Button>
    </Grid>
  );
};
