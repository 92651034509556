import { Params, useSelectId } from "src/hooks/use-select-id";

export const useConversationSelectId = () => {
  const { selectedId: selectedConversationId, setSelectId: setSelectedConversationId } =
    useSelectId({
      fieldName: Params.SELECTED_CONVERSATION_ID
    });

  return {
    selectedConversationId: selectedConversationId ?? undefined,
    openDrawer: (conversationId: string, messageId?: string) => {
      if (messageId) {
        setSelectedConversationId(
          conversationId,
          new URLSearchParams({
            [Params.SELECTED_MESSAGE_ID]: messageId
          })
        );
      } else {
        setSelectedConversationId(conversationId);
      }
    },
    closeDrawer: () => {
      setSelectedConversationId(
        undefined,
        new URLSearchParams({
          [Params.SELECTED_MESSAGE_ID]: ""
        })
      );
    }
  };
};
