import { useBackofficeGetUpsellOffersQueryEnhanced } from "../../../../../graphql/queries/enhanced-queries/get-upsell-offers";
import { usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { mapQueryStatusToEntityStateStatus } from "../../../../../graphql/transform/transform-utils";
import { ReservationStatus } from "../../../../../domain/reservation-status";

export const useUpsellUnitGroups = ({
  reservationId,
  reservationStatus
}: {
  reservationStatus?: ReservationStatus;
  reservationId?: string;
}) => {
  const { features } = usePropertyConfig({});

  const { data, status } = useBackofficeGetUpsellOffersQueryEnhanced(
    { pmsReservationId: reservationId ?? "" },
    {
      skip:
        !features?.upsellUnitGroupEnabled ||
        !reservationId ||
        reservationStatus !== ReservationStatus.CONFIRMED
    }
  );

  return {
    items: data?.BackofficeGetUpsellOffers ?? [],
    status: mapQueryStatusToEntityStateStatus(status)
  };
};
