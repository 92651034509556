import { createAsyncThunk } from "@reduxjs/toolkit";
import { GuestJourneyApi } from "../api/guest-journey.api";
import { IdCheckStatus } from "../domain/reservation";

export const updateIdCheck = createAsyncThunk<
  void,
  { reservationId: string; status: IdCheckStatus }
>("identities/updateIdCheck", (arg) => GuestJourneyApi.updateReservationIdCheckStatus(arg));

export const updateIdsCheck = createAsyncThunk<
  void,
  { reservationIds: string[]; status: IdCheckStatus }
>("identities/updateIdChecks", (arg) => GuestJourneyApi.updateReservationIdCheckStatuses(arg));
