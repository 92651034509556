import { FC } from "react";
import { DoorProvider, useDoorProviderConfig } from "@likemagic-tech/sv-magic-library";
import { Reservation } from "../../domain/reservation";
import { KeyAssignWs } from "./key-assign-ws";
import { KeyAssignApi } from "./key-assign-api";

export interface AssignKeyHandlerProps {
  reservation: Reservation;
  tagReaderId: string;
  onClose: () => void;
  isFormInputLabelDisabled: (status: boolean) => void;
  automaticallyCall: boolean;
  hideEncoder: (status: boolean) => void;
  onSuccess?: () => void;
}
export const AssignKeyHandler: FC<AssignKeyHandlerProps> = (props) => {
  const config = useDoorProviderConfig(props.reservation.propertyId);

  //At the moment just DoorProvider.SALTO uses WS approach
  return config?.doorProvider === DoorProvider.SALTO ? (
    <KeyAssignWs {...props} />
  ) : (
    <KeyAssignApi {...props} />
  );
};
