import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { ReservationActionPayload, usePerformReservationAction } from "./use-reservation-actions";
import { AllowedActionEnum } from "src/domain/reservation-table-dto";

interface AddPreferenceInReservationV1Props {
  reservationId: string;
  payload: ReservationActionPayload;
}

const action = AllowedActionEnum.EDIT_PREFERENCES;

export const useAddPreferenceInReservation = () => {
  const { isRESTVersion } = useApiVersion();
  const [performAction, result] = usePerformReservationAction();

  const addPreferenceInReservationV1 = ({
    reservationId,
    payload
  }: AddPreferenceInReservationV1Props) => {
    return performAction({
      reservationId: reservationId,
      action: action,
      payload: payload
    });
  };

  const addPreferenceInReservationV2 = () => {
    return Promise.reject("not yet implemented");
  };

  const functionCall = isRESTVersion ? addPreferenceInReservationV1 : addPreferenceInReservationV2;
  const resultFunction = isRESTVersion ? result : [];

  return [functionCall, resultFunction] as [typeof addPreferenceInReservationV1, typeof result];
};
