import { api } from "../upsell-reservation.generated";

const getUserProfileByIdEnhanced = api.enhanceEndpoints({
  endpoints: {
    BackofficeUpsellReservation: {
      invalidatesTags: ["UpsellReservation"]
    }
  }
});

export const {
  useBackofficeUpsellReservationMutation: useBackofficeUpsellReservationMutationEnhanced
} = getUserProfileByIdEnhanced;
