import { api } from "../GetLeftoverTasks.generated";

const getLeftoverTasksEnhanced = api.enhanceEndpoints({
  endpoints: {
    GetLeftoverTasks: {
      providesTags: ["LeftoverTasks"]
    }
  }
});

export const { useGetLeftoverTasksQuery: useGetLeftoverTasksQueryEnhanced } =
  getLeftoverTasksEnhanced;
