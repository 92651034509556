import { Card, Divider, Grid } from "@mui/material";
import * as React from "react";
import { FC, useCallback, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { HousekeepingCardDetails } from "./housekeeping-card-details";
import { HousekeepingCardServiceType } from "./housekeeping-card-service-type";
import { ParagraphSmall, useApiVersion } from "@likemagic-tech/sv-magic-library";
import { HousekeepingTaskComponent } from "../housekeeping-task-component";
import { HousekeepingCardUnitKeySlider } from "./housekeeping-card-unit-key-slider";
import { HousekeepingCardAction } from "./housekeeping-card-action";
import { HousekeepingCardsTypeEnum } from "../housekeeping-cards-floor";
import {
  MaintenanceSlotStatus,
  Reservation,
  TaskType,
  Unit
} from "../../../graphql-tasks/generated/graphql";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { HousekeepingTaskType } from "../../../features/tasks/domain/task";
import { useUpdateHousekeepingCardMutationEnhanced } from "../../../graphql-tasks/mutations/enhanced-mutations/update-housekeeping-card-enhanced";
import { useSelector } from "../../../store";
import { pickerDateSelector } from "../../../slices/date-picker";
import { isSameDay } from "date-fns";
import { generateUpdateTaskRequest } from "../../../utils/housekeeping";
import { MaintenanceSlotInfo } from "../../../components/maintenance-slot-info/maintenance-slot-info";

export interface HousekeepingCardType {
  id: string;
  reservation?: Reservation;
  unit?: Unit;
}

const useStyles = makeStyles()((theme) => ({
  card: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignSelf: "stretch",
    gap: theme.spacing(2)
  }
}));

interface HousekeepingCardProps {
  housekeepingCard: HousekeepingCardType;
  propertyId: string;
  housekeepingCardsType: HousekeepingCardsTypeEnum;
}

export const HousekeepingCard: FC<HousekeepingCardProps> = ({
  housekeepingCard,
  propertyId,
  housekeepingCardsType
}) => {
  const { t } = useTranslationWrapper();
  const { classes } = useStyles();
  const [updateHousekeepingCardAction] = useUpdateHousekeepingCardMutationEnhanced();
  const date = useSelector(pickerDateSelector);
  const { isRESTVersion } = useApiVersion();

  const futureDate = useMemo(() => {
    return !isSameDay(new Date(date), new Date());
  }, [date]);

  const housekeepingTask = useMemo(() => {
    switch (housekeepingCardsType) {
      case HousekeepingCardsTypeEnum.UNITS:
        return housekeepingCard.unit?.housekeepingTasks?.find(
          (task) =>
            task.type === TaskType.DepartureCleaning || task.type === TaskType.StayoverCleaning
        );
      case HousekeepingCardsTypeEnum.RESERVATIONS:
        return housekeepingCard.reservation?.housekeepingTasks?.find(
          (task) =>
            task.type === TaskType.DepartureCleaning || task.type === TaskType.StayoverCleaning
        );
      default:
        return undefined;
    }
  }, [housekeepingCard, housekeepingCardsType]);

  const additionalTasks = useMemo(() => {
    switch (housekeepingCardsType) {
      case HousekeepingCardsTypeEnum.UNITS:
        return housekeepingCard.unit?.housekeepingTasks?.filter(
          (task) =>
            task.type !== TaskType.DepartureCleaning && task.type !== TaskType.StayoverCleaning
        );
      case HousekeepingCardsTypeEnum.RESERVATIONS:
        return housekeepingCard.reservation?.housekeepingTasks?.filter(
          (task) =>
            task.type !== TaskType.DepartureCleaning && task.type !== TaskType.StayoverCleaning
        );
    }
  }, [housekeepingCard, housekeepingCardsType]);

  const handleUpdateHousekeepingCard = useCallback(
    async (values: HousekeepingTaskType) => {
      updateHousekeepingCardAction({
        pmsPropertyId: propertyId,
        request: generateUpdateTaskRequest(values),
        taskId: values.id
      });
    },
    [updateHousekeepingCardAction, propertyId]
  );

  const ongoingMaintenance = useMemo(() => {
    return housekeepingCard?.unit?.maintenanceSlots?.find(
      (unit) => unit.status === MaintenanceSlotStatus.Ongoing
    );
  }, [housekeepingCard.unit]);

  if (!housekeepingTask) {
    return <></>;
  }
  return (
    <Grid item md={4} xs={12}>
      <Card className={classes.card} variant="outlined">
        <HousekeepingCardDetails
          propertyId={propertyId}
          housekeepingCard={housekeepingCard}
          housekeepingCardsType={housekeepingCardsType}
          futureDate={futureDate}
        />
        <HousekeepingCardServiceType task={housekeepingTask} />
        {!!ongoingMaintenance && isRESTVersion && (
          <MaintenanceSlotInfo
            maintenance={{
              ...ongoingMaintenance,
              unitIds: [housekeepingCard?.unit?.pmsUnitId ?? ""]
            }}
          />
        )}
        {!!additionalTasks?.length && (
          <Grid container justifyContent="space-between" alignItems="baseline" gap={1}>
            <Grid container>
              <ParagraphSmall>{t("labels__additional_tasks")}</ParagraphSmall>
            </Grid>
            {additionalTasks?.map((task) => (
              <Grid container gap={1} key={task.id}>
                <HousekeepingTaskComponent
                  task={task}
                  handleUpdateHousekeepingCard={handleUpdateHousekeepingCard}
                  futureDate={futureDate}
                />
              </Grid>
            ))}
          </Grid>
        )}

        <Divider />
        {housekeepingCardsType === HousekeepingCardsTypeEnum.UNITS && (
          <HousekeepingCardUnitKeySlider unit={housekeepingCard?.unit} propertyId={propertyId} />
        )}
        <HousekeepingCardAction
          housekeepingCard={housekeepingCard}
          tasks={additionalTasks ?? []}
          housekeepingTask={housekeepingTask}
          handleSubmit={handleUpdateHousekeepingCard}
          futureDate={futureDate}
        />
      </Card>
    </Grid>
  );
};
