import { magicApi } from "../magic.api";
import { GetRequestParams, PageableResponse } from "../../types/pageable";
import { FilterObject } from "../../types/filters/filters";
import {
  AccessibleDoor,
  AllowedActionEnum,
  ReservationConditionEnum,
  ReservationTableDto,
  SentNotifications
} from "../../domain/reservation-table-dto";
import { Price } from "../../domain/price";
import { Person } from "../../domain/person";
import { Service } from "../../domain/service";
import { transformFiltersToQueryParams, transformObjToQueryParams } from "../../utils/filters";
import { BackofficeKeyCard, ReservationCheckInOutAuthor } from "src/graphql/generated/graphql";
import { PaymentTypeEnum, RefundStatusEnum } from "src/features/reservations/domain/enums";

export interface TableDtoSortingParams<T> extends GetRequestParams<T> {
  propertyId: string;
}

export type ReservationConditionsResponse = {
  [key in ReservationConditionEnum]: number;
};

interface RateBreakDownTypeItem {
  id: string;
  items: Array<{
    price: Price;
    name: string;
    quantity: number;
    type: string;
  }>;
}
export interface RateBrakeDown {
  id: string;
  breakDownItems: Array<RateBreakDownTypeItem>;
}

interface FolioCharges {
  price: Price;
  id: string;
  name: string;
  quantity: number;
  created: string;
  pmsId: string;
  note: string;
  subName: string;
  reference?: string;
}
export interface FolioPayments {
  id: string;
  cardsDetail: string;
  createdAt: string;
  price: Price;
  refundable: boolean;
  refundStatus?: RefundStatusEnum | null;
  type: PaymentTypeEnum;
}
export interface PendingPayment {
  id: string;
  cardsDetail: string;
  createdAt: string;
  price: Price;
}
export interface FolioPreview {
  id: string;
  number: string;
  charges: Array<FolioCharges>;
  payments: Array<FolioPayments>;
  balance: Price;
  closed: boolean;
  metadata: any;
  createdAt: string;
  pendingPayments: Array<PendingPayment>;
}

export interface ReservationDetailsDTO extends ReservationTableDto {
  travelBuddies: Person[];
  booker: Person;
  services: Service[];
  displayId?: string;
  accessibleDoors: AccessibleDoor[];
  keyCards: BackofficeKeyCard[];
  sentNotifications: SentNotifications[];
  maxCompanions?: number;
  contractualCheckInTime?: string;
  rateBreakDown?: Array<RateBrakeDown>;
  folios: Array<FolioPreview>;
  checkedInBy?: ReservationCheckInOutAuthor;
  checkedOutBy?: ReservationCheckInOutAuthor;
  originalPmsUnitGroupId?: string;
}

export interface UnitGroup {
  description: string;
  name: string;
  unitGroupId: string;
  propertyId: string;
}

interface PerformActionArgs {
  reservationId: string;
  action: AllowedActionEnum;
  payload?: any;
}

const reservationTableEndpoints = magicApi.injectEndpoints({
  endpoints: (builder) => ({
    getReservationTableDTO: builder.query<
      PageableResponse<ReservationTableDto>,
      TableDtoSortingParams<ReservationTableDto>
    >({
      query: (arg) => ({
        url: `/backoffice/${arg.propertyId}/reservations/overview?${transformObjToQueryParams(
          arg
        )}`,
        headers: { method: "GET" }
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.content.map(({ id }) => ({
                type: "Reservations" as const,
                id
              }))
            ]
          : ["Reservations"]
    }),
    getReservationConditionDTO: builder.query<
      ReservationConditionsResponse,
      { propertyId: string; filterParams: Array<FilterObject> }
    >({
      query: (arg) => ({
        url: `/backoffice/${
          arg.propertyId
        }/reservations/condition-counts?${transformFiltersToQueryParams(arg.filterParams)}`,
        headers: { method: "GET" }
      }),
      providesTags: ["Reservations"]
    }),
    getReservationDetails: builder.query<
      ReservationDetailsDTO,
      { reservationId: string; skipSpinner?: boolean }
    >({
      query: (arg) => ({
        url: `/backoffice/reservations/${arg.reservationId}`,
        headers: { method: "GET" }
      }),
      providesTags: (result, error, arg) => [{ id: arg.reservationId, type: "ReservationDetails" }],
      onQueryStarted(
        { reservationId },
        { queryFulfilled, getCacheEntry, dispatch, getState }
      ): Promise<void> | void {
        return queryFulfilled.then(() => {
          if (getCacheEntry().isSuccess) {
            reservationTableEndpoints.util
              .selectInvalidatedBy(getState(), [{ type: "Reservations", id: reservationId }])
              .forEach(({ endpointName, originalArgs, queryCacheKey }) => {
                if (endpointName === "getReservationTableDTO") {
                  dispatch(
                    reservationTableEndpoints.util.updateQueryData(
                      endpointName,
                      originalArgs,
                      (draft) => {
                        const data = getState()?.api?.queries[queryCacheKey]
                          ?.data as PageableResponse<ReservationTableDto>;

                        const index = data.content.findIndex((item) => item.id === reservationId);

                        const newDataContent = [...data.content];
                        newDataContent[index] = getCacheEntry().data as ReservationDetailsDTO;

                        Object.assign(draft, {
                          ...data,
                          content: newDataContent
                        });
                        return;
                      }
                    )
                  );
                }
              });
          }
        });
      }
    }),
    performReservationAction: builder.mutation({
      query: (arg: PerformActionArgs) => {
        return {
          url: `/backoffice/reservations/${arg.reservationId}/perform-action/${arg.action}`,
          headers: { method: "POST" },
          body: arg.payload,
          isVoid: true
        };
      },
      invalidatesTags: (result, error, arg) => [
        { id: arg.reservationId, type: "ReservationDetails" }
      ]
    }),
    setPreferredCleaningDays: builder.mutation({
      query: (arg: { reservationId: string; payload: any }) => {
        return {
          url: `/backoffice/reservations/${arg.reservationId}/set-preferred-cleaning-days`,
          headers: { method: "POST" },
          body: arg.payload,
          isVoid: true
        };
      },
      invalidatesTags: (result, error, arg) => [
        { id: arg.reservationId, type: "ReservationDetails" }
      ]
    })
  }),
  overrideExisting: false
});

export const {
  useGetReservationTableDTOQuery,
  useGetReservationConditionDTOQuery,
  useGetReservationDetailsQuery,
  usePerformReservationActionMutation,
  useSetPreferredCleaningDaysMutation
} = reservationTableEndpoints;
