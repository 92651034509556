import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BatteryCriticalIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.6665 8V16H6.6665L6.6665 8L18.6665 8ZM4.6665 7.33V9H2.6665L2.6665 15H4.6665V16.67C4.6665 17.0227 4.80663 17.361 5.05605 17.6105C5.30548 17.8599 5.64377 18 5.9965 18H21.3365C22.0665 18 22.6665 17.4 22.6665 16.67V7.33C22.6665 6.97726 22.5264 6.63897 22.277 6.38955C22.0275 6.14012 21.6892 6 21.3365 6L5.9965 6C5.2665 6 4.6665 6.6 4.6665 7.33Z" />
    </svg>
  </SvgIcon>
);
