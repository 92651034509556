import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type CardOnfilePaymentMutationVariables = Types.Exact<{
  cardOnFilePaymentRequest: Types.CardOnFilePaymentRequest;
}>;

export type CardOnfilePaymentMutation = {
  __typename?: "Mutation";
  CardOnFilePayment?: boolean | null;
};

export const CardOnfilePaymentDocument = `
    mutation CardOnfilePayment($cardOnFilePaymentRequest: CardOnFilePaymentRequest!) {
  CardOnFilePayment(cardOnFilePaymentRequest: $cardOnFilePaymentRequest)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    CardOnfilePayment: build.mutation<
      CardOnfilePaymentMutation,
      CardOnfilePaymentMutationVariables
    >({
      query: (variables) => ({ document: CardOnfilePaymentDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useCardOnfilePaymentMutation } = injectedRtkApi;
