import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type BackofficeAddMembershipToProfileMutationVariables = Types.Exact<{
  pmsProfileId: Types.Scalars["String"]["input"];
  pmsReservationId: Types.Scalars["String"]["input"];
  membershipAccount: Types.MembershipAccountInput;
}>;

export type BackofficeAddMembershipToProfileMutation = {
  __typename?: "Mutation";
  BackofficeAddMembershipToProfile?: boolean | null;
};

export const BackofficeAddMembershipToProfileDocument = `
    mutation BackofficeAddMembershipToProfile($pmsProfileId: String!, $pmsReservationId: String!, $membershipAccount: MembershipAccountInput!) {
  BackofficeAddMembershipToProfile(
    pmsReservationId: $pmsReservationId
    pmsProfileId: $pmsProfileId
    membershipAccount: $membershipAccount
  )
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    BackofficeAddMembershipToProfile: build.mutation<
      BackofficeAddMembershipToProfileMutation,
      BackofficeAddMembershipToProfileMutationVariables
    >({
      query: (variables) => ({ document: BackofficeAddMembershipToProfileDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useBackofficeAddMembershipToProfileMutation } = injectedRtkApi;
