import { Backdrop, CircularProgress } from "@mui/material";
import React, { FC } from "react";

interface DefaultLoaderProps {
  visible: boolean;
}

export const DefaultLoader: FC<DefaultLoaderProps> = ({ visible }) => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 10000 }} open={visible}>
      <CircularProgress size={100} />
    </Backdrop>
  );
};
