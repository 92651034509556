import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export const useTranslationWrapper = (namespace: string = "common") => {
  const { t } = useTranslation(namespace);
  const tApplied = useCallback(
    (key: string, additionalInterpolationMap?: Record<string, string>) =>
      t(key, { ...additionalInterpolationMap }),
    [t]
  );

  //i18n.exists didn't work this is workaround
  const exists = (key: string) => t(key) !== key;

  return { t: tApplied, exists };
};
