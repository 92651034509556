import { DefaultConfirmationIcon, Paragraph } from "@likemagic-tech/sv-magic-library";
import { Grid, useTheme } from "@mui/material";
import * as React from "react";

export const HousekeepingConfirmation = ({ label }: { label: string }) => {
  const { spacing } = useTheme();
  return (
    <Grid container flexDirection="column" alignItems="center" px={spacing(8)}>
      <DefaultConfirmationIcon />
      <Paragraph textAlign="center">{label}</Paragraph>
    </Grid>
  );
};
