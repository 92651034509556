import { useDispatch, useSelector } from "../../../../../../store";
import {
  selectSelectedSecondScreenId,
  setSelectedSecondScreenId
} from "../../../../../../slices/second-screen.slice";
import { useCallback } from "react";

export const useSecondScreenId = () => {
  const dispatch = useDispatch();

  const secondScreenId = useSelector(selectSelectedSecondScreenId);

  const setSecondScreenId = useCallback(
    (secondScreenId?: string) => dispatch(setSelectedSecondScreenId(secondScreenId)),
    [dispatch]
  );
  return { secondScreenId, setSecondScreenId };
};
