import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetAllPropertiesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAllPropertiesQuery = {
  __typename?: "Query";
  GetAllProperties?: Array<{
    __typename?: "Property";
    pmsId: string;
    name?: string | null;
    defaultCurrency?: string | null;
    timezone?: string | null;
  } | null> | null;
};

export const GetAllPropertiesDocument = `
    query GetAllProperties {
  GetAllProperties {
    pmsId
    name
    defaultCurrency
    timezone
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetAllProperties: build.query<GetAllPropertiesQuery, GetAllPropertiesQueryVariables | void>({
      query: (variables) => ({ document: GetAllPropertiesDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetAllPropertiesQuery, useLazyGetAllPropertiesQuery } = injectedRtkApi;
