import i18next from "i18next";
import { useGetUnitGroupsQueryV1 } from "src/store/endpoints/unit-group.endpoints";
import { twoLetterLanguageCode } from "../components/language-selector/language-selector";
import { UnitGroup } from "../domain/unit-group";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useMemo } from "react";
import { useGetUnitGroupsQuery } from "src/graphql/queries/GetUnitGroups.generated";
import { transformUnitGroupsV2ToCommonUnitGroups } from "src/graphql/transform/transform-unit-groups";

export const useUnitGroupById = (propertyId: string, unitGroupId?: string): UnitGroup => {
  const allUnitGroups = useUnitGroups(propertyId);
  return (
    allUnitGroups?.find(
      (ug) =>
        ug.unitGroupId === unitGroupId && ug.language === twoLetterLanguageCode(i18next.language)
    ) ?? ({} as UnitGroup)
  );
};

export const useUnitGroups = (propertyId: string): UnitGroup[] => {
  const { isRESTVersion, isGraphQlVersion } = useApiVersion();
  const { data: unitGroups } = useGetUnitGroupsQueryV1(undefined, {
    skip: isGraphQlVersion,
    refetchOnMountOrArgChange: false
  });

  const { data: dataV2 } = useGetUnitGroupsQuery(
    {
      filter: {
        pmsPropertyId: propertyId
      }
    },
    { skip: isRESTVersion, refetchOnMountOrArgChange: false }
  );

  const allUnitGroupsV1 = unitGroups?.filter(
    (ug) => ug.propertyId === propertyId && ug.language === twoLetterLanguageCode(i18next.language)
  );

  return useMemo(
    () =>
      isRESTVersion
        ? allUnitGroupsV1 ?? []
        : dataV2?.GetUnitGroups?.map(transformUnitGroupsV2ToCommonUnitGroups) ?? [],
    [allUnitGroupsV1, isRESTVersion, dataV2?.GetUnitGroups]
  );
};
