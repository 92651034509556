import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";

export type LoaderSliceState = {
  isRootSuspenseShowed: boolean;
  isForceHideSpinners: boolean;
};

const initialState: LoaderSliceState = {
  isRootSuspenseShowed: false,
  isForceHideSpinners: true
};

export const loaderSlice = createSlice({
  name: "loaderSlice",
  initialState,
  reducers: {
    showRootSuspense: (state) => {
      state.isRootSuspenseShowed = true;
    },
    hideRootSuspense: (state) => {
      state.isRootSuspenseShowed = false;
    },
    forceHideSpinners: (state) => {
      state.isForceHideSpinners = true;
    },
    doNotForceHideSpinners: (state) => {
      state.isForceHideSpinners = false;
    }
  }
});

export const { hideRootSuspense, showRootSuspense, forceHideSpinners, doNotForceHideSpinners } =
  loaderSlice.actions;

export const selectLoaderSlice = (state: RootState) => state[loaderSlice.name];
