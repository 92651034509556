import { useDispatch, useSelector } from "../../store";
import { fetchBreakfast, selectBreakfast } from "../../slices/dashboard-breakfast";
import { useCallback, useEffect } from "react";
import { useApiVersion, usePropertyConfig } from "@likemagic-tech/sv-magic-library";

export const useFetchBreakfast = ({ propertyId, date }: { propertyId?: string; date: string }) => {
  const breakfastReports = useSelector(selectBreakfast);
  const dispatch = useDispatch();
  const { features } = usePropertyConfig({
    propertyId
  });
  const { isRESTVersion } = useApiVersion();

  const fetchBreakfastAction = useCallback(
    ({ date, propertyId }: { date: string; propertyId: string }) => {
      if (isRESTVersion) {
        return dispatch(
          fetchBreakfast({
            date,
            propertyId
          })
        );
      } else {
        //TODO implement V2 endpoint
      }
    },
    [dispatch, isRESTVersion]
  );

  useEffect(() => {
    if (features?.breakfastViewEnabled && isRESTVersion) {
      const breakfastPromise = fetchBreakfastAction({
        date,
        propertyId: propertyId ?? ""
      });

      return () => breakfastPromise?.abort();
    }
  }, [features?.breakfastViewEnabled, isRESTVersion, date, propertyId, fetchBreakfastAction]);

  return { breakfastReports, fetchBreakfastAction };
};
