import { useContext, useEffect } from "react";
import { useDispatch } from "../../store";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { conversationSubscribeApi } from "../../graphql-messaging/subscribtion/conversation-subscribe";
import { Pageable } from "../../graphql/generated/graphql";

export const useSubscribeToMessages = ({
  conversationId
}: {
  pageable: Pageable;
  conversationId?: string | null;
}) => {
  const dispatch = useDispatch();
  const { tenantId, apiKey, keycloak } = useContext(TenantContext);

  const authToken = keycloak.token;
  useEffect(() => {
    if (apiKey && tenantId && conversationId) {
      const promise = dispatch(
        conversationSubscribeApi.endpoints.SubscribeToMessages.initiate({
          tenantId,
          apiKey,
          conversationId,
          authorization: authToken
        })
      );
      return () => {
        dispatch(
          conversationSubscribeApi.internalActions.removeQueryResult({
            // @ts-ignore
            queryCacheKey: promise.queryCacheKey
          })
        );
      };
    }
  }, [dispatch, tenantId, apiKey, conversationId, authToken]);
};
