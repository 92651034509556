import { FC } from "react";
import { SvgIcon } from "@mui/material";

export const PaymentPreAuthorization: FC = () => (
  <SvgIcon
    sx={{ color: "#FFFFFF" }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="Accounting-Bills--Streamline-Ultimate"
  >
    <desc>{"Accounting Bills Streamline Icon: https://streamlinehq.com"}</desc>
    <path
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M18.75 4.5H2.25c-0.82843 0 -1.5 0.67157 -1.5 1.5v9c0 0.8284 0.67157 1.5 1.5 1.5h16.5c0.8284 0 1.5 -0.6716 1.5 -1.5V6c0 -0.82843 -0.6716 -1.5 -1.5 -1.5Z"
      strokeWidth={1.5}
    />
    <path
      stroke="#000000"
      d="M4.125 8.25c-0.20711 0 -0.375 -0.16789 -0.375 -0.375s0.16789 -0.375 0.375 -0.375"
      strokeWidth={1.5}
    />
    <path
      stroke="#000000"
      d="M4.125 8.25c0.20711 0 0.375 -0.16789 0.375 -0.375S4.33211 7.5 4.125 7.5"
      strokeWidth={1.5}
    />
    <path
      stroke="#000000"
      d="M16.875 13.5c-0.2071 0 -0.375 -0.1679 -0.375 -0.375s0.1679 -0.375 0.375 -0.375"
      strokeWidth={1.5}
    />
    <path
      stroke="#000000"
      d="M16.875 13.5c0.2071 0 0.375 -0.1679 0.375 -0.375s-0.1679 -0.375 -0.375 -0.375"
      strokeWidth={1.5}
    />
    <path
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.5 13.5c1.6569 0 3 -1.3431 3 -3 0 -1.65685 -1.3431 -3 -3 -3 -1.65685 0 -3 1.34315 -3 3 0 1.6569 1.34315 3 3 3Z"
      strokeWidth={1.5}
    />
    <path
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M23.25 9v9c0 0.3978 -0.158 0.7794 -0.4393 1.0607s-0.6629 0.4393 -1.0607 0.4393H5.25"
      strokeWidth={1.5}
    />
  </SvgIcon>
);
