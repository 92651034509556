import { FC, useContext } from "react";
import { TenantContext, THEMES } from "@likemagic-tech/sv-magic-library";
import { StaykooookLoader } from "./staykooook-loader";
import { DefaultLoader } from "./default-loader";

interface LoaderProps {
  visible: boolean;
}

export const Loader: FC<LoaderProps> = ({ visible }) => {
  const { theme } = useContext(TenantContext);

  return theme === THEMES.STAYKOOOK_THEME ? (
    <StaykooookLoader visible={visible} />
  ) : (
    <DefaultLoader visible={visible} />
  );
};
