import {
  BREAKFAST_OPERATOR_ROLE,
  FeatureFlags,
  HOST_ROLE,
  HOUSEKEEPER_ROLE
} from "@likemagic-tech/sv-magic-library";
import ContactsIcon from "@mui/icons-material/Contacts";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import { ReactElement } from "react";
import { Groups, KingBed, StorefrontRounded, Task } from "@mui/icons-material";
import {
  generateArrivalURLSearchParams,
  generateDefaultReservationTableURLSearchParams,
  generateDepartureURLSearchParams,
  generateInHouseURLSearchParams
} from "src/components/table/hooks/use-search-filter-params";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import HomeIcon from "@mui/icons-material/Home";
import { generateDefaultTaskTableURLSearchParams } from "src/features/tasks/task-query-params";
import InboxIcon from "@mui/icons-material/Inbox";
import { generateDefaulMessagingTableURLSearchParams } from "src/messaging/domain/conversation-constants";

export interface SectionInterface {
  title: string;
  path: string;
  params?: URLSearchParams;
  icon: ReactElement;
  roles: string[];
  isCollapsable?: boolean;
  collapseOptions?: CollapseOptionsInterface;
  badgeNumber?: number;
  disabledDueToMultiProperty?: boolean;
}

export interface CollapseOptionsInterface {
  key: string;
  items: SectionInterface[];
}

export const sectionsOption: ({
  t,
  features,
  isRESTVersion,
  multiPropertySelected
}: {
  t: (s: string) => string;
  features?: FeatureFlags;
  isRESTVersion: boolean;
  multiPropertySelected: boolean;
}) => Array<{
  key: string;
  items: Array<SectionInterface>;
  isCollapsable?: boolean;
  collapseOptions?: Array<SectionInterface>;
}> = ({ t, features, isRESTVersion, multiPropertySelected }) => {
  const reservationsTableItem: Array<SectionInterface> = features?.reservationTableEnabled
    ? [
        {
          title: t("title__reservations"),
          path: `/reservations`,
          params: generateDefaultReservationTableURLSearchParams(),
          icon: <KingBed fontSize="small" />,
          roles: [HOST_ROLE],
          isCollapsable: true,
          collapseOptions: accordionPagesForReservationOptions(t, features)
        }
      ]
    : [];

  const breakfastItem = features?.breakfastViewEnabled
    ? [
        {
          title: t("title__breakfast"),
          path: "/breakfast",
          icon: <LocalDiningIcon fontSize="small" />,
          roles: [HOST_ROLE, BREAKFAST_OPERATOR_ROLE]
        }
      ]
    : [];
  const boxesItem: Array<SectionInterface> =
    features?.boxEnabled && isRESTVersion
      ? [
          {
            title: t("title__boxes"),
            path: "/boxes",
            icon: <StorefrontRounded fontSize="small" />,
            roles: [HOST_ROLE, HOUSEKEEPER_ROLE]
          }
        ]
      : [];

  const identityItem: Array<SectionInterface> = isRESTVersion
    ? [
        {
          title: t("title__identity_check"),
          path: "/identities",
          icon: <ContactsIcon fontSize="small" />,
          roles: [HOST_ROLE]
        }
      ]
    : [];

  const overviewItem: Array<SectionInterface> = isRESTVersion
    ? [
        {
          title: t("title__overview"),
          path: "/",
          icon: <ViewQuiltIcon fontSize="small" />,
          roles: [HOST_ROLE]
        }
      ]
    : [];

  const profiles: Array<SectionInterface> = features?.guestListEnabled
    ? [
        {
          title: t("title__profiles"),
          path: "/profiles",
          icon: <Groups fontSize="small" />,
          roles: [HOST_ROLE]
        }
      ]
    : [];

  //housekeepingV2Enabled
  const housekeepingV2Beta: Array<SectionInterface> = features?.housekeepingV2Enabled
    ? [
        {
          title: t("title__housekeeping_v2_beta"),
          path: "/housekeeping",
          icon: <LocalLaundryServiceIcon fontSize="small" />,
          roles: [HOUSEKEEPER_ROLE]
        }
      ]
    : [];

  return [
    {
      key: "General",
      items: [
        ...overviewItem,
        ...reservationsTableItem,
        {
          title: t("title__messaging"),
          path: "/messaging",
          params: generateDefaulMessagingTableURLSearchParams(),
          icon: <InboxIcon fontSize="small" />,
          roles: [HOST_ROLE],
          disabledDueToMultiProperty: multiPropertySelected
        } as SectionInterface,
        ...breakfastItem,
        {
          title: t("title__housekeeping"),
          path: "/dirty-units",
          icon: <LocalLaundryServiceIcon fontSize="small" />,
          roles: [HOUSEKEEPER_ROLE]
        },
        ...housekeepingV2Beta,
        {
          title: t("title__units"),
          path: "/units",
          icon: <LocalHotelIcon fontSize="small" />,
          roles: [HOST_ROLE, HOUSEKEEPER_ROLE]
        } as SectionInterface,
        {
          title: t("title__tasks"),
          path: "/tasks",
          params: generateDefaultTaskTableURLSearchParams(),
          icon: <Task fontSize="small" />,
          roles: [HOST_ROLE, HOUSEKEEPER_ROLE]
        } as SectionInterface,
        ...identityItem,
        ...boxesItem,
        ...profiles
      ] as Array<SectionInterface>
    }
  ];
};

export const accordionPagesForReservationOptions = (
  t: (s: string) => string,
  features: FeatureFlags
): CollapseOptionsInterface => {
  const arrivalsItem: Array<SectionInterface> = [
    {
      title: t("title__arrivals"),
      path: "/arrival",
      params: generateArrivalURLSearchParams(features?.arrivalsWithoutInHouseEnabled),
      icon: <FlightLandIcon fontSize="small" />,
      roles: [HOST_ROLE]
    }
  ];

  const departuresItem: Array<SectionInterface> = [
    {
      title: t("title__departures"),
      path: "/departure",
      params: generateDepartureURLSearchParams(),
      icon: <FlightTakeoffIcon fontSize="small" />,
      roles: [HOST_ROLE]
    }
  ];

  const inHotelItem: Array<SectionInterface> = [
    {
      title: t("title__in_hotel"),
      path: "/in-hotel",
      params: generateInHouseURLSearchParams(),
      icon: <HomeIcon fontSize="small" />,
      roles: [HOST_ROLE]
    }
  ];

  return {
    key: "ReservationAccordion",
    items: [...arrivalsItem, ...departuresItem, ...inHotelItem]
  };
};
