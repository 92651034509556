import { useApiVersion, useAuth, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { useMemo } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useProperty } from "src/hooks/use-property";
import { Box } from "@mui/material";
import { SidebarItems } from "./sidebar-items";
import { sectionsOption } from "./options";
import { useSelector } from "react-redux";
import { selectAllUnreadMessageCount } from "../../../slices/unread-message-count.slice";

const SidebarSections = () => {
  const { t } = useTranslationWrapper();
  const { isRESTVersion } = useApiVersion();
  const { selectedProperty, selectedPropertyIds } = useProperty();
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });

  const messageBadgeCount = useSelector(selectAllUnreadMessageCount);

  const sections = useMemo(
    () =>
      sectionsOption({
        t,
        features,
        isRESTVersion,
        multiPropertySelected: selectedPropertyIds.length > 1
      }),
    [t, features, isRESTVersion, selectedPropertyIds.length]
  );

  const { hasRole } = useAuth();

  return (
    <Box sx={{ p: 1 }}>
      {sections
        .flatMap((section) => section.items)
        .filter((items) => items?.roles?.some((role) => hasRole(role)))
        .map((item) => (
          <SidebarItems
            key={item.title}
            title={item.title}
            path={item.path}
            icon={item.icon}
            params={item.params}
            isCollapsable={!!item.isCollapsable}
            collapseOptions={item.collapseOptions}
            availableDueToMultiProperty={
              item.disabledDueToMultiProperty || selectedPropertyIds.length === 1
            }
            disabledDueToMultiPropertyLabel={t("labels__disabled_due_to_multi_property_label")}
            badgeNumber={item.path === "/messaging" ? messageBadgeCount : undefined}
          />
        ))}
    </Box>
  );
};

export default SidebarSections;
