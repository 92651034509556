import * as React from "react";
import { FC, useMemo } from "react";
import { BaseTableFiltersProps, FilterObjectType, serializeIdOfFilter } from "./table-filters";
import { FilterOperator } from "../../../types/filters/filters";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { SearchField } from "../../search-field/search-field";
import { Box } from "@mui/material";
import { useIsMobile } from "../../../hooks/use-is-mobile";

interface FreeTextFilterProps extends BaseTableFiltersProps {
  disabled?: boolean;
}

export const FREE_TEXT_SEARCH = "freeText";

export const FreeTextFilter: FC<FreeTextFilterProps> = ({
  filterMap,
  onFilterMapChange,
  disabled
}) => {
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();

  const freeTextSearchValue = useMemo(() => {
    return (
      filterMap[
        serializeIdOfFilter({
          name: FREE_TEXT_SEARCH,
          type: FilterObjectType.FREE_TEXT_SEARCH,
          operator: FilterOperator.Equality
        })
      ]?.value || ""
    );
  }, [filterMap]);

  const onFreeTextSearchChange = (value: string) => {
    const filterObject = {
      name: FREE_TEXT_SEARCH,
      type: FilterObjectType.FREE_TEXT_SEARCH,
      operator: FilterOperator.Equality,
      value
    };

    onFilterMapChange({
      ...filterMap,
      [serializeIdOfFilter(filterObject)]: filterObject
    });
  };
  return (
    <Box sx={{ minWidth: "280px", width: isMobile ? "100%" : "auto" }}>
      <SearchField
        disabled={disabled}
        value={freeTextSearchValue}
        onChange={onFreeTextSearchChange}
        placeholder={t("labels__search")}
      />
    </Box>
  );
};
