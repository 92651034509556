import { AdditionalServicesCodes } from "src/domain/additional-services-codes";
import BakeryDiningOutlinedIcon from "@mui/icons-material/BakeryDiningOutlined";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import { useTheme } from "@mui/material/styles";
import { FC } from "react";
import { EarlyCheckin } from "src/icons/EarlyCheckin";
import { LateCheckOut } from "src/icons/LateCheckOut";
import { BabyBed } from "src/icons/BabyBed";
import { LuggageLocker } from "src/icons/LuggageLocker";
import { ServiceIcon } from "src/icons/ServiceIcon";

interface DisplayServiceIconProps {
  code: string;
  fontSize: "inherit" | "large" | "medium" | "small";
}

export const DisplayServiceIcon: FC<DisplayServiceIconProps> = ({ code, fontSize }) => {
  const theme = useTheme();
  switch (code) {
    case AdditionalServicesCodes.DONATE_BREAKFAST:
    case AdditionalServicesCodes.FREE_BREAKFAST:
      return (
        <BakeryDiningOutlinedIcon
          fontSize={fontSize}
          sx={{ color: theme.palette.secondary.main }}
        />
      );
    case AdditionalServicesCodes.PAID_CLEANING:
    case AdditionalServicesCodes.FREE_CLEANING:
      return (
        <CleaningServicesOutlinedIcon
          fontSize={fontSize}
          sx={{ color: theme.palette.secondary.main }}
        />
      );
    case AdditionalServicesCodes.EARLY_CHECKIN:
      return <EarlyCheckin sx={{ color: theme.palette.secondary.main }} fontSize={fontSize} />;
    case AdditionalServicesCodes.LATE_CHECKOUT:
      return <LateCheckOut sx={{ color: theme.palette.secondary.main }} fontSize={fontSize} />;
    case AdditionalServicesCodes.BABY_BED:
      return <BabyBed sx={{ color: theme.palette.secondary.main }} fontSize={fontSize} />;
    case AdditionalServicesCodes.STORAGE:
      return <LuggageLocker sx={{ color: theme.palette.secondary.main }} fontSize={fontSize} />;
    default:
      return <ServiceIcon sx={{ color: theme.palette.secondary.main }} fontSize={fontSize} />;
  }
};
