export enum ReservationTabs {
  OVERVIEW = "OVERVIEW",
  INSIGHTS = "INSIGHTS",
  COMMENTS = "COMMENTS",
  SERVICES = "SERVICES",
  NOTIFICATIONS = "NOTIFICATIONS",
  TASKS = "TASKS",
  BILLING = "BILLING",
  KEYS = "KEYS",
  RESTAURANT = "RESTAURANT",
  UPSELL_UNIT_GROUP = "UPSELL_UNIT_GROUP"
}

export const TabsList = [
  ReservationTabs.OVERVIEW,
  ReservationTabs.INSIGHTS,
  ReservationTabs.COMMENTS,
  ReservationTabs.SERVICES,
  ReservationTabs.UPSELL_UNIT_GROUP,
  ReservationTabs.KEYS,
  ReservationTabs.NOTIFICATIONS,
  ReservationTabs.BILLING,
  ReservationTabs.RESTAURANT
];

export const labelsList = [
  "labels__reservation__details__overview__guests__actor",
  "labels__reservation__details__overview__guests__contact",
  "labels__reservation__details__overview__guests__channel",
  "labels__reservation__details__overview__guests__language",
  "labels__reservation__details__overview__guests__preferences"
];
