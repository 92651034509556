import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type SyncDoorAccessMutationVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
  status: Types.DoorAccessState;
}>;

export type SyncDoorAccessMutation = { __typename?: "Mutation"; SyncDoorAccess?: boolean | null };

export const SyncDoorAccessDocument = `
    mutation SyncDoorAccess($pmsReservationId: String!, $status: DoorAccessState!) {
  SyncDoorAccess(pmsReservationId: $pmsReservationId, status: $status)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    SyncDoorAccess: build.mutation<SyncDoorAccessMutation, SyncDoorAccessMutationVariables>({
      query: (variables) => ({ document: SyncDoorAccessDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useSyncDoorAccessMutation } = injectedRtkApi;
