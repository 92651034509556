import { ChangeEvent, FC } from "react";
import { Input } from "@likemagic-tech/sv-magic-library";

interface AmountPickerProps {
  value: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  label: string;
  id?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
}
export const AmountInput: FC<AmountPickerProps> = ({
  value,
  onChange,
  error,
  label,
  id,
  name,
  required,
  disabled
}) => {
  return (
    <Input
      required={required}
      variant="outlined"
      type="number"
      label={label}
      onChange={onChange}
      inputProps={{ step: "0.01", lang: navigator.language }}
      value={value}
      error={error}
      id={id}
      name={name}
      disabled={disabled}
    />
  );
};
