import React, { FC, useCallback, useMemo, useState } from "react";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import { PaymentFoliosPreview } from "../../../payment/payment-folios-preview";
import { Box, Grid } from "@mui/material";
import { Heading4, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import {
  ShareToDevice,
  ShareToDeviceType
} from "../../../../../components/share-to-device/share-to-device";
import { PaymentFoliosEmptyPreview } from "src/features/reservations/payment/payment-folios-empty-preview";
import { getOkModalArg } from "../../../../../utils/modal-util";
import { PaymentFolioSelection } from "../../../payment/payment-folio-selection";
import { Notification } from "src/components/notification";
import { SplitButton } from "../../../../../components/split-button/split-button";
import { SendInvoiceModal } from "./send-invoice-modal";
import { openBanner } from "../../../../../slices/banner.slice";
import { useDispatch, useSelector } from "../../../../../store";
import { SendLinkModal } from "../../../payment/payment-component-handlers/send-link-modal";
import { paymentLinkSelector } from "../../../../../slices/send-payment-link-modal.slice";
import { TerminalPaymentModal } from "src/features/reservations/payment/payment-component-handlers/terminal-payment-modal";
import { useTerminalPayment } from "src/features/reservations/payment/hooks/use-terminal-payment";

enum FoliosSplitButtonOptions {
  RATE_BREAKDOWN = "RATE_BREAKDOWN",
  SEND_INVOICE = "SEND_INVOICE"
}

interface ReservationDetailsFoliosProps {
  reservation: ReservationDetailsDTO;
}

export const ReservationDetailsFolios: FC<ReservationDetailsFoliosProps> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const { open } = useGlobalModal();
  const [openSendInvoiceModal, setOpenSendInvoiceModal] = useState(false);
  const dispatch = useDispatch();
  const paymentLink = useSelector(paymentLinkSelector);
  const { openModal: terminalPaymentModal } = useTerminalPayment();

  const splitButtonOptions = useMemo(
    () =>
      [
        { id: FoliosSplitButtonOptions.SEND_INVOICE, label: t("buttons__view_send_invoice") },
        { id: FoliosSplitButtonOptions.RATE_BREAKDOWN, label: t("buttons__view_rate_breakdown") }
      ].filter(
        (item) => item.id !== FoliosSplitButtonOptions.SEND_INVOICE || reservation.folios.length
      ),
    [reservation.folios.length, t]
  );

  const showRateBreakdown = useCallback(() => {
    open(
      getOkModalArg(
        t("subtitle__rate_breakdown"),
        //375 is width for mobile
        <Box minWidth={{ xs: "auto", sm: 450 }}>
          <PaymentFolioSelection reservation={reservation} />
        </Box>,
        t("buttons__close")
      )
    );
  }, [open, t, reservation]);

  const showSendInvoice = useCallback(() => {
    setOpenSendInvoiceModal(true);
  }, []);

  const onSplitButtonClick = useCallback(
    (id: string) => {
      switch (id) {
        case FoliosSplitButtonOptions.SEND_INVOICE:
          showSendInvoice();
          break;
        case FoliosSplitButtonOptions.RATE_BREAKDOWN:
          showRateBreakdown();
          break;
        default:
          console.warn("Invalid selection.");
      }
    },
    [showRateBreakdown, showSendInvoice]
  );

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" my={2} pl={3} pr={1}>
        <Box display="flex" alignItems="center" gap={2}>
          <Heading4>{t("labels__billing_management")}</Heading4>
          <ShareToDevice reservationId={reservation.id} type={ShareToDeviceType.FOLIOS} />
        </Box>

        <Grid item>
          <Box>
            <SplitButton onClick={onSplitButtonClick} options={splitButtonOptions} />
            {openSendInvoiceModal && (
              <SendInvoiceModal
                folios={reservation.folios}
                primaryGuest={reservation.primaryGuest}
                isOpen={openSendInvoiceModal}
                closeModal={() => {
                  setOpenSendInvoiceModal(false);
                }}
                reservationId={reservation.id}
                onSuccess={() => {
                  dispatch(
                    openBanner({
                      type: "success",
                      title: t("labels__action_successfully_performed")
                    })
                  );
                  setOpenSendInvoiceModal(false);
                }}
              />
            )}
          </Box>
          {/* TODO bring back onece supported */}
          {/* <Box>
          <FormControlLabel
            control={
              <Checkbox
                id="no-posts-allowed"
                name="no-posts-allowed"
                onChange={() => {}}
                color="primary"
                checked={false}
              />
            }
            label={t("labels__no_posts_allowed")}
          />
        </Box>*/}
        </Grid>
      </Grid>
      <PaymentFoliosPreview reservation={reservation} propertyId={reservation.propertyId} />
      <Box
        sx={{
          mt: 2
        }}
      >
        <Box
          sx={{
            mb: 1
          }}
        >
          <Notification type="info" title={t("labels__new_folio_info")} fullWidth />
        </Box>
        <PaymentFoliosEmptyPreview
          reservation={reservation}
          propertyId={reservation.propertyId}
          nextStep={() => {}}
        />
      </Box>
      {!!paymentLink && <SendLinkModal reservation={reservation} />}
      {terminalPaymentModal && <TerminalPaymentModal />}
    </>
  );
};
