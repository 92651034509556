import { Box, Grid, Paper } from "@mui/material";
import { FC, useState } from "react";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  EmptyState,
  EmptyStateSize,
  ParagraphBold,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { EditReservationCommentModal } from "./edit-reservation-comment/edit-reservation-comment-modal";
import { Comments } from "../../../components/card-comments/card-comments";
import { numberOfComments } from "../overview/reservation-details-overview";
import { ReservationDetailsDTO } from "../../../../../store/endpoints/reservation-table.endpoints";

interface ReservationDetailsCommentsProps {
  reservation: ReservationDetailsDTO;
  isChangeCommentAllowed: boolean;
}

const ReservationDetailsComments: FC<ReservationDetailsCommentsProps> = ({
  reservation,
  isChangeCommentAllowed
}) => {
  const theme = useTheme();
  const comments = reservation.notes;

  const { t } = useTranslationWrapper();
  const [openModal, setOpenModal] = useState(false);
  const numberComments = numberOfComments(comments);

  const commentsToShowObject = [
    {
      comment: comments["guestComment"].join("\r\n"),
      label: "labels__notes__guest__comments"
    },
    {
      comment: comments["bookerComment"].join("\r\n"),
      label: "labels__notes__booker__comment"
    },
    {
      comment: comments["comment"].join("\r\n"),
      label: "labels__notes__reservation__comment"
    },
    {
      comment: comments["bookingComment"].join("\r\n"),
      label: "labels__notes__booking__comment"
    }
  ];

  const handleOpen = () => {
    setOpenModal(true);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid
        container
        rowSpacing={theme.spacing(3)}
        p={isChangeCommentAllowed ? 2 : 1.5}
        direction="column"
      >
        {isChangeCommentAllowed && (
          <Grid item xs display="flex" justifyContent="end">
            <Button variant="primary" onClick={handleOpen}>
              <Grid display="flex" direction="row" alignItems="center">
                <AddIcon
                  fontSize="small"
                  sx={{
                    color: theme.palette.background.paper,
                    marginRight: theme.spacing(2)
                  }}
                />

                {t("labels__tabs__comments__add")}
              </Grid>
            </Button>
          </Grid>
        )}
        <EditReservationCommentModal
          reservation={reservation}
          isOpen={openModal}
          onClose={handleClose}
        />
        {numberComments > 0 && (
          <Grid item>
            {commentsToShowObject.map(
              (comment: Comments) =>
                comment.comment && (
                  <Grid item xs={12} key={comment.label} paddingBottom={2}>
                    <Paper
                      sx={{
                        background: theme.palette.background.paper,
                        borderColor: theme.palette.background.paper,
                        borderRadius: theme.spacing(1.5)
                      }}
                      variant="outlined"
                      elevation={0}
                    >
                      <Grid p={2} container direction="column">
                        <Grid item>
                          <ParagraphBold>{t(comment.label)}</ParagraphBold>
                        </Grid>
                        <Grid item marginTop={1}>
                          <ParagraphSmall sx={{ whiteSpace: "pre-wrap" }}>
                            {comment.comment}
                          </ParagraphSmall>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
            )}
          </Grid>
        )}
      </Grid>
      {numberComments === 0 && (
        <Box sx={{ mt: isChangeCommentAllowed ? -10 : 0 }}>
          <EmptyState
            title={t("labels__no__comments__in__reservation")}
            size={EmptyStateSize.LARGE}
          />
        </Box>
      )}
    </>
  );
};

export default ReservationDetailsComments;
