import { DoorAccessState } from "../generated/graphql";
import { DoorType } from "../../api/door-delegate";

export const transformSyncStatesToCommon = (arg: DoorAccessState | null): DoorType => {
  switch (arg) {
    case DoorAccessState.None:
      return DoorType.NONE;
    case DoorAccessState.Public:
      return DoorType.PUBLIC;
    case DoorAccessState.PublicInhouse:
      return DoorType.PUBLIC_INHOUSE;
    case DoorAccessState.PublicAndPrivate:
      return DoorType.PUBLIC_AND_PRIVATE;
    default:
      return DoorType.NONE;
  }
};

export const transformDoorTypeCommonToSyncStatesV2 = (arg: DoorType): DoorAccessState => {
  switch (arg) {
    case DoorType.NONE:
      return DoorAccessState.None;
    case DoorType.PUBLIC:
      return DoorAccessState.Public;
    case DoorType.PUBLIC_INHOUSE:
      return DoorAccessState.PublicInhouse;
    case DoorType.PUBLIC_AND_PRIVATE:
      return DoorAccessState.PublicAndPrivate;
    default:
      return DoorAccessState.None;
  }
};
