import { api } from "../add-preference-to-profile.generated";

const getUserProfileByIdEnhanced = api.enhanceEndpoints({
  endpoints: {
    AddPreferenceToProfile: {
      invalidatesTags: (result, error, arg) => [
        { type: "UserProfile" as any, id: arg.userProfileId },
        "UserProfile"
      ]
    }
  }
});

export const { useAddPreferenceToProfileMutation: useAddPreferenceToProfileMutationEnhanced } =
  getUserProfileByIdEnhanced;
