import { useDispatch } from "../../../store";
import { useCallback, useContext } from "react";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { unreadMessagesSubscribeApi } from "../../../graphql-messaging/subscribtion/unread-messages-subscribe";
import { QueryCacheKey } from "@reduxjs/toolkit/dist/query/core/apiState";

export const useSubscribeUnreadMessages = () => {
  const dispatch = useDispatch();
  const { tenantId, apiKey } = useContext(TenantContext);

  const subscribeAction = useCallback(
    (propertyIds: string[]) =>
      dispatch(
        unreadMessagesSubscribeApi.endpoints.SubscribeToUnreadMessages.initiate({
          tenantId,
          apiKey,
          propertyIds
        })
      ),
    [dispatch, tenantId, apiKey]
  );

  const unsubscribeAction = useCallback(
    (queryCacheKey: QueryCacheKey) => {
      dispatch(
        unreadMessagesSubscribeApi.internalActions.removeQueryResult({
          queryCacheKey: queryCacheKey
        })
      );
    },
    [dispatch]
  );

  return { subscribeAction, unsubscribeAction };
};
