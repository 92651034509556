import { useCallback, useEffect, useRef } from "react";
import { pickerDateSelector, setDate } from "../slices/date-picker";
import { useDispatch, useSelector } from "../store";
import { useSearchParams } from "react-router-dom";
import { useProperty } from "./use-property";

export function useDateSearchParams(onChange: (date: string) => any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const initialSearchParams = useRef(searchParams);
  const { selectedProperty } = useProperty();

  const isInit = useRef(true);
  const date = useSelector(pickerDateSelector);

  const fetch = useCallback(
    (newDate: string) => {
      return onChange(newDate);
    },
    [onChange]
  );

  const setURL = useCallback(
    (newDate: string) => {
      const newSearchParams = new URLSearchParams(initialSearchParams.current);
      newSearchParams.set("date", newDate);
      if (selectedProperty) {
        newSearchParams.set("property", selectedProperty.propertyId);
      }
      setSearchParams(newSearchParams, { replace: true });
    },
    [setSearchParams, selectedProperty]
  );

  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      const dateFromURL = searchParams.get("date");
      if (dateFromURL) {
        dispatch(setDate(dateFromURL));
        fetch(dateFromURL);
      }
    }
  }, [isInit, searchParams, fetch, dispatch]);

  useEffect(() => {
    if (!date) {
      return;
    }
    setURL(date);
    const promise = fetch(date);
    return () => {
      promise?.abort();
    };
  }, [date, fetch, setURL]);
}
