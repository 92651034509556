import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type BackofficeUpsellReservationMutationVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
  pmsUnitGroupId: Types.Scalars["String"]["input"];
  pmsUpsellRuleId: Types.Scalars["String"]["input"];
}>;

export type BackofficeUpsellReservationMutation = {
  __typename?: "Mutation";
  BackofficeUpsellReservation?: boolean | null;
};

export const BackofficeUpsellReservationDocument = `
    mutation BackofficeUpsellReservation($pmsReservationId: String!, $pmsUnitGroupId: String!, $pmsUpsellRuleId: String!) {
  BackofficeUpsellReservation(
    pmsReservationId: $pmsReservationId
    pmsUnitGroupId: $pmsUnitGroupId
    pmsUpsellRuleId: $pmsUpsellRuleId
  )
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    BackofficeUpsellReservation: build.mutation<
      BackofficeUpsellReservationMutation,
      BackofficeUpsellReservationMutationVariables
    >({
      query: (variables) => ({ document: BackofficeUpsellReservationDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useBackofficeUpsellReservationMutation } = injectedRtkApi;
