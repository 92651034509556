import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import { BatteryLevel } from "../domain/available-door";
import { BoxApi } from "../api/box.api";
import { initFetchEventSource, OpenBoxEventsArgs } from "../features/boxes/box-events";
import { handleSliceError } from "../utils/error-handling";

export const enum BoxType {
  RENT = "RENT",
  BUY = "BUY"
}

export enum BoxState {
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
  BOOKED = "BOOKED",
  RETURNED = "RETURNED",
  UNDER_REVIEW = "UNDER_REVIEW"
}
export interface BoxItem {
  boxId: string;
  boxName: string;
  service: {
    id: string;
    name: string;
  };
  boxState: BoxState;
  boxCollection?: string;
  unitId?: string;
  batteryLevel: BatteryLevel;
  type: BoxType;
  rentalDueDate: string;
}

export const boxAdapter = createEntityAdapter<BoxItem>({
  selectId: (model) => model.boxId,
  sortComparer: (a, b) => a.boxName.localeCompare(b.boxName)
});

const initialState = boxAdapter.getInitialState<{
  status: EntityStateStatus;
}>({
  status: EntityStateStatus.IDLE
});

export const fetchAllBoxes = createAsyncThunk<
  Array<BoxItem>,
  { propertyId: string },
  { state: RootState; rejectValue: { reason: string } }
>("box/fetchBoxes", async (arg, thunkApi) => {
  return BoxApi.fetchBoxes(arg.propertyId, {
    signal: thunkApi.signal
  });
});

export const updateBoxState = createAsyncThunk<
  void,
  { boxId: string; state: BoxState; propertyId: string },
  { state: RootState }
>("box/updateBox", async (arg, thunkAPI) => {
  try {
    return BoxApi.updateBoxState(arg.propertyId, arg.boxId, arg.state, {
      signal: thunkAPI.signal
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const openBox = createAsyncThunk<
  void,
  OpenBoxEventsArgs,
  { state: RootState; rejectValue: { reason: string } }
>("box/updateBox", async (arg, thunkApi) => {
  return initFetchEventSource(
    arg,
    (event) => {
      console.log(event);
    },
    thunkApi,
    arg.onInit
  );
});

export const boxSlice = createSlice({
  name: "box",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBoxes.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchAllBoxes.fulfilled, (state, action) => {
        boxAdapter.setAll(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchAllBoxes.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.status === EntityStateStatus.LOADING) {
            state.status = EntityStateStatus.IDLE;
          }
          return;
        }
        state.status = EntityStateStatus.FAILED;
      });
  }
});

export const { reducer } = boxSlice;

export const { selectAll: selectAllBoxes } = boxAdapter.getSelectors<RootState>(
  (state) => state.box
);
