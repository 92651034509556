import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BatteryUnknownIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6 11.8L14 12.5C13.6 12.9 13.4 13.2 13.3 13.8H11.9C12 13.2 12.3 12.6 12.7 12.2L13.6 11.3C13.9 11.1 14 10.7 14 10.3C14 9.5 13.4 8.9 12.6 8.9C11.8 8.9 11.2 9.5 11.2 10.3H9.7C9.7 8.8 10.9 7.5 12.5 7.5C14.1 7.5 15.3 8.7 15.3 10.3C15.3 10.9 15 11.4 14.6 11.8ZM13.2 16.5H11.8V15.1H13.2V16.5ZM4 7.3V9H2L2 15H4V16.7C4 17.4 4.6 18 5.3 18H20.6C21.4 18 22 17.4 22 16.7V7.4C22 6.7 21.4 6.1 20.7 6.1L5.3 6.1C4.6 6 4 6.6 4 7.3Z"
        fill="black"
      />
    </svg>
  </SvgIcon>
);
