import { api } from "../GetConversationDetails.generated";

const getConversationDetailsEnhanced = api.enhanceEndpoints({
  addTagTypes: ["ConversationDetails"],
  endpoints: {
    GetConversationDetails: {
      providesTags: (result) => {
        return result?.GetConversations
          ? [
              ...result?.GetConversations.content.map(({ conversationId }) => ({
                type: "ConversationDetails" as const,
                id: conversationId
              }))
            ]
          : ["ConversationDetails"];
      }
    }
  }
});

export const { useGetConversationDetailsQuery: useGetConversationDetailsQueryEnhanced } =
  getConversationDetailsEnhanced;
