import {
  ParagraphBold,
  ParagraphSmall,
  ParagraphSmallBold
} from "@likemagic-tech/sv-magic-library";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Paper,
  useTheme
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { FC, useCallback } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { formatDate } from "src/utils/timezoned-date";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { Notification } from "src/components/notification";
import { useProperty } from "src/hooks/use-property";
import { mappingStatusColors } from "../utils/utils";
import { ReservationFromConversations } from "../domain/conversation";
import { formatPriceToString } from "src/utils/price";
import { transformFullPrice } from "src/graphql/transform/transform-utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DisplayServiceIcon } from "../../features/reservations/reservation-details/reservation-details-tabs/services/display-service-icon";

interface ChatPreviewReservationsProps {
  reservations: ReservationFromConversations;
  onSelect: (reservationId: string) => void;
}

export const ChatPreviewReservations: FC<ChatPreviewReservationsProps> = ({
  reservations,
  onSelect
}) => {
  const { palette, spacing } = useTheme();

  const { t } = useTranslationWrapper();

  const { getProperty } = useProperty();

  const getPropertyName = useCallback(
    (propertyId: string) => {
      const property = getProperty(propertyId);
      return property?.details?.name;
    },
    [getProperty]
  );

  return (
    <Accordion sx={{ boxShadow: "none" }} defaultExpanded>
      <AccordionSummary
        sx={{ px: 0 }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="last-reservations"
        id="last-reservations"
      >
        <ParagraphBold>
          <span>{t("title__reservations")}</span>
          <span style={{ fontVariantLigatures: "none" }}>
            <>&#160;</>
            <>&#40;</>
            <span>{reservations?.length}</span>
            <>&#41;</>
          </span>
        </ParagraphBold>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0 }}>
        <Grid item>
          <Grid container spacing={1}>
            {reservations?.map((reservationInfo) => (
              <Grid item xs={12} key={reservationInfo?.pmsId}>
                <Paper
                  elevation={0}
                  sx={{
                    background: grey[100],
                    borderColor: grey[100],
                    borderRadius: spacing(2)
                  }}
                  variant="outlined"
                >
                  <Grid container py={1} px={2} direction="column" spacing={0.5}>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        alignItems="start"
                        justifyContent="space-between"
                      >
                        <Grid item xs={10}>
                          <ParagraphBold>
                            {reservationInfo?.confirmationNumber ?? reservationInfo?.pmsId}
                          </ParagraphBold>
                          <ParagraphSmall sx={{ color: palette.text.secondary }}>
                            {getPropertyName(reservationInfo?.pmsPropertyId ?? "")}
                          </ParagraphSmall>
                        </Grid>
                        <Grid item xs={2} textAlign="right">
                          <IconButton
                            onClick={() => onSelect(reservationInfo?.pmsId ?? "")}
                            sx={{ mt: -0.5 }}
                          >
                            <OpenInNewIcon sx={{ color: "common.black" }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <ParagraphSmallBold>{`${formatDate(reservationInfo?.arrival)} - ${formatDate(
                        reservationInfo?.departure
                      )}`}</ParagraphSmallBold>
                    </Grid>
                    <Grid item width="fit-content">
                      <Notification
                        showIcon={false}
                        title={t(`labels__conversation__status__${reservationInfo?.status}`)}
                        type={mappingStatusColors(reservationInfo?.status)}
                      />
                    </Grid>
                    {reservationInfo?.services && reservationInfo?.services.length > 0 && (
                      <Grid item>
                        {reservationInfo?.services.map((service, index) => (
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            paddingTop={index === 0 ? 0 : 1}
                            key={`chat-preview-service-${service?.magicServiceCodeEnum}`}
                          >
                            <Grid item xs={2} textAlign="center">
                              <DisplayServiceIcon
                                code={service?.magicServiceCodeEnum!}
                                fontSize={"medium"}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <ParagraphSmall sx={{ wordBreak: "break-word" }}>
                                {service?.name}
                              </ParagraphSmall>
                            </Grid>
                            <Grid item xs={1} textAlign="center">
                              <ParagraphSmall>{service?.quantity}</ParagraphSmall>
                            </Grid>
                            <Grid item xs={3} textAlign="center">
                              <ParagraphSmallBold>
                                {formatPriceToString(transformFullPrice(service?.price))}
                              </ParagraphSmallBold>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
