import * as React from "react";
import { FC, PropsWithChildren } from "react";
import { Grid, Paper } from "@mui/material";

export const ManualWizardStepItem: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Paper sx={{ borderRadius: 2 }} elevation={0}>
      <Grid p={3} container direction="row" alignItems="center">
        {children}
      </Grid>
    </Paper>
  );
};
