import React, { FC, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import { PaymentOption } from "./domain/payment-option";
import {
  PaperRadioButton,
  ParagraphBold,
  RadioButtonGroup
} from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { PaymentLink } from "../../../icons/payment-link";
import { PaymentPreAuthorization } from "../../../icons/payment-pre-authorization";
import { PaymentTerminal } from "../../../icons/payment-terminal";
import { PaymentCash } from "../../../icons/payment-cash";
import { PaymentMethodDataItemType } from "./payment-component-handlers/use-payment-method-options";

const PaymentOptionIconsMapper = {
  [PaymentOption.PaymentLink]: <PaymentLink />,
  [PaymentOption.Cash]: <PaymentCash />,
  [PaymentOption.TerminalPayment]: <PaymentTerminal />,
  [PaymentOption.PreAuthorization]: <PaymentPreAuthorization />,
  [PaymentOption.StoredPaymentMethod]: <PaymentLink />,
  [PaymentOption.AdyenWidget]: <></>,
  [PaymentOption.MewsPayment]: <></>,
  [PaymentOption.PayAtCheckout]: <></>,
  [PaymentOption.PayAtCheckin]: <></>,
  [PaymentOption.DatatransWidget]: <></>
};
interface PaymentSelectionProps {
  value?: string;
  setValue: (option: string) => void;
  filterPaymentOptions?: Array<PaymentOption>;
  options: Array<PaymentMethodDataItemType>;
  cardOnFileLabel?: string;
}
export const PaymentSelection: FC<PaymentSelectionProps> = ({
  value,
  setValue,
  filterPaymentOptions = [],
  options
}) => {
  const { t } = useTranslationWrapper();

  const cardOnFileLabel = useCallback(
    // @ts-ignore
    ({ config: cardOnFile }: PaymentMethodDataItemType) =>
      cardOnFile?.holderName
        ? `${cardOnFile?.holderName} *${cardOnFile?.lastFour} ${cardOnFile?.expiryMonth}/${cardOnFile?.expiryYear}`
        : `*${cardOnFile?.lastFour} ${cardOnFile?.expiryMonth}/${cardOnFile?.expiryYear}`,
    []
  );

  return (
    <Box>
      <RadioButtonGroup value={value} onChange={(_, value) => setValue(value)}>
        <Grid container spacing={1}>
          {options
            .filter((item) => !filterPaymentOptions.includes(item.type))
            .map((item, index) => (
              <Grid item xs={12} md={6} mt={-2} key={`payment_option_${item}_${index}`}>
                <PaperRadioButton
                  content={
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>{PaymentOptionIconsMapper[item.type]}</Grid>
                      <Grid item>
                        <ParagraphBold>
                          {item.type === PaymentOption.StoredPaymentMethod
                            ? cardOnFileLabel(item)
                            : t(`labels__payment_${item?.type}`)}
                        </ParagraphBold>
                      </Grid>
                    </Grid>
                  }
                  value={item.hashCode}
                />
              </Grid>
            ))}
        </Grid>
      </RadioButtonGroup>
    </Box>
  );
};
