import { BaseApiClient } from "@likemagic-tech/sv-magic-library";

export class ApiKeyApiClient extends BaseApiClient {
  protected async fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    return super.fetch(input, init);
  }

  protected async fetchApi(input: RequestInfo, init?: RequestInit): Promise<Response> {
    return super.fetchApi(input, init);
  }
}
