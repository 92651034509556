import * as Types from "../generated/graphql";

export type ActorFragment = {
  __typename?: "Actor";
  actorId?: string | null;
  actorGroupId?: string | null;
  displayName?: string | null;
};

export const ActorFragmentDoc = `
    fragment Actor on Actor {
  actorId
  actorGroupId
  displayName
}
    `;
