import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type AdminOpenDoorMutationVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
  doorId: Types.Scalars["String"]["input"];
}>;

export type AdminOpenDoorMutation = { __typename?: "Mutation"; AdminOpenDoor?: boolean | null };

export const AdminOpenDoorDocument = `
    mutation AdminOpenDoor($pmsPropertyId: String!, $doorId: String!) {
  AdminOpenDoor(pmsPropertyId: $pmsPropertyId, doorId: $doorId)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    AdminOpenDoor: build.mutation<AdminOpenDoorMutation, AdminOpenDoorMutationVariables>({
      query: (variables) => ({ document: AdminOpenDoorDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useAdminOpenDoorMutation } = injectedRtkApi;
