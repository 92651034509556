import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type SendCustomNotificationMutationVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
  payload?: Types.InputMaybe<Types.Scalars["Json"]["input"]>;
}>;

export type SendCustomNotificationMutation = {
  __typename?: "Mutation";
  PerformAction?: boolean | null;
};

export const SendCustomNotificationDocument = `
    mutation SendCustomNotification($pmsReservationId: String!, $payload: Json) {
  PerformAction(
    pmsReservationId: $pmsReservationId
    payload: $payload
    reservationAction: SEND_CUSTOM_NOTIFICATION
  )
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    SendCustomNotification: build.mutation<
      SendCustomNotificationMutation,
      SendCustomNotificationMutationVariables
    >({
      query: (variables) => ({ document: SendCustomNotificationDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useSendCustomNotificationMutation } = injectedRtkApi;
