import { IconButton, useTheme } from "@mui/material";
import React, { FC } from "react";
import { Refresh } from "@mui/icons-material";

interface ReloadButtonProps {
  onClick?: () => void;
}

export const ReloadButton: FC<ReloadButtonProps> = ({ onClick }) => {
  const theme = useTheme();
  return (
    <IconButton
      color="primary"
      onClick={onClick}
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: `${theme.shape.borderRadius}px`
      }}
    >
      <Refresh />
    </IconButton>
  );
};
