import { createAsyncThunk, createSelector, createSlice, Selector } from "@reduxjs/toolkit";
import { ApiErrorJson, UserInfo } from "@likemagic-tech/sv-magic-library";
import { RootState } from "../store";
import { EntityStateStatus } from "../domain/EntityStateStatus";

export type KeycloakSliceState = {
  userInfo?: UserInfo;
  status: EntityStateStatus;
};

const initialState: KeycloakSliceState = {
  userInfo: undefined,
  status: EntityStateStatus.IDLE
};

export const fetchUserInfo = createAsyncThunk<
  UserInfo,
  { loadUserInfo: () => Promise<any> },
  { state: RootState; rejectValue: ApiErrorJson }
>(
  "keycloak/fetchUserInfo",
  (arg) => {
    return arg.loadUserInfo();
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      let status = thunkAPI.getState().keycloak.status;
      return !(status === EntityStateStatus.SUCCEEDED || status === EntityStateStatus.LOADING);
    }
  }
);

export const keycloakSlice = createSlice({
  name: "keycloak",
  initialState,

  reducers: {
    clearUserInfoSlice: (state) => {
      state.status = EntityStateStatus.IDLE;
      state.userInfo = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload;
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchUserInfo.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.status === EntityStateStatus.LOADING) {
            state.status = EntityStateStatus.IDLE;
          }
          return;
        }
      });
  }
});

export const { clearUserInfoSlice } = keycloakSlice.actions;

const selectSelf: Selector<RootState, KeycloakSliceState> = (state: RootState) =>
  state[keycloakSlice.name];

export const selectKeycloakSliceStatus = createSelector(
  selectSelf,
  (res) => res.status === EntityStateStatus.SUCCEEDED
);
export const selectKeycloakSliceUserInfo = createSelector(selectSelf, (res) => res.userInfo);
