import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { EnhancedTableHead, HeadCell } from "../../components/table/enhanced-table-head";
import { TaskDto } from "../../graphql-tasks/generated/graphql";
import TableBody from "@mui/material/TableBody";
import { HousekeepingTaskTableRow } from "./housekeeping-task-table-row";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { FC, useState } from "react";
import { Order } from "../../types/pageable";
import { HousekeepingTaskType } from "../../features/tasks/domain/task";

interface HousekeepingTaskTableProps {
  housekeepingTableType: HousekeepingTableType;
  tasks: Array<HousekeepingTaskType>;
  handleUpdate: (values: HousekeepingTaskType) => Promise<void>;
}

export enum HousekeepingTableType {
  ADDITIONAL,
  LEFTOVER
}

const headCells = (housekeepingTableType: HousekeepingTableType): HeadCell<TaskDto>[] => {
  const assigneeCell: HeadCell<TaskDto> = {
    id: "assignee",
    label: "labels__task_assignee_to",
    disableSort: true
  };

  return [
    {
      id: "id",
      label: "labels__task",
      disableSort: true
    },
    housekeepingTableType === HousekeepingTableType.ADDITIONAL
      ? assigneeCell
      : {
          id: "id",
          label: "labels__unit",
          disableSort: true
        },
    housekeepingTableType === HousekeepingTableType.ADDITIONAL
      ? {
          id: "priority",
          label: "labels__task_priority",
          disableSort: true
        }
      : assigneeCell,
    {
      id: "due",
      label: "labels__task_due_date",
      disableSort: true
    }
  ];
};

export const HousekeepingTaskTable: FC<HousekeepingTaskTableProps> = ({
  housekeepingTableType,
  tasks,
  handleUpdate
}) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof TaskDto>("id");

  const handleRequestSort = (event: React.MouseEvent<unknown>, orderBy: keyof TaskDto) => {
    setOrder(order === "desc" ? "asc" : "desc");
    setOrderBy(orderBy);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <EnhancedTableHead<TaskDto>
            headCells={headCells(housekeepingTableType)}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {tasks?.map((task) => (
              <HousekeepingTaskTableRow
                key={task.id}
                housekeepingTableType={housekeepingTableType}
                task={task}
                handleUpdate={handleUpdate}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
