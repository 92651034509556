import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type UpdateNoteMutationVariables = Types.Exact<{
  pmsProfileNoteId: Types.Scalars["String"]["input"];
  note: Types.NoteInput;
}>;

export type UpdateNoteMutation = {
  __typename?: "Mutation";
  UpdateNote: { __typename?: "ProfileNote"; pmsId: string };
};

export const UpdateNoteDocument = `
    mutation UpdateNote($pmsProfileNoteId: String!, $note: NoteInput!) {
  UpdateNote(pmsProfileNoteId: $pmsProfileNoteId, note: $note) {
    pmsId
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    UpdateNote: build.mutation<UpdateNoteMutation, UpdateNoteMutationVariables>({
      query: (variables) => ({ document: UpdateNoteDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useUpdateNoteMutation } = injectedRtkApi;
