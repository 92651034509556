import { Params, useSelectId } from "../../../hooks/use-select-id";

export const useManualCheckinId = () => {
  const { selectedId, setSelectId } = useSelectId({
    fieldName: Params.SELECTED_MANUAL_CHECKIN_RESERVATION_ID
  });

  return {
    manualCheckinId: selectedId,
    setManualCheckinId: setSelectId,
    closeCheckinAndOpenReservation: (id: string) =>
      setSelectId(
        undefined,
        new URLSearchParams({
          [Params.SELECTED_RESERVATION_ID]: id
        })
      )
  };
};
