import { magicApi } from "../magic.api";
import { ReservationPreferencesCategory } from "../../domain/reservation-table-dto";

export type GuestPreferencesMapper = {
  [key in ReservationPreferencesCategory]: Array<string>;
};
const preferencesEndpoints = magicApi.injectEndpoints({
  endpoints: (builder) => ({
    getPreferencesMap: builder.query<GuestPreferencesMapper, { propertyId: string }>({
      query: (arg) => ({
        url: `/backoffice/${arg.propertyId}/guest-preferences-mapping`,
        headers: { method: "GET" }
      })
    })
  }),
  overrideExisting: false
});
export const { useGetPreferencesMapQuery } = preferencesEndpoints;
