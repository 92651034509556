import { Params, useSelectId } from "../../../hooks/use-select-id";

export const useProfileDrawer = () => {
  const { selectedId, setSelectId } = useSelectId({
    fieldName: Params.PROFILE_ID_SEARCH_PARAM_NAME
  });

  return {
    profileGuestIdentityId: selectedId ?? undefined,
    openDrawer: (profileId: string | null) => setSelectId(profileId ?? undefined),
    closeDrawer: () =>
      setSelectId(
        undefined,
        new URLSearchParams({
          [Params.PROFILE_ID_SEARCH_PARAM_NAME]: ""
        })
      )
  };
};
