import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useCallback } from "react";
import { openDoor, openDoorByAdmin } from "../slices/available-doors";
import { useDispatch } from "../store";
import { api as openDoorApi } from "../graphql/mutations/open-door.generated";
import { api as openAdminDoorApi } from "../graphql/mutations/admin-open-door.generated";
import { updateDoorState } from "../slices/available-doors-state.slice";

interface OpenDoorAgs {
  magicToken: string;
  magicId: string;
  doorId: string;
}

interface OpenDoorByAdminArgs {
  propertyId: string;
  doorId: string;
}
export const useOpenDoor = () => {
  const dispatch = useDispatch();
  const { isRESTVersion } = useApiVersion();

  const openDoorV1 = useCallback(
    ({ magicToken, magicId, doorId }: OpenDoorAgs) =>
      dispatch(openDoor({ magicToken, magicId, doorId })).unwrap(),
    [dispatch]
  );
  const openDoorV2 = useCallback(
    ({ magicToken, magicId, doorId }: OpenDoorAgs) =>
      dispatch(
        openDoorApi.endpoints.OpenDoor.initiate({
          magicId,
          magicToken,
          doorId
        })
      ).unwrap(),
    [dispatch]
  );

  const openAdminDoorV1 = useCallback(
    ({ propertyId, doorId }: OpenDoorByAdminArgs) =>
      dispatch(openDoorByAdmin({ propertyId, doorId })).unwrap(),
    [dispatch]
  );
  const openAdminDoorV2 = useCallback(
    ({ propertyId, doorId }: OpenDoorByAdminArgs) =>
      dispatch(
        openAdminDoorApi.endpoints.AdminOpenDoor.initiate({
          pmsPropertyId: propertyId,
          doorId
        })
      ).unwrap(),
    [dispatch]
  );

  const openDoorAction = useCallback(
    (arg: OpenDoorAgs) => {
      const openDoorFn = isRESTVersion ? openDoorV1 : openDoorV2;

      return openDoorFn(arg)
        .then(() => {
          dispatch(
            updateDoorState({
              doorId: arg.doorId,
              error: false,
              unlocked: true
            })
          );
        })
        .catch(() => {
          dispatch(
            updateDoorState({
              doorId: arg.doorId,
              error: true,
              unlocked: false
            })
          );
        });
    },
    [isRESTVersion, openDoorV2, openDoorV1, dispatch]
  );

  const openDoorAdminAction = useCallback(
    (arg: OpenDoorByAdminArgs) => {
      const openDoorFn = isRESTVersion ? openAdminDoorV1 : openAdminDoorV2;
      return openDoorFn(arg)
        .then(() => {
          dispatch(
            updateDoorState({
              doorId: arg.doorId,
              error: false,
              unlocked: true
            })
          );
        })
        .catch(() => {
          dispatch(
            updateDoorState({
              doorId: arg.doorId,
              error: true,
              unlocked: false
            })
          );
        });
    },
    [isRESTVersion, openAdminDoorV2, openAdminDoorV1, dispatch]
  );

  return {
    openDoor: openDoorAction,
    openAdminDoor: openDoorAdminAction
  };
};
