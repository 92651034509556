import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetPreferencesQueryVariables = Types.Exact<{
  pmsPropertyIds?: Types.InputMaybe<
    Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]
  >;
}>;

export type GetPreferencesQuery = {
  __typename?: "Query";
  GetPreferences: Array<{
    __typename?: "PreferenceCategory";
    pmsId: string;
    name: string;
    description?: string | null;
    preferences: Array<{
      __typename?: "Preference";
      pmsId: string;
      pmsPreferenceCategoryId: string;
      name: string;
      description?: string | null;
      pmsPropertyId?: string | null;
    }>;
  }>;
};

export const GetPreferencesDocument = `
    query GetPreferences($pmsPropertyIds: [String!]) {
  GetPreferences(pmsPropertyIds: $pmsPropertyIds) {
    pmsId
    name
    description
    preferences {
      pmsId
      pmsPreferenceCategoryId
      name
      description
      pmsPropertyId
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetPreferences: build.query<GetPreferencesQuery, GetPreferencesQueryVariables | void>({
      query: (variables) => ({ document: GetPreferencesDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetPreferencesQuery, useLazyGetPreferencesQuery } = injectedRtkApi;
