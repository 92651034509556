import React, { FC, useRef } from "react";
import { Box, Grid, SpeedDial, SpeedDialIcon } from "@mui/material";
import { areAdminKeysAvailable } from "../utils/key-utils";
import { useDoorProviderConfig, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { PublicDoorPreview } from "./public-door-preview/public-door-preview";
import { useProperty } from "../hooks/use-property";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";
import { useFetchAvailableDoors } from "../hooks/use-fetch-available-doors";
import { KeyIcon } from "../icons/KeyIcon";

interface KeysSpeedDialProps {
  propertyId: string;
}

export const KeysSpeedDial: FC<KeysSpeedDialProps> = ({ propertyId }) => {
  const { t } = useTranslationWrapper();
  const doorConfig = useDoorProviderConfig(propertyId);
  const { publicDoors } = useFetchAvailableDoors(propertyId);
  const { open } = useGlobalModal();
  const { selectedProperty } = useProperty();
  useFetchAvailableDoors(selectedProperty?.propertyId ?? "");

  const keySliderRefs = useRef<{ [doorId: number]: any }>({});

  const modalContent = () => {
    return (
      <Grid minWidth={260}>
        {publicDoors.map((doorData) => (
          <Box key={doorData.id}>
            <PublicDoorPreview
              doorData={doorData}
              keySliderRefs={keySliderRefs}
              propertyId={selectedProperty?.propertyId}
            />
          </Box>
        ))}
      </Grid>
    );
  };
  const openPublicDoorModal = () =>
    open({
      modalActions: [],
      modalProps: {
        title: t("labels__public_keys"),
        content: modalContent()
      }
    });

  return areAdminKeysAvailable(doorConfig?.doorProvider) && publicDoors.length ? (
    <>
      <SpeedDial
        sx={{ position: "fixed", right: 16, bottom: 16 }}
        ariaLabel="Open public doors"
        icon={<SpeedDialIcon icon={<KeyIcon />} />}
        onClick={openPublicDoorModal}
      />
    </>
  ) : null;
};
