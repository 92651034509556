import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { formatInTimeZone } from "date-fns-tz";

export const formatDate = (date: Date, timeZone: string | undefined = "Europe/Zurich") =>
  formatInTimeZone(date, timeZone, "yyyy-MM-dd");

const slice = createSlice({
  name: "datePicker",
  initialState: {
    date: formatDate(new Date())
  },
  reducers: {
    setDate(state, action: PayloadAction<string>) {
      state.date = action.payload;
    }
  }
});

export const { setDate } = slice.actions;
export const { reducer } = slice;
export const pickerDateSelector = (state: RootState) => state.datePicker.date;
