import * as Types from "../generated/graphql";

import { tasksApi } from "../tasks.api";
export type GetActorsQueryVariables = Types.Exact<{
  pmsPropertyId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type GetActorsQuery = {
  __typename?: "Query";
  GetActors: Array<{
    __typename?: "Actor";
    actorId?: string | null;
    actorType: Types.ActorType;
    displayName?: string | null;
    assignable: boolean;
    keycloakUUID?: string | null;
    pmsPropertyIds: Array<string>;
  }>;
};

export const GetActorsDocument = `
    query getActors($pmsPropertyId: String) {
  GetActors(pmsPropertyId: $pmsPropertyId) {
    actorId
    actorType
    displayName
    assignable
    keycloakUUID
    pmsPropertyIds
  }
}
    `;

const injectedRtkApi = tasksApi.injectEndpoints({
  endpoints: (build) => ({
    getActors: build.query<GetActorsQuery, GetActorsQueryVariables | void>({
      query: (variables) => ({ document: GetActorsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetActorsQuery, useLazyGetActorsQuery } = injectedRtkApi;
