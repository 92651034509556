import * as React from "react";
import { FC } from "react";
import { InputWithIcon } from "../input-with-icon";

interface SearchFieldProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
}

export const SearchField: FC<SearchFieldProps> = ({ value, onChange, placeholder, disabled }) => {
  return (
    <InputWithIcon
      disabled={disabled}
      value={value}
      onChange={onChange}
      placeholder={placeholder ?? ""}
    />
  );
};
