import { formatDate } from "src/utils/timezoned-date";
import { FilterObject, FilterOperatorPreview } from "src/types/filters/filters";
import {
  FilterMapInterface,
  FilterObjectType,
  FilterObjectWithType
} from "src/components/table/filters/table-filters";
import { TableDtoSortingParams } from "../store/endpoints/reservation-table.endpoints";
import { TaskOwnershipFilters } from "../features/tasks/domain/task";

interface DisplayFilterProps {
  filter: FilterObjectWithType;
  t: (s?: string) => string;
  exists: (s?: string) => boolean;
  filterValuesMapper: {
    //name of the filter
    [key: string]: {
      // mapped value of the filter
      [key: string]: string;
    };
  };
}

export const displayFilters = ({
  filter,
  t,
  filterValuesMapper,
  exists
}: DisplayFilterProps): string => {
  const value = displayFilterValue({ filter, t, filterValuesMapper, exists });
  const filterTitle = t(`labels__filter_title_${filter.name}`);
  const operator = filter.operator && FilterOperatorPreview[filter.operator];
  return `${filterTitle} ${operator} ${value}`;
};

export const displayFilterValue = (props: DisplayFilterProps): string | null | undefined => {
  const { value } = props.filter;
  const valueArray = value && value.split(",");

  if (valueArray && valueArray.length > 1) {
    return valueArray.map((item) => handleSingleValue(item, props)).join(", ");
  } else {
    return handleSingleValue(value, props);
  }
};

const handleSingleValue = (
  item: string | null | undefined,
  { filter, t, filterValuesMapper, exists }: DisplayFilterProps
): string | undefined | null => {
  const { name, value, type } = filter;
  if (
    name &&
    value &&
    filterValuesMapper &&
    filterValuesMapper[name] &&
    filterValuesMapper[name][value]
  ) {
    return filterValuesMapper[name][value];
  }

  const additionalFilterLabelPart = "labels__additional_filter_";

  if (item && exists(additionalFilterLabelPart.concat(item))) {
    return t(additionalFilterLabelPart.concat(item));
  }

  if (type === FilterObjectType.DATE_RANGE) {
    return formatDate(item);
  }

  return item;
};

export const compareFiltersMaps = (a: FilterMapInterface, b: FilterMapInterface) => {
  if (Object.keys(a).length === Object.keys(b).length) {
    return Object.keys(a).every((aKey) => {
      const aItem = a[aKey];
      const bItem = b[aKey];

      return compareFilters(aItem, bItem);
    });
  } else {
    return false;
  }
};

export const compareFilters = (a?: FilterObjectWithType, b?: FilterObjectWithType) =>
  a?.type === b?.type &&
  a?.value === b?.value &&
  a?.name === b?.name &&
  a?.operator === b?.operator;

export const transformFiltersToQueryParams = (filters: Array<FilterObject>) =>
  filters
    .map(
      (filterObj: FilterObject) =>
        `${filterObj.name}=${encodeURIComponent(`${filterObj.operator},${filterObj.value}`)}`
    )
    .join("&");

export const transformFiltersToGraphqlFilters = (
  filters: FilterObject[],
  transformValue: (name: string, value: string) => string
): any => {
  return filters.reduce((acc: Record<string, any[]>, next: FilterObject) => {
    if (next.name && acc[next.name]) {
      acc[next.name].push(`${next.operator},${transformValue(next.name, next?.value ?? "")}`);
    } else {
      acc[next.name as keyof typeof acc] = [
        `${next.operator},${transformValue(next.name ?? "", next.value ?? "")}`
      ];
    }
    return acc;
  }, {});
};

export const skipTransformingValue = (name: string, value: string) => value;

export const transformObjToQueryParams = <T>({
  pageable,
  sortingRules,
  filterParams
}: TableDtoSortingParams<T>) => {
  const paging = pageable ? `page=${pageable.pageNumber}&size=${pageable.pageSize}` : undefined;

  const sorting = sortingRules
    ? `sort=${sortingRules.map((rule) => `${String(rule.id)},${rule.order}`)}`
    : undefined;

  const filtering = transformFiltersToQueryParams(filterParams);
  return [paging, sorting, filtering].filter((item) => !!item).join("&");
};

export const filterParamSelector = (condition: string) => {
  switch (condition) {
    case TaskOwnershipFilters.REPORTED_BY_ME:
      return "reportedBy";
    case TaskOwnershipFilters.ASSIGNED_TO_ME:
      return "assignedTo";
    case TaskOwnershipFilters.ASSIGNED_TO_MY_TEAMS:
      return "assignedToTeamsOf";
  }
};
