import { api } from "../BulkSetTasksToDone.generated";

const bulkSetTasksToDone = api.enhanceEndpoints({
  endpoints: {
    BulkSetTasksToDone: {
      // @ts-ignore
      invalidatesTags: [
        "HousekeepingCards",
        "AdditionalTasks",
        "HousekeepingTabCounts",
        "LeftoverTasks"
      ]
    }
  }
});

export const { useBulkSetTasksToDoneMutation: useBulkSetTasksToDoneMutationEnhanced } =
  bulkSetTasksToDone;
