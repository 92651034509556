import { AvailableDoor, BatteryLevel, LockAccessibility } from "../../domain/available-door";
import { GetSupportSyncStatesQuery } from "../queries/GetSupportSyncStates.generated";
import { BatteryLevel as BatteryLevelV2, DoorLockAccessibility } from "../generated/graphql";

type AvailableDoorsV2 = Exclude<GetSupportSyncStatesQuery["GetProperty"], undefined | null>;

type AvailableDoorV2 = Exclude<AvailableDoorsV2["availableDoors"], undefined | null>[number];

export const transformAvailableDoors = (arg?: AvailableDoorV2 | null): AvailableDoor => {
  return {
    batteryLevel: transformBatteryLevel(arg?.batteryLevel),
    id: arg?.id ?? "",
    lockAccessibility: transformDoorLockAccessibilityToCommon(arg?.lockAccessibility),
    pin: arg?.pin ?? "",
    privacyMode: arg?.privacyMode ?? false,
    privacyModeLastChangeTimestamp: arg?.privacyModeLastChangeTimestamp ?? "",
    title: arg?.title ?? "",
    unitId: arg?.unitId ?? ""
  };
};

const transformBatteryLevel = (arg?: BatteryLevelV2 | null): BatteryLevel => {
  switch (arg) {
    case BatteryLevelV2.Critical:
      return BatteryLevel.CRITICAL;
    case BatteryLevelV2.Low:
      return BatteryLevel.LOW;
    case BatteryLevelV2.Fresh:
      return BatteryLevel.FRESH;
    case BatteryLevelV2.Good:
      return BatteryLevel.GOOD;
    case BatteryLevelV2.Unknown:
      return BatteryLevel.UNKNOWN;
    case BatteryLevelV2.None:
      return BatteryLevel.NONE;
    default:
      return BatteryLevel.NONE;
  }
};

const transformDoorLockAccessibilityToCommon = (
  arg?: DoorLockAccessibility | null
): LockAccessibility => {
  switch (arg) {
    case DoorLockAccessibility.BackofficeOnly:
      return LockAccessibility.BACKOFFICE_ONLY;
    case DoorLockAccessibility.Public:
      return LockAccessibility.PUBLIC;
    case DoorLockAccessibility.PublicInhouse:
      return LockAccessibility.PUBLIC_INHOUSE;
    case DoorLockAccessibility.Private:
      return LockAccessibility.PRIVATE;
    case DoorLockAccessibility.PrivateService:
      return LockAccessibility.PRIVATE;
    case DoorLockAccessibility.Restricted:
      return LockAccessibility.RESTRICTED;
    case DoorLockAccessibility.Service:
      return LockAccessibility.SERVICE;
    default:
      return LockAccessibility.BACKOFFICE_ONLY;
  }
};
