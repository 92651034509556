import React, { FC, useState } from "react";
import { ManualCheckinStepProps } from "../manual-checkin-step-domain";
import { useBackofficeUpsellReservationMutationEnhanced } from "../../../../../graphql/mutations/enhanced-mutations/upsell.reservation-enhanced";
import { ManualWizardStepItem } from "../../../components/manual-wizard/manual-wizard-step-item";
import { ManualWizardStepItemNavigation } from "../../../components/manual-wizard/manual-wizard-step-item-navigation";
import { useManualCheckinSteps } from "../../../../../hooks/use-manual-checkin-steps";
import { openBanner } from "../../../../../slices/banner.slice";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { useDispatch } from "../../../../../store";
import { Box } from "@mui/material";
import {
  UpsellRequest,
  UpsellUnitGroupComponent
} from "../../../reservation-details/reservation-details-tabs/upsell/upsell-unit-group-component";

export const ManualCheckinStepUpsell: FC<ManualCheckinStepProps> = ({ reservationDetails }) => {
  const [pmsUpsellRequest, setPmsUpsellRequest] = useState<UpsellRequest | undefined>();
  const [upsellReservation] = useBackofficeUpsellReservationMutationEnhanced();
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const { nextStep, previousStep } = useManualCheckinSteps({
    propertyId: reservationDetails?.propertyId
  });

  const onNext = async () => {
    if (pmsUpsellRequest) {
      await upsellReservation(pmsUpsellRequest)
        .unwrap()
        .then(() => {
          dispatch(
            openBanner({
              type: "success",
              title: t("labels__action_successfully_performed")
            })
          );
        });
    }
    nextStep();
  };

  return (
    <Box mt={2}>
      <ManualWizardStepItem>
        {reservationDetails ? (
          <UpsellUnitGroupComponent
            pmsUpsellRequest={pmsUpsellRequest}
            setPmsUpsellRequest={setPmsUpsellRequest}
            reservation={reservationDetails}
          />
        ) : null}
      </ManualWizardStepItem>
      <ManualWizardStepItemNavigation onNextStep={onNext} onPreviousStep={previousStep} />
    </Box>
  );
};
