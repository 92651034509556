import { api } from "../GetReservationDetails.generated";
import { getReservationOverviewEnhanced } from "./get-reservations-overview";
import { OverviewTableQuery } from "../GetReservationOverview.generated";

export const getReservationDetailsEnhanced = api.enhanceEndpoints({
  endpoints: {
    OverviewDetails: {
      providesTags: (result) => [
        {
          type: "ReservationDetails",
          id: result?.OverviewDetails?.pmsReservationId ?? ""
        }
      ],
      serializeQueryArgs: ({ queryArgs }) => {
        const { pmsReservationId } = queryArgs;
        return { pmsReservationId };
      },

      async onQueryStarted(
        { pmsReservationId },
        { queryFulfilled, getCacheEntry, dispatch, getState }
      ): Promise<void> {
        return queryFulfilled.then(() => {
          if (getCacheEntry().isSuccess) {
            getReservationOverviewEnhanced.util
              .selectInvalidatedBy(getState(), ["ReservationsOverview"])
              .forEach(({ endpointName, originalArgs, queryCacheKey }) => {
                if (endpointName === "OverviewTable") {
                  dispatch(
                    getReservationOverviewEnhanced.util.updateQueryData(
                      endpointName,
                      originalArgs,
                      (draft) => {
                        const data = getState()?.graphql.queries[queryCacheKey]
                          ?.data as OverviewTableQuery;

                        const index =
                          data?.OverviewTable?.content.findIndex(
                            (item) => item.pmsReservationId === pmsReservationId
                          ) ?? -1;

                        if (index >= 0 && data?.OverviewTable?.content) {
                          const newDataContent = [...data.OverviewTable.content];
                          newDataContent[index] = getCacheEntry().data?.OverviewDetails as any;

                          Object.assign(draft, {
                            ...data,
                            OverviewTable: {
                              ...draft.OverviewTable,
                              content: newDataContent
                            }
                          });
                        } else {
                          console.warn("Data with pmsId has not be found", pmsReservationId);
                        }
                        return;
                      }
                    )
                  );
                }
              });
          }
        });
      }
    }
  }
});

export const { useOverviewDetailsQuery: useOverviewDetailsQueryEnhanced } =
  getReservationDetailsEnhanced;
