import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BatteryFullIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.6665 8.33V10H2.6665L2.6665 14H4.6665V15.67C4.6665 16.4 5.2665 17 5.9965 17H21.3265C22.0665 17 22.6665 16.4 22.6665 15.67V8.34C22.6665 7.6 22.0665 7 21.3365 7L5.9965 7C5.2665 7 4.6665 7.6 4.6665 8.33Z" />
    </svg>
  </SvgIcon>
);
