import { Button, EmptyState, Paragraph, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React, { FC, useMemo, useState } from "react";
import { ReservationDetailsDTO } from "../../../../../store/endpoints/reservation-table.endpoints";
import { RowsForTableGuestOverview } from "./rows-for-table-guest-overview/rows-for-table-guest-overview";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useTheme } from "@mui/material/styles";
import {
  labelsList,
  ReservationTabs
} from "../../reservation-details/reservation-details-constants";
import { CardHeader } from "./card-header/card-header";
import { Notes } from "src/domain/reservation-table-dto";
import { CardComments } from "../../../components/card-comments/card-comments";
import { TaskModal } from "src/features/tasks/task-modal/task-modal";
import { CardTasksInfo } from "./card-tasks/card-tasks-info";
import { useNavigateWithPropertyId } from "src/hooks/use-navigate-with-propertyId";
import { generateTaskRelatedToQueryParams } from "src/features/tasks/task-query-params";
import AddIcon from "@mui/icons-material/Add";
import { useTaskModal } from "src/features/tasks/use-task-select-id";
import { Task } from "../../../../tasks/domain/task";
import { Services } from "../services/services";
import { useProperty } from "../../../../../hooks/use-property";
import { SevenroomsWidget } from "../../../../../components/sevenrooms-widget/sevenrooms-widget";
import { useSevenRoomsWidgetConfig } from "../../../../../hooks/use-configuration";
import { SevenRoomsLoader } from "../../../../../components/sevenrooms-widget/seven-rooms-loader";
import { ReservationDetailsOverviewRestaurants } from "./components/restaurants/reservation-details-overview-restaurants";

export const numberOfComments = (notes: Notes) => {
  return (
    (notes.comment?.length > 0 ? 1 : 0) +
    (notes.bookingComment?.length > 0 ? 1 : 0) +
    (notes.guestComment?.length > 0 ? 1 : 0) +
    (notes.bookerComment?.length > 0 ? 1 : 0) +
    (notes.rateBreakdownComment && notes.rateBreakdownComment?.length > 0 ? 1 : 0) +
    (notes.beddingComment && notes.beddingComment?.length > 0 ? 1 : 0)
  );
};

interface ReservationDetailsOverviewProps {
  reservation: ReservationDetailsDTO;
  tasks: Task[];
  changeTabMoreInfo: (tab: string) => void;
}

export const ReservationDetailsOverview: FC<ReservationDetailsOverviewProps> = ({
  reservation,
  changeTabMoreInfo,
  tasks
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const navigate = useNavigateWithPropertyId();
  const { openNewTaskWithParam } = useTaskModal({});
  const { selectedProperty } = useProperty();
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });
  const sevenRoomsWidgetConfig = useSevenRoomsWidgetConfig();
  const [showComponent, setShowComponent] = useState(false);

  const TaskCreationButton = useMemo(() => {
    return (
      <Button
        onClick={() => openNewTaskWithParam(reservation.id, "RESERVATION")}
        variant="secondary"
        startIcon={<AddIcon sx={{ paddingRight: 1 }} fontSize="medium" />}
      >
        {t("buttons__create_task")}
      </Button>
    );
  }, [openNewTaskWithParam, reservation.id, t]);

  const RestaurantReservationCreationButton = useMemo(() => {
    return (
      <Button
        onClick={() => setShowComponent(!showComponent)}
        variant="secondary"
        startIcon={<AddIcon sx={{ paddingRight: 1 }} fontSize="medium" />}
      >
        {t("buttons__new_reservation")}
      </Button>
    );
  }, [t, setShowComponent, showComponent]);

  const person = reservation.primaryGuest;

  return (
    <Grid container rowSpacing={1} spacing={1} marginTop={theme.spacing(2)}>
      <Grid item xs={12}>
        <Paper sx={{ borderRadius: 2 }} elevation={0}>
          <CardHeader
            title={t("labels__reservation__details__overview__guests")}
            viewAllInfo={() => changeTabMoreInfo(ReservationTabs.INSIGHTS)}
          />
          <Grid p={3} paddingTop={0} container>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Paragraph>{t("labels__fullName")}</Paragraph>
                    </TableCell>
                    {labelsList.map((item) => (
                      <TableCell align="left" key={item}>
                        <Paragraph>{t(item)}</Paragraph>
                      </TableCell>
                    ))}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RowsForTableGuestOverview reservation={reservation} />
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ borderRadius: 2, height: "100%" }} elevation={0}>
          <CardHeader
            title={
              numberOfComments(reservation.notes) > 0
                ? `${t("labels__reservation__details__overview__comments")} (${numberOfComments(
                    reservation.notes
                  )})`
                : t("labels__reservation__details__overview__comments")
            }
            viewAllInfo={() => changeTabMoreInfo(ReservationTabs.COMMENTS)}
          />
          <Grid container rowSpacing={1} justifyContent="center" padding={3} paddingTop={0}>
            <CardComments
              notes={reservation?.notes}
              numberOfComments={numberOfComments(reservation.notes)}
            />
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ borderRadius: 2, height: "100%" }} elevation={0}>
          <CardHeader
            title={
              reservation.services.length > 0
                ? `${t("labels__reservation__details__overview__services")} (${
                    reservation.services.length
                  })`
                : t("labels__reservation__details__overview__services")
            }
            viewAllInfo={() => changeTabMoreInfo(ReservationTabs.SERVICES)}
          />
          <Grid container rowSpacing={1} justifyContent="center" padding={3} paddingTop={0}>
            {reservation.services.length > 0 ? (
              <Services
                hiddenCells
                services={reservation.services}
                preferredCleaningDays={reservation.preferredCleaningDays}
              />
            ) : (
              <EmptyState title={t("labels__no__services__in__reservation")} />
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ borderRadius: 2 }} elevation={0}>
          <CardHeader
            title={
              tasks.length > 0
                ? `${t("labels__reservation__details__overview__tasks")} (${tasks.length})`
                : t("labels__reservation__details__overview__tasks")
            }
            viewAllInfo={() =>
              navigate("/tasks", generateTaskRelatedToQueryParams(reservation?.displayId ?? ""))
            }
            extraAction={TaskCreationButton}
          />
          <Grid container rowSpacing={1} justifyContent="center" padding={3} paddingTop={0}>
            <CardTasksInfo tasks={tasks.slice(0, 3)} />
          </Grid>
        </Paper>
      </Grid>
      {features?.restaurantReservationEnabled && (
        <Grid item xs={12} md={6}>
          <Paper sx={{ borderRadius: 2, height: "100%" }} elevation={0}>
            <CardHeader
              title={
                reservation.restaurantReservations.length > 0
                  ? `${t("labels__reservation__details__overview__restaurant_reservations")} (${
                      reservation.restaurantReservations.length
                    })`
                  : t("labels__reservation__details__overview__restaurant_reservations")
              }
              viewAllInfo={() => changeTabMoreInfo(ReservationTabs.RESTAURANT)}
              extraAction={RestaurantReservationCreationButton}
            />
            <Grid container rowSpacing={1} justifyContent="center" padding={3} paddingTop={0}>
              {reservation.restaurantReservations.length > 0 ? (
                <ReservationDetailsOverviewRestaurants reservation={reservation} />
              ) : (
                <EmptyState title={t("labels__reservation__details__restaurant_no_reservations")} />
              )}
            </Grid>
          </Paper>
          {sevenRoomsWidgetConfig && (
            <SevenRoomsLoader url={sevenRoomsWidgetConfig.url}>
              <SevenroomsWidget
                guestIdentityId={person.guestIdentityId}
                firstName={person.firstName}
                lastName={person.lastName}
                email={person.email}
                phone={person.phone}
                locale={person.preferredLocale}
                hotelReservationArrivalDate={reservation.arrival ?? ""}
                hotelReservationDepartureDate={reservation.departure ?? ""}
                amountOfPeople={reservation.adultsCount}
                //TODO check if there is admin mode ?
                mode="GUEST"
                toggle={showComponent}
                reservationId={reservation.id}
                onClose={(toggle: boolean) => setShowComponent(toggle)}
                apiKey={sevenRoomsWidgetConfig?.apiKey}
              />
            </SevenRoomsLoader>
          )}
        </Grid>
      )}
      <TaskModal />
    </Grid>
  );
};
