import * as Types from "../generated/graphql";

import { messagingApi } from "../messaging.api";
export type SetConversationAsReadMutationVariables = Types.Exact<{
  conversationId: Types.Scalars["String"]["input"];
}>;

export type SetConversationAsReadMutation = {
  __typename?: "Mutation";
  SetConversationAsRead: boolean;
};

export const SetConversationAsReadDocument = `
    mutation SetConversationAsRead($conversationId: String!) {
  SetConversationAsRead(conversationId: $conversationId)
}
    `;

const injectedRtkApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({
    SetConversationAsRead: build.mutation<
      SetConversationAsReadMutation,
      SetConversationAsReadMutationVariables
    >({
      query: (variables) => ({ document: SetConversationAsReadDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useSetConversationAsReadMutation } = injectedRtkApi;
