import { useApiVersion, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import React, { FC, useCallback, useMemo } from "react";
import ActionDropdown from "src/components/action-dropdown/action-dropdown";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { getYesNoModalArg } from "src/utils/modal-util";
import { useSelector } from "../../store";
import { pickerDateSelector } from "../../slices/date-picker";
import { isAfter } from "date-fns";

interface HousekeepingUnitsToCleanActionsProps {
  onClick: (actionType: HousekeepingActionType) => void;
  additionalTasks: boolean;
  allTaskIds: number[];
}

export enum HousekeepingActionType {
  SET_ALL_TASKS_TO_DONE = "SET_ALL_TASKS_TO_DONE",
  ADD_MAINTENANCE = "ADD_MAINTENANCE",
  CREATE_TASK = "CREATE_TASK"
}
export const HousekeepingUnitsToCleanActions: FC<HousekeepingUnitsToCleanActionsProps> = ({
  onClick,
  additionalTasks,
  allTaskIds
}) => {
  const { t } = useTranslationWrapper();
  const { open: openModal } = useGlobalModal();
  const { isRESTVersion } = useApiVersion();
  const date = useSelector(pickerDateSelector);

  const futureDate = useMemo(() => {
    return isAfter(new Date(date), new Date());
  }, [date]);

  const confirmationModal = useCallback(
    async (selectedItem: HousekeepingActionType) => {
      const result = await openModal(
        getYesNoModalArg(
          t("labels__set_all_tasks_to_done"),
          t("labels__housekeeping_units_to_clean_actions_text_some_units_still_have_open_tasks"),
          t("labels__yes"),
          t("labels__no")
        )
      );
      if (result) {
        onClick(selectedItem);
      }
    },
    [openModal, t, onClick]
  );

  const options = useMemo(
    () => [
      ...(!futureDate && !!allTaskIds.length
        ? [
            {
              id: HousekeepingActionType.SET_ALL_TASKS_TO_DONE,
              label: t("labels__set_all_tasks_to_done")
            }
          ]
        : []),
      ...(isRESTVersion
        ? [{ id: HousekeepingActionType.ADD_MAINTENANCE, label: t("labels__add_maintenance") }]
        : []),
      { id: HousekeepingActionType.CREATE_TASK, label: t("buttons__create_task") }
    ],
    [isRESTVersion, futureDate, t, allTaskIds]
  );

  const handleOption = (optionId: string) => {
    switch (optionId) {
      case HousekeepingActionType.SET_ALL_TASKS_TO_DONE:
        additionalTasks ? confirmationModal(optionId) : onClick(optionId as HousekeepingActionType);
        break;

      case HousekeepingActionType.CREATE_TASK:
      case HousekeepingActionType.ADD_MAINTENANCE:
        onClick(optionId as HousekeepingActionType);
        break;
    }
  };

  return (
    <ActionDropdown handleOption={handleOption} options={options}>
      {t("buttons__actions")}
    </ActionDropdown>
  );
};
