import * as prismic from "@prismicio/client";
import { env } from "./env";

// Fill in your repository name
export const repositoryName = "likemagic";

const accessToken =
  env.ENV_STAGE !== "prod"
    ? "MC5ZMFFyMHhBQUFDUUFoODFz.d--_vRJMYVrvv73vv70b77-9Ze-_vRNg77-9KxDvv70SNm1OQ00S77-977-9Z--_ve-_vUPvv70"
    : "MC5ZbWVOSVJjQUFDWUFQZGRs.77-9Lu-_vRNXFO-_ve-_vQDvv73vv73vv73vv70aWTBnLu-_ve-_ve-_ve-_ve-_ve-_vSpt77-977-9Uu-_vV_vv70";

const ref: any = prismic
  .createClient(repositoryName, {
    // ref: "Y0aAFhAAACgAkkht~Y0VLWBAAACcAjOir",
    accessToken: accessToken
  })
  .getRefs()
  .then((refs) => {
    const devRelease = refs.filter((ref) => ref.label === "dev_release")[0];
    return devRelease?.ref || refs[0].ref;
  });

export const client = prismic.createClient(repositoryName, {
  ref: ref,
  accessToken: accessToken
});
