import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type ConditionCountsQueryVariables = Types.Exact<{
  filter: Types.BackofficeConditionCountsFilter;
}>;

export type ConditionCountsQuery = { __typename?: "Query"; ConditionCounts?: any | null };

export const ConditionCountsDocument = `
    query ConditionCounts($filter: BackofficeConditionCountsFilter!) {
  ConditionCounts(filter: $filter)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    ConditionCounts: build.query<ConditionCountsQuery, ConditionCountsQueryVariables>({
      query: (variables) => ({ document: ConditionCountsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useConditionCountsQuery, useLazyConditionCountsQuery } = injectedRtkApi;
