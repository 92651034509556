import * as Types from "../generated/graphql";

import { tasksApi } from "../tasks.api";
export type BulkSetTasksToDoneMutationVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
  taskIds: Array<Types.Scalars["Int"]["input"]> | Types.Scalars["Int"]["input"];
}>;

export type BulkSetTasksToDoneMutation = { __typename?: "Mutation"; BulkSetTasksToDone: boolean };

export const BulkSetTasksToDoneDocument = `
    mutation BulkSetTasksToDone($pmsPropertyId: String!, $taskIds: [Int!]!) {
  BulkSetTasksToDone(pmsPropertyId: $pmsPropertyId, taskIds: $taskIds)
}
    `;

const injectedRtkApi = tasksApi.injectEndpoints({
  endpoints: (build) => ({
    BulkSetTasksToDone: build.mutation<
      BulkSetTasksToDoneMutation,
      BulkSetTasksToDoneMutationVariables
    >({
      query: (variables) => ({ document: BulkSetTasksToDoneDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useBulkSetTasksToDoneMutation } = injectedRtkApi;
