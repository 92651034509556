import * as React from "react";
import { FC, useCallback, useMemo } from "react";
import { HousekeepingTableType, HousekeepingTaskTable } from "./housekeeping-task-table";
import { HousekeepingTaskType } from "../../features/tasks/domain/task";
import { useGetAdditionalTasksQueryEnhanced } from "../../graphql-tasks/query/enhanced-queries/get-additional-tasks-enhanced";
import { useUpdateAdditionalTaskMutationEnhanced } from "../../graphql-tasks/mutations/enhanced-mutations/update-additional-tasks-enhanced";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { HousekeepingTaskCard } from "./housekeeping-task-card";
import { Card } from "@mui/material";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { HousekeepingConfirmation } from "./housekeeping-confirmation";
import { generateUpdateTaskRequest } from "../../utils/housekeeping";

interface AdditionalTasksProps {
  propertyId?: string;
}
export const HousekeepingAdditionalTasks: FC<AdditionalTasksProps> = ({ propertyId }) => {
  const { data, isLoading } = useGetAdditionalTasksQueryEnhanced({
    pmsPropertyId: propertyId ?? ""
  });
  const [updateAction] = useUpdateAdditionalTaskMutationEnhanced();
  const isMobile = useIsMobile();
  const { t } = useTranslationWrapper();

  const tasks = useMemo(() => {
    return data?.GetAdditionalTasks ?? [];
  }, [data]);

  const handleUpdateAdditionalTask = useCallback(
    async (values: HousekeepingTaskType) => {
      await updateAction({
        pmsPropertyId: propertyId ?? "",
        request: generateUpdateTaskRequest(values),
        taskId: values.id
      });
    },
    [propertyId, updateAction]
  );

  if (tasks.length === 0 && !isLoading) {
    return <HousekeepingConfirmation label={t("labels__all_additional_tasks_resolved")} />;
  }
  return isMobile ? (
    <Card>
      {tasks?.map((task) => (
        <HousekeepingTaskCard
          housekeepingTableType={HousekeepingTableType.ADDITIONAL}
          task={task}
          handleUpdate={handleUpdateAdditionalTask}
        />
      ))}
    </Card>
  ) : (
    <HousekeepingTaskTable
      housekeepingTableType={HousekeepingTableType.ADDITIONAL}
      tasks={data?.GetAdditionalTasks ?? []}
      handleUpdate={handleUpdateAdditionalTask}
    />
  );
};
