import { Select } from "@likemagic-tech/sv-magic-library";
import { DialogContent, Grid, TextField } from "@mui/material";
import { FC, useCallback, useEffect } from "react";
import { DialogModal } from "src/components/submit-modal/dialog";
import { DialogActionModal } from "src/components/submit-modal/dialog-actions";
import { DialogTitleModal } from "src/components/submit-modal/dialog-title";
import { useGetProfileNoteCategoriesQuery } from "src/graphql/queries/GetProfileNoteCategories.generated";
import { useProperty } from "src/hooks/use-property";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { openBanner } from "src/slices/banner.slice";
import { useDispatch } from "src/store";
import { useProfileDrawer } from "../use-profile-drawer";
import { Form, Formik } from "formik";
import { NoteForProfile } from "../../types/profile";
import { useAddNoteToProfileMutationEnhanced } from "src/graphql/mutations/enhanced-mutations/add-note-to-profile-enhanced";
import { useUpdateNoteMutationEnhanced } from "src/graphql/mutations/enhanced-mutations/update-note-enhanced";

interface ProfileAddGuestNoteModalProps {
  isOpen: boolean;
  onClose: () => void;
  isEditNote?: boolean;
  note?: NoteForProfile;
}

export const ProfileAddGuestNoteModal: FC<ProfileAddGuestNoteModalProps> = ({
  isOpen,
  onClose,
  note,
  isEditNote
}) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const dispatch = useDispatch();
  const { profileGuestIdentityId } = useProfileDrawer();
  const [addNoteToProfile, resultAddNote] = useAddNoteToProfileMutationEnhanced();
  const [updateNote, resultUpdateNote] = useUpdateNoteMutationEnhanced();

  const { data } = useGetProfileNoteCategoriesQuery({
    pmsPropertyIds: selectedProperty?.propertyId
  });

  const options = data?.GetProfileNoteCategories.map((category) => ({
    label: t(`labels__notes_category_${category.pmsId}`),
    value: category.pmsId
  }));

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (resultAddNote.isSuccess || resultUpdateNote.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );

      handleClose();
    }
  }, [isEditNote, resultAddNote, resultUpdateNote, dispatch, handleClose, t]);

  const handleModalSubmit = useCallback(
    (values: any) => {
      if (isEditNote) {
        updateNote({
          pmsProfileNoteId: note?.pmsId ?? "",
          note: {
            title: values.title,
            text: values.text,
            pmsProfileNoteCategoryId: values.category.value,
            pmsPropertyId: selectedProperty?.propertyId
          }
        });
      } else {
        addNoteToProfile({
          userProfileId: Number(profileGuestIdentityId),
          note: {
            title: values.title,
            text: values.text,
            pmsProfileNoteCategoryId: values.category.value,
            pmsPropertyId: selectedProperty?.propertyId
          }
        });
      }
    },
    [
      isEditNote,
      addNoteToProfile,
      note?.pmsId,
      profileGuestIdentityId,
      selectedProperty?.propertyId,
      updateNote
    ]
  );

  return (
    <Formik
      initialValues={{
        category: { label: note?.category?.name ?? "", value: note?.category?.pmsId ?? "" },
        title: note?.title ?? "",
        text: note?.text ?? ""
      }}
      onSubmit={handleModalSubmit}
      enableReinitialize
    >
      {(formik) => (
        <DialogModal isOpen={isOpen} handleClose={handleClose}>
          <Grid container p={3} flexDirection="column">
            <Form>
              <DialogTitleModal
                title={
                  isEditNote
                    ? t("labels__profile_update_guest_note")
                    : t("labels__profile_add_guest_note")
                }
                onClose={handleClose}
              />
              <DialogContent sx={{ px: 0, py: 2 }}>
                {options && (
                  <Select
                    id="category.value"
                    name="category.value"
                    variant="outlined"
                    label={t("labels__profile_guest_note_category")}
                    options={options}
                    fullWidth
                    value={formik.values.category?.value ?? ""}
                    onChange={formik.handleChange}
                  />
                )}
                <TextField
                  id="title"
                  placeholder={t("labels__profile_guest_note_title_placeholder")}
                  value={formik.values.title}
                  sx={{ width: "100%", paddingTop: 2 }}
                  onChange={formik.handleChange}
                />
                <TextField
                  id="text"
                  multiline
                  value={formik.values.text}
                  sx={{ width: "100%", paddingTop: 2 }}
                  InputProps={{
                    sx: { height: 270, alignItems: "baseline" }
                  }}
                  rows={10}
                  placeholder={t("labels__pofile_guest_note_text_placeholder")}
                  autoFocus
                  onChange={formik.handleChange}
                />
              </DialogContent>
              <DialogActionModal
                title={
                  isEditNote
                    ? t("labels__profile_update_guest_note")
                    : t("labels__profile_add_guest_note")
                }
                disabled={
                  !formik.values.title || !formik.values.text || !formik.values.category?.value
                }
              />
            </Form>
          </Grid>
        </DialogModal>
      )}
    </Formik>
  );
};
