import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type StreamSecondScreenEventsSubscriptionVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
}>;

export type StreamSecondScreenEventsSubscription = {
  __typename?: "Subscription";
  StreamBackofficeSecondScreenEvents?:
    | {
        __typename: "BackofficePing";
        pmsReservationId: string;
        tenantName: string;
        type: Types.BackofficeOverviewDetailEventType;
      }
    | {
        __typename: "SecondScreenBackofficeEvent";
        pmsReservationId: string;
        tenantName: string;
        type: Types.BackofficeOverviewDetailEventType;
        payload:
          | {
              __typename: "BillingConfirmed";
              deviceId: string;
              type: Types.SecondScreenEventType;
              tenantName: string;
            }
          | {
              __typename: "Canceled";
              deviceId: string;
              type: Types.SecondScreenEventType;
              tenantName: string;
            }
          | {
              __typename: "Signed";
              deviceId: string;
              type: Types.SecondScreenEventType;
              tenantName: string;
            }
          | {
              __typename: "VerifyAndSign";
              deviceId: string;
              type: Types.SecondScreenEventType;
              tenantName: string;
            }
          | {
              __typename: "VerifyAndSignAck";
              deviceId: string;
              type: Types.SecondScreenEventType;
              tenantName: string;
              payload: {
                __typename: "VerifyAndSignAckPayload";
                reason?: string | null;
                accepted: boolean;
              };
            }
          | {
              __typename: "VerifyBill";
              deviceId: string;
              type: Types.SecondScreenEventType;
              tenantName: string;
            }
          | {
              __typename: "VerifyBillAck";
              deviceId: string;
              type: Types.SecondScreenEventType;
              tenantName: string;
              payload: {
                __typename: "VerifyBillAckPayload";
                reason?: string | null;
                accepted: boolean;
              };
            };
      }
    | null;
};

export const StreamSecondScreenEventsDocument = `
    subscription StreamSecondScreenEvents($pmsReservationId: String!) {
  StreamBackofficeSecondScreenEvents(pmsReservationId: $pmsReservationId) {
    __typename
    pmsReservationId
    tenantName
    type
    ... on SecondScreenBackofficeEvent {
      __typename
      payload {
        __typename
        deviceId
        type
        tenantName
        ... on VerifyAndSignAck {
          payload {
            __typename
            reason
            accepted
          }
        }
        ... on VerifyBillAck {
          payload {
            __typename
            reason
            accepted
          }
        }
        ... on BillingConfirmed {
          __typename
        }
      }
    }
    ... on BackofficePing {
      __typename
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({})
});

export { injectedRtkApi as api };
export const {} = injectedRtkApi;
