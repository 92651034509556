import { QueryStatus } from "@reduxjs/toolkit/query";
import { EntityStateStatus } from "../../domain/EntityStateStatus";
import { GrossPrice, GrossPriceInput, Price as PriceV2 } from "../generated/graphql";
import { emptyPrice, Price } from "../../domain/price";
import { Platform } from "../../graphql-messaging/generated/graphql";
import { CommunicationChannel } from "@likemagic-tech/sv-magic-library";

export const mapQueryStatusToEntityStateStatus = (queryStatus: QueryStatus) => {
  switch (queryStatus) {
    case QueryStatus.fulfilled:
      return EntityStateStatus.SUCCEEDED;
    case QueryStatus.rejected:
      return EntityStateStatus.FAILED;
    case QueryStatus.pending:
      return EntityStateStatus.LOADING;
    default:
      return EntityStateStatus.IDLE;
  }
};

export const transformFullPrice = (priceV2?: PriceV2 | GrossPrice | null): Price => {
  return priceV2
    ? {
        currency: priceV2?.currency ?? "N/A",
        amount: priceV2?.grossPrice ? priceV2?.grossPrice * 100 : priceV2?.grossPriceInCents ?? 0
      }
    : emptyPrice();
};

export const transformToGrossPrice = (price: Price): GrossPriceInput => {
  return {
    currency: price?.currency ?? "N/A",
    grossPriceInCents: price?.amount
  };
};

export const transformCommunicationPlatformV2 = (
  platform?: Platform | null
): CommunicationChannel | undefined => {
  switch (platform) {
    case Platform.Sms:
      return CommunicationChannel.SMS;
    case Platform.Email:
      return CommunicationChannel.EMAIL;
    case Platform.Whatsapp:
      return CommunicationChannel.WHATSAPP;
    default:
      return undefined;
  }
};
