import { api } from "../update-note.generated";

const getUserProfileByIdEnhanced = api.enhanceEndpoints({
  endpoints: {
    UpdateNote: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: "UserProfileNotes", id: arg.pmsProfileNoteId }];
      }
    }
  }
});

export const { useUpdateNoteMutation: useUpdateNoteMutationEnhanced } = getUserProfileByIdEnhanced;
