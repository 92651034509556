import { UnitGroup } from "src/domain/unit-group";
import { GetUnitGroupsQuery } from "../queries/GetUnitGroups.generated";
import { getI18n } from "react-i18next";

type ExtractedGetUnitGroups = Exclude<GetUnitGroupsQuery["GetUnitGroups"], undefined | null>;

export const transformUnitGroupsV2ToCommonUnitGroups = (
  arg: ExtractedGetUnitGroups[number]
): UnitGroup => {
  return {
    name: arg?.name ?? "",
    propertyId: arg?.property?.pmsId ?? "",
    unitGroupId: arg?.pmsId ?? "",
    language: getI18n().language
  };
};
