export enum EntityStateStatus {
  IDLE = "idle",
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed"
}

export const isStatusSuccess = (status: EntityStateStatus) =>
  status === EntityStateStatus.SUCCEEDED;
export const isStatusLoading = (status: EntityStateStatus) => status === EntityStateStatus.LOADING;
export const isStatusFailed = (status: EntityStateStatus) => status === EntityStateStatus.FAILED;
export const isStatusIdle = (status: EntityStateStatus) => status === EntityStateStatus.IDLE;
