import { useCallback, useContext } from "react";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { QueryCacheKey } from "@reduxjs/toolkit/dist/query/core/apiState";
import { useDispatch } from "../../../../store";
import { StreamBackofficeSecondScreenEventsSubscription } from "../../../../domain/manual-checkin";
import { streamSecondScreenEventsSubscribeApi } from "../../../../graphql/subscribtion/stream-second-screen-subscribe";

export const useStreamReservationOverviewDetails = () => {
  const dispatch = useDispatch();
  const { tenantId, apiKey, keycloak } = useContext(TenantContext);
  const authToken = keycloak.token;

  const subscribeAction = useCallback(
    ({
      pmsReservationId,
      handleResponse
    }: {
      pmsReservationId: string;
      handleResponse: (response: {
        errors?: Array<{ message: string }>;
        data: StreamBackofficeSecondScreenEventsSubscription;
      }) => void;
    }) =>
      dispatch(
        streamSecondScreenEventsSubscribeApi.endpoints.SubscribeToReservationOverviewDetails.initiate(
          {
            tenantId,
            pmsReservationId,
            handleResponse,
            apiKey,
            authorization: authToken
          }
        )
      ),
    [dispatch, tenantId, apiKey, authToken]
  );

  const unsubscribeAction = useCallback(
    (queryCacheKey: QueryCacheKey) => {
      dispatch(
        streamSecondScreenEventsSubscribeApi.internalActions.removeQueryResult({
          queryCacheKey: queryCacheKey
        })
      );
    },
    [dispatch]
  );

  return { subscribeAction, unsubscribeAction };
};
