import i18n from "i18next";
import { utcToZonedTime } from "date-fns-tz";

let timeOptions: { hour: "2-digit"; minute: "2-digit" } = {
  hour: "2-digit",
  minute: "2-digit"
};

export type NullableString = string | null | undefined;

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
};

export const getTimeZonedDateFromString = (date: string, timeZone: string) =>
  getTimeZonedDateFromDate(new Date(date), timeZone);

export const getTimeZonedDateFromDate = (date: Date, timeZone: string) =>
  utcToZonedTime(date, timeZone);

export const getI18nSelectedLanguage = () => i18n.language.toLowerCase();

export const formatDate = (
  dateString: NullableString,
  timeZone: string = "Europe/Zurich"
): NullableString => {
  if (typeof dateString !== "string" || dateString === "") {
    return dateString;
  }
  const date = new Date(dateString);
  return utcToZonedTime(date, timeZone).toLocaleDateString(navigator.language, dateOptions);
};

export const formatTime = (
  dateString: NullableString,
  timeZone: string = "Europe/Zurich"
): NullableString => {
  if (typeof dateString !== "string") {
    return dateString;
  }

  const date = new Date(dateString);
  return utcToZonedTime(date, timeZone).toLocaleTimeString(navigator.language, timeOptions);
};

export const formatDateTime = (
  dateString: NullableString,
  timeZone: string = "Europe/Zurich"
): string => {
  return `${formatDate(dateString, timeZone)} | ${formatTime(dateString, timeZone)}`;
};
