import { useCallback, useMemo } from "react";
import { UnitCondition } from "../domain/Unit";
import { ButtonProps, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { filterableStatuses } from "../pages/unit/utils";
import { useTranslationWrapper } from "./use-translation-wrapper";

export const useUnitConditionModal = () => {
  const { t } = useTranslationWrapper();

  const labelMap = useMemo(() => {
    return {
      [UnitCondition.CLEAN]: t("labels__unit_condition_Clean"),
      [UnitCondition.CLEAN_TO_BE_INSPECTED]: t("labels__unit_condition_CleanToBeInspected"),
      [UnitCondition.DIRTY]: t("labels__unit_condition_Dirty")
    };
  }, [t]);

  const { open: openGlobalModal } = useGlobalModal();

  const getButtonClass = useCallback((condition: UnitCondition) => {
    switch (condition) {
      case UnitCondition.CLEAN:
        return "success";
      case UnitCondition.DIRTY:
        return "error";
      case UnitCondition.CLEAN_TO_BE_INSPECTED:
        return "warning";
      case UnitCondition.UNKNOWN:
        return "primary";
    }
  }, []);

  const open = useCallback(
    async ({ condition, unitName }: { condition: UnitCondition; unitName: string }) => {
      const actionsResults: Array<{
        color: ButtonProps["color"];
        variant: ButtonProps["variant"];
        label: string;
        result: string;
      }> = filterableStatuses
        .filter((value) => value !== condition)
        .map((value) => ({
          variant: "primary",
          color: getButtonClass(value),
          label: labelMap[value as keyof typeof labelMap],
          result: value
        }));

      return openGlobalModal({
        modalProps: {
          title: unitName,
          content: <>{t("labels__please_confirm_status")}</>
        },
        modalActions: actionsResults
      });
    },
    [openGlobalModal, getButtonClass, labelMap, t]
  );

  return {
    open
  };
};
