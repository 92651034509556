import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { AllowedActionEnum } from "../../domain/reservation-table-dto";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { Apps, AssignmentInd, Hotel } from "@mui/icons-material";
import { useGlobalModal, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { getYesNoModalArg } from "../../utils/modal-util";
import { useDispatch } from "../../store";
import { AssignKeyModal } from "../key-assign/assign-key-modal";
import { openBanner } from "../../slices/banner.slice";
import { AssignUnitModal } from "../assign-unit-modal";
import { openInNewTab } from "../../utils/reservation-utils";
import { clearMagicFiles } from "src/slices/magic-files.slice";
import { ConfirmIdentity } from "../dashboard/identity/confirm-identity";
import { IdCheckStatus } from "src/domain/reservation";
import { useProperty } from "src/hooks/use-property";
import { usePerformReservationAction } from "src/hooks/use-reservation-actions";
import { ManualCheckinStepsEnum } from "../../features/reservations/domain/enums";
import { useManualCheckinId } from "../../features/reservations/manual-checkin/use-manual-checkin-id";
import {
  FolioManagementCheckoutModalResult,
  ReservationActionsMenuProps,
  SectionName
} from "./reservation-actions-menu.types";
import { actionToSectionMapping } from "./reservation-actions-menu.constants";
import { useHandleFolioManagementCheckoutModal } from "./hooks/use-handle-folio-management-checkout-modal";
import { Params } from "../../hooks/use-select-id";
import { ActionMenu } from "../action-menu/action-menu";
import { ReservationCheckInOutAuthor } from "../../graphql/generated/graphql";
import Box from "@mui/material/Box/Box";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { ProfileActionsOptions } from "src/features/profiles/profile-drawer/profile-actions";
import { useBlacklistProfile } from "src/hooks/use-blacklist-profile";
import { useUnBlacklistProfile } from "src/hooks/use-unblacklist-profile";
import { useReservationDetailsId } from "../../features/reservations/reservation-details/reservation-details/use-reservation-details-id";
import { ChangePinModal } from "./change-pin-modal";
import { EditReservationCommentModal } from "../../features/reservations/reservation-details/reservation-details-tabs/notes/edit-reservation-comment/edit-reservation-comment-modal";
import { EditGuestPreferencesTextModal } from "../../features/reservations/reservation-details/preferences/edit-guest-preferences-text-modal";
import { GuestPreferencesModal } from "../../features/reservations/reservation-details/preferences/guest-preferences-modal";
import { ReservationTabs } from "../../features/reservations/reservation-details/reservation-details/reservation-details-constants";

export const ReservationActionsMenu: FC<ReservationActionsMenuProps> = ({
  reservation,
  onClose,
  iconVertical
}) => {
  const { t } = useTranslationWrapper();
  const { open: openGlobalModal } = useGlobalModal();
  const dispatch = useDispatch();
  const { selectedProperty } = useProperty();
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });
  const [assignKeyModalOpened, setAssignKeyModalOpened] = useState(false);
  const [changePinModalOpened, setChangePinModalOpened] = useState(false);
  const [assignUnitModalIsOpen, setAssignUnitModalIsOpen] = useState(false);
  const [addCommentModalOpened, setAddCommentModalOpened] = useState(false);
  const [addPreferencesModalOpened, setAddPreferencesModalOpened] = useState(false);
  const [addPreferencesTextModalOpened, setAddPreferencesTextModalOpened] = useState(false);
  const [performAction, result] = usePerformReservationAction();
  const { setManualCheckinId, manualCheckinId } = useManualCheckinId();
  const { handleFolioManagementCheckoutModal } = useHandleFolioManagementCheckoutModal({
    reservation
  });
  const [blacklistProfile] = useBlacklistProfile();
  const [unBlacklistProfile] = useUnBlacklistProfile();

  const { setSelectId } = useReservationDetailsId();

  const handleWithYesNoModal = useCallback(
    async (action: AllowedActionEnum) => {
      return openGlobalModal(
        getYesNoModalArg(
          t(`labels__reservation_action_modal_title_${action}`),
          t(`labels__reservation_action_modal_text_${action}`),
          t("labels__yes"),
          t("labels__no")
        )
      );
    },
    [openGlobalModal, t]
  );

  const handleManualCheckin = useCallback(() => {
    //TODO this could be par of the setManualCheckinId fn
    setManualCheckinId(
      reservation.id,
      new URLSearchParams({
        [Params.TASK_LINK_TYPE_URL_PARAM]: "",
        [Params.TASK_LINK_ID_URL_PARAM]: "",
        [Params.TASK_ID_SEARCH_PARAM_NAME]: "",
        [Params.CREATE_TASK_PARAM]: "",
        [Params.MANUAL_CHECKIN_STEP]: ManualCheckinStepsEnum.REGISTRATION_CARD
      })
    );
  }, [reservation.id, setManualCheckinId]);

  const handleCheckout = useCallback(
    async (action: AllowedActionEnum) => {
      if (features?.tfeFeatureEnabled) {
        const folioManagementCheckoutModal = await handleFolioManagementCheckoutModal();

        switch (folioManagementCheckoutModal) {
          case FolioManagementCheckoutModalResult.GO_TO_FOLIO:
            setSelectId(
              reservation.id,
              new URLSearchParams({
                [Params.TASK_LINK_TYPE_URL_PARAM]: "",
                [Params.TASK_LINK_ID_URL_PARAM]: "",
                [Params.TASK_ID_SEARCH_PARAM_NAME]: "",
                [Params.CREATE_TASK_PARAM]: "",
                [Params.MANUAL_CHECKIN_STEP]: "",
                [Params.SELECTED_TAB]: ReservationTabs.BILLING
              })
            );
            break;
          case FolioManagementCheckoutModalResult.COMPLETE:
            performAction({
              reservationId: reservation.id,
              payload: { actor: ReservationCheckInOutAuthor.Backoffice },
              action
            });
            break;
        }
      } else {
        const checkOutResultYesNoModal = await handleWithYesNoModal(action);
        if (checkOutResultYesNoModal) {
          performAction({
            reservationId: reservation.id,
            action
          });
        }
      }
    },
    [
      features?.tfeFeatureEnabled,
      handleFolioManagementCheckoutModal,
      handleWithYesNoModal,
      performAction,
      reservation.id,
      setSelectId
    ]
  );

  const handleMenuAction = useCallback(
    async (action: AllowedActionEnum) => {
      switch (action) {
        case AllowedActionEnum.RESET_GUEST_FLOW:
        case AllowedActionEnum.RESEND_GUESTFLOW_LINK:
        case AllowedActionEnum.VALIDATE_RESERVATION:
        case AllowedActionEnum.CHANGE_STATUS_TO_IN_HOUSE:
        case AllowedActionEnum.CHANGE_STATUS_TO_NO_SHOW:
        case AllowedActionEnum.CHANGE_STATUS_TO_CANCELED:
        case AllowedActionEnum.CHANGE_UNIT_CONDITION_TO_DIRTY:
        case AllowedActionEnum.CHANGE_UNIT_CONDITION_TO_CLEAN_TO_BE_INSPECTED:
        case AllowedActionEnum.CHANGE_UNIT_CONDITION_TO_CLEAN:
          const resultYesNoModal = await handleWithYesNoModal(action);
          if (resultYesNoModal) {
            performAction({
              reservationId: reservation.id,
              payload: AllowedActionEnum.CHANGE_STATUS_TO_IN_HOUSE
                ? { author: ReservationCheckInOutAuthor.Backoffice }
                : {},
              action
            });
          }
          break;
        case AllowedActionEnum.CHANGE_STATUS_TO_CHECKED_OUT:
          handleCheckout(action);
          break;
        case AllowedActionEnum.CHANGE_RESERVATION_COMMENT:
          setAddCommentModalOpened(true);
          break;
        case AllowedActionEnum.CHANGE_PIN_CONFIGURATION:
          setChangePinModalOpened(true);
          break;
        case AllowedActionEnum.EDIT_PREFERENCES_TEXT:
          setAddPreferencesTextModalOpened(true);
          break;
        case AllowedActionEnum.EDIT_PREFERENCES:
          setAddPreferencesModalOpened(true);
          break;
        case AllowedActionEnum.ENCODE_KEY:
          setAssignKeyModalOpened(true);
          break;
        case AllowedActionEnum.CHANGE_ID_CHECK_STATUS:
          const resultOfIdConfirmationModal = await openGlobalModal({
            modalProps: {
              title: t("labels__reservation_action_title_CHANGE_ID_CHECK_STATUS"),
              content: (
                <ConfirmIdentity
                  files={reservation?.files}
                  primaryGuest={reservation?.primaryGuest}
                  documentUploadEnabled={features?.documentsUploadEnabled}
                  propertyId={selectedProperty?.propertyId}
                  idCheckStatus={reservation.idCheckStatus}
                />
              )
            },
            modalActions: [
              {
                label: t("labels__decline"),
                result: IdCheckStatus.DECLINED,
                variant: "primary",
                color: "error"
              },
              {
                label: t("labels__approve"),
                result: IdCheckStatus.CONFIRMED,
                variant: "primary",
                color: "success"
              },
              {
                label: reservation.primaryGuest.isBlacklisted
                  ? t("labels__unblacklist_profile")
                  : t("labels__blacklist_profile"),
                variant: "secondary",
                result: reservation.primaryGuest.isBlacklisted
                  ? ProfileActionsOptions.UNBLACKLIST_PROFILE
                  : ProfileActionsOptions.BLACKLIST_PROFILE
              }
            ]
          });

          if (resultOfIdConfirmationModal === ProfileActionsOptions.BLACKLIST_PROFILE) {
            blacklistProfile({
              profileId: reservation.primaryGuest.userProfileId ?? 0,
              reservationId: reservation.id
            })
              .then(() => {
                dispatch(
                  openBanner({
                    type: "success",
                    title: t("labels__action_successfully_performed")
                  })
                );
              })
              .catch(() =>
                dispatch(
                  openBanner({
                    type: "error",
                    title: t("labels__action_failed_to_perform")
                  })
                )
              );
          } else if (resultOfIdConfirmationModal === ProfileActionsOptions.UNBLACKLIST_PROFILE) {
            unBlacklistProfile({
              profileId: reservation.primaryGuest.userProfileId ?? 0,
              reservationId: reservation.id
            })
              .then(() => {
                dispatch(
                  openBanner({
                    type: "success",
                    title: t("labels__action_successfully_performed")
                  })
                );
              })
              .catch(() =>
                dispatch(
                  openBanner({
                    type: "error",
                    title: t("labels__action_failed_to_perform")
                  })
                )
              );
          } else if (
            resultOfIdConfirmationModal === IdCheckStatus.CONFIRMED ||
            resultOfIdConfirmationModal === IdCheckStatus.DECLINED
          ) {
            performAction({
              reservationId: reservation.id,
              action,
              payload: { status: resultOfIdConfirmationModal }
            });
          }
          dispatch(clearMagicFiles());
          break;
        case AllowedActionEnum.JUMP_TO_BOOKING:
          openInNewTab(reservation.bookingMagicLink);
          break;
        case AllowedActionEnum.JUMP_TO_RESERVATION:
          openInNewTab(reservation.magicLink);
          break;
        case AllowedActionEnum.ASSIGN_UNIT:
          setAssignUnitModalIsOpen(true);
          break;
        case AllowedActionEnum.COPY_GUESTFLOW_LINK:
          const showCopyLinkError = () =>
            dispatch(
              openBanner({
                type: "error",
                title: t("labels__action_failed_to_perform")
              })
            );
          if (navigator?.clipboard?.writeText === undefined) {
            showCopyLinkError();
          } else {
            navigator.clipboard
              .writeText(reservation.magicLink)
              .then(() => {
                dispatch(
                  openBanner({
                    type: "success",
                    title: t("labels__action_successfully_performed")
                  })
                );
              })
              .catch(() => {
                showCopyLinkError();
              });
          }

          break;
        case AllowedActionEnum.MANUAL_CHECKIN:
          handleManualCheckin();
          break;
        default:
          console.warn("Action has no handler, ", action);
          break;
      }
    },
    [
      handleWithYesNoModal,
      handleCheckout,
      openGlobalModal,
      t,
      reservation?.files,
      reservation?.primaryGuest,
      reservation.idCheckStatus,
      reservation.bookingMagicLink,
      reservation.magicLink,
      reservation.id,
      features?.documentsUploadEnabled,
      selectedProperty?.propertyId,
      dispatch,
      handleManualCheckin,
      performAction,
      blacklistProfile,
      unBlacklistProfile
    ]
  );

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      result.reset();
    }
  }, [result, dispatch, t]);

  const handleMenuItemClick = useCallback(
    async (action: AllowedActionEnum) => {
      handleMenuAction(action);
    },
    [handleMenuAction]
  );
  const icon = useCallback((sectionName: SectionName) => {
    switch (sectionName) {
      case SectionName.UNIT:
        return <Hotel fontSize="small" />;
      case SectionName.RESERVATION:
        return <Apps fontSize="small" />;
      case SectionName.GUEST_JOURNEY:
        return <AssignmentInd fontSize="small" />;
      case SectionName.GUEST:
        return <AssignmentInd fontSize="small" />;
    }
  }, []);

  const menuItems = useMemo(() => {
    if (!reservation.allowedActions) {
      return [];
    }
    return Array.from(actionToSectionMapping)
      .map(([key, actions]) => {
        const actionsInSection = actions
          .filter((action) =>
            reservation.allowedActions.find((allowedAction) => allowedAction === action)
          )
          .map((action) => ({
            label: t(`labels__reservation_action_${action}`),
            action,
            id: action
          }))
          // filter to hide MANUAL_CHECKIN option if it's already open
          .filter((action) => {
            return !(manualCheckinId && action.action === AllowedActionEnum.MANUAL_CHECKIN);
          });

        if (actionsInSection.length > 0) {
          return [
            {
              id: `section_${key}`,
              icon: icon(key),
              label: t(`labels__reservation_action_header_${key}`)
            },
            ...actionsInSection
          ];
        }
        return [];
      })
      .reduce((accumulator, value) => accumulator.concat(value), []);
  }, [manualCheckinId, reservation.allowedActions, icon, t]);

  return (
    <>
      <Tooltip title={t("labels__action")}>
        <Box>
          <ActionMenu
            items={menuItems}
            handleAction={(selection: string) => {
              handleMenuItemClick(selection as AllowedActionEnum);
            }}
            iconVertical={iconVertical}
          />
        </Box>
      </Tooltip>

      {assignKeyModalOpened && (
        <AssignKeyModal
          isOpen
          reservation={reservation}
          onClose={() => {
            setAssignKeyModalOpened(false);
          }}
        />
      )}
      <ChangePinModal
        isOpen={changePinModalOpened}
        reservation={reservation}
        onClose={() => {
          setChangePinModalOpened(false);
          onClose && onClose();
        }}
      />
      <AssignUnitModal
        isOpen={assignUnitModalIsOpen}
        reservation={reservation}
        onClose={() => {
          setAssignUnitModalIsOpen(false);
        }}
      />
      <EditReservationCommentModal
        isOpen={addCommentModalOpened}
        reservation={reservation}
        onClose={() => {
          setAddCommentModalOpened(false);
          onClose && onClose();
        }}
      />
      <EditGuestPreferencesTextModal
        isOpen={addPreferencesTextModalOpened}
        reservation={reservation}
        onClose={() => {
          setAddPreferencesTextModalOpened(false);
          onClose && onClose();
        }}
      />
      <GuestPreferencesModal
        isOpen={addPreferencesModalOpened}
        reservation={reservation}
        propertyId={reservation.propertyId}
        onClose={() => {
          setAddPreferencesModalOpened(false);
          onClose && onClose();
        }}
      />
    </>
  );
};
