export enum FilterOperator {
  Equality = "eq",
  LowerOrEqual = "le",
  GreaterOrEqual = "ge",
  LowerThan = "lt",
  GreaterThan = "gt",
  Negation = "ne",
  In = "in"
}
export interface FilterObject {
  name?: string;
  operator?: FilterOperator;
  value?: string;
  type?: string;
}

export const FilterOperatorPreview = {
  [FilterOperator.Equality]: "=",
  [FilterOperator.LowerOrEqual]: "<=",
  [FilterOperator.GreaterOrEqual]: ">=",
  [FilterOperator.LowerThan]: "<",
  [FilterOperator.GreaterThan]: ">",
  [FilterOperator.Negation]: "!",
  [FilterOperator.In]: ":"
};
