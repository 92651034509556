import { createSvgIcon } from "@mui/material/utils";

const OutOfInventory = createSvgIcon(
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="Delete--Streamline-Ultimate">
    <desc>{"Delete Streamline Icon: https://streamlinehq.com"}</desc>
    <path
      d="M0.75 12a11.25 11.25 0 1 0 22.5 0 11.25 11.25 0 1 0 -22.5 0Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="m4.045 19.955 15.91 -15.91"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>,
  "OutOfInventory"
);

export default OutOfInventory;
