import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetOrderItemsPageQueryVariables = Types.Exact<{
  filter: Types.OrderItemsPageFilter;
}>;

export type GetOrderItemsPageQuery = {
  __typename?: "Query";
  GetOrderItemsPage: {
    __typename?: "OrderItemsPage";
    totalElements: number;
    content: Array<{
      __typename?: "OrderItem";
      id: number;
      pmsId: string;
      pmsReservationId?: string | null;
      displayableReservationId?: string | null;
      pmsAccountId?: string | null;
      pmsFolioId?: string | null;
      name?: string | null;
      pmsServiceId?: string | null;
      start?: any | null;
      end?: any | null;
      accountingState: Types.AccountingState;
      prepaid?: boolean | null;
      hide?: boolean | null;
      price: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
        netPrice?: any | null;
        netPriceInCents?: number | null;
        taxes?: Array<{
          __typename?: "Tax";
          amount?: any | null;
          amountInCents?: number | null;
          code?: string | null;
        } | null> | null;
      };
    }>;
  };
};

export const GetOrderItemsPageDocument = `
    query GetOrderItemsPage($filter: OrderItemsPageFilter!) {
  GetOrderItemsPage(filter: $filter) {
    totalElements
    content {
      id
      pmsId
      pmsReservationId
      displayableReservationId
      pmsAccountId
      pmsFolioId
      name
      pmsServiceId
      start
      end
      accountingState
      price {
        currency
        grossPrice
        grossPriceInCents
        taxes {
          amount
          amountInCents
          code
        }
        netPrice
        netPriceInCents
      }
      prepaid
      hide
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetOrderItemsPage: build.query<GetOrderItemsPageQuery, GetOrderItemsPageQueryVariables>({
      query: (variables) => ({ document: GetOrderItemsPageDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetOrderItemsPageQuery, useLazyGetOrderItemsPageQuery } = injectedRtkApi;
