import { magicApi } from "../magic.api";
import { UnitGroup } from "../../domain/unit-group";

const unitGroupEndpoints = magicApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnitGroups: builder.query<Array<UnitGroup>, void>({
      query: () => ({
        url: "/unit-groups",
        headers: { method: "GET" }
      })
    })
  }),
  overrideExisting: false
});
export const { useGetUnitGroupsQuery: useGetUnitGroupsQueryV1 } = unitGroupEndpoints;
