import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useCallback, useEffect, useMemo } from "react";
import { fetchUnitsToBeCleaned, selectAllUnitsToBeCleaned } from "src/slices/cleaning";
import { useDispatch, useSelector } from "src/store";
import { UnitToBeCleaned } from "../domain/UnitToBeCleaned";
import {
  api as getToBeCleanedApi,
  useGetToBeCleanedTodayQuery
} from "../graphql/queries/GetToBeCleanedToday.generated";
import { transformToBeCleaned } from "../graphql/transform/transform-to-be-cleaned";

interface UseFetchUnitsToBeCleanedProps {
  propertyId: string;
  skipInitialLoad?: boolean;
}

export const useFetchUnitsToBeCleaned = ({
  propertyId,
  skipInitialLoad
}: UseFetchUnitsToBeCleanedProps) => {
  const dispatch = useDispatch();
  const { isRESTVersion } = useApiVersion();

  const unitsV1 = useSelector(selectAllUnitsToBeCleaned);

  const dispatchFetchUnitsToBeCleanedV2 = useCallback(() => {
    return dispatch(
      getToBeCleanedApi.endpoints.GetToBeCleanedToday.initiate(
        {
          propertyPmsId: propertyId
        },
        { forceRefetch: true }
      )
    );
  }, [dispatch, propertyId]);

  const dispatchFetchUnitsToBeCleanedV1 = useCallback(
    () => dispatch(fetchUnitsToBeCleaned({ propertyId: propertyId })),
    [dispatch, propertyId]
  );

  const { data } = useGetToBeCleanedTodayQuery(
    {
      propertyPmsId: propertyId
    },
    { skip: isRESTVersion || skipInitialLoad }
  );

  const dispatchFetchUnitsToBeCleaned = isRESTVersion
    ? dispatchFetchUnitsToBeCleanedV1
    : dispatchFetchUnitsToBeCleanedV2;

  useEffect(() => {
    if (isRESTVersion && propertyId && !skipInitialLoad) {
      dispatchFetchUnitsToBeCleaned();
    }
  }, [isRESTVersion, dispatchFetchUnitsToBeCleaned, propertyId, skipInitialLoad]);

  const unitsV2: UnitToBeCleaned[] = useMemo(
    () =>
      data?.GetProperty?.toBeCleanedToday
        ? transformToBeCleaned(data?.GetProperty?.toBeCleanedToday)
        : [],
    [data?.GetProperty?.toBeCleanedToday]
  );
  return {
    data: isRESTVersion ? unitsV1 : unitsV2,
    fetchAction: dispatchFetchUnitsToBeCleaned
  };
};
