import { useMemo } from "react";
import { ReservationDetailsDTO } from "../../../../store/endpoints/reservation-table.endpoints";
import { ReservationStatus } from "../../../../domain/reservation-status";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { formatDateTime } from "../../../../utils/timezoned-date";
import { useProperty } from "../../../../hooks/use-property";
import {
  isPreStepDone,
  MonitoringSteps,
  PrecheckinSteps,
  PrecheckoutSteps,
  Step
} from "./monitoring-step.util";
import { usePropertyConfig } from "@likemagic-tech/sv-magic-library";

export const useMonitoringSteps = (reservation: ReservationDetailsDTO) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();

  const lastConfirmedPage = `PRECHECKIN_${reservation.lastConfirmedPage}`;
  const lastConfirmedCheckoutPage = `PRECHECKOUT_${reservation.lastConfirmedCheckoutPage}`;
  const { features } = usePropertyConfig({ propertyId: reservation.propertyId });

  const checkoutPreSteps = useMemo(
    () =>
      features?.checkoutFlowEnabled
        ? [
            {
              id: PrecheckoutSteps.DEPARTURE_TIME,
              tooltipText: t(`labels__monitoring_tooltip_${PrecheckoutSteps.DEPARTURE_TIME}`),
              isPreStepDone: isPreStepDone({
                steps: PrecheckoutSteps,
                stepId: PrecheckoutSteps.DEPARTURE_TIME,
                lastConfirmedPage: lastConfirmedCheckoutPage
              })
            },
            {
              id: PrecheckoutSteps.MINIBAR,
              tooltipText: t(`labels__monitoring_tooltip_${PrecheckoutSteps.MINIBAR}`),
              isPreStepDone: isPreStepDone({
                steps: PrecheckoutSteps,
                stepId: PrecheckoutSteps.MINIBAR,
                lastConfirmedPage: lastConfirmedCheckoutPage
              })
            },
            {
              id: PrecheckoutSteps.PAYMENT,
              tooltipText: t(`labels__monitoring_tooltip_${PrecheckoutSteps.PAYMENT}`),
              isPreStepDone: isPreStepDone({
                steps: PrecheckoutSteps,
                stepId: PrecheckoutSteps.PAYMENT,
                lastConfirmedPage: lastConfirmedCheckoutPage
              })
            },
            {
              id: PrecheckoutSteps.CONFIRMATION,
              tooltipText: t(`labels__monitoring_tooltip_${PrecheckoutSteps.CONFIRMATION}`),
              isPreStepDone: isPreStepDone({
                steps: PrecheckoutSteps,
                stepId: PrecheckoutSteps.CONFIRMATION,
                lastConfirmedPage: lastConfirmedCheckoutPage
              })
            }
          ]
        : [],
    [features?.checkoutFlowEnabled, lastConfirmedCheckoutPage, t]
  );

  const steps: Step[] = useMemo(() => {
    return [
      {
        id: MonitoringSteps.BOOKING,
        tooltipText: t(`labels__monitoring_tooltip_${MonitoringSteps.BOOKING}`),
        dateTime: reservation.createdAt
          ? formatDateTime(reservation.createdAt, selectedProperty?.details.timeZone)
          : ""
      },
      {
        id: MonitoringSteps.IN_HOUSE,
        tooltipText: t(`labels__monitoring_tooltip_${MonitoringSteps.IN_HOUSE}`, {
          author: t(`labels__monitoring_legend_${reservation.checkedInBy ?? "UNKNOWN"}`)
        }),
        hasEmptySpace: !features?.checkoutFlowEnabled,
        author: reservation.checkedInBy,
        dateTime: reservation.checkInTime
          ? formatDateTime(reservation.checkInTime, selectedProperty?.details.timeZone)
          : "",
        preSteps: [
          {
            id: PrecheckinSteps.PERSONAL_DATA,
            tooltipText: t(`labels__monitoring_tooltip_${PrecheckinSteps.PERSONAL_DATA}`),
            isPreStepDone: isPreStepDone({
              steps: PrecheckinSteps,
              stepId: PrecheckinSteps.PERSONAL_DATA,
              lastConfirmedPage
            })
          },
          {
            id: PrecheckinSteps.PREFERRED_CHANNEL,
            tooltipText: reservation.primaryGuest.preferredCommunicationChannel
              ? t(`labels__monitoring_tooltip_${PrecheckinSteps.PREFERRED_CHANNEL}`, {
                  channel: t(
                    `labels__guest__communication__channel__${reservation.primaryGuest.preferredCommunicationChannel}`
                  )
                })
              : t(`labels__monitoring_tooltip_${PrecheckinSteps.PREFERRED_CHANNEL}_NO_DATA`),
            isPreStepDone: isPreStepDone({
              steps: PrecheckinSteps,
              stepId: PrecheckinSteps.PREFERRED_CHANNEL,
              lastConfirmedPage
            })
          },
          {
            id: PrecheckinSteps.LEGAL,
            tooltipText: t(`labels__monitoring_tooltip_${PrecheckinSteps.LEGAL}`),
            isPreStepDone: isPreStepDone({
              steps: PrecheckinSteps,
              stepId: PrecheckinSteps.LEGAL,
              lastConfirmedPage
            })
          },
          {
            id: PrecheckinSteps.ADDRESS,
            tooltipText: t(`labels__monitoring_tooltip_${PrecheckinSteps.ADDRESS}`),
            isPreStepDone: isPreStepDone({
              steps: PrecheckinSteps,
              stepId: PrecheckinSteps.ADDRESS,
              lastConfirmedPage
            })
          },
          ...(features?.upsellUnitGroupEnabled
            ? [
                {
                  id: PrecheckinSteps.PRECHECKIN_UPSELL,
                  tooltipText: t(`labels__monitoring_tooltip_${PrecheckinSteps.PRECHECKIN_UPSELL}`),
                  isPreStepDone: isPreStepDone({
                    steps: PrecheckinSteps,
                    stepId: PrecheckinSteps.PRECHECKIN_UPSELL,
                    lastConfirmedPage
                  })
                }
              ]
            : []),
          {
            id: PrecheckinSteps.SERVICES,
            tooltipText: t(`labels__monitoring_tooltip_${PrecheckinSteps.SERVICES}`, {
              count: `${reservation.services?.length}`
            }),
            isPreStepDone: isPreStepDone({
              steps: PrecheckinSteps,
              stepId: PrecheckinSteps.SERVICES,
              lastConfirmedPage
            })
          },
          {
            id: PrecheckinSteps.PAYMENT,
            tooltipText: t(`labels__monitoring_tooltip_${PrecheckinSteps.PAYMENT}`),
            isPreStepDone: isPreStepDone({
              steps: PrecheckinSteps,
              stepId: PrecheckinSteps.PAYMENT,
              lastConfirmedPage
            })
          },
          {
            id: PrecheckinSteps.CONFIRMATION,
            tooltipText: t(`labels__monitoring_tooltip_${PrecheckinSteps.CONFIRMATION}`),
            isPreStepDone: isPreStepDone({
              steps: PrecheckinSteps,
              stepId: PrecheckinSteps.CONFIRMATION,
              lastConfirmedPage
            })
          }
        ]
      },
      {
        id: MonitoringSteps.CHECK_OUT,
        tooltipText: t(`labels__monitoring_tooltip_${MonitoringSteps.CHECK_OUT}`, {
          author: t(`labels__monitoring_legend_${reservation.checkedOutBy ?? "UNKNOWN"}`)
        }),
        author: reservation.checkedOutBy,
        dateTime: reservation.checkOutTime
          ? formatDateTime(reservation.checkOutTime, selectedProperty?.details.timeZone)
          : "",
        preSteps: checkoutPreSteps
      }
    ];
  }, [
    checkoutPreSteps,
    features?.checkoutFlowEnabled,
    features?.upsellUnitGroupEnabled,
    lastConfirmedPage,
    reservation.checkInTime,
    reservation.checkOutTime,
    reservation.checkedInBy,
    reservation.checkedOutBy,
    reservation.createdAt,
    reservation.primaryGuest.preferredCommunicationChannel,
    reservation.services?.length,
    selectedProperty?.details.timeZone,
    t
  ]);

  const currentStepId = useMemo(() => {
    if (reservation.reservationStatus === ReservationStatus.CHECKED_OUT) {
      return MonitoringSteps.CHECK_OUT;
    }

    if (reservation.reservationStatus === ReservationStatus.IN_HOUSE) {
      return MonitoringSteps.IN_HOUSE;
    }

    if (
      Object.values(PrecheckinSteps).find(
        (step) => step === `PRECHECKIN_${reservation.lastConfirmedPage}`
      )
    ) {
      return `PRECHECKIN_${reservation.lastConfirmedPage}`;
    }

    if (
      Object.values(PrecheckoutSteps).find(
        (step) => step === `PRECHECKOUT_${reservation.lastConfirmedCheckoutPage}`
      )
    ) {
      return `PRECHECKOUT_${reservation.lastConfirmedCheckoutPage}`;
    }

    return MonitoringSteps.BOOKING;
  }, [
    reservation.lastConfirmedCheckoutPage,
    reservation.lastConfirmedPage,
    reservation.reservationStatus
  ]);

  const flatSteps = useMemo(() => {
    return steps.reduce((acc, step) => {
      if (step.preSteps) {
        acc.push(...step.preSteps);
      }
      acc.push(step);
      return acc;
    }, [] as Step[]);
  }, [steps]);

  return { steps, currentStepId, flatSteps };
};
