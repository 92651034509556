import { JSONApiResponse } from "@likemagic-tech/sv-magic-library";
import { ApiKeyApiClient } from "./base/api-key-api-client";
import { Report } from "../domain/report";

const BASE_URL = "/api/guest-journey-service/reports";

class ReportsApiClient extends ApiKeyApiClient {
  async fetchBreakfastReport(
    date: string,
    propertyId: string,
    init?: RequestInit
  ): Promise<Report[]> {
    const response = await this.fetchApi(
      `${BASE_URL}/breakfast?propertyId=${propertyId}&date=${date}`,
      init
    );
    return new JSONApiResponse<Report[]>(response).value();
  }

  async fetchScheduledCleaningReport(
    date: string,
    propertyId: string,
    init?: RequestInit
  ): Promise<Report[]> {
    const response = await this.fetchApi(
      `${BASE_URL}/scheduled-cleanings?propertyId=${propertyId}&date=${date}`,
      init
    );
    return new JSONApiResponse<Report[]>(response).value();
  }
}

export const ReportsApi = new ReportsApiClient();
