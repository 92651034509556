import "react-perfect-scrollbar/dist/css/styles.css";
import "nprogress/nprogress.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import smoothscroll from "smoothscroll-polyfill";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// kick off the polyfill!
smoothscroll.polyfill();
