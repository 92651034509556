import { useCallback } from "react";
import { syncDoors } from "../slices/available-doors";
import { useDispatch } from "../store";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { DoorType } from "../api/door-delegate";
import { api as syncAdminDoorAccessApi } from "../graphql/mutations/admin-sync-door-access.generated";
import { transformDoorTypeCommonToSyncStatesV2 } from "../graphql/transform/transform-sync-states";

export const useSyncDoors = () => {
  const { isRESTVersion } = useApiVersion();
  const dispatch = useDispatch();
  const syncDoorsV1 = useCallback(
    ({ reservationId, doorType }: { reservationId: string; doorType: DoorType }) =>
      dispatch(syncDoors({ reservationId, doorType })),
    [dispatch]
  );
  const syncDoorsV2 = useCallback(
    ({ reservationId, doorType }: { reservationId: string; doorType: DoorType }) =>
      dispatch(
        syncAdminDoorAccessApi.endpoints.SyncDoorAccess.initiate({
          pmsReservationId: reservationId,
          status: transformDoorTypeCommonToSyncStatesV2(doorType)
        })
      ).unwrap(),
    [dispatch]
  );

  return { syncDoors: isRESTVersion ? syncDoorsV1 : syncDoorsV2 };
};
