import { Button, Heading2, Paragraph } from "@likemagic-tech/sv-magic-library";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { AllowedActionEnum } from "src/domain/reservation-table-dto";
import { openBanner } from "src/slices/banner.slice";
import { useDispatch } from "src/store";
import { ReservationActionsModal } from "../../../../../../components/menu/reservation-actions-modal";
import { usePerformReservationAction } from "src/hooks/use-reservation-actions";

interface EditReservationCommentModalProps extends ReservationActionsModal {}

export const EditReservationCommentModal: FC<EditReservationCommentModalProps> = ({
  reservation,
  isOpen,
  onClose
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const comment = reservation.notes.comment;

  const reservationCommentsDefaultValue = comment ? comment.join("\r\n") : "";
  const [commentToSend, setCommentToSend] = useState(reservationCommentsDefaultValue);
  const action = AllowedActionEnum.CHANGE_RESERVATION_COMMENT;
  const dispatch = useDispatch();

  const [performAction, result] = usePerformReservationAction();

  const handleAddComment = useCallback(() => {
    performAction({
      reservationId: reservation.id,
      action,
      payload: { reservationComment: commentToSend }
    });
  }, [performAction, reservation.id, action, commentToSend]);

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      result.reset();
      onClose();
    }
  }, [result, dispatch, t, onClose]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }
      }}
    >
      <Grid container p={3} flexDirection="column">
        <DialogTitle sx={{ flex: "1 1 0", padding: "0" }}>
          <Grid container direction="row">
            <Grid item xs={11}>
              <Heading2 sx={{ color: theme.palette.text.primary }}>
                {t("labels__tabs__comments__add__reservation__comment")}
              </Heading2>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="end">
              <IconButton onClick={onClose}>
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ padding: "0" }}>
          <TextField
            multiline
            defaultValue={reservationCommentsDefaultValue}
            sx={{ width: "100%" }}
            InputProps={{
              sx: { height: 270, alignItems: "baseline" }
            }}
            rows={10}
            placeholder={t("labels__modal__write__comment")}
            autoFocus
            onFocus={(e) => {
              if (reservationCommentsDefaultValue) {
                e.target.value = reservationCommentsDefaultValue + "\r\n";
              }
            }}
            onChange={(e) => {
              setCommentToSend(e.target.value);
            }}
          ></TextField>
        </DialogContent>
        <DialogActions sx={{ flex: "1 1 0", px: "0" }}>
          <Button
            variant="primary"
            onClick={() => handleAddComment()}
            fullWidth
            sx={{ marginTop: theme.spacing(5) }}
          >
            <Paragraph
              sx={{
                color: theme.palette.background.paper,
                textTransform: "none"
              }}
            >
              {t("labels__add")}
            </Paragraph>
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};
