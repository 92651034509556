import { createSelector, createSlice, PayloadAction, Selector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import { Price } from "../domain/price";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";

export interface PaymentSliceState {
  status: EntityStateStatus;
  paymentDTO: {
    price?: Price;
  };
  terminalPayment: TerminalPayment;
}

export interface TerminalPayment {
  promiseRef?: QueryActionCreatorResult<any>;
  price?: Price;
  paymentStatus: EntityStateStatus;
  propertyId?: string;
  serviceId?: string;
  transactionId?: string;
  selectedTerminal?: { label: string; value: string };
  openModal: boolean;
}

interface TerminalPaymentOptional {
  paymentStatus?: EntityStateStatus;
  promiseRef?: QueryActionCreatorResult<any>;
  price?: Price;
  propertyId?: string;
  serviceId?: string;
  transactionId?: string;
  selectedTerminal?: { label: string; value: string };
  openModal?: boolean;
}

const initialTerminalPayment = {
  paymentStatus: EntityStateStatus.IDLE,
  promiseRef: undefined,
  price: undefined,
  propertyId: undefined,
  serviceId: undefined,
  transactionId: undefined,
  openModal: false
};

const initialState: PaymentSliceState = {
  status: EntityStateStatus.IDLE,
  paymentDTO: { price: undefined },
  terminalPayment: initialTerminalPayment
};
export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPriceToPay: (state, action: PayloadAction<Price>) => {
      state.paymentDTO.price = action.payload;
    },
    setTerminalPayment: (state, action: PayloadAction<TerminalPaymentOptional>) => {
      state.terminalPayment = {
        ...state.terminalPayment,
        ...action.payload
      };
    },
    setTerminalPaymentModal: (state, action: PayloadAction<boolean>) => {
      state.terminalPayment.openModal = action.payload;
    },
    unsetTerminalPayment: (state) => {
      state.terminalPayment = initialTerminalPayment;
    }
  }
});

export const { setPriceToPay, setTerminalPaymentModal, setTerminalPayment, unsetTerminalPayment } =
  paymentSlice.actions;

const selectSelf: Selector<RootState, PaymentSliceState> = (state: RootState) =>
  state[paymentSlice.name];

export const selectPaymentDTO = createSelector(selectSelf, (res) => res.paymentDTO);
export const selectTerminalPayment = createSelector(selectSelf, (res) => res.terminalPayment);
export const selectTerminalPaymentModal = createSelector(
  selectSelf,
  (res) => res.terminalPayment.openModal
);
