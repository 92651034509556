import { useState, useEffect } from "react";
import enGBLocale from "date-fns/locale/en-GB";

const useDynamicLocale = () => {
  const [locale, setLocale] = useState(enGBLocale);

  useEffect(() => {
    const importLocaleFile = async () => {
      try {
        const localeToSet = await import(
          /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
          `date-fns/locale/${navigator.language}/index.js`
        );

        setLocale(localeToSet.default);
      } catch (e) {
        console.log("Error", e);
      }
    };

    importLocaleFile();
  }, []);

  return locale;
};

export default useDynamicLocale;
