import type { FC } from "react";
import { Grid } from "@mui/material";
import { ReportStatus } from "../../domain/report";
import { BreakfastStatusPreview } from "./breakfast-status-preview";

export const filterableStatuses = [ReportStatus.BOOKED, ReportStatus.OBTAINED];

interface BreakfastFilterProps {
  value?: ReportStatus;
  onConditionSelected: (status: ReportStatus) => void;
}

export const BreakfastFilter: FC<BreakfastFilterProps> = ({ value, onConditionSelected }) => {
  return (
    <Grid container spacing={1}>
      {filterableStatuses.map((status) => (
        <Grid item key={status}>
          <BreakfastStatusPreview
            status={status}
            selected={value === status}
            onClick={() => onConditionSelected(status)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
