import { Paragraph, Select } from "@likemagic-tech/sv-magic-library";
import { Box, Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import * as React from "react";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useTheme } from "@mui/material/styles";
import { FolioPreview } from "../../../../store/endpoints/reservation-table.endpoints";
import { useProperty } from "../../../../hooks/use-property";
import { FolioTargetType } from "../../../../graphql/generated/graphql";
import { useDispatch } from "../../../../store";
import { openBanner } from "../../../../slices/banner.slice";
import { useMovePaymentsMutation } from "src/graphql/mutations/move-payment.generated";
import { DialogTitleModal } from "../../../../components/submit-modal/dialog-title";

interface MovePaymentModalProps {
  reservationId: string;
  folios: Array<FolioPreview>;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  paymentPmsIds: string[];
  folioId: string;
  folioMetadata: any;
}

const getFolioMetadata = (folios: Array<FolioPreview>, folioId?: string) =>
  folios.find((item) => item.id === folioId)?.metadata;
export const MovePaymentsModal: FC<MovePaymentModalProps> = ({
  reservationId,
  isOpen,
  onClose,
  onSuccess,
  paymentPmsIds,
  folioId,
  folios
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [selectedTargetFolioId, setSelectedTargetFolioId] = useState<string | undefined>(undefined);
  const { selectedProperty } = useProperty();

  const [movePayments] = useMovePaymentsMutation();

  const isNewFolioSelected = useMemo(
    () => selectedTargetFolioId === "new",
    [selectedTargetFolioId]
  );

  const movePayment = useCallback(async () => {
    const folioMetadata = getFolioMetadata(folios, selectedTargetFolioId);
    await movePayments({
      movePaymentsInput: {
        folioTargetType: isNewFolioSelected
          ? FolioTargetType.NewFolio
          : FolioTargetType.ExistingFolio,
        pmsPaymentIds: paymentPmsIds,
        pmsPropertyId: selectedProperty?.propertyId ?? "",
        sourcePmsFolioId: folioId,
        sourcePmsReservationId: reservationId,
        targetFolioMetadata: isNewFolioSelected ? null : folioMetadata,
        targetPmsFolioId: isNewFolioSelected ? null : selectedTargetFolioId,
        targetPmsReservationId: reservationId
      }
    })
      .unwrap()
      .then(() => {
        dispatch(
          openBanner({
            type: "success",
            title: t("labels__payment_moved")
          })
        );
        onSuccess && onSuccess();
        onClose();
      })
      .catch(() => {
        dispatch(
          openBanner({
            type: "error",
            title: t("labels__error")
          })
        );
      });
  }, [
    folios,
    selectedTargetFolioId,
    movePayments,
    isNewFolioSelected,
    paymentPmsIds,
    selectedProperty?.propertyId,
    folioId,
    reservationId,
    dispatch,
    t,
    onSuccess,
    onClose
  ]);

  const handleClose = useCallback(() => {
    setSelectedTargetFolioId(undefined);
    onClose();
  }, [onClose]);

  // TODO - Number(folio.number) <= 100 should be removed once folio.closed is available
  const exsitingFolioOptions = folios
    .filter(
      (folio) =>
        (!folio.closed && folio.closed !== null) ||
        (Number(folio.number) <= 100 && folio.id !== folioId)
    )
    .map((folio) => ({
      value: folio.id,
      label: `${t("labels__folio")} ${folio.number}`
    }));

  const newFolioOption = useMemo(
    () => ({
      value: "new",
      label: t("labels__new_folio")
    }),
    [t]
  );

  const targetFolioOptions = useMemo(() => {
    return [...exsitingFolioOptions, newFolioOption];
  }, [exsitingFolioOptions, newFolioOption]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }
      }}
    >
      <Grid container p={3} flexDirection="column">
        <DialogTitleModal onClose={handleClose} title={t("labels__move_payment")} />
        <DialogContent sx={{ padding: "0" }}>
          <Box pt={3}>
            <Select
              variant="outlined"
              value={selectedTargetFolioId}
              options={targetFolioOptions}
              label={t("labels__select_target_folio")}
              disabled={targetFolioOptions.length === 0}
              onChange={(e) => setSelectedTargetFolioId(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ flex: "1 1 0", px: "0", flexDirection: "column" }}>
          <Button
            variant="primary"
            onClick={() => movePayment()}
            fullWidth
            sx={{ marginTop: theme.spacing(2) }}
            disabled={!selectedTargetFolioId}
          >
            <Paragraph
              sx={{
                color: theme.palette.background.paper,
                textTransform: "none"
              }}
            >
              {t("buttons__move_payment")}
            </Paragraph>
          </Button>
          <Button
            variant="secondary"
            onClick={() => onClose()}
            fullWidth
            sx={{ marginTop: theme.spacing(2), mr: 1 }}
          >
            <Paragraph
              sx={{
                textTransform: "none"
              }}
            >
              {t("buttons__cancel")}
            </Paragraph>
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};
