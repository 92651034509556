import { FC, PropsWithChildren, useContext } from "react";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { GraphqlClient } from "./graphql.api";
import { MessagingGraphqlClient } from "../graphql-messaging/messaging.api";

export const GraphqlTenantHeadersSetup: FC<PropsWithChildren> = ({ children }) => {
  const { apiKey, tenantId } = useContext(TenantContext);

  const headers = () => ({
    "tenant-id": tenantId,
    "sk-magic-api-key": apiKey || ""
  });

  GraphqlClient.setHeaders(headers());
  MessagingGraphqlClient.setHeaders(headers());

  return <>{children}</>;
};
