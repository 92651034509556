import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SupportIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <title>headphones-customer-support-question</title>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      d="M4.5,18H3.75a3,3,0,0,1-3-3V12a3,3,0,0,1,3-3H4.5a.75.75,0,0,1,.75.75v7.5A.75.75,0,0,1,4.5,18Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      d="M20.25,18H19.5a.75.75,0,0,1-.75-.75V9.75A.75.75,0,0,1,19.5,9h.75a3,3,0,0,1,3,3v3A3,3,0,0,1,20.25,18Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      d="M3.75,9A8.25,8.25,0,0,1,12,.75h0A8.25,8.25,0,0,1,20.25,9"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      d="M15,21.75h2.25a3,3,0,0,0,3-3h0V18"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      d="M13.5,23.25H12a1.5,1.5,0,0,1-1.5-1.5h0a1.5,1.5,0,0,1,1.5-1.5h1.5a1.5,1.5,0,0,1,1.5,1.5h0A1.5,1.5,0,0,1,13.5,23.25Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      d="M9,8.25a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415v.256"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      d="M12,15.75a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,15.75h0"
    />
  </SvgIcon>
);
