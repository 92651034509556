import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Params } from "../hooks/use-select-id";

interface DrawerStackInterface {
  key: string;
}
export const drawerStackAdapter = createEntityAdapter<DrawerStackInterface>({
  selectId: (model) => model.key
});

const initialState = drawerStackAdapter.getInitialState();

export const drawerStackSlice = createSlice({
  name: "drawerStack",
  initialState,
  reducers: {
    addDrawerItem(state, action: PayloadAction<string>) {
      if (
        ![
          Params.SELECTED_CONVERSATION_ID,
          Params.SELECTED_RESERVATION_ID,
          Params.PROFILE_ID_SEARCH_PARAM_NAME,

          Params.SELECTED_MANUAL_CHECKIN_RESERVATION_ID
        ].includes(action.payload as Params)
      ) {
        return;
      }
      if (state.ids.indexOf(action.payload) > -1) {
        drawerStackAdapter.removeOne(state, action.payload);
      }
      drawerStackAdapter.setOne(state, { key: action.payload });
    },
    removeDrawerItem(state, action: PayloadAction<string>) {
      drawerStackAdapter.removeOne(state, action.payload);
    }
  }
});

export const { addDrawerItem, removeDrawerItem } = drawerStackSlice.actions;

const selectSelf = (state: RootState) => state[drawerStackSlice.name];

export const selectDrawerItemIndex = createSelector(
  selectSelf,
  (_: any, drawerItem: string) => drawerItem,
  (s, drawerItem) => s.ids.indexOf(drawerItem)
);

export const selectIsTopDrawer = createSelector(
  selectSelf,
  (_: any, drawerItem: string) => drawerItem,
  (s, drawerItem) => s.ids.length === s.ids.indexOf(drawerItem) + 1
);
