import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import useSettings from "../../hooks/use-settings";
import { useProperty } from "../../hooks/use-property";
import { UnitConditionFilter } from "./unit-condition-filter";
import { Unit, UnitCondition } from "../../domain/Unit";
import { UnitDashboardDTO } from "../../domain/UnitDashboardDTO";
import { UnitCard } from "./unit-card";
import PageTitle from "src/components/page-title";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { SearchField } from "../../components/search-field/search-field";
import { ReloadButton } from "../../components/reload-button";
import { responsiveVariables } from "../../hooks/use-is-mobile";
import { useTheme } from "@mui/material/styles";
import { useUnitsWithAvailableDoors } from "src/hooks/use-units-with-available-doors";
import { useUnits } from "src/hooks/use-unit-by-id";
import { UnitsActions } from "./units-actions";
import { UnitActionType } from "../dirty-units/dirty-units-actions";
import { MaintenanceModal } from "src/components/maintenance-modal/maintenance-modal";
import { useAddMaintenanceSlot } from "src/hooks/use-maintenance";
import { MaintenanceSlotArgs } from "src/store/endpoints/unit.endpoints";
import { useFetchUnitsToBeCleaned } from "src/hooks/use-fetch-units-to-be-cleaned";
import { useDispatch } from "src/store";
import { openBanner } from "src/slices/banner.slice";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";

export const UnitsPage: FC = () => {
  const { t } = useTranslationWrapper();
  const [freeTextSearch, setFreeTextSearch] = useState<string>();
  const { breakpoints, spacing } = useTheme();
  const [maintenanceModalOpen, setMaintenanceModalOpen] = useState(false);
  const { selectedProperty, selectedPropertiesPreview } = useProperty();
  const [addMaintenance, addResult] = useAddMaintenanceSlot();
  const { fetchAction } = useFetchUnitsToBeCleaned({
    propertyId: selectedProperty?.propertyId ?? ""
  });
  const { isRESTVersion } = useApiVersion();
  const dispatch = useDispatch();

  const [conditionFilter, setConditionFilter] = useState<UnitCondition | null>(null);
  const { fetchAction: reloadUnitsAction } = useUnits({
    propertyId: selectedProperty?.propertyId ?? "",
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (addResult?.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      addResult.reset();
      fetchAction();
      setMaintenanceModalOpen(false);
    }
  }, [dispatch, t, addResult, fetchAction]);

  const reloadUnits = useCallback(async () => {
    if (!selectedProperty?.propertyId) {
      return {};
    }

    await reloadUnitsAction();
  }, [selectedProperty?.propertyId, reloadUnitsAction]);

  const units: UnitDashboardDTO[] | undefined = useUnitsWithAvailableDoors(
    selectedProperty?.propertyId ?? ""
  );
  const { settings } = useSettings();

  const unitsWithFilter = useMemo(
    () =>
      (conditionFilter
        ? units?.filter((value) => value?.status?.condition === conditionFilter)
        : units
      )
        ?.filter((value) => !freeTextSearch || value.name.indexOf(freeTextSearch) > -1)
        .sort((a: Unit, b: Unit) => a.name.localeCompare(b.name)),
    [units, conditionFilter, freeTextSearch]
  );

  const setConditionFilterWithDeselect = useCallback(
    (unitCondition: UnitCondition) => {
      if (unitCondition === conditionFilter) {
        setConditionFilter(null);
      } else {
        setConditionFilter(unitCondition);
      }
    },
    [conditionFilter]
  );

  const bulkAction = async (selectedItem: string) => {
    if (selectedItem === UnitActionType.MAINTENANCE) {
      setMaintenanceModalOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 4
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container rowGap={3}>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              md={12}
              xs={10}
            >
              <PageTitle subTitle={selectedPropertiesPreview} title={t("labels__units")} />
            </Grid>
            <Grid item md={6} xs={2}>
              <Box
                sx={{
                  textAlign: "right",
                  [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
                    position: "absolute",
                    right: spacing(3),
                    width: "80%"
                  }
                }}
              >
                <SearchField
                  value={freeTextSearch ?? ""}
                  onChange={setFreeTextSearch}
                  placeholder={t("labels__units_search")}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={0} />
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <UnitConditionFilter
                    value={conditionFilter ?? undefined}
                    onConditionSelected={setConditionFilterWithDeselect}
                  />
                </Grid>

                {isRESTVersion && (
                  <Grid gap={1} item display="flex" alignItems={"center"}>
                    <UnitsActions onClick={bulkAction} />
                    <ReloadButton
                      onClick={() => {
                        reloadUnits();
                        reloadUnitsAction();
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2} pb={3}>
              {unitsWithFilter?.map((unit) => (
                <UnitCard
                  key={`${unit.id}_${unit.name}`}
                  unit={unit}
                  propertyId={selectedProperty?.propertyId ?? ""}
                  refreshUnitList={reloadUnits}
                />
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
      {!!maintenanceModalOpen && (
        <MaintenanceModal
          onSubmit={(values: MaintenanceSlotArgs) => addMaintenance(values)}
          onClose={() => setMaintenanceModalOpen(false)}
        />
      )}
    </>
  );
};
