import { SvgIcon, SvgIconProps } from "@mui/material";

export function EarlyCheckin(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" sx={{ color: "common.white" }} {...props}>
      <g clipPath="url(#clip0_1197_790)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2461 5.99829C20.8428 5.99829 21.4151 6.23534 21.8371 6.6573C22.259 7.07926 22.4961 7.65155 22.4961 8.24829V17.2483C22.4961 17.845 22.259 18.4173 21.8371 18.8393C21.4151 19.2612 20.8428 19.4983 20.2461 19.4983H14.9921C14.5779 19.4983 14.2421 19.8341 14.2421 20.2483C14.2421 20.6625 14.5779 20.9983 14.9921 20.9983H20.2461C21.2407 20.9983 22.1945 20.6032 22.8977 19.8999C23.601 19.1967 23.9961 18.2429 23.9961 17.2483V8.24829C23.9961 7.25373 23.601 6.2999 22.8977 5.59664C22.1945 4.89338 21.2407 4.49829 20.2461 4.49829H6.74609C5.75153 4.49829 4.7977 4.89338 4.09444 5.59664C3.39118 6.2999 2.99609 7.25373 2.99609 8.24829V9.00029C2.99609 9.4145 3.33188 9.75029 3.74609 9.75029C4.16031 9.75029 4.49609 9.4145 4.49609 9.00029V8.24829C4.49609 7.65155 4.73315 7.07926 5.1551 6.6573C5.57706 6.23534 6.14936 5.99829 6.74609 5.99829H20.2461Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9961 4.49829C17.5819 4.49829 17.2461 4.83408 17.2461 5.24829V20.2483C17.2461 20.6625 17.5819 20.9983 17.9961 20.9983C18.4103 20.9983 18.7461 20.6625 18.7461 20.2483V5.24829C18.7461 4.83408 18.4103 4.49829 17.9961 4.49829Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.99609 4.49829C8.58188 4.49829 8.24609 4.83408 8.24609 5.24829V8.99929C8.24609 9.4135 8.58188 9.74929 8.99609 9.74929C9.41031 9.74929 9.74609 9.4135 9.74609 8.99929V5.24829C9.74609 4.83408 9.41031 4.49829 8.99609 4.49829Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.409 0.657301C10.831 0.235344 11.4033 -0.00170898 12 -0.00170898H15C15.2955 -0.00170898 15.5881 0.0564889 15.861 0.169562C16.134 0.282635 16.3821 0.448369 16.591 0.657301C16.7999 0.866233 16.9657 1.11427 17.0787 1.38725C17.1918 1.66024 17.25 1.95282 17.25 2.24829V5.24829C17.25 5.6625 16.9142 5.99829 16.5 5.99829C16.0858 5.99829 15.75 5.6625 15.75 5.24829V2.24829C15.75 2.1498 15.7306 2.05227 15.6929 1.96128C15.6552 1.87028 15.6 1.7876 15.5303 1.71796C15.4607 1.64832 15.378 1.59307 15.287 1.55538C15.196 1.51769 15.0985 1.49829 15 1.49829H12C11.8011 1.49829 11.6103 1.57731 11.4697 1.71796C11.329 1.85861 11.25 2.04938 11.25 2.24829V5.24829C11.25 5.6625 10.9142 5.99829 10.5 5.99829C10.0858 5.99829 9.75 5.6625 9.75 5.24829V2.24829C9.75 1.65155 9.98705 1.07926 10.409 0.657301Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.75 12.0002C3.85051 12.0002 1.5 14.3507 1.5 17.2502C1.5 20.1497 3.85051 22.5002 6.75 22.5002C9.6495 22.5002 12 20.1497 12 17.2502C12 14.3507 9.6495 12.0002 6.75 12.0002ZM0 17.2502C0 13.5223 3.02208 10.5002 6.75 10.5002C10.4779 10.5002 13.5 13.5223 13.5 17.2502C13.5 20.9782 10.4779 24.0002 6.75 24.0002C3.02208 24.0002 0 20.9782 0 17.2502Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.75 13.8483C7.16421 13.8483 7.5 14.1841 7.5 14.5983V16.5003H9.401C9.81521 16.5003 10.151 16.8361 10.151 17.2503C10.151 17.6645 9.81521 18.0003 9.401 18.0003H6.75C6.33579 18.0003 6 17.6645 6 17.2503V14.5983C6 14.1841 6.33579 13.8483 6.75 13.8483Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1197_790">
          <rect width="24" height="24" fill="white" transform="translate(0 0.000244141)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
