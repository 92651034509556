import { FC } from "react";
import { ChatItemContentBaseProps } from "./domain/chat-item-content-base-props";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTheme } from "@mui/material/styles";

interface ChatItemContentImageProps extends ChatItemContentBaseProps {
  caption?: string;
}

export const ChatItemContentImage: FC<ChatItemContentImageProps> = ({
  content,
  caption,
  isLoggedUser
}) => {
  const { palette } = useTheme();

  return (
    <>
      <img alt={content?.url} width="100%" src={content} />
      <Paragraph
        sx={{
          color: isLoggedUser ? palette.background.paper : palette.primary.main
        }}
      >
        {caption}
      </Paragraph>
    </>
  );
};
