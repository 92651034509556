import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type RemovePreferenceFromProfileMutationVariables = Types.Exact<{
  userProfileId: Types.Scalars["Int"]["input"];
  pmsPreferenceId: Types.Scalars["String"]["input"];
}>;

export type RemovePreferenceFromProfileMutation = {
  __typename?: "Mutation";
  RemovePreferenceFromProfile: {
    __typename?: "UserProfile";
    id: number;
    userAccountUuid?: string | null;
  };
};

export const RemovePreferenceFromProfileDocument = `
    mutation RemovePreferenceFromProfile($userProfileId: Int!, $pmsPreferenceId: String!) {
  RemovePreferenceFromProfile(
    userProfileId: $userProfileId
    pmsPreferenceId: $pmsPreferenceId
  ) {
    id
    userAccountUuid
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    RemovePreferenceFromProfile: build.mutation<
      RemovePreferenceFromProfileMutation,
      RemovePreferenceFromProfileMutationVariables
    >({
      query: (variables) => ({ document: RemovePreferenceFromProfileDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useRemovePreferenceFromProfileMutation } = injectedRtkApi;
