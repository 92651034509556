import { BedOutlined, CleaningServicesOutlined } from "@mui/icons-material";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Box, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { TaskDto, TaskType } from "../../../graphql-tasks/generated/graphql";
import { FC } from "react";

const useStyles = makeStyles()((theme) => ({
  box: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    padding: "8px 0",
    borderRadius: theme.spacing(0.5),
    border: "1px solid",
    borderColor: theme.palette.warning.dark,
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1)
  }
}));

const mapTaskTypeLabel = (type: TaskType) => {
  switch (type) {
    case TaskType.StayoverCleaning:
      return "labels__stayover_cleaning";
    case TaskType.DepartureCleaning:
      return "labels__departure_cleaning";
    default:
  }
};

const mapTaskTypeIcon = (type: TaskType) => {
  switch (type) {
    case TaskType.StayoverCleaning:
      return <BedOutlined fontSize="small" />;
    case TaskType.DepartureCleaning:
      return <CleaningServicesOutlined fontSize="small" />;
    default:
  }
};

interface HousekeepingCardServiceTypeProps {
  task: TaskDto | undefined;
}

export const HousekeepingCardServiceType: FC<HousekeepingCardServiceTypeProps> = ({ task }) => {
  const { classes } = useStyles();
  const { palette } = useTheme();
  const { t } = useTranslationWrapper();
  return task ? (
    <Box textAlign="center" className={classes.box}>
      {mapTaskTypeIcon(task?.type)}
      <ParagraphSmall color={palette.warning.dark}>
        {t(mapTaskTypeLabel(task?.type) ?? "")}
      </ParagraphSmall>
    </Box>
  ) : (
    <></>
  );
};
