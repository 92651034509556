import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useConditionCountsQuery } from "src/graphql/queries/GetConditionCounts.generated";
import {
  transformReservationConditionsCountV2ToCommon,
  transformValueV2ByTheName
} from "src/graphql/transform/transform-reservation-overview-filters";
import { useGetReservationConditionDTOQuery } from "src/store/endpoints/reservation-table.endpoints";
import { FilterObject } from "src/types/filters/filters";
import { transformFiltersToGraphqlFilters } from "../utils/filters";

export const useReservationConditions = ({
  propertyId,
  filterParams
}: {
  propertyId?: string;
  filterParams: FilterObject[];
}) => {
  const { isGraphQlVersion, isRESTVersion } = useApiVersion();

  const { data: dataConditionsV1 } = useGetReservationConditionDTOQuery(
    {
      propertyId: propertyId ?? "",
      filterParams
    },
    {
      skip: !propertyId || isGraphQlVersion,
      refetchOnMountOrArgChange: true
    }
  );

  const { data: dataConditionsV2 } = useConditionCountsQuery(
    {
      filter: {
        pmsPropertyId: propertyId ?? "",
        filters: transformFiltersToGraphqlFilters(filterParams, transformValueV2ByTheName)
      }
    },
    {
      skip: !propertyId || isRESTVersion,
      refetchOnMountOrArgChange: true
    }
  );

  return isRESTVersion
    ? dataConditionsV1
    : transformReservationConditionsCountV2ToCommon(dataConditionsV2);
};
