import React from "react";
import { Avatar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { AvatarProps, AvatarTypeMap } from "@mui/material/Avatar/Avatar";

type UserAvatarTypeMap = AvatarTypeMap<{ lettersToDisplay?: string }>;

export const UserAvatar: OverridableComponent<UserAvatarTypeMap> = (
  props: AvatarProps & { lettersToDisplay?: string }
) => {
  const { lettersToDisplay, ...avatarProps } = props;
  if (lettersToDisplay) {
    return <Avatar {...avatarProps}>{lettersToDisplay}</Avatar>;
  }
  return (
    <Avatar {...avatarProps}>
      <PersonIcon />
    </Avatar>
  );
};
