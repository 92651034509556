import "./App.css";
import "./config/i18n/i18n-config";
import { AppProviders } from "./app-providers";
import { Content } from "./content";
import { DataPreloader } from "./components/data-preloader/data-preloader";
import { Manifest } from "./components/manifest";
import AuthGuard from "./components/auth-guard";

function App() {
  return (
    <AppProviders>
      <Manifest />
      <AuthGuard>
        <DataPreloader>
          <Content />
        </DataPreloader>
      </AuthGuard>
    </AppProviders>
  );
}

export default App;
