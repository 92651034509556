import React, { FC } from "react";
import { Box, Divider } from "@mui/material";
import { EmptyState, Heading4 } from "@likemagic-tech/sv-magic-library";
import { Services } from "./services";
import { transformAdditionalServiceToService } from "../../../../../graphql/transform/transform-additional-services-availability";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { AdditionalServiceWithPrice, Service } from "../../../../../domain/service";

interface ServicesWithCartProps {
  additionalServices: Array<AdditionalServiceWithPrice>;
  bookedServices: Array<Service>;
  preferredCleaningDays: string[] | null;
  onRemove: (service: Service) => void;
}

export const ServicesWithCart: FC<ServicesWithCartProps> = ({
  additionalServices,
  bookedServices,
  onRemove,
  preferredCleaningDays
}) => {
  const { t } = useTranslationWrapper();

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {additionalServices && additionalServices.length > 0 ? (
        <>
          <Heading4 sx={{ marginBottom: 1.5 }}>{t("labels__wizard_additional_services")}</Heading4>
          <Services
            services={additionalServices.map(transformAdditionalServiceToService)}
            serviceOnRemove={onRemove}
            preferredCleaningDays={preferredCleaningDays}
            addOns
          />
          <Divider sx={{ my: 2 }} />
        </>
      ) : null}
      <Heading4 sx={{ marginTop: additionalServices?.length ? 2 : 0, marginBottom: 1.5 }}>
        {t("labels__wizard_booked_services")}
      </Heading4>
      {bookedServices.length > 0 ? (
        <Services services={bookedServices} preferredCleaningDays={preferredCleaningDays} />
      ) : (
        <EmptyState title={t("labels__no__services__in__reservation")} />
      )}
    </Box>
  );
};
