import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { Button, useTheme } from "@mui/material";
import { FC } from "react";

interface DialogActionModalProps {
  title: string;
  handleSubmit?: () => void | null;
  disabled?: boolean | null;
  mt?: number;
}

export const DialogActionModal: FC<DialogActionModalProps> = ({
  title,
  handleSubmit,
  disabled,
  mt
}) => {
  const theme = useTheme();
  return (
    <Button
      variant="primary"
      onClick={handleSubmit}
      fullWidth
      type="submit"
      disabled={disabled ?? false}
      sx={{ marginTop: mt ?? theme.spacing(5) }}
    >
      <Paragraph
        sx={{
          color: theme.palette.background.paper,
          textTransform: "none"
        }}
      >
        {title}
      </Paragraph>
    </Button>
  );
};
