import * as Types from "../generated/graphql";

import { PersonFragmentFragmentDoc } from "../fragments/Person.generated";
import { graphqlApi } from "../graphql.api";
export type OverviewTableQueryVariables = Types.Exact<{
  filter: Types.BackofficeReservationOverviewFilter;
}>;

export type OverviewTableQuery = {
  __typename?: "Query";
  OverviewTable?: {
    __typename?: "BackofficeReservationOverviewTablePage";
    totalElements: number;
    content: Array<{
      __typename?: "BackofficeReservationOverviewDetail";
      adultsCount: number;
      allowedActions: Array<Types.ReservationAction>;
      arrival: any;
      bookedPmsUnitGroupId?: string | null;
      bookingChannel?: Types.BookingChannelFilterValue | null;
      bookingMagicLink: string;
      estimatedArrivalTime?: any | null;
      estimatedDepartureTime?: any | null;
      checkinTime?: any | null;
      checkoutTime?: any | null;
      checkoutOnAr?: boolean | null;
      childrenAges?: Array<number> | null;
      childrenCount: number;
      completed?: boolean | null;
      contractualCheckInTime?: any | null;
      createdAt: any;
      departure: any;
      idCheckStatus: Types.IdCheckStatus;
      lastConfirmedPage?: Types.GuestFlowCheckPoint | null;
      magicId: string;
      magicLink: string;
      magicToken: string;
      pmsOriginalPropertyId: string;
      pmsPropertyId: string;
      pmsReservationId: string;
      primaryGuestSameAsBooker?: boolean | null;
      reservationCondition?: Types.ReservationCondition | null;
      reservationConditionReasons?: any | null;
      reservationIsPaid?: boolean | null;
      reservationStatus?: Types.ReservationStatus | null;
      unitCleanOnCheckin?: boolean | null;
      booker?: {
        __typename?: "Person";
        id: number;
        pmsId: string;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        email?: string | null;
        gender?: Types.Gender | null;
        birthdate?: any | null;
        age?: number | null;
        isMinor?: boolean | null;
        preferredCommunicationChannel?: Types.CommunicationChannel | null;
        termsAndConditionsGeneral: boolean;
        termsAndConditionsOnline: boolean;
        termsAndConditionsMarketingConsent: boolean;
        termsAndConditionsMarketingConsentDoubleOptin: boolean;
        emergencyEvacuationHelpNeeded: boolean;
        enrolledInLoyaltyProgram: boolean;
        identificationDocumentNumber?: string | null;
        identificationDocumentType?: string | null;
        userProfileId?: number | null;
        preferredLanguage?: string | null;
        nationality?: string | null;
        vipCode?: string | null;
        licensePlate?: string | null;
        publicTransportationTickets?: Array<{
          __typename?: "PublicTransportationTicket";
          code?: string | null;
          link?: string | null;
          name?: string | null;
          valid?: boolean | null;
          validUntil?: any | null;
          ownerMagicId?: string | null;
        } | null> | null;
        userProfile?: {
          __typename?: "UserProfile";
          isRecurring: boolean;
          userAccountUuid?: string | null;
          blacklisted: boolean;
          licensePlate?: string | null;
          preferences: Array<{
            __typename?: "Preference";
            pmsId: string;
            pmsPreferenceCategoryId: string;
            name: string;
            description?: string | null;
            pmsPropertyId?: string | null;
          }>;
          notes: Array<{
            __typename?: "ProfileNote";
            pmsId: string;
            title?: string | null;
            text?: string | null;
            pmsPropertyId?: string | null;
            pmsProfileNoteCategoryId?: string | null;
            category?: {
              __typename?: "ProfileNoteCategory";
              pmsId: string;
              name?: string | null;
            } | null;
          }>;
        } | null;
        address?: {
          __typename?: "Address";
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          countryCode?: string | null;
          postalCode?: string | null;
          region?: string | null;
        } | null;
        membershipAccounts: Array<{
          __typename?: "MembershipAccount";
          membershipTypeCode?: string | null;
          membershipId?: string | null;
          membershipClass?: Types.MemberShipClassEnum | null;
          pmsId?: string | null;
          name?: string | null;
        }>;
      } | null;
      files: Array<{
        __typename?: "BackofficeMagicFileDescriptor";
        contentType: string;
        fileName: string;
        metaData?: any | null;
      }>;
      notes?: {
        __typename?: "BackofficeNote";
        previousVisits?: number | null;
        guestComment?: Array<string> | null;
        bookerComment?: Array<string> | null;
        bookingComment?: Array<string> | null;
        rateBreakdownComment?: Array<string> | null;
        beddingComment?: Array<string> | null;
        comment?: Array<string> | null;
      } | null;
      primaryGuest?: {
        __typename?: "Person";
        id: number;
        pmsId: string;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        email?: string | null;
        gender?: Types.Gender | null;
        birthdate?: any | null;
        age?: number | null;
        isMinor?: boolean | null;
        preferredCommunicationChannel?: Types.CommunicationChannel | null;
        termsAndConditionsGeneral: boolean;
        termsAndConditionsOnline: boolean;
        termsAndConditionsMarketingConsent: boolean;
        termsAndConditionsMarketingConsentDoubleOptin: boolean;
        emergencyEvacuationHelpNeeded: boolean;
        enrolledInLoyaltyProgram: boolean;
        identificationDocumentNumber?: string | null;
        identificationDocumentType?: string | null;
        userProfileId?: number | null;
        preferredLanguage?: string | null;
        nationality?: string | null;
        vipCode?: string | null;
        licensePlate?: string | null;
        publicTransportationTickets?: Array<{
          __typename?: "PublicTransportationTicket";
          code?: string | null;
          link?: string | null;
          name?: string | null;
          valid?: boolean | null;
          validUntil?: any | null;
          ownerMagicId?: string | null;
        } | null> | null;
        userProfile?: {
          __typename?: "UserProfile";
          isRecurring: boolean;
          userAccountUuid?: string | null;
          blacklisted: boolean;
          licensePlate?: string | null;
          preferences: Array<{
            __typename?: "Preference";
            pmsId: string;
            pmsPreferenceCategoryId: string;
            name: string;
            description?: string | null;
            pmsPropertyId?: string | null;
          }>;
          notes: Array<{
            __typename?: "ProfileNote";
            pmsId: string;
            title?: string | null;
            text?: string | null;
            pmsPropertyId?: string | null;
            pmsProfileNoteCategoryId?: string | null;
            category?: {
              __typename?: "ProfileNoteCategory";
              pmsId: string;
              name?: string | null;
            } | null;
          }>;
        } | null;
        address?: {
          __typename?: "Address";
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          countryCode?: string | null;
          postalCode?: string | null;
          region?: string | null;
        } | null;
        membershipAccounts: Array<{
          __typename?: "MembershipAccount";
          membershipTypeCode?: string | null;
          membershipId?: string | null;
          membershipClass?: Types.MemberShipClassEnum | null;
          pmsId?: string | null;
          name?: string | null;
        }>;
      } | null;
      unit?: {
        __typename?: "BackofficeUnit";
        pmsUnitId?: string | null;
        pmsUnitGroupId?: string | null;
        name?: string | null;
        privacyMode?: boolean | null;
        privacyModeLastChangeTimestamp?: any | null;
        condition?: Types.UnitState | null;
      } | null;
    }>;
  } | null;
};

export const OverviewTableDocument = `
    query OverviewTable($filter: BackofficeReservationOverviewFilter!) {
  OverviewTable(filter: $filter) {
    totalElements
    content {
      adultsCount
      allowedActions
      arrival
      bookedPmsUnitGroupId
      booker {
        ...PersonFragment
      }
      bookingChannel
      bookingMagicLink
      estimatedArrivalTime
      estimatedDepartureTime
      checkinTime
      checkoutTime
      checkoutOnAr
      childrenAges
      childrenCount
      completed
      contractualCheckInTime
      createdAt
      departure
      files {
        contentType
        fileName
        metaData
      }
      idCheckStatus
      lastConfirmedPage
      magicId
      magicLink
      magicToken
      notes {
        previousVisits
        guestComment
        bookerComment
        bookingComment
        rateBreakdownComment
        beddingComment
        comment
      }
      pmsOriginalPropertyId
      pmsPropertyId
      pmsReservationId
      primaryGuest {
        ...PersonFragment
      }
      primaryGuestSameAsBooker
      reservationCondition
      reservationConditionReasons
      reservationIsPaid
      reservationStatus
      unit {
        pmsUnitId
        pmsUnitGroupId
        name
        privacyMode
        privacyModeLastChangeTimestamp
        condition
      }
      unitCleanOnCheckin
      idCheckStatus
    }
  }
}
    ${PersonFragmentFragmentDoc}`;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    OverviewTable: build.query<OverviewTableQuery, OverviewTableQueryVariables>({
      query: (variables) => ({ document: OverviewTableDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useOverviewTableQuery, useLazyOverviewTableQuery } = injectedRtkApi;
