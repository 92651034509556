import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type TerminalPaymentSubscriptionVariables = Types.Exact<{
  paymentRequest?: Types.InputMaybe<Types.TerminalPaymentRequest>;
}>;

export type TerminalPaymentSubscription = {
  __typename?: "Subscription";
  TerminalPayment?: {
    __typename?: "TerminalResponse";
    success?: boolean | null;
    errorCode?: string | null;
    errorReason?: string | null;
    serviceId?: string | null;
    transactionId?: string | null;
  } | null;
};

export const TerminalPaymentDocument = `
    subscription TerminalPayment($paymentRequest: TerminalPaymentRequest) {
  TerminalPayment(paymentRequest: $paymentRequest) {
    success
    errorCode
    errorReason
    serviceId
    transactionId
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({})
});

export { injectedRtkApi as api };
export const {} = injectedRtkApi;
