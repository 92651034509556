import { FC, useCallback, useEffect, useState } from "react";
import { Unit } from "src/domain/Unit";
import { PreferenceTypes } from "src/domain/reservation";
import { useProperty } from "src/hooks/use-property";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";

interface PreferanceLabelProps {
  preference: any;
  preferenceType: any;
  units: Unit[];
}

export const PreferenceLabel: FC<PreferanceLabelProps> = ({
  preference,
  preferenceType,
  units
}) => {
  const { getProperty } = useProperty();
  const { t } = useTranslationWrapper();
  const [label, setLabel] = useState("");

  const getPropertyName = useCallback(
    (propertyId: string) => {
      const property = getProperty(propertyId);
      return `${property?.details?.name}, `;
    },
    [getProperty]
  );

  useEffect(() => {
    if (preferenceType === PreferenceTypes.SPECIFIC_UNIT) {
      const unit = units?.find((unit) => unit?.id === preference?.unitId);
      const unitName = unit?.name || "";
      const propertyName = getPropertyName(preference?.propertyId);

      const preferenceLabel = `${propertyName} ${t("labels__unit")} ${unitName}`;
      setLabel(preferenceLabel);
    } else {
      setLabel(t(`labels__preference_type_${preference}`, { note: preference }));
    }
  }, [t, units, getPropertyName, preference, preferenceType]);

  return <>{label}</>;
};
