import { Box, Grid, Paper } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { ReservationDetailsDTO } from "../../../../../store/endpoints/reservation-table.endpoints";
import { useProperty } from "../../../../../hooks/use-property";
import { Button, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { AdditionalServicesAutocomplete } from "../../../components/services/additional-services-autocomplete";
import { AdditionalServiceWithPrice, Service } from "../../../../../domain/service";
import { sumPrices } from "../../../../../utils/price";
import { ServicesWithCart } from "./services-with-cart";
import { useAddServicesToReservationMutationEnhanced } from "src/graphql/mutations/enhanced-mutations/add-services-to-reservation-enhanced";

interface ReservationDetailsServicesProps {
  reservation: ReservationDetailsDTO;
}

const ReservationDetailsServices: FC<ReservationDetailsServicesProps> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });
  const [addServicesToReservation] = useAddServicesToReservationMutationEnhanced();
  const [additionalServices, setAdditionalServices] = useState<AdditionalServiceWithPrice[]>([]);

  const onSubmit = async () => {
    const price = sumPrices(additionalServices?.map((item) => item.price) ?? []);
    if (price) {
      await addServicesToReservation({
        pmsReservationId: reservation.id ?? "",
        services: additionalServices.map((serviceToAdd) => ({
          dates: serviceToAdd.dates,
          pmsServiceId: serviceToAdd.serviceId,
          quantity: serviceToAdd.quantity
        }))
      });

      setAdditionalServices([]);
    }
  };
  const onRemove = (service: Service) => {
    setAdditionalServices((prev) => prev.filter((s) => s.serviceId !== service.serviceId));
  };

  if (!reservation) {
    return <></>;
  }

  return (
    <Box>
      {features?.tfeFeatureEnabled && (
        <Grid mt={3} container justifyContent="flex-end" alignItems="center">
          <Grid item xs={3}>
            <AdditionalServicesAutocomplete
              setAdditionalServices={setAdditionalServices}
              additionalServices={additionalServices}
              reservationDetails={reservation}
            />
          </Grid>
        </Grid>
      )}
      <Box mt={3}>
        <Paper sx={{ p: 3 }} elevation={0}>
          <ServicesWithCart
            additionalServices={additionalServices}
            preferredCleaningDays={reservation.preferredCleaningDays}
            bookedServices={reservation.services}
            onRemove={onRemove}
          />
        </Paper>
      </Box>
      {features?.tfeFeatureEnabled && (
        <Box textAlign="right" mt={3}>
          <Button variant="primary" onClick={onSubmit} disabled={!additionalServices.length}>
            {t("labels__confirm")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ReservationDetailsServices;
