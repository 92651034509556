import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "../store";
import { useAddMaintenanceSlot } from "./use-maintenance";
import { useProperty } from "./use-property";
import { openBanner } from "../slices/banner.slice";
import { useTranslationWrapper } from "./use-translation-wrapper";
import { useTaskModal } from "../features/tasks/use-task-select-id";
import { HousekeepingActionType } from "../pages/housekeeping/housekeeping-units-to-clean-actions";
import { useBulkSetTasksToDoneMutationEnhanced } from "../graphql-tasks/mutations/enhanced-mutations/bulk-set-tasks-to-done-enhanced";

export const useHousekeepingActions = ({ allTaskIds }: { allTaskIds: number[] }) => {
  const { selectedProperty } = useProperty();
  const { t } = useTranslationWrapper();
  const [maintenanceModalOpen, setMaintenanceModalOpen] = useState(false);
  const [addMaintenance, addResult] = useAddMaintenanceSlot();
  const dispatch = useDispatch();
  const { openNewTask } = useTaskModal({});
  const [bulkSetTasksToDone] = useBulkSetTasksToDoneMutationEnhanced();

  const setAllTasksToDone = useCallback(() => {
    bulkSetTasksToDone({
      pmsPropertyId: selectedProperty?.propertyId ?? "",
      taskIds: allTaskIds
    });
  }, [bulkSetTasksToDone, allTaskIds, selectedProperty?.propertyId]);

  const bulkAction = async (selectedItem: string) => {
    switch (selectedItem) {
      case HousekeepingActionType.SET_ALL_TASKS_TO_DONE:
        setAllTasksToDone();
        break;
      case HousekeepingActionType.ADD_MAINTENANCE:
        setMaintenanceModalOpen(true);
        return;
      case HousekeepingActionType.CREATE_TASK:
        return openNewTask();
    }
  };

  useEffect(() => {
    if (addResult?.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      addResult.reset();
      setMaintenanceModalOpen(false);
    }
  }, [dispatch, t, addResult]);

  return {
    bulkAction,
    addMaintenance,
    maintenanceModalOpen,
    setMaintenanceModalOpen
  };
};
