import React, { FC, useCallback } from "react";
import { Grid } from "@mui/material";
import { PricePicker } from "../../../../components/price-picker/price-picker";
import { Price } from "../../../../domain/price";
import { Button } from "@likemagic-tech/sv-magic-library";
import { Form, Formik } from "formik";
import { PaymentComponentProps } from "../payment-component";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { transformToGrossPrice } from "../../../../graphql/transform/transform-utils";
import { useCashPaymentMutation } from "../../../../graphql/mutations/CachPayment.generated";
import { parsePricePickerAmountToAmountInCents } from "../../../../utils/price";

export const PaymentCashComponent: FC<PaymentComponentProps> = ({
  price,
  magicId,
  folioMetadata,
  onSuccess
}) => {
  const { t } = useTranslationWrapper();
  const [payWithCashAction] = useCashPaymentMutation();

  const payWithCash = useCallback(
    (priceToPay: Price) => {
      payWithCashAction({
        magicId: magicId,
        cashPaymentRequest: {
          reference: magicId,
          amount: transformToGrossPrice(priceToPay),
          targetFolioMetadata: folioMetadata
        }
      })
        .unwrap()
        .then(() => {
          onSuccess();
        });
    },
    [onSuccess, magicId, payWithCashAction, folioMetadata]
  );
  const initialValues = {
    price: {
      ...price,
      amount: price.amount / 100
    }
  };

  const handleSubmit = (values: { price: { amount: number; currency: string } }) => {
    payWithCash({
      amount: parsePricePickerAmountToAmountInCents(values.price.amount),
      currency: values.price.currency
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => (
        <Form id="cash-payment">
          <Grid item xs={12} mb={2}>
            <PricePicker
              value={formik.values.price}
              onChange={formik.handleChange}
              prefix="price"
            />
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Button sx={{ width: "50%" }} type="submit">
              {t("buttons__pay")}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
