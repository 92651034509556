import { createSvgIcon } from "@mui/material/utils";

const OutOfService = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="Humidity-None--Streamline-Ultimate"
  >
    <desc>{"Humidity None Streamline Icon: https://streamlinehq.com"}</desc>
    <path
      d="M15.687 4.99A23.948 23.948 0 0 0 12.2 0.75C8.535 4.212 4.867 9.855 4.867 15.913"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M7.212 21.441A7.45 7.45 0 0 0 12.2 23.25a7.08 7.08 0 0 0 7.337 -7.337 16.155 16.155 0 0 0 -1.04 -5.34"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="m22.5 2.25 -21 21"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>,
  "OutOfService"
);

export default OutOfService;
