import * as Types from "../generated/graphql";

import { tasksApi } from "../tasks.api";
export type GetHousekeepingTabCountsQueryVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
}>;

export type GetHousekeepingTabCountsQuery = { __typename?: "Query"; GetHousekeepingTabCounts: any };

export const GetHousekeepingTabCountsDocument = `
    query GetHousekeepingTabCounts($pmsPropertyId: String!) {
  GetHousekeepingTabCounts(pmsPropertyId: $pmsPropertyId)
}
    `;

const injectedRtkApi = tasksApi.injectEndpoints({
  endpoints: (build) => ({
    GetHousekeepingTabCounts: build.query<
      GetHousekeepingTabCountsQuery,
      GetHousekeepingTabCountsQueryVariables
    >({
      query: (variables) => ({ document: GetHousekeepingTabCountsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetHousekeepingTabCountsQuery, useLazyGetHousekeepingTabCountsQuery } =
  injectedRtkApi;
