import { api } from "../GetUserProfileById.generated";

const getUserProfileByIdEnhanced = api.enhanceEndpoints({
  addTagTypes: ["UserProfile", "UserProfileNotes"],
  endpoints: {
    GetUserProfileById: {
      providesTags: (result) => {
        return [
          { id: result?.GetUserProfileById?.id, type: "UserProfile" },
          ...(result?.GetUserProfileById?.notes.map((note) => {
            return { id: note.pmsId, type: "UserProfileNotes" as any };
          }) ?? [])
        ];
      }
    }
  }
});

export const { useGetUserProfileByIdQuery: useGetUserProfileByIdEnhanced } =
  getUserProfileByIdEnhanced;
