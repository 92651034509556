import React, { FC, useCallback, useState } from "react";
import { Box, useTheme } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import { Button } from "@likemagic-tech/sv-magic-library";
import { MagicFileData } from "../../../../types/magic-file";
import {
  UploadFileResponse,
  useUploadFileMutation
} from "../../../../store/task-management-files.api";
import { Attachments } from "../../domain/task";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { FieldWithIcon } from "./field-with-icon";
import { AttachFile } from "@mui/icons-material";
import { AttachmentsPreview } from "./attachments-preview";
import { AttachmentsInput } from "./attachments-input";

interface AttachmentsFormProps {
  taskId?: number;
  attachments: Attachments;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const AttachmentsForm: FC<AttachmentsFormProps> = ({
  attachments,
  setFieldValue,
  taskId
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();

  const [notUploadFiles, setNotUploadFiles] = useState<Array<MagicFileData>>([]);

  const removeFile = useCallback(
    (uuid: string) => {
      setNotUploadFiles(notUploadFiles.filter((item) => item.fileName !== uuid));
      setFieldValue("attachments", attachments?.filter((item) => item.uuid !== uuid));
    },

    [attachments, notUploadFiles, setFieldValue]
  );

  const [uploadAction] = useUploadFileMutation();

  return (
    <FieldWithIcon icon={<AttachFile />} alignTop>
      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }} ml={1}>
        {attachments.map((attachment) => (
          <AttachmentsPreview
            key={`attachment-${attachment.uuid}`}
            attachment={attachment}
            removeFileWithPreview={removeFile}
          />
        ))}

        <AttachmentsInput
          disabled={false}
          onChange={(files) => {
            files &&
              Promise.all(files?.map(async (file) => uploadAction({ file, taskId })))
                .then((response) => {
                  // get the uuid from response and all other data from file

                  const newtAttachments = response.flatMap((item) => {
                    const t = item as { data: UploadFileResponse };
                    return t.data;
                  });

                  const newFiles = newtAttachments.map((item, index) => {
                    return {
                      uuid: item.uuid,
                      contentType: files[index].type,
                      fileName: files[index].name,
                      originalFileName: files[index].name
                    };
                  });

                  setFieldValue("attachments", [...attachments, ...newFiles]);
                })
                .catch((error) => {
                  console.error(error);
                });
          }}
          showPreview={true}
          inputComponent={
            <Button
              variant="ghost"
              size={"medium"}
              sx={{ px: theme.spacing(1), py: theme.spacing(0.5) }}
            >
              {t("labels__add_attachment")}
            </Button>
          }
        />
      </Box>
    </FieldWithIcon>
  );
};
