import { Box, DialogActions, DialogContent } from "@mui/material";
import { DialogTitleModal } from "../../../../components/submit-modal/dialog-title";
import { DialogModal } from "../../../../components/submit-modal/dialog";
import React, { FC, useCallback, useMemo } from "react";
import { Form, Formik } from "formik";
import { Button, Input, Paragraph } from "@likemagic-tech/sv-magic-library";
import { CopyToClipboard } from "../../../../components/copy-to-clipboard";
import { MultipleInput } from "../../../../components/multiple-input/multiple-input";
import { validateEmail } from "../../../../utils/email";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { CommunicationChannel, NotificationType } from "../../../../graphql/generated/graphql";
import { ReservationDetailsDTO } from "../../../../store/endpoints/reservation-table.endpoints";
import { useSendCustomNotificationMutation } from "../../../../graphql/mutations/send-custom-notification.generated";
import { useFormValidations } from "../../../../hooks/use-form-validation";
import { useDispatch, useSelector } from "../../../../store";
import {
  paymentLinkSelector,
  setPaymentLink
} from "../../../../slices/send-payment-link-modal.slice";
import { DialogActionModal } from "../../../../components/submit-modal/dialog-actions";
import { openBanner } from "../../../../slices/banner.slice";

interface SendLinkModalProps {
  reservation?: ReservationDetailsDTO;
}

interface SendInvoiceFormData {
  sendTo: Array<string>;
  copySendTo: Array<string>;
  message: string;
  attachments: Array<string>;
}

export const SendLinkModal: FC<SendLinkModalProps> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const [action] = useSendCustomNotificationMutation();
  const { sendPaymentLinkValidation } = useFormValidations();
  const dispatch = useDispatch();
  const paymentLink = useSelector(paymentLinkSelector);

  const handleSubmitSend = useCallback(
    (values: SendInvoiceFormData) => {
      action({
        pmsReservationId: reservation?.id ?? "",
        payload: {
          sendTos: values.sendTo,
          sendBCCs: values.copySendTo,
          template: NotificationType.PayByLink,
          channel: CommunicationChannel.Email,
          customMessage: values.message.replaceAll("\n", "<br/>"),
          //we need to remove the property from the values
          attachmentIds: values.attachments.map((attachment) =>
            attachment.split("_").slice(0, 3).join("_")
          ),
          payByLink: paymentLink
        }
      })
        .unwrap()
        .then(() => {
          dispatch(setPaymentLink(undefined));
          dispatch(
            openBanner({
              type: "success",
              title: t("labels__action_successfully_performed")
            })
          );
        });
    },
    [action, reservation?.id, paymentLink, dispatch, t]
  );

  const initialValuesSend: SendInvoiceFormData = useMemo(() => {
    return {
      message: t("labels__email_template_send_payment_link_" + reservation?.propertyId, {
        firstName: reservation?.primaryGuest?.firstName ?? "",
        lastName: reservation?.primaryGuest?.lastName ?? "",
        confirmationNumber: reservation?.displayId ?? ""
      }),
      sendTo: reservation?.primaryGuest?.email ? [reservation?.primaryGuest?.email] : [],
      copySendTo: [],
      attachments: []
    };
  }, [
    t,
    reservation?.primaryGuest?.firstName,
    reservation?.primaryGuest?.lastName,
    reservation?.displayId,
    reservation?.primaryGuest?.email,
    reservation?.propertyId
  ]);

  const getMultiSelectItems: string[] = useMemo(() => {
    switch (reservation?.propertyId) {
      case "ABSY":
        return [
          "PBL_FORM_1_ABSY",
          "PBL_FORM_2_ABSY",
          "PBL_FORM_3_ABSY",
          "PBL_FORM_4_ABSY",
          "PBL_FORM_5_ABSY"
        ];
      case "AABR":
        return ["PBL_FORM_1_ABSY"];
      case "TESY":
        return [
          "PBL_FORM_1_TESY",
          "PBL_FORM_2_TESY",
          "PBL_FORM_3_TESY",
          "PBL_FORM_4_TESY",
          "PBL_FORM_5_TESY"
        ];
      default:
        return [];
    }
  }, [reservation?.propertyId]);

  const handleClose = useCallback(() => {
    dispatch(setPaymentLink(undefined));
  }, [dispatch]);

  if (!reservation) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValuesSend}
      onSubmit={handleSubmitSend}
      validationSchema={sendPaymentLinkValidation}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formik) => {
        return (
          <DialogModal isOpen={true} handleClose={handleClose}>
            <Box p={3} width="100%">
              <Form>
                <DialogTitleModal
                  title={t("modals__title_send_payment_link")}
                  onClose={handleClose}
                />
                <DialogContent sx={{ padding: 0, pt: 1 }}>
                  <Paragraph mb={2}>{t("labels__successfully_created_payment_link")}</Paragraph>
                  <Input
                    variant="outlined"
                    value={paymentLink}
                    readOnly
                    fullWidth
                    endAdornment={<CopyToClipboard textToCopy={paymentLink ?? ""} />}
                  />
                  <Paragraph mb={2} mt={2}>
                    {t("labels__do_you_want_to_send_it")}
                  </Paragraph>

                  <Box>
                    <MultipleInput
                      options={getMultiSelectItems}
                      value={formik.values.attachments}
                      setValue={(newValue) => {
                        formik.setFieldValue("attachments", newValue);
                      }}
                      label={t("labels__attachments")}
                      translate
                      validateItem={(item) => getMultiSelectItems.includes(item)}
                      freeSolo={false}
                      error={
                        formik.errors.attachments?.length
                          ? formik.errors.attachments.toString()
                          : undefined
                      }
                    />
                  </Box>

                  <Box mt={2}>
                    <MultipleInput
                      value={formik.values.sendTo}
                      error={
                        formik.errors.sendTo?.length ? formik.errors.sendTo.toString() : undefined
                      }
                      setValue={(newValue) => {
                        formik.setFieldValue("sendTo", newValue);
                      }}
                      label={t("labels__send_to")}
                      validateItem={validateEmail}
                    />
                  </Box>

                  <Box mt={2}>
                    <Input
                      name="message"
                      value={formik.values.message}
                      error={formik.errors.message}
                      onChange={formik.handleChange}
                      multiline
                      variant="outlined"
                      label={t("labels__message")}
                      minRows={8}
                    />
                  </Box>

                  <Box mt={2}>
                    <MultipleInput
                      value={formik.values.copySendTo}
                      error={
                        formik.errors.copySendTo?.length
                          ? formik.errors.copySendTo.toString()
                          : undefined
                      }
                      setValue={(newValue) => {
                        formik.setFieldValue("copySendTo", newValue);
                      }}
                      label={t("labels__copy_send_to")}
                      validateItem={validateEmail}
                    />
                  </Box>
                </DialogContent>
                <DialogActions sx={{ padding: 0 }}>
                  <Box
                    sx={{ width: "100%", mt: -3, display: "flex", gap: 2, flexDirection: "column" }}
                  >
                    <DialogActionModal title={t("buttons__send_payment_link")} />
                    <Button
                      variant="secondary"
                      size="medium"
                      fullWidth
                      onClick={() => handleClose()}
                    >
                      {t("buttons__skip_and_finish")}
                    </Button>
                  </Box>
                </DialogActions>
              </Form>
            </Box>
          </DialogModal>
        );
      }}
    </Formik>
  );
};
