import React, { FC, useCallback } from "react";
import { DatePicker } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { LocalizationDatePicker } from "src/components/language-selector/localization-date-picker";
import { isValid } from "date-fns";
import { getTime } from "@likemagic-tech/sv-magic-library";
import { QuickButtonsToolbar } from "../../../features/tasks/task-modal/task-form/due-modal";

interface DateTimePickerInterface {
  date?: Date;
  setDate: (value: Date) => void;
  setTime: (value: Date) => void;
  time?: Date;
  label?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  minimumTime?: string;
  dateError?: string;
  variant?: "standard" | "outlined" | "filled";
  hideLabel?: boolean;
  inputFontSize?: number;
}

const DateTimePicker: FC<DateTimePickerInterface> = ({
  time,
  date,
  setTime,
  setDate,
  label,
  disabled,
  minDate,
  maxDate,
  minimumTime,
  dateError = undefined,
  variant,
  hideLabel,
  inputFontSize
}) => {
  const { t } = useTranslationWrapper();

  const handleTimeChange = (time: string) => {
    if (time && date) {
      const [hours, minutes] = time.split(":").map(Number);
      const temp = new Date(date);
      temp.setHours(hours);
      temp.setMinutes(minutes);
      setTime(temp);
    }
  };

  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (date) {
        setDate(date);
      }
    },
    [setDate]
  );

  const QuickButtonsToolbarCallBack = useCallback(
    (props: any) => <QuickButtonsToolbar {...props} onAccept={handleDateChange} />,
    [handleDateChange]
  );

  const isSameDate = date && minDate && date.toDateString() === minDate.toDateString();

  return (
    <LocalizationDatePicker>
      <Grid container spacing={2}>
        {/*Key is needed to rerender DatePicker once is updated*/}
        <Grid item xs={6} key={date?.toDateString()}>
          <DatePicker
            PopperProps={{
              placement: "auto"
            }}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            ToolbarComponent={QuickButtonsToolbarCallBack}
            showToolbar
            label={label}
            value={date ?? ""}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                helperText={dateError ?? ""}
                error={!!dateError}
                variant={variant}
                InputProps={{
                  sx: {
                    fontSize: inputFontSize,
                    ...params.InputProps?.sx
                  },
                  ...params.InputProps
                }}
                InputLabelProps={{
                  sx: {
                    opacity: 0.5
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={disabled || !date || !isValid(date)}
            fullWidth
            value={getTime(time)}
            label={!hideLabel ? t("labels__time") : undefined}
            type="time"
            onChange={(e) => handleTimeChange(e.target.value)}
            placeholder=""
            inputProps={{
              step: 60,
              min: isSameDate && minimumTime ? minimumTime : undefined
            }}
            InputProps={{
              sx: {
                fontSize: inputFontSize
              }
            }}
            error={isSameDate && minimumTime ? minimumTime > getTime(time) : undefined}
            InputLabelProps={{ shrink: true }}
            variant={variant}
          />
        </Grid>
      </Grid>
    </LocalizationDatePicker>
  );
};

export default DateTimePicker;
