import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BabyBed = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_1197_788)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 10.5002C1.16421 10.5002 1.5 10.836 1.5 11.2502V14.2502C1.5 14.6645 1.16421 15.0002 0.75 15.0002C0.335786 15.0002 0 14.6645 0 14.2502V11.2502C0 10.836 0.335786 10.5002 0.75 10.5002Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.25 10.5002C23.6642 10.5002 24 10.836 24 11.2502V14.2502C24 14.6645 23.6642 15.0002 23.25 15.0002C22.8358 15.0002 22.5 14.6645 22.5 14.2502V11.2502C22.5 10.836 22.8358 10.5002 23.25 10.5002Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14.2502C0 13.836 0.335786 13.5002 0.75 13.5002H23.25C23.6642 13.5002 24 13.836 24 14.2502V21.7502C24 22.347 23.7629 22.9193 23.341 23.3412C22.919 23.7632 22.3467 24.0002 21.75 24.0002H2.25C1.65326 24.0002 1.08097 23.7632 0.65901 23.3412C0.237053 22.9193 0 22.347 0 21.7502V14.2502ZM1.5 15.0002V21.7502C1.5 21.9492 1.57902 22.1399 1.71967 22.2806C1.86032 22.4212 2.05109 22.5002 2.25 22.5002H21.75C21.9489 22.5002 22.1397 22.4212 22.2803 22.2806C22.421 22.1399 22.5 21.9492 22.5 21.7502V15.0002H1.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 13.5002C5.66421 13.5002 6 13.836 6 14.2502V23.2502C6 23.6645 5.66421 24.0002 5.25 24.0002C4.83579 24.0002 4.5 23.6645 4.5 23.2502V14.2502C4.5 13.836 4.83579 13.5002 5.25 13.5002Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 13.5002C10.1642 13.5002 10.5 13.836 10.5 14.2502V23.2502C10.5 23.6645 10.1642 24.0002 9.75 24.0002C9.33579 24.0002 9 23.6645 9 23.2502V14.2502C9 13.836 9.33579 13.5002 9.75 13.5002Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 13.5002C14.6642 13.5002 15 13.836 15 14.2502V23.2502C15 23.6645 14.6642 24.0002 14.25 24.0002C13.8358 24.0002 13.5 23.6645 13.5 23.2502V14.2502C13.5 13.836 13.8358 13.5002 14.25 13.5002Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 13.5002C19.1642 13.5002 19.5 13.836 19.5 14.2502V23.2502C19.5 23.6645 19.1642 24.0002 18.75 24.0002C18.3358 24.0002 18 23.6645 18 23.2502V14.2502C18 13.836 18.3358 13.5002 18.75 13.5002Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 0.000244141C16.1642 0.000244141 16.5 0.336031 16.5 0.750244V5.25024C16.5 5.66446 16.1642 6.00024 15.75 6.00024C15.3358 6.00024 15 5.66446 15 5.25024V0.750244C15 0.336031 15.3358 0.000244141 15.75 0.000244141Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 1.50024C10.9142 1.50024 11.25 1.83603 11.25 2.25024V5.45124C11.25 5.86546 10.9142 6.20124 10.5 6.20124C10.0858 6.20124 9.75 5.86546 9.75 5.45124V2.25024C9.75 1.83603 10.0858 1.50024 10.5 1.50024Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 1.50024C21.4142 1.50024 21.75 1.83603 21.75 2.25024V5.45124C21.75 5.86546 21.4142 6.20124 21 6.20124C20.5858 6.20124 20.25 5.86546 20.25 5.45124V2.25024C20.25 1.83603 20.5858 1.50024 21 1.50024Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.25024C9 1.83603 9.33579 1.50024 9.75 1.50024H21.75C22.1642 1.50024 22.5 1.83603 22.5 2.25024C22.5 2.66446 22.1642 3.00024 21.75 3.00024H9.75C9.33579 3.00024 9 2.66446 9 2.25024Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 6.25024C10.0858 6.25024 9.75 6.58603 9.75 7.00024C9.75 7.41446 10.0858 7.75024 10.5 7.75024C10.9142 7.75024 11.25 7.41446 11.25 7.00024C11.25 6.58603 10.9142 6.25024 10.5 6.25024ZM8.25 7.00024C8.25 5.7576 9.25736 4.75024 10.5 4.75024C11.7426 4.75024 12.75 5.7576 12.75 7.00024C12.75 8.24288 11.7426 9.25024 10.5 9.25024C9.25736 9.25024 8.25 8.24288 8.25 7.00024Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 6.25024C15.3358 6.25024 15 6.58603 15 7.00024C15 7.41446 15.3358 7.75024 15.75 7.75024C16.1642 7.75024 16.5 7.41446 16.5 7.00024C16.5 6.58603 16.1642 6.25024 15.75 6.25024ZM13.5 7.00024C13.5 5.7576 14.5074 4.75024 15.75 4.75024C16.9926 4.75024 18 5.7576 18 7.00024C18 8.24288 16.9926 9.25024 15.75 9.25024C14.5074 9.25024 13.5 8.24288 13.5 7.00024Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 6.25024C20.5858 6.25024 20.25 6.58603 20.25 7.00024C20.25 7.41446 20.5858 7.75024 21 7.75024C21.4142 7.75024 21.75 7.41446 21.75 7.00024C21.75 6.58603 21.4142 6.25024 21 6.25024ZM18.75 7.00024C18.75 5.7576 19.7574 4.75024 21 4.75024C22.2426 4.75024 23.25 5.7576 23.25 7.00024C23.25 8.24288 22.2426 9.25024 21 9.25024C19.7574 9.25024 18.75 8.24288 18.75 7.00024Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1197_788">
        <rect width="24" height="24" fill="white" transform="translate(0 0.000244141)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
