import { api } from "../GetReservationOverview.generated";

export const getReservationOverviewEnhanced = api.enhanceEndpoints({
  endpoints: {
    OverviewTable: {
      providesTags: ["ReservationsOverview"]
    }
  }
});

export const { useOverviewTableQuery: useOverviewTableQueryEnhanced } =
  getReservationOverviewEnhanced;
