import { FC } from "react";
import { Platform } from "../../graphql-messaging/generated/graphql";
import { EmailOutlined, QuestionMarkOutlined, SmsOutlined, WhatsApp } from "@mui/icons-material";

export const ChatItemPlatformIcon: FC<{ platform?: Platform }> = ({ platform }) => {
  switch (platform) {
    case Platform.Whatsapp:
      return <WhatsApp />;
    case Platform.Sms:
      return <SmsOutlined />;
    case Platform.Email:
      return <EmailOutlined />;
    default:
      return <QuestionMarkOutlined />;
  }
};
