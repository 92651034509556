import React, { FC } from "react";
import { Button } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Status } from "../../../../graphql-tasks/generated/graphql";
import { Box } from "@mui/material";
import { Autorenew, Check, PriorityHigh } from "@mui/icons-material";

interface StatusButtonsProps {
  status: Status;
  handleChange: (status: string) => void;
}

export const StatusButtons: FC<StatusButtonsProps> = ({ status, handleChange }) => {
  const { t } = useTranslationWrapper();

  return (
    <Box sx={{ display: "flex", gap: 1.5 }}>
      <Button
        variant={status === Status.Todo ? "primary" : "secondary"}
        onClick={() => handleChange(Status.Todo)}
        size="medium"
        startIcon={<PriorityHigh sx={{ fontSize: 12 }} />}
      >
        {t("labels__task_status_todo")}
      </Button>
      <Button
        variant={status === Status.InProgress ? "primary" : "secondary"}
        onClick={() => handleChange(Status.InProgress)}
        size="medium"
        startIcon={<Autorenew sx={{ fontSize: 12 }} />}
      >
        {t("labels__task_status_in_progress")}
      </Button>
      <Button
        variant={status === Status.Done ? "primary" : "secondary"}
        onClick={() => handleChange(Status.Done)}
        size="medium"
        startIcon={<Check sx={{ fontSize: 12 }} />}
      >
        {t("labels__task_status_done")}
      </Button>
    </Box>
  );
};
