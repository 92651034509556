export enum AdditionalServicesCodes {
  FREE_CLEANING = "FREE_CLEANING",
  PAID_CLEANING = "PAID_CLEANING",
  FREE_BREAKFAST = "FREE_BREAKFAST",
  DONATE_BREAKFAST = "DONATE_BREAKFAST",
  LATE_CHECKOUT = "LATE_CHECKOUT",
  EARLY_CHECKIN = "EARLY_CHECKIN",
  BABY_BED = "BABY_BED",
  STORAGE = "STORAGE",
  UNKNOWN = "UNKNOWN"
}
