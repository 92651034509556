import { emptyPrice, Price } from "../domain/price";

export const formatPriceToString = (price?: Price | null) => {
  if (!price || price.currency === "N/A") {
    return "N/A";
  }

  try {
    return new Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: price?.currency
    }).format(price?.amount / 100);
  } catch (e) {
    return "N/A";
  }
};

export const parsePricePickerAmountToAmountInCents = (amount: number): number => {
  return parseInt((amount * 100).toFixed(2));
};

export const sumPrices = (prices?: Price[]): Price => {
  if (!prices) {
    return emptyPrice();
  }

  return prices.reduce(
    (acc, currentPrice) => {
      if (acc.currency === "") {
        return currentPrice;
      }
      if (acc.currency !== currentPrice.currency) {
        throw new Error("Different currency inside balance");
      } else {
        const newPrice: Price = { ...acc };
        newPrice.amount = newPrice.amount + currentPrice.amount;
        return newPrice;
      }
    },
    { amount: 0, currency: "" }
  ); // todo
};
export const multiplyPrices = (price: Price, multiplier: number): Price => {
  return {
    currency: price.currency,
    amount: price.amount * multiplier
  };
};
