import { Grid } from "@mui/material";
import { FC } from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { Chip, Heading4, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTheme } from "@mui/material/styles";
import { IdCheckStatus } from "src/domain/reservation";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import {
  getGuestInsightInfoIcon,
  GuestInsightInfoTypes
} from "./reservation-details-guest-insight-utils";

interface PersonInfoProps {
  guestInsightInfoType: GuestInsightInfoTypes;
  title: string;
  edit?: boolean;
  add?: boolean;
  show?: boolean;
  info: any;
  verified?: IdCheckStatus;
}

const PersonInfo: FC<PersonInfoProps> = ({
  guestInsightInfoType,
  title,
  edit,
  add,
  show,
  info,
  verified
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();

  const verifiedLabelStyling = (status: IdCheckStatus) => {
    switch (status) {
      case "CONFIRMED":
        return "success";
      case "UNCONFIRMED":
        return "warning";
      case "DECLINED":
        return "error";
    }
  };

  return (
    <>
      {show && (
        <>
          <Grid container justifyContent="left" alignItems="center">
            <Grid item display="flex" justifyContent="center" paddingRight={2}>
              {getGuestInsightInfoIcon(guestInsightInfoType)}
            </Grid>
            <Grid item paddingRight={3}>
              <Heading4 sx={{ color: theme.palette.text.primary }}>{title}</Heading4>
            </Grid>
            {edit && (
              <Grid item paddingRight={2}>
                <EditIcon />
              </Grid>
            )}
            {add && (
              <Grid item>
                <AddIcon />
              </Grid>
            )}
            {verified && (
              <Grid item>
                <Chip
                  label={t("labels__guest__verified__" + verified)}
                  color={verifiedLabelStyling(verified)}
                  size="small"
                />
              </Grid>
            )}
          </Grid>
          <Grid container>
            {guestInsightInfoType !== GuestInsightInfoTypes.PREFERENCES &&
              guestInsightInfoType !== GuestInsightInfoTypes.NOTES &&
              guestInsightInfoType !== GuestInsightInfoTypes.ACCOUNT &&
              info?.map(
                (dataInfo: string) =>
                  dataInfo && (
                    <Grid item key={dataInfo} paddingRight={5} paddingTop={1}>
                      <Paragraph sx={{ color: theme.palette.text.primary }}>{dataInfo}</Paragraph>
                    </Grid>
                  )
              )}
            {(guestInsightInfoType === GuestInsightInfoTypes.PREFERENCES ||
              guestInsightInfoType === GuestInsightInfoTypes.NOTES) && <>{info}</>}
            {guestInsightInfoType === GuestInsightInfoTypes.ACCOUNT && (
              <Grid item paddingTop={1}>
                <Paragraph sx={{ color: theme.palette.text.primary }}>{info}</Paragraph>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
export default PersonInfo;
