import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { selectLoaderSlice } from "./loader.slice";
import { RootState } from "../../store";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { selectIsDownloading } from "../../slices/download.slice";
import { selectIsMagicFilesDownloading } from "../../slices/magic-files.slice";
import { selectIsUnitsUpdating } from "src/slices/cleaning";

const queriesWithDisabledSpinner = ["getReservationConditionDTO"];
const selectIsLoaderVisible = createSelector(
  selectLoaderSlice,
  selectIsDownloading,
  selectIsMagicFilesDownloading,
  selectIsUnitsUpdating,
  (loaderSliceState, isDownloading, isMagicFilesDownloading, isUnitsUpdating) =>
    loaderSliceState.isRootSuspenseShowed ||
    !loaderSliceState.isForceHideSpinners ||
    isDownloading ||
    isMagicFilesDownloading ||
    isUnitsUpdating
);

export const useSpinners = () => {
  const isLoaderVisible = useSelector(selectIsLoaderVisible);
  const isApiSliceLoadingV1 = useSelector((state: RootState) =>
    Object.values(state.api.queries)
      .filter((query) => {
        const args = query?.originalArgs as any;
        return (
          !queriesWithDisabledSpinner.some((item) => query?.endpointName === item) &&
          !args?.skipSpinner
        );
      })
      .some((query) => query && query.status === QueryStatus.pending)
  );
  const isApiMutationsLoadingV1 = useSelector((state: RootState) =>
    Object.values(state.api.mutations).some(
      (mutation) => mutation && mutation.status === QueryStatus.pending
    )
  );
  const isApiQueriesLoadingV2 = useSelector((state: RootState) =>
    Object.values(state.graphql.queries)
      .filter((query) => {
        const args = query?.originalArgs as any;
        return (
          !queriesWithDisabledSpinner.some((item) => query?.endpointName === item) &&
          !args?.skipSpinner
        );
      })
      .some((query) => query && query.status === QueryStatus.pending)
  );
  const isApiMutationsLoadingV2 = useSelector((state: RootState) =>
    Object.values(state.graphql.mutations).some(
      (query) => query && query.status === QueryStatus.pending
    )
  );

  const isMessageApiQueriesLoadingV2 = useSelector((state: RootState) =>
    Object.values(state["graphql-messaging"].queries).some(
      (query) => query && query.status === QueryStatus.pending
    )
  );
  const isMessageApiMutationsLoadingV2 = useSelector((state: RootState) =>
    Object.values(state["graphql-messaging"].mutations).some(
      (query) => query && query.status === QueryStatus.pending
    )
  );
  const isTaskApiQueriesLoadingV2 = useSelector((state: RootState) =>
    Object.values(state["graphql-tasks"].queries).some(
      (query) => query && query.status === QueryStatus.pending
    )
  );
  const isTaskApiMutationsLoadingV2 = useSelector((state: RootState) =>
    Object.values(state["graphql-tasks"].mutations).some(
      (query) => query && query.status === QueryStatus.pending
    )
  );
  return {
    isLoaderVisible:
      isLoaderVisible ||
      isApiSliceLoadingV1 ||
      isApiMutationsLoadingV1 ||
      isApiQueriesLoadingV2 ||
      isApiMutationsLoadingV2 ||
      isMessageApiQueriesLoadingV2 ||
      isMessageApiMutationsLoadingV2 ||
      isTaskApiMutationsLoadingV2 ||
      isTaskApiQueriesLoadingV2
  };
};
