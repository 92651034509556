import React, { FC, useEffect, useMemo, useState } from "react";

import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import {
  AutocompleteComponent,
  AutocompleteOption
} from "../../../../components/autocomplete-component";
import { ReservationTableDto } from "../../../../domain/reservation-table-dto";
import { GuestPreferencesActor } from "../reservation-details-tabs/overview/rows-for-table-guest-overview/rows-for-table-guest-overview";

interface PreferencesActorSelectionComponentProps {
  reservation: ReservationTableDto;
  setSelectedActor: (actor: AutocompleteOption | null) => void;
  selectedActor?: AutocompleteOption | null;
}

export const PreferencesActorSelectionComponent: FC<PreferencesActorSelectionComponentProps> = ({
  reservation,
  setSelectedActor,
  selectedActor
}) => {
  const { t } = useTranslationWrapper();

  useState<AutocompleteOption | null>(null);

  const actorOptions = useMemo(() => {
    const actorOptions = [];
    if (reservation.primaryGuest?.userProfileId || reservation?.primaryGuestSameAsBooker) {
      actorOptions.push({
        id: GuestPreferencesActor.PRIMARY_GUEST,
        label: reservation.primaryGuest.firstName + " " + reservation.primaryGuest.lastName
      });
    }
    if (reservation.booker?.userProfileId && !reservation?.primaryGuestSameAsBooker) {
      actorOptions.push({
        id: GuestPreferencesActor.BOOKER,
        label: reservation.booker.firstName + " " + reservation.booker.lastName
      });
    }
    return actorOptions;
  }, [reservation]);

  useEffect(() => {
    if (actorOptions.length === 1) {
      setSelectedActor(actorOptions[0]);
    }
  }, [actorOptions, setSelectedActor]);

  return actorOptions?.length > 1 ? (
    <AutocompleteComponent
      options={actorOptions}
      onSelected={(value) => {
        setSelectedActor(value);
      }}
      value={selectedActor as AutocompleteOption}
      label={t("labels__preference_profile")}
    />
  ) : (
    <>{selectedActor?.label}</>
  );
};
