import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import {
  FolioPreview,
  ReservationDetailsDTO
} from "../../../../store/endpoints/reservation-table.endpoints";
import { MovePaymentsModal } from "../modals/move-payments-modal";
import { RefundPaymentModal } from "../modals/refund-payment-modal";
import { ActionMenu } from "../../../../components/action-menu/action-menu";

export interface PaymentsActionsMenuProps {
  reservation: ReservationDetailsDTO;
  folios: Array<FolioPreview>;
  paymentPmsIds: string[];
  folioId: string;
  isFolioClosed: boolean | false;
  folioMetadata: Object | null;
  hasSelect: boolean;
  magicId: string;
  onSelectPayment: () => void;
}

export const PaymentsActionsMenu: FC<PaymentsActionsMenuProps> = ({
  reservation,
  paymentPmsIds,
  folioId,
  isFolioClosed,
  folioMetadata,
  folios,
  hasSelect,
  magicId,
  onSelectPayment
}) => {
  const { t } = useTranslationWrapper();

  const [refundPaymentModalOpened, setRefundModalPaymentOpened] = useState(false);
  const [movePaymentModalOpened, setMovePaymentModalOpened] = useState(false);

  const onMoveOptionSelect = useCallback(() => {
    setMovePaymentModalOpened(true);
  }, []);

  const onRefundOptionSelect = useCallback(() => {
    setRefundModalPaymentOpened(true);
  }, []);

  const payment = useMemo(() => {
    if (paymentPmsIds.length !== 1) {
      return null;
    }
    return folios
      .find((folio) => folio.id === folioId)
      ?.payments?.find((payment) => payment.id === paymentPmsIds[0]);
  }, [paymentPmsIds, folios, folioId]);

  const menuItems = useMemo(() => {
    const menuItems = payment?.refundable ? [{ label: t("labels__refund"), id: "refund" }] : [];

    if (isFolioClosed) {
      return menuItems;
    } else {
      return menuItems.concat(
        {
          label: t("labels__move"),
          id: "move"
        },
        { label: t("labels__select"), id: "select" }
      );
    }
  }, [payment, isFolioClosed, t]);

  const handleAction = useCallback(
    (selectedItemId: string) => {
      switch (selectedItemId) {
        case "move":
          onMoveOptionSelect();
          break;
        case "select":
          onSelectPayment();
          break;
        case "refund":
          onRefundOptionSelect();
          break;
      }
    },
    [onMoveOptionSelect, onSelectPayment, onRefundOptionSelect]
  );
  return (
    <>
      <ActionMenu items={menuItems} handleAction={handleAction} />

      <MovePaymentsModal
        isOpen={movePaymentModalOpened}
        reservationId={reservation.id}
        folios={folios}
        onClose={() => {
          setMovePaymentModalOpened(false);
        }}
        paymentPmsIds={paymentPmsIds}
        folioId={folioId}
        folioMetadata={folioMetadata}
      />
      <RefundPaymentModal
        isOpen={refundPaymentModalOpened}
        magicId={magicId}
        folios={folios}
        onClose={() => {
          setRefundModalPaymentOpened(false);
        }}
        pmsPaymentId={payment?.id ?? ""}
        folioId={folioId}
        folioMetadata={folioMetadata}
      />
    </>
  );
};
