import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetAssignableUnitsQueryVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
  pmsUnitGroupId: Types.Scalars["String"]["input"];
}>;

export type GetAssignableUnitsQuery = {
  __typename?: "Query";
  GetAssignableUnits: Array<{
    __typename?: "Unit";
    name?: string | null;
    state?: Types.UnitState | null;
    active?: boolean | null;
    occupied?: boolean | null;
    pmsId?: string | null;
    privacyMode?: boolean | null;
    privacyModeLastChangeTimestamp?: any | null;
    pmsUnitGroupIds?: Array<string> | null;
  }>;
};

export const GetAssignableUnitsDocument = `
    query GetAssignableUnits($pmsReservationId: String!, $pmsUnitGroupId: String!) {
  GetAssignableUnits(
    pmsReservationId: $pmsReservationId
    pmsUnitGroupId: $pmsUnitGroupId
  ) {
    name
    state
    active
    occupied
    pmsId
    privacyMode
    privacyModeLastChangeTimestamp
    pmsUnitGroupIds
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetAssignableUnits: build.query<GetAssignableUnitsQuery, GetAssignableUnitsQueryVariables>({
      query: (variables) => ({ document: GetAssignableUnitsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetAssignableUnitsQuery, useLazyGetAssignableUnitsQuery } = injectedRtkApi;
