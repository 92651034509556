import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useCallback } from "react";
import { encodeKeyWithAPI, selectAssignKeyWithAPIStatus } from "../../slices/assign-key";
import { AllowedActionEnum } from "../../domain/reservation-table-dto";
import { usePerformReservationAction } from "../../hooks/use-reservation-actions";
import { useDispatch, useSelector } from "../../store";
import { mapQueryStatusToEntityStateStatus } from "../../graphql/transform/transform-utils";

export const useAssignApiKey = ({
  reservationId,
  tagReaderId
}: {
  reservationId: string;
  tagReaderId: string;
}) => {
  const { isRESTVersion } = useApiVersion();
  const [performAction, result] = usePerformReservationAction();
  const dispatch = useDispatch();
  const action = useCallback(() => {
    if (isRESTVersion) {
      return dispatch(encodeKeyWithAPI({ reservationId: reservationId, tagReaderId })).unwrap();
    } else {
      return performAction({
        reservationId: reservationId,
        action: AllowedActionEnum.ENCODE_KEY,
        payload: { encoderId: tagReaderId }
      });
    }
  }, [dispatch, reservationId, tagReaderId, isRESTVersion, performAction]);

  const statusV1 = useSelector(selectAssignKeyWithAPIStatus);
  const status = isRESTVersion ? statusV1 : mapQueryStatusToEntityStateStatus(result.status);

  return { action, status };
};
