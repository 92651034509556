export interface Price {
  amount: number;
  currency: string;
}
// Good for displaying sane default value.
// Not good as input to `sumPrices` (currencies might not match)
export const emptyPrice = (currency = "CHF"): Price => ({
  amount: 0,
  currency
});

export interface FullPrice {
  grossPrice: number;
  netPrice: number;
  currency: string;
}
