import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { FolioPreview } from "../../../../store/endpoints/reservation-table.endpoints";
import { MoveChargesModal } from "../modals/move-charges-modal";
import { AdjustChargesModal } from "../modals/adjust-charges-modal";
import { ActionMenu } from "../../../../components/action-menu/action-menu";

export interface ChargesActionsMenuProps {
  reservationId: string;
  folios: Array<FolioPreview>;
  orderItemPmsIds: string[];
  folioId: string;
  propertyId: string;
  isFolioClosed: boolean | false;
  folioMetadata: Object | null;
  hasSelect: boolean;
  onSelectCharge: () => void;
}

export const ChargeActionsMenu: FC<ChargesActionsMenuProps> = ({
  reservationId,
  orderItemPmsIds,
  folioId,
  propertyId,
  isFolioClosed,
  folioMetadata,
  folios,
  hasSelect,
  onSelectCharge
}) => {
  const { t } = useTranslationWrapper();
  const [moveOrderItemModalOpened, setMoveOrderItemModalOpened] = useState(false);
  const [adjustOrderItemModalOpened, setAdjustOrderItemModalOpened] = useState<boolean>(false);

  const onMoveOptionSelect = useCallback(() => {
    setMoveOrderItemModalOpened(true);
  }, []);

  const onAdjustOptionSelect = useCallback(() => {
    setAdjustOrderItemModalOpened(true);
  }, []);

  const menuItems = useMemo(() => {
    if (isFolioClosed) {
      return [];
    } else {
      return [
        {
          label: t("labels__move"),
          id: "move"
        },
        {
          label: t("labels__adjust"),
          id: "adjust"
        },
        { label: t("labels__select"), id: "select" }
      ];
    }
  }, [isFolioClosed, t]);

  const handleAction = useCallback(
    (actionId: string) => {
      switch (actionId) {
        case "adjust":
          onAdjustOptionSelect();
          break;
        case "move":
          onMoveOptionSelect();
          break;
        case "select":
          onSelectCharge();
          break;
      }
    },
    [onAdjustOptionSelect, onMoveOptionSelect, onSelectCharge]
  );

  return (
    <>
      <ActionMenu items={menuItems} handleAction={handleAction} disabled={!menuItems.length} />

      {moveOrderItemModalOpened && (
        <MoveChargesModal
          isOpen
          reservationId={reservationId}
          onClose={() => {
            setMoveOrderItemModalOpened(false);
          }}
          orderItemPmsIds={orderItemPmsIds}
          folioId={folioId}
          folioMetadata={folioMetadata}
          folios={folios}
        />
      )}
      {adjustOrderItemModalOpened && (
        <AdjustChargesModal
          isOpen
          reservationId={reservationId}
          propertyId={propertyId}
          onClose={() => {
            setAdjustOrderItemModalOpened(false);
          }}
          orderItemPmsIds={orderItemPmsIds}
        />
      )}
    </>
  );
};
