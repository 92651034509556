import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import {
  transformReservationDetailsV2ToCommonReservationDetails,
  transformReservationV1ToCommon
} from "src/graphql/transform/transform-reservation-details";
import {
  ReservationDetailsDTO,
  useGetReservationDetailsQuery
} from "src/store/endpoints/reservation-table.endpoints";
import { useOverviewDetailsQueryEnhanced } from "../graphql/queries/enhanced-queries/get-reservation-details-enhanced";

export const useReservationDetails = ({
  reservationId,
  skipApiCall,
  skipSpinner
}: {
  reservationId: string;
  skipSpinner?: boolean;
  skipApiCall?: boolean;
}): ReservationDetailsDTO | undefined => {
  const { isRESTVersion, isGraphQlVersion } = useApiVersion();
  const { data: dataV1 } = useGetReservationDetailsQuery(
    {
      reservationId: reservationId,
      skipSpinner: skipSpinner
    },
    {
      skip: skipApiCall || !reservationId || isGraphQlVersion,
      refetchOnMountOrArgChange: true
    }
  );

  const { data: dataV2 } = useOverviewDetailsQueryEnhanced(
    {
      pmsReservationId: reservationId,
      // @ts-ignore
      skipSpinner: skipSpinner
    },
    {
      skip: skipApiCall || !reservationId || isRESTVersion,
      refetchOnMountOrArgChange: true
    }
  );

  return isRESTVersion
    ? transformReservationV1ToCommon(dataV1)
    : transformReservationDetailsV2ToCommonReservationDetails(dataV2?.OverviewDetails);
};
