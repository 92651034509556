import { api } from "../GetRelatedTo.generated";

// @ts-ignore
const getRelatedTo = api.enhanceEndpoints({
  endpoints: {
    GetRelatedTo: {
      providesTags: (result, error, arg) => [
        { id: arg.pmsReservationId, type: "Tasks-Related-To-Reservations" },
        ...(result?.GetRelatedToTasks?.map((task) => {
          return { id: task.id, type: "Tasks" as any };
        }) ?? [])
      ]
    }
  }
});

export const { useGetRelatedToQuery: useGetRelatedToQueryEnhanced } = getRelatedTo;
