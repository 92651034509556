import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useRemovePreferenceFromProfileMutationEnhanced } from "src/graphql/mutations/enhanced-mutations/remove-preference-from-profile-enhanced";

interface AddAddPreferenceToProfileProps {
  preferenceId: string;
  profileId: number;
}

export const useDeletePreferenceFromProfile = () => {
  const { isRESTVersion } = useApiVersion();
  const [removePreference, resultRemovePreference] =
    useRemovePreferenceFromProfileMutationEnhanced();

  const deletePreferencesToProfileV1 = () => {
    return Promise.reject("not yet implemented");
  };

  const deletePreferenceToProfileV2 = ({
    preferenceId,
    profileId
  }: AddAddPreferenceToProfileProps) => {
    return removePreference({
      pmsPreferenceId: preferenceId,
      userProfileId: profileId
    });
  };

  const functionCall = isRESTVersion ? deletePreferencesToProfileV1 : deletePreferenceToProfileV2;
  const result = isRESTVersion ? [] : resultRemovePreference;

  return [functionCall, result] as [
    typeof deletePreferenceToProfileV2,
    typeof resultRemovePreference
  ];
};
