import { MESSAGE_BASE_URL, messagingApi } from "../messaging.api";
import { createClient, Message } from "./subscribe.utils";
import {
  StreamUnreadMessagesStatDocument,
  StreamUnreadMessagesStatSubscription
} from "./StreamUnreadMessagesStat.generated";
import { setMessageCountSlice } from "../../slices/unread-message-count.slice";
import { getAuthToken } from "@likemagic-tech/sv-magic-library";

interface UnreadMessagesSubscribeProps {
  apiKey?: string;
  tenantId: string;
  propertyIds: string[];
}

export const unreadMessagesSubscribeApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({
    SubscribeToUnreadMessages: build.query<Message[], UnreadMessagesSubscribeProps>({
      query: (arg) => ({
        document: StreamUnreadMessagesStatDocument,
        variables: { propertyIds: arg.propertyIds }
      }),
      providesTags: [],
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, dispatch, extra }) {
        const client = createClient(
          MESSAGE_BASE_URL,
          arg,
          // @ts-ignore
          await getAuthToken(extra?.keycloak)
        );

        try {
          await cacheDataLoaded;
          const onNext = (value: { data: StreamUnreadMessagesStatSubscription }) => {
            if (value.data.StreamUnreadMessagesStat) {
              const temp = value.data.StreamUnreadMessagesStat;
              dispatch(
                setMessageCountSlice({
                  ASSIGNED_TO_ME: temp.numberOfUnreadMessagesInAssignedToMe,
                  INBOX: temp.numberOfUnreadMessagesInInbox,
                  RESOLVED: temp.numberOfUnreadMessagesInResolved,
                  ALL: temp.totalNumberOfUnreadMessages
                })
              );
            } else {
              console.warn("Subscription has returned falsy data.", value.data);
            }
          };

          new Promise<void>((resolve, reject) => {
            client.subscribe(
              {
                query: StreamUnreadMessagesStatDocument,
                operationName: "StreamUnreadMessagesStat",
                variables: { propertyIds: arg.propertyIds }
              },
              {
                next: onNext,
                error: reject,
                complete: resolve
              }
            );
          });
        } catch (e) {
          console.error("WS subscription for V2 failed", e);
          client.dispose();
        }
        await cacheEntryRemoved;
        client.dispose();
      }
    })
  }),

  overrideExisting: false
});
