import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";

export type DashboardSidebarInitialState = {
  isOpenSideNavigation: boolean;
};

const initialState: DashboardSidebarInitialState = {
  isOpenSideNavigation: true
};

export const dashboardSidebarSlice = createSlice({
  name: "dashboardSidebar",
  initialState,
  reducers: {
    setIsOpenSideNavigation: (state, action: PayloadAction<{ isOpenSideNavigation: boolean }>) => {
      state.isOpenSideNavigation = action.payload.isOpenSideNavigation;
    }
  }
});

const selectSidebar = (state: RootState) => state[dashboardSidebarSlice.name];

export const selectIsOpenSideNavigation = createSelector(
  selectSidebar,
  (res) => res.isOpenSideNavigation
);

export const { setIsOpenSideNavigation } = dashboardSidebarSlice.actions;
