import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import { ReservationApi } from "../api/reservation.api";
import { Reservation } from "../domain/reservation";
import { statusFilter, statusSort } from "../utils/reservation-utils";
import { saveAs } from "file-saver";
import { updateIdsCheck } from "./identities";

export const dashboardArrivalAdapter = createEntityAdapter<Reservation>({
  selectId: (model) => model.id
});

const initialState = dashboardArrivalAdapter.getInitialState<{
  status: EntityStateStatus;
}>({
  status: EntityStateStatus.IDLE
});

export const fetchReservationsWithArrival = createAsyncThunk<
  Array<Reservation>,
  { propertyId: string; date: string },
  { state: RootState; rejectValue: { reason: string } }
>(
  "dashboardArrival/fetchArrivals",
  async (arg, thunkAPI) => {
    return ReservationApi.fetchArrivalsByDateAndPropertyId(arg.date, arg.propertyId, {
      signal: thunkAPI.signal
    });
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const status = thunkAPI.getState().dashboardArrival.status;
      // don't load if it's already loading
      if (status === EntityStateStatus.LOADING) {
        return false;
      }
    }
  }
);

export const downloadArrivalsCSV = createAsyncThunk<
  Blob,
  { propertyId: string; date: string },
  { state: RootState; rejectValue: { reason: string } }
>("dashboardArrival/DownloadCSV", async (arg, thunkAPI) => {
  return ReservationApi.downloadCSVArrivalsByDateAndPropertyId(arg.date, arg.propertyId, {
    signal: thunkAPI.signal
  });
});

export const downloadArrivalsXLSX = createAsyncThunk<
  Blob,
  { propertyId: string; date: string },
  { state: RootState; rejectValue: { reason: string } }
>("dashboardArrival/DownloadXLSX", async (arg, thunkAPI) => {
  return ReservationApi.downloadXLSXArrivalsByDateAndPropertyId(arg.date, arg.propertyId, {
    signal: thunkAPI.signal
  });
});

const slice = createSlice({
  name: "dashboardArrival",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReservationsWithArrival.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchReservationsWithArrival.fulfilled, (state, action) => {
        dashboardArrivalAdapter.setAll(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchReservationsWithArrival.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.status === EntityStateStatus.LOADING) {
            state.status = EntityStateStatus.IDLE;
          }
          return;
        }
        state.status = EntityStateStatus.FAILED;
      })
      .addCase(downloadArrivalsCSV.fulfilled, (state, action) => {
        saveAs(action.payload, action.meta.arg.date + "-" + action.meta.arg.propertyId + ".csv");
      })
      .addCase(downloadArrivalsXLSX.fulfilled, (state, action) => {
        saveAs(action.payload, action.meta.arg.date + "-" + action.meta.arg.propertyId + ".xlsx");
      })
      .addCase(updateIdsCheck.fulfilled, (state, action) => {
        action.meta.arg.reservationIds.forEach((reservationId) => {
          const entity = state?.entities[reservationId];
          if (entity) {
            entity.idCheckStatus = action.meta.arg.status;
          }
        });
      });
  }
});

export const { reducer } = slice;

export const { selectAll: selectArrivals } = dashboardArrivalAdapter.getSelectors<RootState>(
  (state) => state.dashboardArrival
);

export const selectFilteredArrivals = createSelector(selectArrivals, (res) =>
  res.filter(statusFilter)
);
export const selectSortedArrivals = createSelector(selectFilteredArrivals, (res) => {
  res.sort(statusSort);
  return res;
});
