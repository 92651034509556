import { Box, Collapse, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { FC, useCallback, useMemo, useState } from "react";
import {
  Checkbox,
  Counter,
  formatRange,
  formatToIsoDate,
  Heading3,
  isSameDaysInArray,
  MultiCalendar,
  Paragraph,
  parseIsoDateString
} from "@likemagic-tech/sv-magic-library";
import { isSameDay } from "date-fns";
import { PricePreview } from "../../../../../../components/price-preview/price-preview";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import { getI18nSelectedLanguage } from "../../../../../../utils/timezoned-date";
import { useProperty } from "../../../../../../hooks/use-property";
import { Dialog } from "./dialog";
import { ServiceModalsProps } from "./types";
import { multiplyPrices } from "../../../../../../utils/price";
import { DateAndCount, DefaultDatePickerSelection } from "../../../../../../domain/service";
import { emptyPrice } from "../../../../../../domain/price";

const useStyles = makeStyles()(() => ({
  dialogContentContainer: {
    alignSelf: "center"
  },
  counter: {
    "& div": {
      "& p": {
        fontSize: 28
      }
    },
    "& button": {
      padding: 0,
      borderRadius: 40,
      height: 40,
      width: 40,
      "& svg": {
        width: "100%",
        height: "100%"
      }
    }
  }
}));

export const ServiceDatesPickerDialog: FC<ServiceModalsProps> = ({
  onClose,
  onChange,
  reservation,
  service
}) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();

  const initialDates: Date[] = useMemo(() => {
    if (service?.defaultDatePickerSelection === DefaultDatePickerSelection.All) {
      return (service.bookableDates || []).map(
        (dateAndCount: DateAndCount) => new Date(dateAndCount.date)
      );
    }
    return [];
  }, [service]);

  const [dates, setDates] = useState<Date[]>(initialDates);
  const [count, setCount] = useState<number>(1);

  const bookedDates: Date[] = (service.bookedDates || []).map(
    (dateAndCount: any) => new Date(dateAndCount.date)
  );

  const availableDays: Date[] = (service.bookableDates || []).map(
    (dateAndCount: any) => new Date(dateAndCount.date)
  );

  const isDayDisabled = (day: Date) =>
    !availableDays.some((availableDay) => isSameDay(new Date(availableDay), day));

  const isDayHighlighted = useCallback(
    (day: Date) => bookedDates.some((availableDay) => isSameDay(new Date(availableDay), day)),
    [bookedDates]
  );

  const isAllSelected = useMemo(
    () => isSameDaysInArray(dates, availableDays),
    [dates, availableDays]
  );
  const { classes } = useStyles();

  const handleChange = useCallback(() => {
    const values = dates.map((date) => ({
      date: formatToIsoDate(date),
      count: count
    }));
    onChange({ values });
  }, [dates, onChange, count]);

  return (
    <Dialog
      open
      onConfirm={handleChange}
      onDismiss={onClose}
      title={t("labels__wizard_select_dates")}
      content={
        <Box className={classes.dialogContentContainer}>
          <Heading3 align="center" gutterBottom>
            {formatRange(
              selectedProperty?.details.timeZone,
              getI18nSelectedLanguage(),
              reservation?.arrival,
              reservation?.departure
            )}
          </Heading3>

          <Box m={2} mt={0}>
            <MultiCalendar
              values={dates}
              defaultCalendarMonth={
                reservation?.arrival ? parseIsoDateString(reservation.arrival) : undefined
              }
              onChange={setDates}
              isDayHighlighted={isDayHighlighted}
              isDayDisabled={isDayDisabled}
            />
          </Box>

          <Box px={2.5}>
            <Checkbox
              id="servicesDatePickerDialog"
              color="primary"
              onChange={(event) => {
                if ((event.target as any).checked) {
                  setDates(availableDays);
                } else {
                  setDates([]);
                }
              }}
              checked={isAllSelected}
              title={t("labels__wizard_select_all_dates")}
            />
          </Box>

          <Collapse in={!!(dates.length && service.canBookMultiple)}>
            <Box py={2}>
              <Paragraph textAlign={"center"} pb={1}>
                {t("labels__wizard_counter")}
              </Paragraph>
              <Counter
                classes={{ root: classes.counter }}
                initialCount={1}
                minValue={1}
                maxValue={service.maximum}
                onCountChange={setCount}
              />
            </Box>
            <Divider />
          </Collapse>
          <Box display="flex" alignItems="baseline" justifyContent="center" px={2.5} py={2}>
            <PricePreview
              price={multiplyPrices(service?.price ?? emptyPrice(), dates.length * count)}
            />
          </Box>
        </Box>
      }
      buttonLabel={t("labels__wizard_confirm")}
    />
  );
};
