import { JSONApiResponse, VoidApiResponse } from "@likemagic-tech/sv-magic-library";
import { ApiKeyApiClient } from "./base/api-key-api-client";
import { UnitToBeCleaned } from "../domain/UnitToBeCleaned";

const BASE_URL = "/api/guest-journey-service/units/";

class UnitApiClient extends ApiKeyApiClient {
  async fetchUnitsToBeCleaned(propertyId: string, init?: RequestInit): Promise<UnitToBeCleaned[]> {
    const response = await this.fetchApi(
      BASE_URL.concat(propertyId).concat("/to-be-cleaned"),
      init
    );
    return new JSONApiResponse<UnitToBeCleaned[]>(response).value();
  }

  async updateUnit(unitId: string, patches: any, init?: RequestInit) {
    const response = await this.fetchApi(BASE_URL + `patch/${unitId}`, {
      ...init,
      method: "PATCH",
      body: JSON.stringify({ patches })
    });
    return new VoidApiResponse(response).value();
  }

  async updateUnits(unitIds: string[], patches: any, init?: RequestInit) {
    const response = await this.fetchApi(BASE_URL + `patch?unitIds=${unitIds.join()}`, {
      ...init,
      method: "PATCH",
      body: JSON.stringify({ patches })
    });
    return new VoidApiResponse(response).value();
  }
}

export const UnitApi = new UnitApiClient();
