import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetOrderItemAdjustmentReasonCodesQueryVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
}>;

export type GetOrderItemAdjustmentReasonCodesQuery = {
  __typename?: "Query";
  GetOrderItemAdjustmentReasonCodes: Array<{
    __typename?: "OrderItemAdjustmentReasonCode";
    pmsId: string;
    name: string;
  }>;
};

export const GetOrderItemAdjustmentReasonCodesDocument = `
    query GetOrderItemAdjustmentReasonCodes($pmsPropertyId: String!) {
  GetOrderItemAdjustmentReasonCodes(pmsPropertyId: $pmsPropertyId) {
    pmsId
    name
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetOrderItemAdjustmentReasonCodes: build.query<
      GetOrderItemAdjustmentReasonCodesQuery,
      GetOrderItemAdjustmentReasonCodesQueryVariables
    >({
      query: (variables) => ({ document: GetOrderItemAdjustmentReasonCodesDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const {
  useGetOrderItemAdjustmentReasonCodesQuery,
  useLazyGetOrderItemAdjustmentReasonCodesQuery
} = injectedRtkApi;
