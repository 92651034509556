import { Box, Divider, Drawer, Fade, useTheme } from "@mui/material";
import { selectIsOpenSideNavigation } from "src/slices/dashboard-sidebar";
import { useSelector } from "../../../store";
import PropertySelector from "./property-selector/property-selector";
import SidebarSections from "./sidebar-sections";

const MobileSidebar = () => {
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const { spacing } = useTheme();
  const topSpacing = spacing(8);

  return openSideNavigation ? (
    <Drawer
      variant="permanent"
      anchor="left"
      open={openSideNavigation}
      PaperProps={{
        sx: {
          top: topSpacing,
          width: "100%",
          height: `calc(100% - ${topSpacing})`,
          zIndex: 1200 + 5 //5 is a max number of detail drawers that we can support
        }
      }}
    >
      <Box display="inline-flex" m={2} alignItems="center">
        <Fade in={openSideNavigation}>
          <Box sx={{ width: "100%" }}>
            <PropertySelector />
          </Box>
        </Fade>
      </Box>
      <Divider />
      <SidebarSections />
    </Drawer>
  ) : (
    <></>
  );
};

export default MobileSidebar;
