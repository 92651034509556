import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import { DoorDelegateApi, DoorType } from "../api/door-delegate";

export const supportedSyncStatesAdapter = createEntityAdapter<DoorType>({
  selectId: (model) => String(model)
});

const initialState = supportedSyncStatesAdapter.getInitialState<{
  status: EntityStateStatus;
}>({
  status: EntityStateStatus.IDLE
});

export const fetchSupportedSyncStates = createAsyncThunk<
  Array<DoorType>,
  { propertyId: string },
  { state: RootState; rejectValue: { reason: string } }
>(
  "supported-sync-states/fetchAll",
  async (arg, thunkAPI) => {
    return DoorDelegateApi.supportedSyncStates(arg.propertyId, {
      signal: thunkAPI.signal
    });
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const status = thunkAPI.getState().supportedSyncStates.status;
      if (status === EntityStateStatus.LOADING || status === EntityStateStatus.SUCCEEDED) {
        return false;
      }
    }
  }
);

const slice = createSlice({
  name: "supportedSyncStatess",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupportedSyncStates.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchSupportedSyncStates.fulfilled, (state, action) => {
        supportedSyncStatesAdapter.setAll(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchSupportedSyncStates.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.status === EntityStateStatus.LOADING) {
            state.status = EntityStateStatus.IDLE;
          }
          return;
        }
        state.status = EntityStateStatus.FAILED;
      });
  }
});

export const { reducer } = slice;

export const { selectAll: selectSupportedSyncStates } =
  supportedSyncStatesAdapter.getSelectors<RootState>((state) => state.supportedSyncStates);
