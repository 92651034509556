import { useSelector } from "react-redux";
import { closeBanner, selectBannerState } from "./banner.slice";
import { useCallback, useEffect } from "react";
import { useDispatch } from "../store";

export const useBanner = () => {
  const bannerState = useSelector(selectBannerState);
  const dispatch = useDispatch();
  const onClose = useCallback(() => dispatch(closeBanner()), [dispatch]);
  useEffect(() => {
    return () => {
      if (bannerState.isOpen) {
        dispatch(closeBanner());
      }
    };
  }, [dispatch, bannerState.isOpen]);

  useEffect(() => {
    if (bannerState.timeout) {
      setTimeout(() => onClose(), bannerState.timeout);
    }
  }, [bannerState.timeout, onClose]);

  return {
    bannerState,
    onClose
  };
};
