import React, { FC, useMemo } from "react";
import { Backdrop, Box, keyframes, SvgIcon } from "@mui/material";
import { generateBlinkerAnimation } from "./loader-utils";
import { makeStyles } from "tss-react/mui";

interface LoaderProps {
  visible: boolean;
}

const useStyles = makeStyles()(() => ({
  circle1: {
    animation: `${keyframes(generateBlinkerAnimation(75, 100))} 2s infinite`
  },
  circle2: {
    animation: `${keyframes(generateBlinkerAnimation(50, 75))} 2s infinite`
  },
  circle3: {
    animation: `${keyframes(generateBlinkerAnimation(24, 50))} 2s infinite`
  },
  title: {
    fontSize: 24
  },
  contentWrapper: {
    width: "60%",
    maxWidth: 700
  },
  content: {
    height: "100vh",
    width: "100%"
  },
  verticallyAlignContent: {
    height: "100vh"
  }
}));
export const StaykooookLoader: FC<LoaderProps> = ({ visible }) => {
  const { classes } = useStyles();
  const randomHead = useMemo(() => {
    const heads = ["head1", "head2", "head3", "head4"];
    return heads[Math.floor(Math.random() * heads.length)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1000,
        background: "rgba(256,256,256,0.8)"
      }}
      open={visible}
    >
      <Box className={classes.contentWrapper}>
        <SvgIcon x="0px" y="0px" viewBox="0 0 291.9 291.9" className={classes.content}>
          <defs>
            <style>
              {`.st0{opacity:0.2;fill:#FF6A13;}.st1{fill:#07090A;}.st2{fill:#FEFEFE;}.st3{fill:#C5B9AB;}.st4{fill:#ED6E2C;}.st5{fill:#121414;}`}
            </style>
          </defs>
          <g id="ring3" className={classes.circle1}>
            <circle className="st0" cx="146.5" cy="144.2" r="146.8" />
          </g>
          <g id="ring2" className={classes.circle2}>
            <circle className="st0" cx="146.5" cy="144.2" r="124.4" />
          </g>
          <g id="ring1" className={classes.circle3}>
            <circle className="st0" cx="147.2" cy="144.9" r="100.2" />
          </g>
          {randomHead === "head1" && (
            <g id="head_1">
              <g>
                <circle className="st1" cx="146" cy="146" r="74.5" />
                <path
                  className="st2"
                  d="M104.1,169.4c-1.5-1.3-2.3-3.1-3.1-4.9c-3-6.7-5.3-13.6-6.4-20.8c-0.3-2.3-0.6-4.6,0.1-6.9
      c1.8,0.7,2.9,1.9,3.2,3.9c0.3,1.9,0.7,3.9,1.2,5.7c1.2,4.9,3.2,9.3,7,12.8c7.3,6.6,20.4,5.7,27.3-1.9c4.3-4.8,6.6-10.4,7.9-16.6
      c0.2-0.7-0.1-1.1-0.5-1.6c-1.3-1.6-1.2-3,0.3-3.8c2.1-1.2,4.4-1.4,6.8-1c0.1,0.8,0.2,1.6,0.4,2.3c1.1,4.4,2.6,8.8,4.5,12.9
      c1.2,2.7,0.6,4.3-2.3,5.2c-0.8,0.2-1.5,0.4-2.3,0.6c-1.8,0.5-3.8,0.7-5.5,1.4c-1.2,0.5-2.3,1-2.8,2.3c-0.3,0.8-0.1,1.4,0.9,1.5
      c0.7,0.6,1.5,0.5,2.3,0.5c0.4,0.5,1,0.4,1.5,0.5c3.3,0.8,5.7,2.9,7.8,5.4c-2.1-0.1-4,0.4-5.7,1.7c-0.3,0.2-0.6,0.4-0.9,0.1
      c-3-2.4-6.2-2.1-9.5-1c-3.4,1.1-6.6,2.9-9.9,4.2c-0.8,0.3-0.7,0.6-0.4,1.2c1.6,3.2,3.4,6.1,5.6,8.9c2.4,3,5.2,5.3,8.8,6.5
      c0.5,2.4,2.5,3.1,4.4,3.7c2.1,0.7,4.3,1,6.3,2.1c1.5,0.8,2.2,2,2.1,3.6c-0.2,2-1,3.7-2.4,5.2c-1.3,1.4-3.1,2.2-4.7,3.2
      c-0.1,0-0.3,0-0.4,0c-0.1-0.2-0.3-0.1-0.5-0.1c-2.9-0.3-5.5-1.7-8.1-3.1c-6.9-3.8-13-8.6-18.8-13.7c-2.4-2.1-4.6-4.3-6.8-6.6
      c-2-2.1-3.7-4.5-4.3-7.4c-0.4-1.8-0.9-3.5-1.3-5.2c-0.3-1.1-0.2-2.1-0.2-3.2c0-0.5,0.1-1.2-0.7-1.1c-0.7,0.1-0.7,0.6-0.7,1.2
      C104.3,168,104.7,168.7,104.1,169.4z M126,167.4c0-0.9-0.5-1.4-1.4-1.4c-0.9,0-1.4,0.6-1.5,1.4c-0.1,0.8,0.7,1.6,1.5,1.5
      C125.6,168.8,126,168.3,126,167.4z"
                />
                <path
                  className="st3"
                  d="M152.6,167.1c-2.2-2.5-4.5-4.6-7.9-5.4c-0.5-0.1-1.1,0-1.5-0.5c0.6-0.5,1.3-0.4,2,0c-0.4-1.3-1.4-2.1-2.6-1.9
      c-0.9,0.1-1.7,0.4-1.8,1.5c-1-0.1-1.2-0.6-0.9-1.5c0.5-1.3,1.6-1.8,2.8-2.3c1.8-0.7,3.7-0.9,5.5-1.4c0.8-0.2,1.6-0.4,2.3-0.6
      c2.8-1,3.5-2.6,2.3-5.2c-1.9-4.2-3.4-8.5-4.5-12.9c-0.2-0.8-0.3-1.6-0.4-2.3c1.1-0.1,2.1,0.2,3,0.5c2.4,0.9,2.8,2.5,1.1,4.4
      c-0.4,0.5-0.5,0.9-0.4,1.5c0.9,4.5,2.5,8.7,4.9,12.7c5.8,9.4,15.6,12.3,25.2,9c1.3-0.4,2.4-1.1,3.7-1.7c-0.6,1.3-1.9,2-2.9,2.9
      c-4.9,3.8-10.4,5.6-16.6,4.8c-0.3,0-0.6,0-0.9,0c5,1.7,8.7,5.4,12.7,8.5c1,0.8,2,1.4,3.1,2.1c1.1,0.6,2.2,1,3.5,1.4
      c-1.7,2.1-3.6,4-5.6,5.8c-7.3,6.6-15.1,12.4-23.7,17.1c-2.4,1.3-4.9,2.5-7.6,3c-0.4,0.1-0.7,0.2-1.1,0c1.6-1,3.4-1.7,4.7-3.2
      c1.4-1.5,2.3-3.2,2.4-5.2c0.1-1.6-0.6-2.8-2.1-3.6c-2-1-4.2-1.4-6.3-2.1c-1.9-0.6-3.9-1.4-4.4-3.7c1.4,0.4,2.8,0.7,4.2,0.8
      c4.7,0.3,9.2-0.3,12.9-3.6c3.9-3.5,6.7-7.9,9.4-12.3c0.4-0.6,0-0.9-0.5-1.1C162.2,169.9,157.7,167.6,152.6,167.1z M150,161.3
      c0.8-0.5,1.4-0.5,2-0.3c0.4,0.2,0.9,0.2,1.3,0.2c0.5-0.1,1-0.1,1.1-0.7c0.1-0.6-0.3-0.9-0.8-1.1
      C152.2,158.7,150.7,159.5,150,161.3z"
                />
                <path
                  className="st4"
                  d="M185.5,160.8c-1.2,0.6-2.4,1.2-3.7,1.7c-9.5,3.3-19.4,0.4-25.2-9c-2.4-3.9-4-8.1-4.9-12.6
      c-0.1-0.6-0.1-1,0.4-1.5c1.6-1.9,1.2-3.6-1.1-4.4c-1-0.4-2-0.6-3-0.5c-2.4-0.4-4.7-0.2-6.8,1c-1.5,0.8-1.6,2.3-0.3,3.8
      c0.4,0.5,0.6,0.9,0.5,1.6c-1.3,6.2-3.6,11.9-7.9,16.6c-6.9,7.6-20,8.5-27.3,1.9c-3.8-3.5-5.8-7.9-7-12.8c-0.5-1.9-0.9-3.8-1.1-5.7
      c-0.3-2-1.4-3.2-3.2-3.9c-1.6-0.3-1.8-1.3-1.7-2.7c0.1-1.6,0-1.6,1.7-1.6c1.9-0.1,3.6-0.5,5.2-1.5c4.5-2.9,9.3-4.9,14.6-5.8
      c4.4-0.7,8.7-0.2,13,1.2c0.5,0.2,1.1,0.3,1.6,0.5c1.1,0.5,2.2,1,3.3,1.5c1.8,0.9,3.6,1.9,5.4,2.7c1.2,0.6,2.3,0.3,2.7-0.6
      c0.3-0.7,0.7-0.7,1.3-0.7c1.9,0,3.7,0,5.6-0.1c1.4,0,2.9,0,4.3,0.1c0.4,0,0.7,0,1,0.4c0.6,1.2,1.3,1.5,2.5,1
      c1.2-0.4,2.3-1,3.4-1.8c1.6-0.7,3.2-1.5,4.8-2.1c4.1-1.7,8.4-2.5,12.8-2.4c0.8,0.3,1.6,0.2,2.4,0.3c2.3,0.3,4.4,1.1,6.7,1.6l0,0
      c0,0.2,0.1,0.2,0.2,0.2c3.2,1.3,6.2,2.9,9.1,4.8c1.4,0.9,3.1,0.8,4.7,0.8c0.8,0,1,0.2,0.9,0.9c0,0.1,0,0.2,0,0.4
      c0.2,1.6,0,2.7-1.9,3.2c-2,0.5-2.9,2.3-3.2,4.4c-0.4,3.4-1.2,6.6-2.5,9.8c-1.4,3.4-3.4,6.4-6.1,8.9
      C186.1,160.2,185.6,160.3,185.5,160.8C185.5,160.8,185.5,160.8,185.5,160.8z M124.9,128.1c-3.3-1-6.6-1-9.9-0.4
      c-3.7,0.7-7,2.1-9.6,4.9c-1.8,1.8-3,3.9-3.5,6.3c-1.5,7.7,1.4,13.9,7.2,18.8c3.8,3.2,8.3,4.4,13.2,3.2c7.8-1.8,14.3-9.8,14.8-18.2
      C137.7,134.7,131.5,129.5,124.9,128.1z M174.3,127.4c-4.1,0-7.8,0.8-11.1,2.7c-4.8,2.8-7.5,6.9-7.2,12.5
      c0.4,7.3,3.9,12.9,10.1,16.7c5.3,3.2,10.7,3.1,16-0.1c5.1-3.1,8.1-7.7,9.2-13.5c1.2-6.2-1.2-11.9-6.5-15.3
      C181.5,128.2,177.8,127.4,174.3,127.4z"
                />
                <path
                  className="st3"
                  d="M158.5,129.3c-1,0.8-2.2,1.3-3.4,1.8c-1.2,0.5-1.9,0.2-2.5-1c-0.2-0.5-0.6-0.4-1-0.4c-1.4,0-2.9,0-4.3-0.1
      c0-1-0.1-2,0.1-3c0.5-2.8,1.8-5.3,3.2-7.8c0.7-1.1,1.7-2.1,1.9-3.4c6.7-4.7,12.7-10.2,18.1-16.6c3,7.5,6.4,14.7,10.6,21.6
      c-2.1,0.2-4.1,0.3-6,0.5c-4.8,0.5-9.6,1.1-14.2,2.4C158.9,124,157.4,127.7,158.5,129.3z"
                />
                <path
                  className="st2"
                  d="M152.6,115.4c-0.3,1.3-1.3,2.3-1.9,3.4c-1.4,2.4-2.8,4.9-3.2,7.8c-0.2,1-0.1,2-0.1,3c-1.9,0-3.7,0.1-5.6,0.1
      c-0.6,0-1,0-1.3,0.7c-0.4,1-1.5,1.2-2.7,0.6c-1.8-0.9-3.6-1.8-5.4-2.7c-1.1-0.5-2.2-1-3.3-1.5c6.4-2.2,12.8-4.7,18.6-8.3
      C149.3,117.6,151,116.5,152.6,115.4z"
                />
                <path
                  className="st3"
                  d="M104.1,169.4c0.6-0.6,0.2-1.4,0.3-2.2c0.1-0.6,0.1-1.1,0.7-1.2c0.8-0.1,0.7,0.6,0.7,1.1
      c0,1.1-0.1,2.1,0.2,3.2c-0.4,0.4-0.2,0.8-0.2,1.2c0,3.2,0,6.5,0,9.7c-0.8-0.1-0.7,0.5-0.7,1c0.2,3,0.7,6,1.2,9
      c1,5.6,3.4,10.6,6.7,15.2c0.2,0.3,0.4,0.5,0.2,0.9l0,0c-2.2-1.1-4-2.7-5.8-4.3c-0.7-0.6-0.5-1.4-0.6-2.1c-0.1-0.4,0-0.9,0-1.4
      c-0.5,0.6-0.4,1.4-0.9,2c-2.3-2.2-4.4-4.7-5.3-7.9c-0.5-1.5,0.4-3.1,1.1-4.5c0.3-0.6,0.7-1.1,1.1-1.8c-1.3,0.4-2.1,1.3-2.9,2
      c-0.7-2.8,0.9-6.3,3.6-7.5c0.7-0.3,1-0.7,1-1.5c-0.1-3.2,0-6.5,0-9.7C104.6,170.3,104.6,169.8,104.1,169.4z"
                />
                <path
                  className="st4"
                  d="M113,206.6c-3.3-4.6-5.7-9.6-6.7-15.2c-0.5-3-1-6-1.2-9c0-0.5-0.1-1.1,0.7-1c4.3,0.9,6.4,5.3,5.7,11.3
      c-1-0.1-1.8-0.9-2.8-1.1c-0.1-0.1-0.3-0.2-0.4-0.1c-0.1,0.2,0.1,0.2,0.2,0.3c0.3,0.4,0.7,0.7,1,1.1c1.3,1.5,2.2,3.1,2.1,5.2
      C111.5,201,112.1,203.8,113,206.6z"
                />
                <path
                  className="st3"
                  d="M185.3,126.7c-2.2-0.5-4.4-1.3-6.7-1.6c-0.8-0.1-1.7,0-2.4-0.3c2.6-0.9,5.1-1.4,7.6,0.2
      C184.4,125.4,185,125.9,185.3,126.7z"
                />
                <path
                  className="st3"
                  d="M185.5,160.8c0.1-0.5,0.6-0.6,0.9-0.8C186.1,160.2,186.1,160.8,185.5,160.8z"
                />
                <path
                  className="st3"
                  d="M145.4,206.3c0.2-0.1,0.4-0.1,0.5,0.1C145.7,206.5,145.5,206.5,145.4,206.3z"
                />
                <path
                  className="st3"
                  d="M185.5,126.9c-0.1,0-0.2-0.1-0.2-0.2C185.4,126.6,185.5,126.8,185.5,126.9z"
                />
                <path
                  className="st3"
                  d="M113.3,207.5c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.1C113.4,207.6,113.3,207.5,113.3,207.5
      C113.3,207.5,113.3,207.5,113.3,207.5z"
                />
                <path
                  className="st4"
                  d="M152.6,167.1c5.1,0.5,9.6,2.8,14,5.2c0.4,0.2,0.8,0.5,0.5,1.1c-2.7,4.5-5.5,8.8-9.4,12.3
      c-3.7,3.3-8.2,3.9-12.9,3.6c-1.4-0.1-2.8-0.4-4.2-0.8c-3.7-1.1-6.5-3.5-8.8-6.5c-2.2-2.8-4-5.7-5.6-8.9c-0.3-0.6-0.4-0.9,0.4-1.2
      c3.3-1.3,6.5-3.1,9.9-4.2c3.4-1.1,6.6-1.3,9.5,1c0.4,0.3,0.6,0.2,0.9-0.1C148.6,167.5,150.5,167,152.6,167.1z M156,176.1
      c-1.5-0.2-2.8-0.5-4.1-0.8c-1.9-0.5-3.9-1.4-5.8-1.1c-3.4,0.5-6.7,1.6-10.2,1.9C141.2,179.7,150.3,180.1,156,176.1z"
                />
                <path
                  className="st3"
                  d="M126,167.4c0,0.9-0.5,1.4-1.3,1.5c-0.8,0.1-1.6-0.7-1.5-1.5c0.1-0.8,0.5-1.4,1.5-1.4
      C125.6,166,126,166.4,126,167.4z"
                />
                <path
                  className="st5"
                  d="M140.9,160.7c0-1.2,0.9-1.4,1.8-1.5c1.2-0.1,2.1,0.6,2.6,1.9c-0.7-0.3-1.4-0.5-2,0
      C142.4,161.2,141.6,161.3,140.9,160.7z"
                />
                <path
                  className="st5"
                  d="M150,161.3c0.7-1.8,2.2-2.5,3.6-1.9c0.4,0.2,0.9,0.5,0.8,1.1c-0.1,0.6-0.6,0.7-1.1,0.7
      c-0.4,0.1-0.9,0-1.3-0.2C151.4,160.8,150.8,160.7,150,161.3z"
                />
                <path
                  className="st3"
                  d="M174.3,127.4c3.6,0,7.2,0.8,10.5,2.9c5.3,3.3,7.8,9.1,6.5,15.3c-1.2,5.8-4.1,10.4-9.2,13.5
      c-5.3,3.2-10.7,3.3-16,0.1c-6.2-3.8-9.7-9.4-10.1-16.7c-0.3-5.6,2.4-9.7,7.2-12.5C166.5,128.1,170.1,127.4,174.3,127.4z
       M164.3,140.7c1,0.2,2,0.4,3,0.6c1,0.2,2,0.4,3.1,0.6c0.1,0.2,0.3,0.2,0.5,0.2c3.7,0.2,7.2-0.3,10.5-1.9c2.3-1.1,4.5-2.6,6.3-4.7
      c-3.1-0.1-5.7-1.3-8.2-2.4c-6.2-2.8-12.3-1.2-16.3,4.2c-0.2,0.2-0.3,0.4-0.4,0.6c-0.8,0.2-1.4,0.9-2.2,1.2
      c-0.3,0.1-0.6,0.3-0.5,0.7c0,0.4,0.4,0.6,0.7,0.7C161.9,140.6,163.1,140.8,164.3,140.7z"
                />
                <path
                  className="st2"
                  d="M124.9,128.1c6.6,1.4,12.8,6.5,12.3,14.8c-0.5,8.4-7,16.4-14.8,18.2c-4.9,1.1-9.4-0.1-13.2-3.2
      c-5.9-4.9-8.7-11.1-7.2-18.8c0.5-2.4,1.7-4.5,3.5-6.3c1.2-0.2,2.5-0.5,3.8-0.7C114.6,131.1,119.8,129.8,124.9,128.1z M122.4,142
      c1.6,0.1,3.1-0.4,4.6-1c-1.5,0.3-3.1,0.5-4.6,0.8c-3.9,0.1-7.3-1.3-10.3-3.7c0.7-0.8,1.6-1.3,2.4-1.9c0.7-0.5,1-0.6,1.3,0.5
      c0.6,2.5,2.7,4.3,5,4.6c2.3,0.2,4.6-1,5.7-3.2c0.5-0.9,0.6-1.9,0.8-3c1,0.8,2,1.4,2.9,2.1c-0.3-0.7-0.7-1.3-1.1-1.8
      c-3.7-3.9-9.7-4.9-15-2.4c-2.5,1.2-5,2.3-7.9,2.4c0.1,0.5,0.5,0.7,0.8,1C111.3,140.4,116.4,142.3,122.4,142z M131,137.7
      c0.2,1.4-0.7,2.2-1.8,3.1c1.4-0.1,2.7-0.1,3.8-0.5c0.3-0.1,0.6-0.2,0.6-0.6c0-0.4-0.2-0.5-0.5-0.7
      C132.4,138.5,131.8,138.1,131,137.7z"
                />
                <path
                  className="st5"
                  d="M124.9,128.1c-5.1,1.7-10.4,2.9-15.7,3.9c-1.2,0.2-2.5,0.4-3.8,0.7c2.6-2.8,5.9-4.2,9.6-4.9
      C118.3,127.1,121.7,127.1,124.9,128.1z"
                />
                <path
                  className="st1"
                  d="M108.6,191.8c-0.1-0.1-0.3-0.2-0.2-0.3c0.1-0.1,0.3,0,0.4,0.1C108.7,191.7,108.7,191.7,108.6,191.8z"
                />
                <path
                  className="st2"
                  d="M156,176.1c-5.7,3.9-14.9,3.5-20.1,0c3.6-0.4,6.8-1.5,10.2-1.9c2-0.3,3.9,0.6,5.8,1.1
      C153.2,175.6,154.5,175.9,156,176.1z"
                />
                <path
                  className="st5"
                  d="M163.2,137.2c4-5.4,10.1-7,16.3-4.2c2.6,1.2,5.1,2.4,8.2,2.4c-1.9,2.1-4,3.6-6.3,4.7
      c-3.3,1.6-6.8,2.2-10.5,1.9c-0.2,0-0.4,0-0.5-0.2c0.5,0,1,0,1.4,0c3.4,0,6.4-1.2,9.1-3.2c0.5-0.4,0.7-0.7,0.1-1.1
      c-0.9-0.6-1.8-1.3-2.8-1.7c-0.9,3.6-2.9,5.6-5.9,5.6c-1.3,0-2.5-0.5-3.5-1.3c-1.5-1.2-2.1-2.9-2.3-4.8
      C165.2,135.6,164.4,136.8,163.2,137.2z"
                />
                <path
                  className="st2"
                  d="M163.2,137.2c1.2-0.4,2-1.6,3.3-2c0.2,1.9,0.8,3.6,2.3,4.8c1,0.8,2.1,1.3,3.5,1.3c3,0,5.1-1.9,5.9-5.6
      c1.1,0.4,1.9,1.1,2.8,1.7c0.7,0.5,0.5,0.8-0.1,1.1c-2.7,2-5.7,3.2-9.1,3.2c-0.5,0-1,0-1.4,0c-1-0.2-2.1-0.4-3.1-0.6
      c-1-0.2-2-0.4-3-0.6c-0.8-0.8-1.9-1.5-1.6-2.9C162.9,137.5,163,137.4,163.2,137.2z"
                />
                <path
                  className="st5"
                  d="M162.7,137.7c-0.3,1.4,0.8,2.1,1.6,2.9c-1.2,0.2-2.4-0.1-3.6-0.4c-0.3-0.1-0.7-0.3-0.7-0.7
      c0-0.4,0.2-0.6,0.5-0.7C161.3,138.6,161.9,137.9,162.7,137.7z"
                />
                <path
                  className="st5"
                  d="M122.4,142c-6,0.3-11.1-1.6-15.5-5.7c-0.3-0.3-0.7-0.5-0.8-1c2.8-0.1,5.4-1.2,7.9-2.4
      c5.3-2.5,11.3-1.5,15,2.4c0.5,0.5,0.9,1.1,1.1,1.8c-0.9-0.7-1.9-1.4-2.9-2.1c-0.2,1.1-0.4,2.1-0.8,3c-1.1,2.2-3.4,3.5-5.7,3.2
      c-2.3-0.2-4.4-2.1-5-4.6c-0.2-1.1-0.5-1-1.3-0.5c-0.8,0.6-1.7,1.1-2.4,1.9c3,2.4,6.4,3.8,10.3,3.7c0,0,0.1,0.1,0.1,0.1
      C122.5,141.9,122.4,142,122.4,142z"
                />
                <path
                  className="st5"
                  d="M131,137.7c0.8,0.5,1.5,0.9,2.2,1.2c0.3,0.2,0.6,0.3,0.5,0.7c0,0.4-0.4,0.5-0.6,0.6c-1.2,0.4-2.4,0.4-3.8,0.5
      C130.2,139.8,131.2,139.1,131,137.7z"
                />
                <path
                  className="st3"
                  d="M122.4,142c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1-0.1-0.1c1.5-0.3,3.1-0.5,4.6-0.8C125.5,141.7,124,142.1,122.4,142z
      "
                />
              </g>
            </g>
          )}
          {randomHead === "head2" && (
            <g id="head2">
              <g>
                <circle className="st1" cx="146.5" cy="144.2" r="76.2" />
                <path
                  className="st3"
                  d="M156.5,106.5c6.7-2.6,13.4-5.3,19.9-8.6c1-0.5,1.7-0.6,2.5,0.2c0.2,0.2,0.5,0.4,0.7,0.5c4.1,2.8,7,6,5.8,11.5
      c-0.4,1.7,0.1,3.5,0.1,5.3c0.1,1.8,0.2,3.5,0.3,5.4c-0.8-0.1-1.2-0.8-1.7-1.3c-8-6.3-6.7-5.9-15.5-2.9c-3.1,1.1-6.2,2.4-9.1,3.9
      c-2.1,1.1-4.3,5.2-4,7.6c0.2,1.3,0.9,1.1,1.8,0.8c2.5-1,4.9-2.4,7.2-3.7c3.3-1.8,6.7-3.6,10-5.4c1.4-0.8,2.8-0.8,4.3-0.3
      c2.5,0.9,4.7,2.4,7,3.8c0.5,0.3,0.6,0.8,0.7,1.3c1.7,12.5,5.4,24.5,11.2,35.7c0.7,1.3,0.8,2.4,0.4,3.8
      c-3.9,15.6-13.8,26.6-27,35.1c-6.5,4.2-13.4,6.8-21.2,6.6c-2.3,0-4.6-0.3-6.9-0.4c0.4-0.6,1.1-0.7,1.7-0.9
      c1.2-0.5,2.3-1.1,3.2-1.9c1.1-0.9,1.9-2,1.7-3.5c-0.3-1.7-1.3-2.8-2.9-3.2c-1.2-0.3-2.5-0.8-3.8-1c-3.5-0.7-5.8-2.8-7.3-5.8
      c7.1,2.1,13.9,1.6,20.5-1.6c6.4-3.1,11.7-7.6,16-13.8c-10.9,1.4-21.3,1-31.7-0.5c0.3-0.1,0.6-0.3,1-0.4c2.6-0.9,3-2.4,1.2-4.4
      c-0.6-0.7-1.2-1.3-1.8-2c-1-1.1-0.7-2,0.4-2.9c1-0.9,2.3-1.3,3.5-1.6c2.6-0.7,5.2-1.2,7.5-2.6c1.5-0.9,1.8-2.1,1.1-3.6
      c-0.5-1.3-1.3-2.4-2.1-3.5c-5.4-7.1-7.3-15.1-5.7-23.8c1.4-7.5,4.9-14,9.7-19.9c0.4-0.5,1-0.9,0.9-1.6
      C156.2,106.8,156.3,106.7,156.5,106.5z M179.1,137.4c0.5,0.4,0.8,0,1.1-0.3c0.7-0.7,2-0.7,2.5-1.7c0.5,0,0.9,0.7,1.5,0.3
      c0,0.1,0,0.1,0,0.2c0.1,0,0.1,0,0.2,0c-0.1-0.1-0.1-0.1-0.2-0.2c-2.9-3.8-6.2-7-11.7-6.1c-2.9,0.5-5.9,0.7-8.1,3.1
      c-0.2,0.2-0.9,0.4-0.2,0.9c-0.5,0.3-1,0.7-1.5,1c-0.8,0.5-1.6,1-2.1,1.8c-0.3,0.4-0.5,0.8-0.2,1.3c0.3,0.3,0.7,0.4,1.1,0.3
      c0.6-0.1,1.2-0.6,1.8-0.2c1.4-0.1,2.8,0.2,4.2,0.5c2.7,0.9,5.4,0.9,8.2,0.5C176.8,138.6,178.4,138.7,179.1,137.4L179.1,137.4z
       M151.8,163.2c2,0.2,3.8,1,5.7,1.5c0.4,0.1,0.9,0.3,1.2-0.1c0.4-0.4,0.2-0.9,0-1.3c-0.3-0.7-0.7-1.1-1.4-1.2
      C155.4,161.9,153.6,162.2,151.8,163.2z M175.5,175.6c3.4-2.3,3-5-2-7.3C175.2,170.3,176.1,172.6,175.5,175.6z"
                />
                <path
                  className="st2"
                  d="M156,107c0.1,0.8-0.5,1.1-0.9,1.6c-4.8,5.9-8.4,12.4-9.7,19.9c-1.6,8.7,0.4,16.7,5.7,23.8
      c0.8,1.1,1.6,2.2,2.1,3.5c0.6,1.5,0.4,2.7-1.1,3.6c-2.3,1.3-5,1.9-7.5,2.6c-1.3,0.3-2.5,0.8-3.5,1.6c-1,0.8-1.3,1.8-0.4,2.9
      c0.6,0.7,1.2,1.4,1.8,2c1.8,2.1,1.4,3.6-1.2,4.4c-0.3,0.1-0.7,0.3-1,0.4c-0.5,0-1,0.1-1.4,0c-5-0.9-10-1.8-15-2.7
      c-2.4-0.5-4.9-1-7.7-1.6c5,8.5,10.3,16.1,19.3,20.2c1.5,3.1,3.8,5.2,7.3,5.8c1.3,0.2,2.5,0.7,3.8,1c1.6,0.5,2.7,1.6,2.9,3.2
      c0.2,1.5-0.6,2.6-1.7,3.5c-1,0.8-2,1.4-3.2,1.9c-0.6,0.2-1.3,0.3-1.7,0.9c-0.2,0-0.4,0-0.7,0c-0.6-0.4-1.2-0.3-1.8-0.2
      c-5.1-0.3-10-1.3-14.4-4.1c-8.9-5.6-17.2-11.8-23.1-20.7c-4.5-6.8-6.7-14.5-8.7-22.3c-1.7-6.5-2.1-13-1.5-19.7
      c4.5-3.2,9.1-6.3,13.8-9c2.6-1.5,5.3-2.9,8.2-3.8c1.9,0.3,3.8,0.7,5.8,1c4.4,0.7,8.9,1.5,13.3,2.2c2.2,0.4,2.4,0,1.8-2.2
      c-0.9-3.3-2.6-5.6-6.4-5.6c-0.8,0-1.6-0.2-2.5-0.3c0.7-0.3,1.4-0.7,2-1C137.9,116.1,147.2,112,156,107z M114,159.5
      c-1.4,0-2.4,0.9-3.5,1.6c-2.1,1.5-2,3.5,0.3,5.3c-0.3-2.2,0.2-3.9,1.8-5.4C113.1,160.6,113.5,160,114,159.5c0,0,0.1,0,0.1-0.1
      c0,0,0-0.1,0-0.1C114.1,159.4,114.1,159.5,114,159.5z M108.6,137.1c2-0.8,3.9-1.5,6-2.3c-0.3,2.2,1.2,3.3,2.7,4.5
      c-1.5-0.3-3.1-0.5-4.9-0.8c2.9,2,7.5,2.4,12,1.2c-0.7-0.1-1.2-0.1-1.9-0.2c3.5-1.2,4.2-2,4.3-5.2c0.5-0.2,0.8,0.4,1.3,0.4
      c0.2,0.3,0.4,0.5,0.8,0.4c-0.2-0.2-0.4-0.4-0.5-0.7c-0.6-0.8-1.4-1.5-2.3-1.9c-3.5-1.4-7.2-1.9-10.9-1.2
      C113.3,131.7,109.3,135.4,108.6,137.1c0-0.2-0.1-0.2-0.3,0c0,0,0,0.1,0,0.2C108.5,137.4,108.6,137.3,108.6,137.1z M137.9,163.2
      c-1.8-1-3.6-1.4-5.5-1c-1.1,0.2-1.7,1.2-1.5,2.1c0.3,1.1,1.2,0.5,1.9,0.3C134.5,164.1,136.1,163.5,137.9,163.2z M130.5,135.9
      c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.6-0.1,1.3-0.2,1.9c-0.1,0.3-0.7,0.7,0.1,0.9c0.7,0.2,1.5,0.6,2.2,0.1c0.5-0.4,0.1-0.9-0.1-1.2
      C131.6,137.2,131,136.6,130.5,135.9z"
                />
                <path
                  className="st4"
                  d="M114.6,126c-2.9,0.8-5.6,2.3-8.2,3.8c-4.8,2.7-9.4,5.8-13.8,9c-1.1,0.7-2.2,1.3-3.3,2c-0.2-0.6,0.3-0.7,0.5-1
      c12.2-12.4,26.9-20.4,43.3-25.8c7.5-2.5,14.9-5,22.4-7.3c0.3-0.1,0.7-0.1,1-0.2c-0.2,0.2-0.3,0.3-0.5,0.5c-8.9,5-18.1,9-27.4,13
      c-0.7,0.3-1.4,0.7-2,1C122.5,122.5,118.5,124.1,114.6,126z"
                />
                <path
                  className="st2"
                  d="M94.8,127.9c0.9-4.3,2.8-8.2,4.8-12.1c0.2-0.4,0.7-0.7,1.1-0.9c7.6-4,15.4-7.4,23.5-10.3
      c0.4-0.1,0.7-0.3,1.3,0c-5.3,2.6-10.1,5.8-14.7,9.3c-5.2,4-10,8.3-14.6,13C95.7,127.3,95.5,128,94.8,127.9L94.8,127.9z"
                />
                <path
                  className="st3"
                  d="M140.3,205.5c0.6-0.1,1.3-0.2,1.8,0.2C141.5,205.7,140.9,205.8,140.3,205.5z"
                />
                <path
                  className="st2"
                  d="M94.7,127.8c0.2,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1,0C94.5,128,94.6,127.9,94.7,127.8
      C94.8,127.9,94.7,127.8,94.7,127.8z"
                />
                <path
                  className="st4"
                  d="M135.4,189.3c-9-4.2-14.3-11.8-19.3-20.2c2.9,0.6,5.3,1.2,7.7,1.6c5,0.9,10,1.8,15,2.7c0.5,0.1,1,0,1.4,0
      c10.4,1.5,20.8,1.9,31.7,0.5c-4.3,6.2-9.6,10.7-16,13.8C149.3,190.9,142.5,191.4,135.4,189.3z M123.8,174
      c7.3,9,22.6,12.1,32.3,5.5c-5.5,0-10.9-0.6-16.3-1.4C134.5,177.1,129.2,175.8,123.8,174z"
                />
                <path
                  className="st5"
                  d="M184.2,135.7c-0.6,0.4-1-0.2-1.5-0.3c-1.4-0.6-2.7-1.2-4.2-1.8c0,2.2-1.3,3.3-3.2,4.2
      c1.5,0.3,2.7-0.4,3.9-0.4c-0.8,1.4-2.3,1.3-3.5,1.4c-2.7,0.4-5.5,0.3-8.2-0.5c0.9,0,1.8-0.1,2.7-0.1c-3.8-1.6-4.4-2.6-3.7-5.9
      c-0.8,0.5-1.6,0.9-2.3,1.3c-0.7-0.4,0-0.7,0.2-0.9c2.2-2.4,5.2-2.6,8.1-3.1C177.9,128.6,181.2,131.8,184.2,135.7L184.2,135.7z"
                />
                <path
                  className="st2"
                  d="M164.2,133.6c0.8-0.4,1.5-0.9,2.3-1.3c-0.8,3.3-0.1,4.3,3.7,5.9c-1,0-1.9,0.1-2.7,0.1
      c-1.4-0.3-2.7-0.6-4.2-0.5c-0.7-1-1.1-2-0.6-3.1C163.2,134.3,163.7,133.9,164.2,133.6z"
                />
                <path
                  className="st2"
                  d="M179.2,137.4c-1.3-0.1-2.4,0.7-3.9,0.4c1.9-0.8,3.2-1.9,3.2-4.2c1.5,0.6,2.8,1.2,4.2,1.8
      c-0.5,1-1.7,1-2.5,1.7C179.9,137.2,179.4,136.9,179.2,137.4L179.2,137.4z"
                />
                <path
                  className="st5"
                  d="M151.8,163.2c1.7-1,3.5-1.4,5.4-1.1c0.7,0.1,1.2,0.5,1.4,1.2c0.2,0.4,0.3,0.9,0,1.3c-0.3,0.3-0.8,0.2-1.2,0.1
      C155.6,164.2,153.8,163.4,151.8,163.2z"
                />
                <path
                  className="st2"
                  d="M175.5,175.6c0.6-3-0.3-5.3-2-7.3C178.5,170.6,178.8,173.2,175.5,175.6z"
                />
                <path
                  className="st5"
                  d="M162.7,134.6c-0.5,1.2-0.1,2.2,0.6,3.1c-0.7-0.4-1.2,0.1-1.8,0.2c-0.4,0.1-0.8,0-1.1-0.3
      c-0.3-0.4-0.1-0.9,0.2-1.3C161.2,135.7,161.9,135.1,162.7,134.6z"
                />
                <path
                  className="st5"
                  d="M179.1,137.4c0.3-0.5,0.7-0.2,1.1-0.3C179.9,137.4,179.6,137.8,179.1,137.4z"
                />
                <path
                  className="st5"
                  d="M184.1,135.7c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0-0.1,0-0.2,0C184.2,135.8,184.2,135.8,184.1,135.7
      C184.2,135.7,184.1,135.7,184.1,135.7z"
                />
                <path
                  className="st5"
                  d="M128.2,134.7c-0.5,0.1-0.8-0.6-1.3-0.4c-0.1,3.1-0.8,4-4.3,5.2c0.7,0.1,1.2,0.1,1.9,0.2
      c-4.5,1.2-9.1,0.8-12-1.2c1.8,0.3,3.3,0.6,4.9,0.8c-1.5-1.2-3-2.3-2.7-4.5c-2.1,0.8-4,1.6-6,2.3l0,0.1c0.6-1.8,4.6-5.5,6.6-5.9
      c3.7-0.7,7.4-0.2,10.9,1.2c0.9,0.4,1.7,1.1,2.3,1.9C128.4,134.5,128.3,134.6,128.2,134.7z"
                />
                <path
                  className="st5"
                  d="M114.6,126c3.9-1.9,7.9-3.5,12-4.9c0.8,0.1,1.6,0.3,2.5,0.3c3.8,0,5.5,2.3,6.4,5.6c0.6,2.2,0.4,2.6-1.8,2.2
      c-4.4-0.7-8.9-1.4-13.3-2.2C118.4,126.7,116.5,126.3,114.6,126z"
                />
                <path
                  className="st5"
                  d="M137.9,163.2c-1.8,0.3-3.4,0.9-5.1,1.3c-0.7,0.2-1.7,0.8-1.9-0.3c-0.2-0.9,0.4-1.9,1.5-2.1
      C134.3,161.8,136.1,162.2,137.9,163.2z"
                />
                <path
                  className="st3"
                  d="M114,159.5c-0.5,0.5-0.9,1.1-1.4,1.5c-1.6,1.4-2,3.2-1.8,5.4c-2.3-1.7-2.3-3.7-0.3-5.3
      C111.6,160.4,112.6,159.5,114,159.5L114,159.5z"
                />
                <path
                  className="st5"
                  d="M130.5,135.9c0.5,0.7,1,1.3,1.5,2c0.3,0.4,0.6,0.8,0.1,1.2c-0.7,0.5-1.5,0.1-2.2-0.1
      c-0.8-0.2-0.1-0.6-0.1-0.9c0.1-0.6,0.1-1.3,0.2-1.9C130.2,136.1,130.4,136,130.5,135.9z"
                />
                <path
                  className="st5"
                  d="M128.5,134.4c0.2,0.2,0.4,0.4,0.5,0.7c-0.4,0.1-0.6-0.1-0.8-0.4C128.3,134.6,128.4,134.5,128.5,134.4z"
                />
                <path
                  className="st5"
                  d="M108.6,137.1c-0.1,0.2-0.1,0.3-0.3,0.2c0,0,0-0.1,0-0.2C108.5,136.9,108.6,136.9,108.6,137.1
      C108.7,137.2,108.6,137.1,108.6,137.1z"
                />
                <path
                  className="st3"
                  d="M114,159.5c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0.1,0,0.1C114.1,159.5,114,159.5,114,159.5
      C114,159.5,114,159.5,114,159.5z"
                />
                <path
                  className="st2"
                  d="M123.8,174c5.4,1.9,10.6,3.2,16,4.1c5.4,0.9,10.8,1.4,16.3,1.4C146.4,186.1,131.1,183,123.8,174z"
                />
              </g>
            </g>
          )}
          {randomHead === "head3" && (
            <g id="head3">
              <g>
                <circle className="st1" cx="146.5" cy="144.2" r="76.2" />
                <path
                  className="st3"
                  d="M170,131c2.8,2.7,6.2,1.9,9.4,1.5c4.5-0.5,8.4-2.5,12.2-4.9c5.7,18.4-6.4,38.5-24.8,45
      c-3.4-3-7.2-5.6-10.9-8.2c-2.2-1.6-4.3-2.1-6.6-0.4c-0.6-2.5,0.9-4,2.6-5.5c0.7-0.6,2.3-0.6,1.4-2.2c0.5-0.3,0.9-0.7,1.4-1
      c2.4-1.4,0.9-2.7-0.4-3.4c-3.4-1.9-4.6-4.9-5.1-8.4c-0.2-1.4-0.3-2.8-0.7-4.2c-0.8-3.2-0.9-6.8-4.6-8.4c-4.2-1.7-8.5-3.1-13.2-2.9
      c-0.1,0.1-0.2,0.1-0.3,0c-0.9-0.4-1.7-0.9-2.6-1.3c0-0.2,0.1-0.5,0.1-0.6c-3-1.7-2.4-4.3-1.9-7c1.6-8,3-16,4.5-24c0.3,0,0.7,0,1,0
      c0,4.7,0,9.5,0,14.2s0.2,9.5,0.2,14.3c0,2,0.6,2.5,2.6,2.6c9.8,0,19.6-0.1,29.4-0.7c0.8-0.1,2.1-1,2.5,0.7l0.1-0.1
      c-0.2,0.2-0.3,0.3-0.5,0.4c0.2,0.1,0.3,0.2,0.4,0.3c-0.5,0.7-1.3,1-2.1,1.2c-0.9,0.7-2.8,0.7-2.6,2c0.3,1.6,2.2,0.9,3.3,1
      c0.2,0,0.4,0,0.6-0.1C167.1,130.8,168.5,132.3,170,131z"
                />
                <path
                  className="st2"
                  d="M130.9,127.9c4.6-0.2,9,1.2,13.2,2.9c3.6,1.5,3.8,5.2,4.6,8.4c0.3,1.4,0.5,2.8,0.7,4.2
      c0.5,3.5,1.7,6.6,5.1,8.4c1.3,0.7,2.8,2,0.4,3.4c-1.7-2.8-3.1,0.1-4.6,0.4c1.1,0.2,2.1,0.4,3.2,0.6c0.9,1.6-0.7,1.6-1.4,2.2
      c-1.7,1.5-3.2,3-2.6,5.5c-1.3,1.3-2.4,0.3-3.4-0.4c-1.2-0.8-2.3-1.2-3.6-0.3c-4.4,2.9-9,5.6-12.7,9.6c-18.9-6.2-30-25.1-25.9-44.5
      c3.4,1.6,6.7,3.6,10.6,4c1.4,0.2,2.8,0.4,4.2,0.4c5.1,0.1,6.3-0.7,8-5.6c0.4-1.1,0.7-0.8,1.4-0.5c0.9,0.4,1.7,0.9,2.6,1.3
      c-0.1,1.1-0.8,2-1.3,2.9c1.4,0.3,3.1,0.3,3.6-0.8C133.6,128.8,131.4,129,130.9,127.9z M144.9,155.6c-1.2-0.6-2.1-1-3-1.4
      c-0.8-0.3-1.7-0.2-1.9,0.8c-0.1,0.8,0.8,1.2,1.5,1.1C142.5,156.1,143.5,155.9,144.9,155.6z"
                />
                <path
                  className="st2"
                  d="M166.5,175.5c7.1,3.6,14.5,6.4,22.8,7.7c-3.6,4.7-6.6,9.2-11.6,12c-8.1,4.4-16.3,8.3-25.3,10.7
      c-2.8,0.7-5.5,0.5-8.1-0.3c-9.8-2.9-19.1-7.1-27.7-12.7c-3.1-2-5.1-4.7-6.6-8.1c-0.7-1.7-0.5-2.3,1.2-2.6
      c6.5-1.3,12.5-3.9,18.4-6.7c3.1,9,10.2,14.8,18.5,14.4C155.9,189.4,164,185.4,166.5,175.5z"
                />
                <path
                  className="st4"
                  d="M166.5,175.5c-2.6,9.9-10.6,14-18.2,14.4c-8.3,0.4-15.4-5.4-18.5-14.4c0.6-0.5,1.2-1,1.8-1.6
      c-0.7-0.4-1.3-0.7-1.9-1.1c3.6-4,8.2-6.7,12.7-9.6c1.3-0.9,2.5-0.4,3.6,0.3c1.1,0.7,2.1,1.7,3.4,0.4c2.3-1.7,4.3-1.2,6.6,0.4
      c3.7,2.6,7.4,5.2,10.9,8.2c-0.8,0.4-1.5,0.8-2.4,1.3C165.2,174.4,165.9,175,166.5,175.5z M156,174.8c-1.5-0.5-13-0.9-14.3-0.6
      c-1.5,0.4-0.7,1.1-0.2,1.7C144.9,179.6,152.8,179,156,174.8z"
                />
                <path
                  className="st2"
                  d="M176.3,132.1c1.9-1.4,2.9-2.8,3.2-4.7c0.1-0.6-0.6-2.3,1.1-1.6c1.5,0.6,3.2,1.1,4,2.8c0.1,0.2-0.6,0.7-1,1
      C181.6,130.9,179.2,131.7,176.3,132.1z"
                />
                <path
                  className="st2"
                  d="M118.6,132.1c-3.5-0.5-6.4-1.4-8.8-3.8c1.6-1.4,3.3-2.4,5.4-3C115,128.2,116.1,130.3,118.6,132.1z"
                />
                <path
                  className="st2"
                  d="M164.3,128c0.8-0.2,1.6-0.5,2.1-1.2c0.1-0.1,0.2-0.2,0.2-0.2c0-0.2-0.1-0.3-0.1-0.5l-0.1,0.1
      c0.6,0.4,2-0.4,1.8,1.3c-0.1,1.5,0.9,2.5,1.8,3.5c-1.5,1.3-2.9-0.2-4.4-0.1C164.9,130.1,164.1,129.3,164.3,128z"
                />
                <path
                  className="st3"
                  d="M191.3,125.4c-1.9-0.2-3.3-0.8-4.8-1.5C189.7,122.4,190.5,122.6,191.3,125.4z"
                />
                <path
                  className="st3"
                  d="M105,125.1c0.4-2.4,0.4-2.4,3.5-1.3C107.3,124.5,106.2,125,105,125.1z"
                />
                <path
                  className="st5"
                  d="M153.5,156.3c-1.1-0.2-2.1-0.4-3.2-0.6c1.5-0.3,2.8-3.2,4.6-0.4C154.4,155.6,153.9,155.9,153.5,156.3z"
                />
                <path
                  className="st5"
                  d="M130.9,127.9c0.4,1,2.6,0.9,2,2.1c-0.5,1.1-2.2,1.1-3.6,0.8c0.5-1,1.3-1.8,1.3-2.9
      C130.8,128,130.8,128,130.9,127.9z"
                />
                <path
                  className="st5"
                  d="M164.3,128c-0.2,1.3,0.6,2.1,1.4,3c-0.2,0-0.4,0.1-0.6,0.1c-1.2-0.2-3,0.5-3.3-1
      C161.5,128.7,163.4,128.7,164.3,128z"
                />
                <path
                  className="st1"
                  d="M166.5,126.1c0.1,0.2,0.1,0.3,0.1,0.5c0,0.1-0.1,0.2-0.2,0.2c-0.2-0.1-0.3-0.2-0.4-0.3
      C166.1,126.3,166.3,126.2,166.5,126.1z"
                />
                <path
                  className="st5"
                  d="M144.9,155.6c-1.4,0.2-2.3,0.5-3.3,0.5c-0.7,0.1-1.6-0.3-1.5-1.1c0.1-1,1-1.1,1.9-0.8
      C142.8,154.6,143.6,155.1,144.9,155.6z"
                />
                <path
                  className="st5"
                  d="M156,174.8c-3.1,4.2-11.1,4.8-14.5,1.1c-0.6-0.6-1.3-1.2,0.2-1.7C143,173.9,154.5,174.3,156,174.8z"
                />
              </g>
            </g>
          )}
          {randomHead === "head4" && (
            <g id="head4">
              <g>
                <circle className="st1" cx="146.5" cy="144.2" r="76.2" />
                <path
                  className="st2"
                  d="M111.6,124.6c0.7-5.8,1.3-11.6,1-17.5c-0.1-1.4,0.2-2.3,1.6-2.9c1.8-0.7,3.5-1.7,5.5-2.1c17.2,0,34.4,0,52,0
      c-2.9,2.7-3.9,5.6-4.2,9c-0.3,3.7-0.9,7.3-1.3,11c-0.6,4.6-2.7,8.4-6.6,11c-1.4,0.9-2.7,2-4,3c-2.5,1.9-4.2,4.3-3.7,7.7
      c0.5,3.3,1,6.6,1.7,9.9c1,4.5,2.7,8.8,3.8,13.2c0.7,2.6-0.1,4.1-2.5,5.2c-1.5,0.7-3,1.3-4.3,2.2c-2.7,1.6-4.7,3.8-4.1,7.3
      c-6.1,1.5-12.2,3.3-18.5,4.2c-7,1-13.7,0.7-19.5-4.1c-4.8-4-8.2-9.1-11.2-14.5c-0.4-0.7-0.1-1.1,0.2-1.6
      c6.1-10.3,10.3-21.3,12.9-32.9c0.1-0.5,0.2-1,0.3-1.6c1.8,0.3,3.7,0.5,5.5,0.8c7.8,1.1,15.6,2.1,23.5,3.2c1.4,0.2,1.8-0.3,1.2-1.5
      c-1.9-3.7-4.1-7.1-8.7-7.8c-2.1-0.3-4.1-0.6-6.2-0.9C121,124.3,116.3,124.6,111.6,124.6z M107.1,147.5c2.4-0.9,4.8-1.9,7.3-2.9
      c-0.4,2.7,1.3,4,2.9,5.5c-1.8-0.3-3.7-0.7-5.7-1.1c3.7,2.6,9.5,3.1,15.3,1.5c-0.4-0.3-1.1,0.1-1.5-0.4c3.6-1.7,4.5-3.1,3.8-6.5
      c0.7,0.4,1.3,0.7,1.9,1c-0.7-1.6-1.9-2.7-3.5-3.3c-3.1-1-6.4-1.6-9.7-1.5c-2.2,0.1-4.3,0.6-5.9,2.2c-1.7,1.6-3.5,3.3-5,5.1
      c-0.1,0.1-0.4,0.3-0.1,0.4C107,147.8,107.1,147.7,107.1,147.5z M140.6,177.6c-1.5-2.1-4.9-3.2-7-2.5c-0.8,0.3-1.5,0.6-1.3,1.6
      c0.2,0.9,0.8,1.4,1.7,1.6c0.7,0.1,1.3-0.1,1.9-0.4C137.4,177.4,138.8,176.9,140.6,177.6z M132.3,149.5c0.9,0.2,1.5,0.4,2.2,0.6
      c0.5,0.1,1.1,0.1,1.4-0.4c0.3-0.6,0-1-0.3-1.5c-0.7-0.9-1.5-1.5-2.8-2.4C133.5,147.5,133.5,148.6,132.3,149.5z"
                />
                <path
                  className="st3"
                  d="M146.3,181.5c-0.6-3.5,1.4-5.6,4.1-7.3c1.4-0.8,2.9-1.5,4.3-2.2c2.4-1.1,3.2-2.6,2.5-5.2
      c-1.1-4.5-2.8-8.8-3.8-13.2c-0.7-3.3-1.2-6.6-1.7-9.9c-0.5-3.4,1.2-5.7,3.7-7.7c1.3-1,2.6-2.1,4-3c4-2.6,6-6.3,6.6-11
      c0.5-3.7,1-7.3,1.3-11c0.3-3.4,1.3-6.3,4.2-9c-17.6,0-34.8,0-52,0c0.6-0.6,1.4-0.3,2.1-0.3c15.6,0,31.3,0.1,46.9-0.1
      c9-0.1,15.5,4,20.8,10.8c3.1,4,4.8,8.8,5.5,13.8c0.1,0.7,0.4,1.2,0.9,1.7c4.6,4.4,6.7,9.9,7,16c0.6,11.3-2.8,21.6-8.3,31.2
      c-1.9,3.4-4.8,6-9.2,5.9c-1.6,0-2.7-0.8-3-2.3c-0.6-3.2-1.3-3.6-5-3.7c-4.5-0.1-8.9,0.9-13.3,1.9
      C158.1,178.5,152.2,180,146.3,181.5z M166.9,134c-2.9-0.5-6.1,1.7-8.8,4.7c-1.1,1.2-0.9,1.7,0.7,2c1.9,0.3,3.8,0.5,5.7,0.7
      c5.7,0.6,11.4,1.2,17.1,1.6c5.8,0.5,11.6,0.8,17.4,1.2c0.4,0,1,0.3,1.2-0.4c0.1-0.5-0.3-0.7-0.5-1.1c-1.5-1.7-3.5-2.7-5.6-3.5
      C185.7,136.3,177.1,134.6,166.9,134z M193.1,148.3c-1.1-0.2-1.7-0.3-2.2-0.4c-9.1-0.7-18.1-1.4-27.2-2.1c-0.6,0-1.3-0.2-1.6,0.5
      c-0.3,0.6,0.1,1.2,0.5,1.6c0.4,0.4,0.7,0.8,1.1,1.2c3.3,2.9,7.1,4.9,11.2,6.3c0.2,0.1,0.5,0.2,0.7-0.1c-1.9-1.4-4.3-2.1-6.4-4.1
      C177.5,150.7,185.1,150.3,193.1,148.3z M153.9,176.9c1.4-0.6,2.7-0.7,4.1-0.4c0.7,0.1,1.4,0.1,2.1,0c1.1-0.1,1.7-0.9,1.7-2
      c0-1.2-0.9-1.3-1.8-1.3C157.3,173.2,155.5,174.8,153.9,176.9z"
                />
                <path
                  className="st3"
                  d="M123.1,191.5c18.4,0.9,35.8-1.7,52-10.3c0.5,0.5,0.1,0.8,0,1.2c-2.6,6.9-6.6,12.9-12.6,17.3
      c-8.9,6.6-18.3,6.2-27.8,1.2C130.3,198.6,126.5,195.5,123.1,191.5z M131.5,194.1c0,0.3,0,0.3,0,0.4c0.1,0.1,0.3,0.2,0.4,0.3
      c8.6,3.2,17.3,4.1,26,0.5c3.4-1.4,6.4-3.6,8.3-7.1C155.2,193.2,143.6,194.8,131.5,194.1z"
                />
                <path
                  className="st4"
                  d="M111.6,124.6c4.7-0.1,9.4-0.3,14.1,0.2c2.1,0.2,4.2,0.6,6.2,0.9c4.6,0.7,6.8,4,8.7,7.8
      c0.6,1.3,0.2,1.7-1.2,1.5c-7.8-1.1-15.6-2.1-23.5-3.2c-1.8-0.3-3.7-0.5-5.5-0.8C110.9,128.9,111.2,126.8,111.6,124.6z"
                />
                <path
                  className="st5"
                  d="M107,147.3c1.5-1.9,3.3-3.5,5-5.1c1.6-1.5,3.7-2.1,5.9-2.2c3.3-0.1,6.5,0.5,9.7,1.5c1.6,0.5,2.8,1.6,3.5,3.3
      c-0.6-0.3-1.3-0.7-1.9-1c0.7,3.4-0.2,4.8-3.8,6.5c0.4,0.5,1.1,0,1.5,0.4c-5.8,1.6-11.6,1.1-15.3-1.5c2.1,0.4,3.9,0.7,5.7,1.1
      c-1.6-1.5-3.3-2.8-2.9-5.5c-2.5,1-4.9,1.9-7.3,2.9C107.1,147.4,107,147.3,107,147.3z"
                />
                <path
                  className="st5"
                  d="M140.6,177.6c-1.8-0.7-3.2-0.2-4.7,0.4c-0.6,0.2-1.2,0.5-1.9,0.4c-0.9-0.2-1.5-0.7-1.7-1.6
      c-0.2-1,0.5-1.3,1.3-1.6C135.7,174.4,139.1,175.5,140.6,177.6z"
                />
                <path
                  className="st5"
                  d="M132.3,149.5c1.2-0.9,1.3-2,0.5-3.6c1.3,0.9,2.2,1.5,2.8,2.4c0.3,0.4,0.7,0.9,0.3,1.5
      c-0.3,0.5-0.9,0.5-1.4,0.4C133.8,150,133.1,149.8,132.3,149.5z"
                />
                <path
                  className="st5"
                  d="M107.1,147.5c0,0.2-0.1,0.3-0.3,0.2c-0.3-0.2,0-0.3,0.1-0.4C107,147.3,107.1,147.4,107.1,147.5z"
                />
                <path
                  className="st4"
                  d="M166.9,134c10.3,0.6,18.8,2.3,27.2,5.3c2.1,0.7,4.1,1.7,5.6,3.5c0.3,0.3,0.7,0.6,0.5,1.1
      c-0.2,0.7-0.8,0.4-1.2,0.4c-5.8-0.4-11.6-0.7-17.4-1.2c-5.7-0.5-11.4-1.1-17.1-1.6c-1.9-0.2-3.8-0.4-5.7-0.7
      c-1.6-0.2-1.8-0.8-0.7-2C160.8,135.7,163.9,133.5,166.9,134z"
                />
                <path
                  className="st5"
                  d="M193.1,148.3c-8,2-15.6,2.4-23.8,2.8c2.1,2,4.5,2.7,6.4,4.1c-0.2,0.4-0.5,0.2-0.7,0.1
      c-4.1-1.4-7.9-3.3-11.2-6.3c-0.4-0.4-0.7-0.8-1.1-1.2c-0.4-0.4-0.8-1-0.5-1.6c0.3-0.7,1-0.5,1.6-0.5c9.1,0.7,18.1,1.4,27.2,2.1
      C191.5,148,192,148.1,193.1,148.3z"
                />
                <path
                  className="st5"
                  d="M153.9,176.9c1.5-2,3.3-3.7,6.1-3.6c0.9,0,1.8,0.1,1.8,1.3c0,1.1-0.6,1.8-1.7,2c-0.7,0.1-1.5,0.1-2.1,0
      C156.6,176.2,155.3,176.3,153.9,176.9z"
                />
                <path
                  className="st2"
                  d="M131.5,194.1c12,0.7,23.7-0.9,34.7-5.9c-1.9,3.4-4.8,5.6-8.3,7.1c-8.7,3.7-17.4,2.8-26-0.5
      c-0.2-0.1-0.3-0.2-0.4-0.3C131.5,194.5,131.6,194.4,131.5,194.1z"
                />
              </g>
            </g>
          )}
        </SvgIcon>
      </Box>
    </Backdrop>
  );
};
