import { useGlobalModal, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { Box, PopperPlacementType, Tooltip } from "@mui/material";
import { FC, useCallback, useEffect, useMemo } from "react";
import { ActionMenu } from "src/components/action-menu/action-menu";
import { useBlacklistProfile } from "src/hooks/use-blacklist-profile";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useUnBlacklistProfile } from "src/hooks/use-unblacklist-profile";
import { openBanner } from "src/slices/banner.slice";
import { useDispatch } from "src/store";
import { getYesNoModalArg } from "src/utils/modal-util";
import { useProfileDrawer } from "./use-profile-drawer";
import { useReservationDetailsId } from "src/features/reservations/reservation-details/reservation-details/use-reservation-details-id";
import { useProperty } from "src/hooks/use-property";

export enum ProfileActionsOptions {
  BLACKLIST_PROFILE = "BLACKLIST_PROFILE",
  UNBLACKLIST_PROFILE = "UNBLACKLIST_PROFILE",
  OPEN_PROFILE = "OPEN_PROFILE"
}

interface ProfileActionsProps {
  isBlacklisted: boolean;
  profileId: number;
  menuPlacement?: PopperPlacementType;
}

export const ProfileActions: FC<ProfileActionsProps> = ({
  isBlacklisted,
  profileId,
  menuPlacement
}) => {
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const { open: openGlobalModal } = useGlobalModal();
  const [blacklistProfile, resultBlacklistProfile] = useBlacklistProfile();
  const [unBlacklistProfile, resultUnBlacklistProfile] = useUnBlacklistProfile();
  const { openDrawer } = useProfileDrawer();
  const { selectedId: reservationId } = useReservationDetailsId();
  const { selectedProperty } = useProperty();
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });

  useEffect(() => {
    if (resultBlacklistProfile?.isSuccess || resultUnBlacklistProfile?.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
    }
  }, [dispatch, t, resultBlacklistProfile, resultUnBlacklistProfile]);

  const menuItems = useMemo(
    () => [
      {
        id: ProfileActionsOptions.BLACKLIST_PROFILE,
        label: t("labels__blacklist_profile"),
        action: ProfileActionsOptions.BLACKLIST_PROFILE
      },
      {
        id: ProfileActionsOptions.UNBLACKLIST_PROFILE,
        label: t("labels__unblacklist_profile"),
        action: ProfileActionsOptions.UNBLACKLIST_PROFILE
      },
      {
        id: ProfileActionsOptions.OPEN_PROFILE,
        label: t("labels__open_profile"),
        action: ProfileActionsOptions.OPEN_PROFILE
      }
    ],
    [t]
  );

  const handleWithYesNoModal = useCallback(
    async (action: ProfileActionsOptions) => {
      return openGlobalModal(
        getYesNoModalArg(
          t(`labels__reservation_action_modal_title_${action}`),
          t(`labels__reservation_action_modal_text_${action}`),
          t("labels__yes"),
          t("labels__no")
        )
      );
    },
    [openGlobalModal, t]
  );

  const handleMenuClick = useCallback(
    async (action: ProfileActionsOptions) => {
      if (action === ProfileActionsOptions.OPEN_PROFILE) {
        return openDrawer(profileId.toString());
      }
      const modalBlaclistProfile = await handleWithYesNoModal(action);
      if (action === ProfileActionsOptions.BLACKLIST_PROFILE && modalBlaclistProfile) {
        return blacklistProfile({ profileId: profileId, reservationId: reservationId ?? "" });
      } else if (modalBlaclistProfile) {
        return unBlacklistProfile({ profileId: profileId, reservationId: reservationId ?? "" });
      }
    },
    [
      handleWithYesNoModal,
      blacklistProfile,
      profileId,
      unBlacklistProfile,
      reservationId,
      openDrawer
    ]
  );

  const filterMenuActions = useMemo(() => {
    return menuItems.reduce(
      (
        filteredMenu: { id: ProfileActionsOptions; label: string; action: ProfileActionsOptions }[],
        item
      ) => {
        // Show "Open Profile" option if there is a reservationId and if not restVersion since profile is not yet implemented in V1
        if (
          reservationId &&
          features?.guestListEnabled &&
          item.id === ProfileActionsOptions.OPEN_PROFILE
        ) {
          filteredMenu.push(item);
        }

        // Toggle between "Blacklist" and "Unblacklist" based on isBlacklisted
        if (isBlacklisted && item.id === ProfileActionsOptions.UNBLACKLIST_PROFILE) {
          filteredMenu.push(item);
        } else if (!isBlacklisted && item.id === ProfileActionsOptions.BLACKLIST_PROFILE) {
          filteredMenu.push(item);
        }

        return filteredMenu;
      },
      []
    );
  }, [reservationId, isBlacklisted, menuItems, features?.guestListEnabled]);

  return (
    <>
      <Tooltip title={t("labels__action")}>
        <Box>
          <ActionMenu
            items={filterMenuActions}
            handleAction={(selected) => {
              handleMenuClick(selected as ProfileActionsOptions);
            }}
            iconVertical
            menuPlacement={menuPlacement}
          />
        </Box>
      </Tooltip>
    </>
  );
};
