import { FC, PropsWithChildren, useEffect } from "react";
import { loadScriptOnce } from "../../utils/file-utils";

interface SevenRoomsLoaderProps {
  url: string;
}

export const SevenRoomsLoader: FC<PropsWithChildren<SevenRoomsLoaderProps>> = ({
  children,
  url
}) => {
  useEffect(() => {
    if (url) {
      loadScriptOnce(url);
    }
  }, [url]);

  return <>{children}</>;
};
