import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EntityStateStatus } from "../domain/EntityStateStatus";

interface AvailableDoorState {
  doorId: string;
  unlocked: boolean;
  error: boolean;
}

export const availableDoorStateAdapter = createEntityAdapter<AvailableDoorState>({
  selectId: (model) => model.doorId
});

const initialState = availableDoorStateAdapter.getInitialState<{
  status: EntityStateStatus;
}>({
  status: EntityStateStatus.IDLE
});

export const availableDoorsStateSlice = createSlice({
  name: "availableDoorsState",
  initialState,
  reducers: {
    resetDoorStatus: (state, action: PayloadAction<string>) => {
      const entity = state.entities[action.payload];
      if (entity) {
        entity.unlocked = false;
        entity.error = false;
      }
    },
    updateDoorState: (state, action: PayloadAction<AvailableDoorState>) => {
      availableDoorStateAdapter.setOne(state, {
        doorId: action.payload.doorId,
        unlocked: action.payload.unlocked,
        error: action.payload.error
      });
    }
  }
});

export const { resetDoorStatus, updateDoorState } = availableDoorsStateSlice.actions;

export const { selectAll: selectAvailableDoorsState, selectById: selectAvailableDoorsStateById } =
  availableDoorStateAdapter.getSelectors<RootState>((state) => state.availableDoorsState);
