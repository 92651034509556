import * as Types from "../generated/graphql";

import { tasksApi } from "../tasks.api";
export type CreateTaskMutationVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
  request: Types.CreateTaskRequest;
}>;

export type CreateTaskMutation = {
  __typename?: "Mutation";
  CreateTask: { __typename?: "TaskDto"; id: number };
};

export const CreateTaskDocument = `
    mutation CreateTask($pmsPropertyId: String!, $request: CreateTaskRequest!) {
  CreateTask(pmsPropertyId: $pmsPropertyId, request: $request) {
    id
  }
}
    `;

const injectedRtkApi = tasksApi.injectEndpoints({
  endpoints: (build) => ({
    CreateTask: build.mutation<CreateTaskMutation, CreateTaskMutationVariables>({
      query: (variables) => ({ document: CreateTaskDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useCreateTaskMutation } = injectedRtkApi;
