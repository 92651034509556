import { Autocomplete, Box, Popper, TextField, useTheme } from "@mui/material";
import React, {
  FC,
  HTMLAttributes,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useRef
} from "react";

export interface AutocompleteOption {
  id: string;
  label: string;
}
interface AutocompleteComponentProps {
  label: string;
  value: AutocompleteOption | null;
  options: Array<AutocompleteOption>;
  onSelected: (value: AutocompleteOption, event?: SyntheticEvent) => void; //Triggered only when option is selected
  fullWidth?: boolean;
  disabled?: boolean;
  displayItem?: (props: HTMLAttributes<HTMLElement>, option: AutocompleteOption) => ReactElement;
  inputEndEndorsement?: ReactElement;
  inputStartAdornment?: ReactElement;
  loading?: boolean;
  required?: boolean;
  name?: string;
  id?: string;
  getOptionDisabled?: (option: AutocompleteOption) => boolean;
  error?: string;
  inputVariant?: "standard" | "outlined" | "filled";
  disableClearable?: boolean;
  placeholder?: string;
  inputFontSize?: number;
}

export const AutocompleteComponent: FC<AutocompleteComponentProps> = ({
  label,
  value,
  options,
  onSelected,
  fullWidth = false,
  disabled = false,
  displayItem,
  inputEndEndorsement,
  inputStartAdornment,
  loading,
  required,
  name,
  id,
  getOptionDisabled,
  error,
  inputVariant = "outlined",
  disableClearable = true,
  placeholder,
  inputFontSize
}) => {
  const ref = useRef();
  const theme = useTheme();

  const PopperMy = useCallback((props: any) => {
    return (
      <Popper {...props} anchorEl={ref.current}>
        <Box my={1}>{props.children}</Box>
      </Popper>
    );
  }, []);

  return (
    <Autocomplete
      ref={ref}
      fullWidth={fullWidth}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      disableClearable={disableClearable}
      getOptionLabel={(option) => {
        return option?.label || option?.id;
      }}
      options={options}
      filterSelectedOptions
      PopperComponent={PopperMy}
      getOptionDisabled={getOptionDisabled}
      renderOption={(props, option) => {
        const disabled = getOptionDisabled?.(option);
        return (
          // eslint-disable-next-line jsx-a11y/role-supports-aria-props
          <li
            {...props}
            aria-disabled={false}
            key={option?.label + " " + option?.id}
            style={{
              color: disabled ? theme.palette.grey.A400 : "inherit",
              cursor: disabled ? "not-allowed" : "pointer",
              ...(disabled && { backgroundColor: "inherit" })
            }}
            onClick={(e) => {
              if (!disabled) {
                props.onClick && props.onClick(e);
              }
            }}
          >
            {displayItem ? displayItem(props, option) : option?.label}
          </li>
        );
      }}
      onChange={(e: SyntheticEvent<Element, Event>, newValue: AutocompleteOption | null) => {
        if (newValue) {
          onSelected(newValue, e);
        } else {
          onSelected({ id: "", label: "" }, e);
        }
      }}
      loading={loading}
      value={value ?? undefined}
      renderInput={(params) => (
        <TextField
          name={name}
          required={required}
          variant={inputVariant}
          {...params}
          error={!!error}
          helperText={error}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            ...(inputEndEndorsement
              ? {
                  endAdornment: (
                    <>
                      {inputEndEndorsement} {params.InputProps.endAdornment}
                    </>
                  )
                }
              : {}),
            startAdornment: inputStartAdornment,
            style: {
              fontSize: inputFontSize ?? "inherit"
            }
          }}
          label={label}
          id={id}
          sx={{
            "&&& :before": {
              borderBottom: inputVariant === "standard" ? "none" : "auto"
            },
            "&& :after": {
              borderBottom: inputVariant === "standard" ? "none" : "auto"
            }
          }}
        />
      )}
    />
  );
};
