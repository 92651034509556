import React, { FC } from "react";
import { formatPriceToString } from "../../utils/price";
import { Price } from "../../domain/price";

interface PricePreviewProps {
  price: Price;
  additionalInfo?: string;
}

export const PricePreview: FC<PricePreviewProps> = ({ price, additionalInfo }) => {
  const formattedPrice = formatPriceToString(price);
  return <span>{additionalInfo ? formattedPrice + ` / ${additionalInfo}` : formattedPrice}</span>;
};
