import { Autocomplete, Box, Popper, TextField } from "@mui/material";
import React, {
  FC,
  HTMLAttributes,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { grey } from "@mui/material/colors";
import { useDebounce } from "../hooks/use-debounce";

export interface AutocompleteOption {
  id: string;
  label: string;
}
interface AutocompleteComponentProps {
  label: string;
  value: AutocompleteOption | null;
  loadItems: (freeText: string) => Promise<Array<AutocompleteOption>>;
  onSelected: (value: AutocompleteOption, event?: SyntheticEvent) => void; //Triggered only when option is selected
  fullWidth?: boolean;
  disabled?: boolean;
  displayItem?: (props: HTMLAttributes<HTMLElement>, option: AutocompleteOption) => ReactElement;
  inputEndEndorsement?: ReactElement;
  required?: boolean;
  name?: string;
  id?: string;
  getOptionDisabled?: (option: AutocompleteOption) => boolean;
  error?: string;
}

export const AutocompleteAsyncComponent: FC<AutocompleteComponentProps> = ({
  label,
  value,
  onSelected,
  fullWidth = false,
  disabled = false,
  displayItem,
  inputEndEndorsement,
  required,
  name,
  id,
  getOptionDisabled,
  error,
  loadItems
}) => {
  const ref = useRef();
  const [options, setOptions] = useState<Array<AutocompleteOption>>([]);
  const [inputString, setInputString] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const inputStringWithDebounce = useDebounce(inputString, 400);

  useEffect(() => {
    if (inputStringWithDebounce) {
      loadItems(inputStringWithDebounce)
        .then((items) => {
          setOptions(items);
          setLoading(false);
        })
        .catch(() => {
          setOptions([]);
          setLoading(false);
        });
    }
  }, [inputStringWithDebounce, loadItems]);

  const PopperMy = useCallback((props: any) => {
    return (
      <Popper {...props} anchorEl={ref.current}>
        <Box my={1}>{props.children}</Box>
      </Popper>
    );
  }, []);

  return (
    <Autocomplete
      ref={ref}
      fullWidth={fullWidth}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      disableClearable={true}
      getOptionLabel={(option) => {
        return option?.label || option?.id;
      }}
      options={options}
      onInputChange={(_, value) => {
        if (value.length > 3) {
          setLoading(true);
          setInputString(value);
        }
      }}
      filterSelectedOptions
      PopperComponent={PopperMy}
      getOptionDisabled={getOptionDisabled}
      renderOption={(props, option) => {
        const disabled = getOptionDisabled?.(option);
        return (
          // eslint-disable-next-line jsx-a11y/role-supports-aria-props
          <li
            {...props}
            aria-disabled={false}
            key={option?.label + " " + option?.id}
            style={{
              color: disabled ? grey[400] : "inherit",
              cursor: disabled ? "not-allowed" : "pointer",
              ...(disabled && { backgroundColor: "inherit" })
            }}
            onClick={(e) => {
              if (!disabled) {
                props.onClick && props.onClick(e);
              }
            }}
          >
            {displayItem ? displayItem(props, option) : option?.label}
          </li>
        );
      }}
      onChange={(e: SyntheticEvent<Element, Event>, newValue: AutocompleteOption) => {
        onSelected(newValue, e);
      }}
      loading={loading}
      value={value ?? undefined}
      renderInput={(params) => (
        <TextField
          name={name}
          required={required}
          variant="outlined"
          {...params}
          error={!!error}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            ...(inputEndEndorsement
              ? {
                  endAdornment: (
                    <>
                      {inputEndEndorsement} {params.InputProps.endAdornment}
                    </>
                  )
                }
              : {})
          }}
          label={label}
          id={id}
        />
      )}
    />
  );
};
