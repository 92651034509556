import { GuestJourneyApi } from "../api/guest-journey.api";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Selector
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { KeycloakSliceState } from "./keycloak.slice";
import { MagicFileData } from "../types/magic-file";
import { MagicFileType } from "../domain/magic-file-type";
import { GuestServiceApi } from "../api/guest-service.apiV2";

export const magicFileAdapter = createEntityAdapter<MagicFileData>({
  selectId: (model) => model.magicFileType + "_" + model.fileName
});
const initialState = magicFileAdapter.getInitialState<{
  status: EntityStateStatus;
}>({
  status: EntityStateStatus.IDLE
});

export const getMagicFiles = createAsyncThunk<
  Array<MagicFileData>,
  { arg: Array<MagicFileData>; isRESTVersion: boolean },
  { rejectValue: { reason: string } }
>("files/getMagicFiles", async ({ arg, isRESTVersion }) => {
  const fetchFileData = (fileName: string, isRESTVersion: boolean) =>
    (isRESTVersion ? GuestJourneyApi : GuestServiceApi).getMagicFile(fileName);

  return Promise.all(
    arg.map(({ fileName, magicFileType, contentType }) =>
      fetchFileData(fileName, isRESTVersion).then((res) => ({
        fileName: fileName,
        fileSrc: res,
        magicFileType: magicFileType,
        contentType
      }))
    )
  );
});

export const magicFiles = createSlice({
  name: "files",
  initialState,
  reducers: {
    clearMagicFiles: (state) => {
      magicFileAdapter.removeAll(state);
      state.status = EntityStateStatus.IDLE;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMagicFiles.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(getMagicFiles.fulfilled, (state, action) => {
        magicFileAdapter.addMany(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(getMagicFiles.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      });
  }
});

const selectSelf: Selector<RootState, KeycloakSliceState> = (state: RootState) =>
  state[magicFiles.name];

export const { clearMagicFiles } = magicFiles.actions;

export const { selectAll: selectAllMagicFiles } = magicFileAdapter.getSelectors<RootState>(
  (state) => state[magicFiles.name]
);

export const selectIsMagicFilesDownloading = createSelector(
  selectSelf,
  (res) => res.status === EntityStateStatus.LOADING
);

export const selectSignatureDocument = createSelector(selectAllMagicFiles, (allFiles) =>
  allFiles.find((file) => file.magicFileType === MagicFileType.SIGNATURE_DOCUMENT)
);

export const selectIdentificationDocument = createSelector(selectAllMagicFiles, (allFiles) =>
  allFiles.find((file) => file.magicFileType === MagicFileType.IDENTIFICATION_DOCUMENT)
);

export const selectAttachmentDocument = createSelector(selectAllMagicFiles, (allFiles) =>
  allFiles.filter((file) => file.magicFileType === MagicFileType.ATTACHMENTS)
);
