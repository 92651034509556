import { FC, useContext, useEffect, useRef, useState } from "react";
import { ChatItemContentBaseProps } from "./domain/chat-item-content-base-props";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Box } from "@mui/material";

interface ChatItemContentLocationProps extends ChatItemContentBaseProps {}
const DEFAULT_ZOOM = 16;

const setInitZoomLevel = (googleMap: google.maps.Map, markersNo: number) => {
  const zoomChangeBoundsListener = google.maps.event.addListenerOnce(
    googleMap,
    "bounds_changed",
    function () {
      if ((markersNo === 1 && googleMap?.getZoom()) || 0 > DEFAULT_ZOOM) {
        googleMap.setZoom(DEFAULT_ZOOM);
      } else if (markersNo > 1 && googleMap?.getZoom()) {
        //Zoom out one level so that tooltip could fit
        googleMap.setZoom((googleMap.getZoom() || 1) - 1);
      }
    }
  );
  setTimeout(function () {
    google.maps.event.removeListener(zoomChangeBoundsListener);
  }, 2000);
};
const initGoogleMap = (googleMapRef: HTMLDivElement) =>
  new window.google.maps.Map(googleMapRef, {
    disableDefaultUI: true,
    zoom: 8
  });
export const ChatItemContentLocation: FC<ChatItemContentLocationProps> = ({ content }) => {
  const googleMapRef = useRef<HTMLDivElement | null>(null);
  const [status, setStatus] = useState<Status>();
  const { googleMaps } = useContext(TenantContext);

  useEffect(() => {
    try {
      if (status === Status.SUCCESS && googleMapRef.current) {
        let bounds = new window.google.maps.LatLngBounds();
        const googleMap = initGoogleMap(googleMapRef.current);

        if (googleMap) {
          setInitZoomLevel(googleMap, 1);

          const marker = new window.google.maps.Marker({
            position: {
              lat: content.latitude,
              lng: content.longitude
            },
            map: googleMap
          });

          // @ts-ignore
          bounds.extend(marker.getPosition());

          googleMap.fitBounds(bounds);
        }
      }
    } catch (e) {
      console.log("maps", e);
    }
  }, [content, status]);

  if (!googleMaps?.apiKey) {
    return <> </>;
  }

  return (
    <Box height={250}>
      <Wrapper
        callback={(status) => {
          setStatus(status);
        }}
        apiKey={googleMaps?.apiKey}
      >
        <div
          ref={googleMapRef}
          style={{
            width: "100%",
            height: "100%"
          }}
        />
      </Wrapper>
    </Box>
  );
};
