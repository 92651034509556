import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type BackofficeGetUpsellOffersQueryVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
}>;

export type BackofficeGetUpsellOffersQuery = {
  __typename?: "Query";
  BackofficeGetUpsellOffers: Array<{
    __typename?: "ReservationUpsellOffer";
    pmsRatePlanId: string;
    pmsUnitGroupId: string;
    pmsUpsellRuleId: string;
    calculatedUpsellInformation: {
      __typename?: "CalculatedUpsellInformation";
      averagePerNightAmountDifference: {
        __typename?: "GrossPrice";
        currency?: string | null;
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
      };
      totalAmountDifference: {
        __typename?: "GrossPrice";
        currency?: string | null;
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
      };
    };
  }>;
};

export const BackofficeGetUpsellOffersDocument = `
    query BackofficeGetUpsellOffers($pmsReservationId: String!) {
  BackofficeGetUpsellOffers(pmsReservationId: $pmsReservationId) {
    pmsRatePlanId
    pmsUnitGroupId
    pmsUpsellRuleId
    calculatedUpsellInformation {
      averagePerNightAmountDifference {
        currency
        grossPrice
        grossPriceInCents
      }
      totalAmountDifference {
        currency
        grossPrice
        grossPriceInCents
      }
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    BackofficeGetUpsellOffers: build.query<
      BackofficeGetUpsellOffersQuery,
      BackofficeGetUpsellOffersQueryVariables
    >({
      query: (variables) => ({ document: BackofficeGetUpsellOffersDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useBackofficeGetUpsellOffersQuery, useLazyBackofficeGetUpsellOffersQuery } =
  injectedRtkApi;
