import { api as getConversationDetailsApi } from "../../graphql-messaging/queries/GetConversationDetails.generated";
import { MessageType } from "../../graphql-messaging/generated/graphql";
import { generateUUID } from "../../utils/data.utils";
import { ConversationMessageStatus } from "../../domain/ConversationMessageStatus";

interface InjectMessageProps {
  conversationId: string;
}

export const useInjectMessage = () => {
  const injectMessage = ({ conversationId }: InjectMessageProps) =>
    getConversationDetailsApi.util.updateQueryData(
      "GetConversationDetails",
      {
        filter: {
          conversationId: conversationId,
          pageable: {
            pageNumber: 0,
            pageSize: 1,
            sort: "createdAt,asc"
          }
        }
      },
      (draft) => {
        const updatedDraft = draft.GetConversations?.content?.map((item) => {
          if (item.conversationId === conversationId) {
            return {
              ...item,
              messages: [
                ...item.messages,
                {
                  messageId: generateUUID(),
                  status: ConversationMessageStatus.Placeholder,
                  type: MessageType.ConversationMessage,
                  conversationId
                }
              ]
            };
          } else {
            return item;
          }
        });

        Object.assign(draft, {
          GetConversations: {
            content: updatedDraft,
            totalElements: draft.GetConversations.totalElements
          }
        });
      }
    );

  return { injectMessage };
};
