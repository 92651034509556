import { AssignKeyHandlerProps } from "./assign-key-handler";
import React, { FC, useCallback, useEffect, useState } from "react";
import { AssignKeyAnimation, AssignKeyAnimationRunning } from "../animations/assign-key-animations";
import {
  AssignKeyContent,
  useApiVersion,
  useDoorProviderConfig,
  useKeyAssign
} from "@likemagic-tech/sv-magic-library";
import { hideRootSuspense, showRootSuspense } from "../../features/loaders/loader.slice";
import { closeBanner, openBanner } from "../../slices/banner.slice";
import { Box, Button } from "@mui/material";

import { useDispatch, useSelector } from "../../store";
import {
  assignTagStatusWithWSFailed,
  assignTagStatusWithWSLoading,
  assignTagStatusWithWSSucceeded,
  selectAssignKeyWithWSStatus
} from "../../slices/assign-key";
import { useTheme } from "@mui/material/styles";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { isStatusIdle } from "../../domain/EntityStateStatus";
import { usePerformReservationAction } from "../../hooks/use-reservation-actions";
import { AllowedActionEnum } from "../../domain/reservation-table-dto";

export const KeyAssignWs: FC<AssignKeyHandlerProps> = ({
  reservation,
  tagReaderId,
  isFormInputLabelDisabled,
  automaticallyCall,
  hideEncoder,
  onSuccess
}) => {
  const dispatch = useDispatch();
  const [cardNumber, setCardNumber] = useState("");
  const config = useDoorProviderConfig(reservation.propertyId);
  const onAssignedKey = useCallback(() => {
    dispatch(assignTagStatusWithWSSucceeded());
  }, [dispatch]);
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const assignStatus = useSelector(selectAssignKeyWithWSStatus);
  const [performAction] = usePerformReservationAction();
  const { isRESTVersion } = useApiVersion();

  const {
    isAborted,
    isConnecting,
    isWaiting,
    isKeyAssigned,
    initializeKey,
    isError,
    assignCardCallback,
    abortKeyAssigment,
    tagNumber,
    onAssignKeySuccess,
    onAssignKeyError
  } = useKeyAssign({
    reservation,
    tagReaderId: tagReaderId,
    closeBanner: () => dispatch(closeBanner()),
    openBanner: (payload) => dispatch(openBanner(payload)),
    timeoutError: t("labels__error__card_timeout_30_seconds"),
    isPageVisible: true,
    onAssignedKey,
    subscribeTimeout: 0
  });

  useEffect(() => {
    !isRESTVersion &&
      tagNumber &&
      performAction({
        reservationId: reservation.id,
        action: AllowedActionEnum.ENCODE_KEY,
        payload: { encoderId: tagNumber }
      })
        .then(onAssignKeySuccess)
        .catch(onAssignKeyError);
  }, [
    isRESTVersion,
    tagNumber,
    performAction,
    reservation.id,
    onAssignKeySuccess,
    onAssignKeyError
  ]);

  useEffect(() => {
    if (automaticallyCall && tagReaderId && isStatusIdle(assignStatus)) {
      initializeKey();
      dispatch(assignTagStatusWithWSLoading());
    }
  }, [automaticallyCall, tagReaderId, assignStatus, dispatch, initializeKey]);

  useEffect(() => {
    if (isError || isAborted) {
      dispatch(assignTagStatusWithWSFailed());
      hideEncoder(true);
    }
  }, [isError, isAborted, hideEncoder, dispatch]);

  useEffect(() => {
    isFormInputLabelDisabled(isWaiting || isConnecting);
  }, [isWaiting, isConnecting, isFormInputLabelDisabled]);

  const assignCardFallback = useCallback(async () => {
    try {
      dispatch(showRootSuspense());
      await assignCardCallback(reservation, cardNumber);
      dispatch(assignTagStatusWithWSSucceeded());
      dispatch(hideRootSuspense());
      onSuccess?.();
    } catch (e) {
      dispatch(hideRootSuspense());
      dispatch(assignTagStatusWithWSFailed());
      dispatch(
        openBanner({
          type: "error",
          title: `Error assigning ${cardNumber}`
        })
      );
    }
  }, [reservation, cardNumber, assignCardCallback, dispatch, onSuccess]);

  return (
    <>
      <AssignKeyContent
        isKeyAssigned={isKeyAssigned}
        isConnecting={isConnecting}
        isWaiting={isWaiting}
        isAborted={isAborted}
        unitName={reservation?.unit?.name ?? ""}
        labels={{
          unit: "Studio:",
          tag: "Key Card:",
          cardNumber: "Card Number",
          cardNumberDescription: isAborted
            ? t("labels__assign__key__card__aborted")
            : t("labels__error__assign__key__card")
        }}
        assignKeyAnimation={<AssignKeyAnimation />}
        assignKeyAnimationRunning={
          <AssignKeyAnimationRunning doorProvider={config?.doorProvider} />
        }
        isError={isError}
        cardNumberValue={cardNumber}
        onChangeCardNumber={(e) => setCardNumber(e.target.value)}
      />
      <Box>
        {isWaiting || isConnecting ? (
          <Button
            sx={{
              marginTop: theme.spacing(2)
            }}
            variant="primary"
            fullWidth
            onClick={abortKeyAssigment}
          >
            {t("labels__abort__assign__key__card__process")}
          </Button>
        ) : (
          <Button
            sx={{
              marginTop: theme.spacing(2)
            }}
            variant="primary"
            fullWidth
            onClick={isError || isAborted ? assignCardFallback : initializeKey}
          >
            {t("labels__assign__key__card__process")}
          </Button>
        )}
      </Box>
    </>
  );
};
