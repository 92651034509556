import { useGetPreferencesQuery } from "src/graphql/queries/GetPreferences.generated";
import { useGetPreferencesMapQuery } from "../store/endpoints/preferences.endpoints";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { transformPreferencesV2ToPreferencesV1 } from "src/graphql/transform/transform-reservation-details";

export const useGuestPreference = ({ propertyId }: { propertyId?: string }) => {
  const { isRESTVersion, isGraphQlVersion } = useApiVersion();

  const { data: dataV1 } = useGetPreferencesMapQuery(
    {
      propertyId: propertyId ?? ""
    },
    { skip: isGraphQlVersion || !propertyId }
  );

  const { data: dataV2 } = useGetPreferencesQuery(
    { pmsPropertyIds: propertyId },
    { skip: isRESTVersion || !propertyId }
  );

  return isRESTVersion ? dataV1 : transformPreferencesV2ToPreferencesV1(dataV2?.GetPreferences);
};
