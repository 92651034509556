import { BaseApiClient } from "@likemagic-tech/sv-magic-library";
import { Reservation } from "../domain/reservation";
import { MagicFileType } from "../domain/magic-file-type";
import { convertBlobToBase64 } from "./guest-journey.api";

export interface UploadFileToReservationOpts {
  reservation: Reservation;
  file: File;
  fileType: MagicFileType;
}

const BASE_URL = "/api/guest-service/files";

class GuestServiceApiClient extends BaseApiClient {
  async getMagicFile(fileName: string): Promise<string> {
    const response: Response = await this.fetchApi(`${BASE_URL}/${fileName}`, {
      headers: {
        accept: "*/*"
      }
    });

    const blob = await response.blob();
    return convertBlobToBase64(blob);
  }
}

export const GuestServiceApi = new GuestServiceApiClient();
