import { api } from "../GetTask.generated";

const getTaskEnhanced = api.enhanceEndpoints({
  endpoints: {
    GetTask: {
      providesTags: (result) => [{ id: result?.GetTask?.id, type: "Tasks" }]
    }
  }
});

export const { useGetTaskQuery: useGetTaskQueryEnhanced } = getTaskEnhanced;
