import * as Types from "../generated/graphql";

import { messagingApi } from "../messaging.api";
export type ReplyMessageMutationVariables = Types.Exact<{
  message: Types.MessageToSend;
}>;

export type ReplyMessageMutation = {
  __typename?: "Mutation";
  Reply: Array<{ __typename?: "Message"; createdAt: any }>;
};

export const ReplyMessageDocument = `
    mutation ReplyMessage($message: MessageToSend!) {
  Reply(message: $message) {
    createdAt
  }
}
    `;

const injectedRtkApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({
    ReplyMessage: build.mutation<ReplyMessageMutation, ReplyMessageMutationVariables>({
      query: (variables) => ({ document: ReplyMessageDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useReplyMessageMutation } = injectedRtkApi;
