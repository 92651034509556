import { FC, useEffect, useState } from "react";
import { ChatItemContentV2BaseProps } from "./domain/chat-item-content-v2-base-props";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "../../../store";
import { fetchMessageAttachment } from "../../../slices/messaging-attachment.slice";

interface ChatItemContentAudioProps extends ChatItemContentV2BaseProps {}

export const ChatItemContentV2Audio: FC<ChatItemContentAudioProps> = ({ mediaUrl }) => {
  const [birdAttachment, setBirdAttachment] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();
  useEffect(() => {
    if (mediaUrl) {
      dispatch(fetchMessageAttachment({ url: mediaUrl }))
        .unwrap()
        .then((blobString: string) => setBirdAttachment(blobString));
    }
  }, [mediaUrl, dispatch]);

  return (
    <>
      {mediaUrl && !birdAttachment ? (
        <CircularProgress />
      ) : (
        <audio controls style={{ width: "100%" }} src={birdAttachment} />
      )}
    </>
  );
};
