import { Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useTheme } from "@mui/material/styles";
import { FolioPreview } from "../../../../store/endpoints/reservation-table.endpoints";
import { DialogTitleModal } from "../../../../components/submit-modal/dialog-title";
import { PricePicker } from "../../../../components/price-picker/price-picker";
import { Form, Formik } from "formik";
import { emptyPrice } from "../../../../domain/price";
import { useRefundPaymentMutation } from "../../../../graphql/mutations/refund-payment.generated";
import { useProperty } from "src/hooks/use-property";
import { InfoIcon } from "src/icons/info.icon";

interface MovePaymentModalProps {
  folios: Array<FolioPreview>;
  isOpen: boolean;
  onClose: () => void;
  pmsPaymentId: string;
  folioId: string;
  folioMetadata: any;
  magicId: string;
}

export const RefundPaymentModal: FC<MovePaymentModalProps> = ({
  isOpen,
  onClose,
  pmsPaymentId,
  folioId,
  folios,
  magicId
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();

  const { selectedProperty } = useProperty();
  const [refundPayment] = useRefundPaymentMutation();

  const folio = useMemo(() => {
    return folios.find((item) => item.id === folioId);
  }, [folioId, folios]);

  const payment = useMemo(() => {
    return folio?.payments.find((item) => item.id === pmsPaymentId);
  }, [folio, pmsPaymentId]);

  const handleSubmit = useCallback(async () => {
    await refundPayment({
      refundPaymentRequest: {
        magicId: magicId,
        pmsPaymentId: pmsPaymentId,
        pmsPropertyId: selectedProperty!.propertyId,
        folioMetadata: folio?.metadata
      }
    });
    onClose();
  }, [folio?.metadata, magicId, pmsPaymentId, selectedProperty, refundPayment, onClose]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const initialValues = {
    price: {
      currency: payment?.price.currency ?? emptyPrice().currency,
      amount: payment?.price.amount ? payment.price.amount / 100 : 0
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => (
        <Dialog
          open={isOpen}
          onClose={handleClose}
          PaperProps={{
            sx: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%"
            }
          }}
        >
          <Grid container p={3} flexDirection="column" width="100%">
            <Form id="cash-payment">
              <DialogTitleModal onClose={handleClose} title={t("labels__refund_payment")} />
              <DialogContent sx={{ paddingInline: "0", paddingBottom: "0" }}>
                <Paragraph>{t("labels__refund_full_amount")}</Paragraph>
                <Box pt={3}>
                  <PricePicker
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    prefix="price"
                    disabled
                  />
                </Box>
                <Alert
                  icon={<InfoIcon sx={{ color: theme.palette.info.main }} />}
                  sx={{
                    alignItems: "center",
                    backgroundColor: theme.palette.info.light,
                    mt: 2
                  }}
                >
                  <ParagraphSmall>{t("labels_refund")}</ParagraphSmall>
                </Alert>
              </DialogContent>
              <DialogActions sx={{ flex: "1 1 0", px: "0", flexDirection: "column" }}>
                <Button
                  variant="primary"
                  fullWidth
                  sx={{ marginTop: theme.spacing(2) }}
                  type="submit"
                >
                  <Paragraph
                    sx={{
                      color: theme.palette.background.paper,
                      textTransform: "none"
                    }}
                  >
                    {t("buttons__yes")}
                  </Paragraph>
                </Button>
              </DialogActions>
            </Form>
          </Grid>
        </Dialog>
      )}
    </Formik>
  );
};
