import { differenceInHours, isAfter, isBefore, isSameDay, startOfDay } from "date-fns";
import { useCallback, useContext, useMemo } from "react";
import { ChipStatus } from "../components/chip/chip-status";
import { IdCheckStatus, Reservation } from "../domain/reservation";
import { ReservationStatus } from "../domain/reservation-status";
import { UnitCondition } from "../domain/Unit";
import { useUnitConditionModal } from "../hooks/use-unit-condition-modal";
import { patchUnit } from "../slices/cleaning";
import { useDispatch } from "../store";
import {
  TenantContext,
  TimeConfig,
  useDoorProviderConfig,
  useGlobalModal,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { getUnitType } from "./tenant-unit-type";
import { useProperty } from "../hooks/use-property";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";
import { ConfirmIdentity } from "src/components/dashboard/identity/confirm-identity";
import { clearMagicFiles } from "src/slices/magic-files.slice";
import { AllowedActionEnum } from "src/domain/reservation-table-dto";
import { usePerformReservationAction } from "src/hooks/use-reservation-actions";
import { utcToZonedTime } from "date-fns-tz";

export enum ReservationStateStatuses {
  GUEST_FLOW = "Guest flow",
  ID_CHECK = "Identity check",
  ROOM_READY = "Room ready",
  ROOM_DOOR = "Room door",
  PUBLIC_DOOR = "Public door"
}

export enum ReservationActionLabels {
  GUEST_FLOW_COMPLETED = "labels__card_guest_flow_completed",
  GUEST_FLOW_NOT_COMPLETED = "labels__card_guest_flow_not_completed",
  ID_CHECK_CONFIRMED = "labels__card_identification_confirmed",
  ID_CHECK_UNCONFIRMED = "labels__card_identification_not_confirmed",
  ID_CHECK_DECLINED = "labels__card_identification_declined",
  UNIT = "labels__card_unit",
  UNIT_READY = "labels__card_unit_ready",
  UNIT_TO_BE_INSPECTED = "labels__card_unit_to_be_inspected",
  UNIT_DIRTY = "labels__card_unit_dirty",
  UNIT_PICKUP = "labels__card_unit_pickup",
  UNIT_DIRTY_AND_OCCUPIED = "labels__card_unit_dirty_and_occupied",
  UNIT_NOT_ASSIGNED = "labels__card_unit_not_assigned",
  UNIT_DOOR_AVAILABLE = "labels__card_unit_door_available",
  UNIT_DOOR_NOT_AVAILABLE = "labels__card_unit_door_not_available",
  PUBLIC_DOOR_AVAILABLE = "labels__card_public_door_available",
  PUBLIC_DOOR_NOT_AVAILABLE = "labels__card_public_door_not_available"
}

export const GuestFlowSteps = [
  "PERSONAL_DATA",
  "PREFERRED_CHANNEL",
  "LEGAL",
  "ADDRESS",
  "SERVICES",
  "PAYMENT",
  "CONFIRMATION"
];

const getChipStatusForIdCheck = (idCheckStatus: IdCheckStatus) => {
  if (idCheckStatus === IdCheckStatus.CONFIRMED) {
    return ChipStatus.OK;
  } else if (idCheckStatus === IdCheckStatus.DECLINED) {
    return ChipStatus.CRITICAL;
  } else {
    return ChipStatus.MINOR;
  }
};

const getGuestFlowStatus = (reservation: Reservation) => {
  const arrival = reservation?.arrival ? new Date(reservation.arrival) : undefined;
  // guest flow not completed
  if (!reservation.flowState.completed) {
    // guest flow not completed in the past or today
    if (
      arrival &&
      (arrival?.toDateString() === new Date().toDateString() || isBefore(arrival, new Date()))
    ) {
      return ChipStatus.CRITICAL;
    }

    // guest flow not completed in the future and recuring guest
    if (reservation.userProfileReservationCount === 1) {
      return ChipStatus.CRITICAL;
    } else {
      return ChipStatus.MINOR;
    }
  }
  return ChipStatus.OK;
};

//TODO check is this really clean unit we should probably check unitCleanOnCheckin for dirty ones that are in house status
export const getUnitStatus = (reservation: Reservation): ChipStatus | null => {
  if (!reservation.unit) {
    return null;
  }
  if (reservation.status === ReservationStatus.CHECKED_OUT) {
    return null;
  }

  if (userFeelsRoomIsClean(reservation) && reservation.status === ReservationStatus.IN_HOUSE) {
    return null;
  }
  switch (reservation.unit?.status?.condition) {
    case UnitCondition.CLEAN:
      return ChipStatus.OK;
    case UnitCondition.CLEAN_TO_BE_INSPECTED:
      return ChipStatus.MINOR;
    case UnitCondition.DIRTY:
      return ChipStatus.CRITICAL;
    default:
      return null;
  }
};

const getRoomDoorStatus = (reservation: Reservation): ChipStatus | null => {
  if (
    !reservation.unit ||
    reservation.status === ReservationStatus.CHECKED_OUT ||
    reservation.status === ReservationStatus.CONFIRMED
  ) {
    return null;
  }

  if (!userFeelsRoomIsClean(reservation) && reservation.status === ReservationStatus.IN_HOUSE) {
    return ChipStatus.CRITICAL;
  }

  return !!reservation.accessibleDoors?.find((d) => !d.is_general)
    ? ChipStatus.OK
    : ChipStatus.CRITICAL;
};

const getPublicDoorStatus = (
  reservation: Reservation,
  availableFromTime?: TimeConfig,
  availableToTime?: TimeConfig
): ChipStatus | null => {
  const now = new Date();
  const availableFrom =
    reservation?.arrival &&
    new Date(reservation?.arrival).setHours(
      availableFromTime?.hours || 8,
      availableFromTime?.minutes || 0,
      0,
      0
    );

  let availableTo =
    reservation.departure &&
    new Date(reservation.departure).setHours(
      availableToTime?.hours || 18,
      availableToTime?.minutes || 0,
      0,
      0
    );
  if (reservation.checkOutTime) {
    availableTo = new Date(reservation.checkOutTime).setHours(
      availableToTime?.hours || 18,
      availableToTime?.minutes || 0,
      0,
      0
    );
  }
  const publicDoorAccessible = !!reservation.accessibleDoors?.find((door) => door.is_general);

  const privateDoorAccessible = !!reservation.accessibleDoors?.find((d) => !d.is_general);

  // for checkins out of default time
  if (publicDoorAccessible) {
    return ChipStatus.OK;
  } else if (privateDoorAccessible && !publicDoorAccessible) {
    return ChipStatus.OK;
  }

  // is date between arrival and departure? just then public doors can be available can be availabe
  if (availableFrom && availableTo && isAfter(now, availableFrom) && isBefore(now, availableTo)) {
    // when flow is not completed, public doors can not be checked!
    if (!reservation.flowState.completed && reservation.status === ReservationStatus.CONFIRMED) {
      return null;
    }
    // public doors available?
    if (publicDoorAccessible) {
      return ChipStatus.OK;
    }

    return ChipStatus.CRITICAL;
  } else {
    return null;
  }
};

export const calculateReservationStatuses = (
  reservation: Reservation,
  availableFromTime?: TimeConfig,
  availableToTime?: TimeConfig
) => {
  return {
    [ReservationStateStatuses.GUEST_FLOW]: getGuestFlowStatus(reservation),
    [ReservationStateStatuses.ID_CHECK]: getChipStatusForIdCheck(reservation.idCheckStatus),
    [ReservationStateStatuses.ROOM_READY]: getUnitStatus(reservation),
    [ReservationStateStatuses.ROOM_DOOR]: getRoomDoorStatus(reservation),
    [ReservationStateStatuses.PUBLIC_DOOR]: getPublicDoorStatus(
      reservation,
      availableFromTime,
      availableToTime
    )
  };
};

const getReservationStatuses: (
  reservation: Reservation,
  availableFromTime?: TimeConfig,
  availableToTime?: TimeConfig
) => (ChipStatus | null)[] = (reservation: Reservation, availableFromTime, availableToTime) => {
  const reservationStatusesWithValues = calculateReservationStatuses(
    reservation,
    availableFromTime,
    availableToTime
  );
  return Object.values(reservationStatusesWithValues);
};

export const useFilterReservationByStatus = () => {
  const { selectedProperty } = useProperty();
  const doorConfig = useDoorProviderConfig(selectedProperty?.propertyId ?? "");

  const timeAvailability = selectedProperty && doorConfig?.timeAvailability;
  return useCallback(
    (reservations: Reservation[], status?: ChipStatus) => {
      switch (status) {
        case ChipStatus.CRITICAL:
          return reservations.filter((reservation) =>
            getReservationStatuses(
              reservation,
              timeAvailability?.publicDoorsAvailableFrom,
              timeAvailability?.publicDoorsAvailableTo
            ).some((statusItem) => statusItem === ChipStatus.CRITICAL)
          );
        case ChipStatus.MINOR:
          return reservations
            .filter((reservation) =>
              getReservationStatuses(
                reservation,
                timeAvailability?.publicDoorsAvailableFrom,
                timeAvailability?.publicDoorsAvailableTo
              ).some((statusItem) => statusItem === ChipStatus.MINOR)
            )
            .filter(
              (reservation) =>
                !getReservationStatuses(
                  reservation,
                  timeAvailability?.publicDoorsAvailableFrom,
                  timeAvailability?.publicDoorsAvailableTo
                ).some((statusItem) => statusItem === ChipStatus.CRITICAL)
            );
        case ChipStatus.OK:
          return reservations.filter(
            (reservation) =>
              !getReservationStatuses(
                reservation,
                timeAvailability?.publicDoorsAvailableFrom,
                timeAvailability?.publicDoorsAvailableTo
              ).some(
                (statusItem) =>
                  statusItem === ChipStatus.MINOR || statusItem === ChipStatus.CRITICAL
              )
          );
        default:
          return reservations;
      }
    },
    [timeAvailability?.publicDoorsAvailableFrom, timeAvailability?.publicDoorsAvailableTo]
  );
};

export const arrivalStatusFilter = (reservation: Reservation) =>
  reservation.status === ReservationStatus.IN_HOUSE ||
  reservation.status === ReservationStatus.CONFIRMED;

export const statusFilter = (reservation: Reservation) =>
  reservation.status !== ReservationStatus.NO_SHOW &&
  reservation.status !== ReservationStatus.DELETED;

export const statusSort = (a: Reservation, b: Reservation) => {
  const map = {
    [ReservationStatus.CONFIRMED]: 1,
    [ReservationStatus.IN_HOUSE]: 2,
    [ReservationStatus.CHECKED_OUT]: 3,
    [ReservationStatus.NO_SHOW]: 4,
    [ReservationStatus.DELETED]: 5
  };

  if (map[a.status] < map[b.status]) {
    return -1;
  }

  if (map[a.status] > map[b.status]) {
    return 1;
  }

  return a.id.localeCompare(b.id);
};

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

function getGuestFlowLabel(reservation: Reservation, t: any) {
  const status = getGuestFlowStatus(reservation);

  switch (status) {
    case ChipStatus.OK:
      return t(ReservationActionLabels.GUEST_FLOW_COMPLETED);
    case ChipStatus.CRITICAL:
    case ChipStatus.MINOR:
    default:
      return t(ReservationActionLabels.GUEST_FLOW_NOT_COMPLETED);
  }
}

function getIdCheckLabel(reservation: Reservation, t: any) {
  const status = getChipStatusForIdCheck(reservation.idCheckStatus);
  switch (status) {
    case ChipStatus.OK:
      return t(ReservationActionLabels.ID_CHECK_CONFIRMED);
    case ChipStatus.MINOR:
      return t(ReservationActionLabels.ID_CHECK_UNCONFIRMED);
    case ChipStatus.CRITICAL:
    default:
      return t(ReservationActionLabels.ID_CHECK_DECLINED);
  }
}

function getRoomReadyLabel(unitType: string, reservation: Reservation, t: any) {
  const status = getUnitStatus(reservation);

  switch (status) {
    case ChipStatus.OK:
      return (
        unitType +
        t(ReservationActionLabels.UNIT_READY).replace(
          "{{unitNumber}}",
          reservation.unit?.name ?? ""
        )
      );
    case ChipStatus.MINOR:
      return (
        unitType +
        t(ReservationActionLabels.UNIT_TO_BE_INSPECTED).replace(
          "{{unitNumber}}",
          reservation.unit?.name ?? ""
        )
      );
    case ChipStatus.CRITICAL:
      if (
        reservation.status === ReservationStatus.CONFIRMED &&
        reservation.unit?.status &&
        reservation.unit?.status.occupied
      ) {
        return (
          unitType +
          t(ReservationActionLabels.UNIT_DIRTY_AND_OCCUPIED).replace(
            "{{unitNumber}}",
            reservation.unit?.name ?? ""
          )
        );
      }

      return (
        unitType +
        t(ReservationActionLabels.UNIT_DIRTY).replace(
          "{{unitNumber}}",
          reservation.unit?.name ?? ""
        )
      );
    default:
      if (reservation.unit?.name) {
        return (
          unitType +
          t(ReservationActionLabels.UNIT).replace("{{unitNumber}}", reservation.unit?.name ?? "")
        );
      }
      return unitType + t(ReservationActionLabels.UNIT_NOT_ASSIGNED);
  }
}

function getRoomLabel(unitType: string, reservation: Reservation, t: any) {
  return !!reservation.accessibleDoors?.find((d) => !d.is_general)
    ? unitType +
        t(ReservationActionLabels.UNIT_DOOR_AVAILABLE).replace(
          "{{unitNumber}}",
          reservation.unit?.name ?? ""
        )
    : unitType +
        t(ReservationActionLabels.UNIT_DOOR_NOT_AVAILABLE).replace(
          "{{unitNumber}}",
          reservation.unit?.name ?? ""
        );
}

function getPublicDoorLabel(reservation: Reservation, t: any) {
  return !!reservation.accessibleDoors?.find(
    (door) => door.is_general || door.title === "MOBILE_KEY"
  )
    ? t(ReservationActionLabels.PUBLIC_DOOR_AVAILABLE)
    : t(ReservationActionLabels.PUBLIC_DOOR_NOT_AVAILABLE);
}

// @deprecated when reservation overview goes live
export const useReservationActions = (
  reservation: Reservation,
  onChange: () => void,
  setDoorData: (modalIsOpen: boolean) => void
) => {
  const { t } = useTranslationWrapper();

  const dispatch = useDispatch();
  const { theme: selectedTheme } = useContext(TenantContext);
  const { open: openUnitConditionModal } = useUnitConditionModal();
  const doorConfig = useDoorProviderConfig(reservation.propertyId);
  const { open: openGlobalModal } = useGlobalModal();
  const [performAction] = usePerformReservationAction();
  const { selectedProperty } = useProperty();
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });

  return useMemo((): Record<
    ReservationStateStatuses,
    { status: ChipStatus | null; onClick?: () => void; label: string }
  > => {
    const timeAvailability = doorConfig?.timeAvailability;

    return {
      [ReservationStateStatuses.GUEST_FLOW]: {
        status: getGuestFlowStatus(reservation),
        label: getGuestFlowLabel(reservation, t),
        onClick: () => openInNewTab(reservation.magicLink)
      },
      [ReservationStateStatuses.ID_CHECK]: {
        status: getChipStatusForIdCheck(reservation.idCheckStatus),
        label: getIdCheckLabel(reservation, t),
        onClick: async () => {
          const resultOfIdConfirmationModal = await openGlobalModal({
            modalProps: {
              title: t("labels__check_identity"),
              content: (
                <ConfirmIdentity
                  files={reservation.files}
                  primaryGuest={reservation.primaryGuest}
                  documentUploadEnabled={features?.documentsUploadEnabled}
                  propertyId={selectedProperty?.propertyId}
                  idCheckStatus={reservation.idCheckStatus}
                />
              )
            },
            modalActions: [
              {
                label: t("labels__decline"),
                result: IdCheckStatus.DECLINED,
                variant: "primary",
                color: "error"
              },
              {
                label: t("labels__approve"),
                result: IdCheckStatus.CONFIRMED,
                variant: "primary",
                color: "success"
              }
            ]
          });
          if (resultOfIdConfirmationModal) {
            performAction({
              reservationId: reservation.id,
              action: AllowedActionEnum.CHANGE_ID_CHECK_STATUS,
              payload: { status: resultOfIdConfirmationModal }
            });
          }
          dispatch(clearMagicFiles());
        }
      },
      [ReservationStateStatuses.ROOM_READY]: {
        status: getUnitStatus(reservation),
        label: getRoomReadyLabel(getUnitType(selectedTheme), reservation, t),
        onClick: async () => {
          if (!reservation.unit) {
            return;
          }
          const result = await openUnitConditionModal({
            unitName: [getUnitType(selectedTheme), reservation.unit.name || ""].join(" "),
            condition: reservation.unit.status.condition
          });
          if (result != null) {
            await dispatch(
              patchUnit({
                unitId: reservation.unit?.id,
                patches: [
                  {
                    op: "replace",
                    path: "/status/condition",
                    value: result
                  }
                ]
              })
            );

            onChange?.();
          }
        }
      },
      [ReservationStateStatuses.ROOM_DOOR]: {
        status: getRoomDoorStatus(reservation),
        label: getRoomLabel(getUnitType(selectedTheme), reservation, t),
        onClick: () => {
          setDoorData(true);
        }
      },
      [ReservationStateStatuses.PUBLIC_DOOR]: {
        status: getPublicDoorStatus(
          reservation,
          timeAvailability?.publicDoorsAvailableFrom,
          timeAvailability?.publicDoorsAvailableTo
        ),
        label: getPublicDoorLabel(reservation, t),
        onClick: () => {
          setDoorData(true);
        }
      }
    };
  }, [
    doorConfig?.timeAvailability,
    reservation,
    t,
    selectedTheme,
    openGlobalModal,
    features?.documentsUploadEnabled,
    selectedProperty?.propertyId,
    dispatch,
    performAction,
    openUnitConditionModal,
    onChange,
    setDoorData
  ]);
};

export const userFeelsRoomIsClean = (reservation: Reservation): boolean =>
  !!(
    reservation?.unitCleanOnCheckin ||
    reservation?.unit?.status?.condition === UnitCondition.CLEAN ||
    reservation?.extras?.cleanUnitDialogSeen
  );

export const getLastStuckGuestJourneyPage = (lastConfirmedPage: string): string => {
  const index = GuestFlowSteps.indexOf(lastConfirmedPage);
  const nextState = index < GuestFlowSteps.length - 1 ? index + 1 : index;
  return GuestFlowSteps[nextState];
};

export const getPercentageOfGJProgress = (
  lastConfirmedPage: string,
  isFlowCompleted: boolean
): number => {
  const index = GuestFlowSteps.indexOf(lastConfirmedPage);

  if (index < 0) {
    return 0;
  }

  if (isFlowCompleted) {
    return 100;
  }

  return ((index + 1) * 100) / GuestFlowSteps.length;
};

export function computeDoNotDisturbLabel(t: any, timestamp?: string) {
  // old locks have no timestamp, until a new event comes
  if (!timestamp) {
    return t("labels__do_not_disturb");
  }
  if (differenceInHours(new Date(), new Date(timestamp)) < 1) {
    return t("labels__do_not_disturb_since_less_than_1_hour");
  }
  return t("labels__do_not_disturb_since", {
    hours: differenceInHours(new Date(), new Date(timestamp), {
      roundingMethod: "round"
    })
  });
}

export const isCheckInAvailable = (arrival: string, timeZone: string = "Europe/Zurich") => {
  const arrivalZonedDateTime = utcToZonedTime(arrival, timeZone);
  const nowZonedDateTime = utcToZonedTime(startOfDay(new Date()), timeZone);
  return isAfter(arrivalZonedDateTime, nowZonedDateTime);
};

export const isCheckOutAvailable = (arrival: string) => {
  return isSameDay(new Date(arrival), new Date());
};
