import type { FC } from "react";
import { ChipStatus } from "./chip-status";
import { Button } from "@likemagic-tech/sv-magic-library";

interface ChipButtonProps {
  status: ChipStatus | null;
  outlined?: boolean;
  label?: string;
  fullWidth?: boolean;
  onClick: () => void;
}

export const buttonVariantMap: {
  [key: string]: "error" | "success" | "warning";
} = {
  [ChipStatus.CRITICAL]: "error",
  [ChipStatus.OK]: "success",
  [ChipStatus.MINOR]: "warning"
};

export const ChipButton: FC<ChipButtonProps> = ({
  status,
  outlined,
  label,
  fullWidth,
  onClick
}) => {
  return (
    <Button
      variant={outlined ? "secondary" : "primary"}
      color={status ? buttonVariantMap[status] : "primary"}
      disabled={!status}
      fullWidth={fullWidth}
      onClick={onClick}
    >
      {label || status}
    </Button>
  );
};
