import { useMemo } from "react";
import { PersonGender } from "../domain/person";
import { useFeatureFlags } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "./use-translation-wrapper";

export const useWizardGenderOptions = () => {
  const { diverseGenderEnabled } = useFeatureFlags();
  const { t } = useTranslationWrapper();

  const genderOptions = useMemo(() => {
    const options = [
      { value: PersonGender.FEMALE, label: t("labels__wizard_gender_female") },
      { value: PersonGender.MALE, label: t("labels__wizard_gender_male") }
    ];

    if (diverseGenderEnabled) {
      options.push({ value: PersonGender.OTHER, label: t("labels__wizard_gender_other") });
    }
    return options;
  }, [t, diverseGenderEnabled]);

  return { genderOptions };
};
