import { Granularity, InteractionType } from "../domain/service";
import { AdditionalServicesAvailabilityResponse } from "../domain/additional-services-availability";

export const calculateInteractionType = (serviceConfig: AdditionalServicesAvailabilityResponse) => {
  switch (serviceConfig.granularity) {
    case Granularity.WHOLE_STAY:
    case Granularity.WHOLE_STAY_PAY_ONCE:
    case Granularity.DEPARTURE_DAY:
    case Granularity.ARRIVAL_DAY:
      if (serviceConfig.canBookMultiple) {
        return InteractionType.COUNTER;
      }
      return InteractionType.BOOLEAN;
    case Granularity.ANY_DAY:
    case Granularity.ANY_DAY_EXCEPT_ARRIVAL_DAY:
      return InteractionType.DATE_PICKER_DIALOG;
    case Granularity.LATE_CHECKOUT:
      return InteractionType.LATE_CHECKOUT_DIALOG;
    case Granularity.EARLY_CHECKIN:
      return InteractionType.EARLY_CHECKIN_DIALOG;
    default:
      console.warn("");
      return undefined;
  }
};
