import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetUserProfileByIdQueryVariables = Types.Exact<{
  userProfileId: Types.Scalars["Int"]["input"];
}>;

export type GetUserProfileByIdQuery = {
  __typename?: "Query";
  GetUserProfileById?: {
    __typename?: "UserProfile";
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    title?: string | null;
    userAccountUuid?: string | null;
    guestIdentityId?: number | null;
    phone?: string | null;
    email?: string | null;
    gender?: Types.Gender | null;
    birthdate?: any | null;
    preferredCommunicationChannel?: Types.CommunicationChannel | null;
    preferredLanguage?: string | null;
    nationality?: string | null;
    vipCode?: string | null;
    blacklisted: boolean;
    licensePlate?: string | null;
    address?: {
      __typename?: "Address";
      addressLine1?: string | null;
      addressLine2?: string | null;
      postalCode?: string | null;
      city?: string | null;
      countryCode?: string | null;
      region?: string | null;
    } | null;
    preferences: Array<{
      __typename?: "Preference";
      pmsId: string;
      pmsPreferenceCategoryId: string;
      name: string;
      description?: string | null;
      pmsPropertyId?: string | null;
    }>;
    notes: Array<{
      __typename?: "ProfileNote";
      pmsId: string;
      title?: string | null;
      text?: string | null;
      pmsPropertyId?: string | null;
      pmsProfileNoteCategoryId?: string | null;
      category?: { __typename?: "ProfileNoteCategory"; pmsId: string; name?: string | null } | null;
    }>;
  } | null;
};

export const GetUserProfileByIdDocument = `
    query GetUserProfileById($userProfileId: Int!) {
  GetUserProfileById(userProfileId: $userProfileId) {
    id
    firstName
    lastName
    title
    userAccountUuid
    guestIdentityId
    phone
    email
    gender
    birthdate
    preferredCommunicationChannel
    preferredLanguage
    nationality
    vipCode
    blacklisted
    licensePlate
    address {
      addressLine1
      addressLine2
      postalCode
      city
      countryCode
      region
    }
    preferences {
      pmsId
      pmsPreferenceCategoryId
      name
      description
      pmsPropertyId
    }
    notes {
      pmsId
      title
      text
      pmsPropertyId
      pmsProfileNoteCategoryId
      category {
        pmsId
        name
      }
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetUserProfileById: build.query<GetUserProfileByIdQuery, GetUserProfileByIdQueryVariables>({
      query: (variables) => ({ document: GetUserProfileByIdDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetUserProfileByIdQuery, useLazyGetUserProfileByIdQuery } = injectedRtkApi;
