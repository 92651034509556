import { createAsyncThunk, createSelector, createSlice, Selector } from "@reduxjs/toolkit";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { DoorDelegateApi } from "../api/door-delegate";
import { EntityStateStatus } from "src/domain/EntityStateStatus";
import { RootState } from "../store";
import { handleSliceError } from "../utils/error-handling";

type KeyAssignState = {
  assignTagStatusWithWS: EntityStateStatus;
  assignTagStatusWithAPI: EntityStateStatus;
};

const initialState: KeyAssignState = {
  assignTagStatusWithWS: EntityStateStatus.IDLE,
  assignTagStatusWithAPI: EntityStateStatus.IDLE
};

export const encodeKeyWithAPI = createAsyncThunk<
  any,
  { reservationId: string; tagReaderId: string },
  { state: RootState; rejectValue: ApiErrorJson }
>("keyAssign/encodeKey", async (arg, thunkAPI) => {
  try {
    return await DoorDelegateApi.encodeKey(arg.reservationId, arg.tagReaderId, {
      signal: thunkAPI.signal
    });
  } catch (e) {
    return handleSliceError(e, thunkAPI.rejectWithValue);
  }
});

export const keyAssignSlice = createSlice({
  name: "keyAssign",
  initialState,
  reducers: {
    resetKeyAssignState: (state) => {
      state.assignTagStatusWithWS = initialState.assignTagStatusWithWS;
      state.assignTagStatusWithAPI = initialState.assignTagStatusWithAPI;
    },
    assignTagStatusWithWSSucceeded: (state) => {
      state.assignTagStatusWithWS = EntityStateStatus.SUCCEEDED;
    },
    assignTagStatusWithWSFailed: (state) => {
      state.assignTagStatusWithWS = EntityStateStatus.FAILED;
    },
    assignTagStatusWithWSLoading: (state) => {
      state.assignTagStatusWithWS = EntityStateStatus.LOADING;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(encodeKeyWithAPI.pending, (state) => {
      state.assignTagStatusWithAPI = EntityStateStatus.LOADING;
    });
    builder.addCase(encodeKeyWithAPI.fulfilled, (state) => {
      state.assignTagStatusWithAPI = EntityStateStatus.SUCCEEDED;
    });
    builder.addCase(encodeKeyWithAPI.rejected, (state) => {
      state.assignTagStatusWithAPI = EntityStateStatus.FAILED;
    });
  }
});

export const {
  resetKeyAssignState,
  assignTagStatusWithWSSucceeded,
  assignTagStatusWithWSFailed,
  assignTagStatusWithWSLoading
} = keyAssignSlice.actions;

export const selectKeyAssignSlice: Selector<RootState, KeyAssignState> = (state: RootState) =>
  state[keyAssignSlice.name];

export const selectAssignKeyWithAPIStatus = createSelector(
  selectKeyAssignSlice,
  (state) => state.assignTagStatusWithAPI
);
export const selectAssignKeyWithWSStatus = createSelector(
  selectKeyAssignSlice,
  (state) => state.assignTagStatusWithWS
);
