import { OverviewDetailsQuery as GetOverviewDetailsQuery } from "../queries/GetReservationDetails.generated";
import { FolioPreview } from "../../store/endpoints/reservation-table.endpoints";
import { transformFullPrice } from "./transform-utils";
import {
  transformPaymentTypeV2ToCommon,
  transformRefundStatusV2ToCommon
} from "./transform-payment";

type ReservationTypeV2 = Exclude<GetOverviewDetailsQuery["OverviewDetails"], undefined | null>;

type FolioV2 = ReservationTypeV2["folios"][number];
type OrderItemV2 = FolioV2["orderItems"][number];
export const transformFolioToCommon = (arg: FolioV2): FolioPreview => {
  return {
    closed: arg.closed,
    balance: transformFullPrice(arg.totalAllowedPayment),
    charges: arg.orderItems.map((item: OrderItemV2) => ({
      price: transformFullPrice(item.price),
      id: item.pmsServiceId ?? "",
      name: item.name ?? "",
      quantity: item.quantity ?? 0,
      created: item.created ?? "",
      pmsId: item.pmsId ?? "",
      note: item.note ?? "",
      subName: item.subName ?? "",
      reference: item.reference ?? undefined
    })),
    id: arg.pmsId ?? "",
    payments: arg.payments?.map((item) => ({
      id: item.pmsId ?? "",
      cardsDetail: (item?.price?.grossPriceInCents ?? 0) < 0 ? "Refund" : "Payment",
      createdAt: item.created,
      price: transformFullPrice(item.price),
      refundable: item.refundable,
      refundStatus: transformRefundStatusV2ToCommon(item.refundStatus),
      type: transformPaymentTypeV2ToCommon(item.type)
    })),
    number: arg.number ?? "",
    metadata: arg.metadata ?? {},
    createdAt: arg.created,
    pendingPayments: arg.pendingPayments?.map((item) => ({
      id: item.id,
      cardsDetail: (item.price?.grossPriceInCents ?? 0) < 0 ? "Pending Refund" : "Pending Payment",
      createdAt: item.created,
      price: transformFullPrice(item.price)
    }))
  };
};
