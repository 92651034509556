export interface AvailableDoor {
  unitId: string;
  id: string;
  title: string;
  pin: string;
  lockAccessibility: LockAccessibility;
  batteryLevel: BatteryLevel;
  privacyMode: boolean;
  privacyModeLastChangeTimestamp: string;
}

export enum LockAccessibility {
  PUBLIC = "PUBLIC",
  PUBLIC_INHOUSE = "PUBLIC_INHOUSE",
  BACKOFFICE_ONLY = "BACKOFFICE_ONLY",
  PRIVATE = "PRIVATE",
  SERVICE = "SERVICE",
  RESTRICTED = "RESTRICTED" // Note: BE should never return these
}

export enum BatteryLevel {
  CRITICAL = "CRITICAL",
  LOW = "LOW",
  GOOD = "GOOD",
  FRESH = "FRESH",
  NONE = "NONE",
  UNKNOWN = "UNKNOWN"
}
