import { GetQuickFilterCountsQuery } from "../../../graphql-tasks/query/GetQuickFilterCounts.generated";
import { GetAdditionalTasksQuery } from "../../../graphql-tasks/query/GetAdditionalTasks.generated";
import { GetLeftoverTasksQuery } from "../../../graphql-tasks/query/GetLeftoverTasks.generated";
import { GetHousekeepingCardsQuery } from "../../../graphql-tasks/query/GetHousekeepingCards.generated";
import { GetTaskQuery } from "../../../graphql-tasks/query/GetTask.generated";
import { Priority, Status } from "src/graphql-tasks/generated/graphql";
import { HousekeepingTaskFragment } from "../../../graphql-tasks/fragment/HousekeepingTaskFragment.generated";

//We do reexport of the generated types because we want to have more control of what is happening to the domain types

export type Task = GetTaskQuery["GetTask"];

export type HousekeepingTaskType = HousekeepingTaskFragment;
export type HousekeepingCards = GetHousekeepingCardsQuery["GetHousekeepingCards"];
export type AdditionalTasks = GetAdditionalTasksQuery["GetAdditionalTasks"];
export type LeftoverTasks = GetLeftoverTasksQuery["GetLeftoverTasks"];
export type Links = Task["links"];
export type Attachments = Exclude<Task["attachments"], undefined | null>;

export type TaskConditionsResponse = Exclude<
  GetQuickFilterCountsQuery,
  null | undefined
>["GetQuickFilterCounts"];

export { Status as TaskStatus };
export { Priority as TaskPriority };

export const SortedTaskPriorities = [Priority.Low, Priority.Medium, Priority.High, Priority.Urgent];

export enum TaskOwnershipFilters {
  REPORTED_BY_ME = "REPORTED_BY_ME",
  ASSIGNED_TO_ME = "ASSIGNED_TO_ME",
  ASSIGNED_TO_MY_TEAMS = "ASSIGNED_TO_MY_TEAMS"
}

export type AssigneeGroup = Task["assigneeGroup"];
export type Assignee = Task["assignee"];
