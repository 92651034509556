import { FC } from "react";
import { SvgIcon } from "@mui/material";

export const PaymentCash: FC = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="Cash-Payment-Bill-4--Streamline-Ultimate"
  >
    <desc>{"Cash Payment Bill 4 Streamline Icon: https://streamlinehq.com"}</desc>
    <g id="Cash-Payment-Bill-4--Streamline-Ultimate">
      <path
        d="m22.25 23.25 -3 -4.5V13.5c0 -2.13 -4.31 -4.42 -5.5 -5.45"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="m15.71 14.79 -3.65 -2.92a1.3 1.3 0 1 0 -1.84 1.84l3.53 4v2.5c0 1.18 1.69 3 1.69 3"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M13.75 20.21a1.51 1.51 0 0 1 -1.5 1.54h-9a1.5 1.5 0 0 1 -1.5 -1.5v-18a1.5 1.5 0 0 1 1.5 -1.5h9a1.5 1.5 0 0 1 1.5 1.5v11"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="M7 14.18a3 3 0 0 1 0.63 -5.93 2.91 2.91 0 0 1 1.7 0.53"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="m4.75 3.5 0 1.5"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        d="m10.75 18 0 1"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </SvgIcon>
);
