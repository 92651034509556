import { Unit, UnitCondition } from "src/domain/Unit";
import { GetUnitsQuery, UnitState } from "../generated/graphql";

type ExtractedGetUnits = Exclude<GetUnitsQuery["GetUnits"], undefined | null>;

export const transformUnitsV2ToCommonUnits = (arg: ExtractedGetUnits[number]): Unit => {
  return {
    name: arg?.name ?? "",
    status: {
      occupied: !!arg?.occupied,
      condition: mapUnitStateV2ToCommon(arg?.state)
    },
    unitGroupId: arg?.pmsUnitGroupIds?.[0] ?? "",
    id: arg?.pmsId ?? "",
    privacyMode: arg?.privacyMode ?? false,
    privacyModeLastChangeTimestamp: arg?.privacyModeLastChangeTimestamp ?? "",
    attributes: [] // TODO missing from API
  };
};

const mapUnitStateV2ToCommon = (state?: UnitState | null): UnitCondition => {
  switch (state) {
    case UnitState.Clean:
      return UnitCondition.CLEAN;
    case UnitState.CleanToBeInspected:
      return UnitCondition.CLEAN_TO_BE_INSPECTED;
    case UnitState.Dirty:
      return UnitCondition.DIRTY;
    default:
      return UnitCondition.UNKNOWN;
  }
};

export const mapCommonToUnitStateV2 = (condition: UnitCondition): UnitState => {
  switch (condition) {
    case UnitCondition.CLEAN:
      return UnitState.Clean;
    case UnitCondition.CLEAN_TO_BE_INSPECTED:
      return UnitState.CleanToBeInspected;
    case UnitCondition.DIRTY:
      return UnitState.Dirty;
    default:
      return UnitState.OutOfService;
  }
};
