import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type RemoveFromBlacklistMutationVariables = Types.Exact<{
  userProfileId: Types.Scalars["Int"]["input"];
}>;

export type RemoveFromBlacklistMutation = { __typename?: "Mutation"; RemoveFromBlacklist: boolean };

export const RemoveFromBlacklistDocument = `
    mutation RemoveFromBlacklist($userProfileId: Int!) {
  RemoveFromBlacklist(userProfileId: $userProfileId)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    RemoveFromBlacklist: build.mutation<
      RemoveFromBlacklistMutation,
      RemoveFromBlacklistMutationVariables
    >({
      query: (variables) => ({ document: RemoveFromBlacklistDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useRemoveFromBlacklistMutation } = injectedRtkApi;
