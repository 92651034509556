import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LateCheckOut = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_1197_789)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.746 5.99823C3.14926 5.99823 2.57697 6.23528 2.15501 6.65724C1.73306 7.0792 1.496 7.65149 1.496 8.24823V17.2482C1.496 17.845 1.73306 18.4173 2.15501 18.8392C2.57697 19.2612 3.14926 19.4982 3.746 19.4982H9C9.41422 19.4982 9.75 19.834 9.75 20.2482C9.75 20.6624 9.41422 20.9982 9 20.9982H3.746C2.75144 20.9982 1.79761 20.6031 1.09435 19.8999C0.39109 19.1966 -0.0039978 18.2428 -0.0039978 17.2482V8.24823C-0.0039978 7.25367 0.39109 6.29984 1.09435 5.59658C1.79761 4.89332 2.75144 4.49823 3.746 4.49823H17.246C18.2406 4.49823 19.1944 4.89332 19.8977 5.59658C20.6009 6.29984 20.996 7.25367 20.996 8.24823V9.00023C20.996 9.41444 20.6602 9.75023 20.246 9.75023C19.8318 9.75023 19.496 9.41444 19.496 9.00023V8.24823C19.496 7.65149 19.2589 7.0792 18.837 6.65724C18.415 6.23528 17.8427 5.99823 17.246 5.99823H3.746Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.996 4.49823C6.41022 4.49823 6.746 4.83402 6.746 5.24823V20.2482C6.746 20.6624 6.41022 20.9982 5.996 20.9982C5.58179 20.9982 5.246 20.6624 5.246 20.2482V5.24823C5.246 4.83402 5.58179 4.49823 5.996 4.49823Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.996 4.49823C15.4102 4.49823 15.746 4.83402 15.746 5.24823V8.99923C15.746 9.41344 15.4102 9.74923 14.996 9.74923C14.5818 9.74923 14.246 9.41344 14.246 8.99923V5.24823C14.246 4.83402 14.5818 4.49823 14.996 4.49823Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.40901 0.65724C7.83097 0.235283 8.40326 -0.00177002 9 -0.00177002H12C12.2955 -0.00177002 12.5881 0.0564279 12.861 0.169501C13.134 0.282574 13.3821 0.448308 13.591 0.65724C13.7999 0.866172 13.9657 1.11421 14.0787 1.38719C14.1918 1.66018 14.25 1.95276 14.25 2.24823V5.24823C14.25 5.66244 13.9142 5.99823 13.5 5.99823C13.0858 5.99823 12.75 5.66244 12.75 5.24823V2.24823C12.75 2.14974 12.7306 2.05221 12.6929 1.96122C12.6552 1.87022 12.6 1.78754 12.5303 1.7179C12.4607 1.64826 12.378 1.59301 12.287 1.55532C12.196 1.51763 12.0985 1.49823 12 1.49823H9C8.80109 1.49823 8.61032 1.57725 8.46967 1.7179C8.32902 1.85855 8.25 2.04932 8.25 2.24823V5.24823C8.25 5.66244 7.91421 5.99823 7.5 5.99823C7.08579 5.99823 6.75 5.66244 6.75 5.24823V2.24823C6.75 1.65149 6.98705 1.0792 7.40901 0.65724Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 12.0002C14.3505 12.0002 12 14.3507 12 17.2502C12 20.1497 14.3505 22.5002 17.25 22.5002C20.1495 22.5002 22.5 20.1497 22.5 17.2502C22.5 14.3507 20.1495 12.0002 17.25 12.0002ZM10.5 17.2502C10.5 13.5223 13.5221 10.5002 17.25 10.5002C20.9779 10.5002 24 13.5223 24 17.2502C24 20.9782 20.9779 24.0002 17.25 24.0002C13.5221 24.0002 10.5 20.9782 10.5 17.2502Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 13.8483C17.6642 13.8483 18 14.1841 18 14.5983V16.5003H19.901C20.3152 16.5003 20.651 16.8361 20.651 17.2503C20.651 17.6645 20.3152 18.0003 19.901 18.0003H17.25C16.8358 18.0003 16.5 17.6645 16.5 17.2503V14.5983C16.5 14.1841 16.8358 13.8483 17.25 13.8483Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1197_789">
        <rect width="24" height="24" fill="white" transform="translate(0 0.000244141)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
