import { useRoutes } from "react-router-dom";
import useScrollReset from "./hooks/use-scroll-reset";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { routesV1, routesV2 } from "./routes";
import { usePropertySearchParams } from "./hooks/use-property-search-params";

export const Content = () => {
  const { isRESTVersion } = useApiVersion();
  const content = useRoutes(isRESTVersion ? routesV1 : routesV2);
  useScrollReset();
  //Prefills property param if not exist
  usePropertySearchParams();
  return content;
};
