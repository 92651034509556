import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Grid, IconButton, useTheme } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { experimentalStyled } from "@mui/material/styles";
import { useDebounce } from "../../hooks/use-debounce";
import {
  formatDate,
  pickerDateSelector,
  setDate as setDateReducer
} from "../../slices/date-picker";
import { useDispatch, useSelector } from "../../store";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { format, isSameDay } from "date-fns";
import { getTimeZonedDateFromString } from "../../utils/timezoned-date";
import { ParagraphSmallBold } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import useDynamicLocale from "src/hooks/use-dynamic-locale";

const DatePickerWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));

interface DatePickerProps {
  timeZone: string;
  disablePast?: boolean;
}

export const DatePicker: FC<DatePickerProps> = ({ timeZone, disablePast }) => {
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const date = useSelector(pickerDateSelector);
  const locale = useDynamicLocale();

  const setDate = useCallback(
    (newDate: Date) => {
      dispatch(setDateReducer(formatDate(newDate, timeZone)));
    },
    [dispatch, timeZone]
  );
  const [internalDate, setInternalDate] = useState<Date | null>(new Date(date));
  useEffect(() => {
    setInternalDate(new Date(date));
  }, [date]);

  const resetDate = useCallback(() => {
    setInternalDate(new Date());
  }, []);

  const decreaseDateByDay = useCallback(() => {
    const newDate = internalDate?.getTime() ? new Date(internalDate?.getTime()) : null;
    newDate?.setDate(newDate.getDate() - 1);
    setInternalDate(newDate);
  }, [internalDate]);

  const increaseDateByDay = useCallback(() => {
    const newDate = internalDate?.getTime() ? new Date(internalDate?.getTime()) : null;
    newDate?.setDate(newDate.getDate() + 1);
    setInternalDate(newDate);
  }, [internalDate]);

  const dateWithDebounce = useDebounce(internalDate, 500);
  useEffect(() => {
    if (!dateWithDebounce) {
      return;
    }
    setDate(dateWithDebounce);
  }, [dateWithDebounce, setDate]);

  const theme = useTheme();

  const disablePastDayButton = useMemo(() => {
    return disablePast ? isSameDay(new Date(date), new Date()) : false;
  }, [disablePast, date]);

  return (
    <DatePickerWrapper>
      <Button sx={{ borderRadius: theme.spacing(8) }} variant="outlined" onClick={resetDate}>
        <ParagraphSmallBold color="textInfo">{t("labels__today")}</ParagraphSmallBold>
      </Button>
      <Grid container ml={8} alignItems="center">
        <IconButton
          onClick={decreaseDateByDay}
          sx={{ padding: 0 }}
          size="large"
          disabled={disablePastDayButton}
        >
          <KeyboardArrowLeft fontSize="large" />
        </IconButton>
        <Box mx={2}>
          <MobileDatePicker
            showToolbar={false}
            value={internalDate}
            onChange={(newDate: Date | null) => setInternalDate(newDate)}
            disableCloseOnSelect={false}
            renderInput={(inputProps) => (
              <ParagraphSmallBold color="textPrimary" onClick={inputProps?.inputProps?.onClick}>
                {format(getTimeZonedDateFromString(date, timeZone), "EEE, MMM dd", { locale })}
              </ParagraphSmallBold>
            )}
            disablePast={disablePast}
          />
        </Box>
        <IconButton onClick={increaseDateByDay} sx={{ padding: 0 }} size="large">
          <KeyboardArrowRight fontSize="large" />
        </IconButton>
      </Grid>
    </DatePickerWrapper>
  );
};
