import React, { FC, useCallback, useEffect, useMemo } from "react";
import { Check, Checklist, CloseOutlined } from "@mui/icons-material";
import { Button, Caption, Checkbox } from "@likemagic-tech/sv-magic-library";
import { FieldArray, Formik } from "formik";
import { SubtasksForm } from "../task-modal";
import { Box, Divider, Grid, IconButton, OutlinedInput, useTheme } from "@mui/material";
import { FieldWithIcon } from "./field-with-icon";
import { Status } from "../../../../graphql-tasks/generated/graphql";
import { useProperty } from "../../../../hooks/use-property";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { useIsMobile } from "../../../../hooks/use-is-mobile";
import { useUpdateTaskMutationEnhanced } from "../../../../graphql-tasks/mutations/enhanced-mutations/update-tasks-enhanced";
import { useCreateTaskMutationEnhanced } from "../../../../graphql-tasks/mutations/enhanced-mutations/create-task-enhanced";

import {
  transformTaskFormToCreateTaskRequest,
  transformTaskFormToUpdateTaskRequest
} from "../task-modal.utils";
import { openBanner } from "../../../../slices/banner.slice";
import { useDispatch } from "react-redux";
import { SubtasksDueDate } from "./subtasks-due-date";
import { SubtasksAssignee } from "./subtasks-assignee";
import EditIcon from "@mui/icons-material/Edit";
import { useFormValidations } from "../../../../hooks/use-form-validation";

interface SubtasksProps {
  subtasks: SubtasksForm[];
  taskId: number;
  setSubtasksOnCreate?: (subtasks: SubtasksForm[]) => void;
}

const emptySubtask = (parentId: number) => ({
  title: "",
  status: Status.Todo,
  parentId,
  dueDate: null,
  id: null,
  isEdit: true
});

export const Subtasks: FC<SubtasksProps> = ({ subtasks, taskId, setSubtasksOnCreate }) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();

  const { selectedProperty } = useProperty();
  const [updateTaskAction, updateResult] = useUpdateTaskMutationEnhanced();
  const [createTaskAction, createResult] = useCreateTaskMutationEnhanced();
  const dispatch = useDispatch();
  const { subtasksFormValidation } = useFormValidations();

  useEffect(() => {
    if (updateResult?.data?.UpdateTask?.id) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
    }

    if (createResult?.data?.CreateTask?.id) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
    }

    if (updateResult.isError || createResult.isError) {
      dispatch(
        openBanner({
          type: "error",
          title: t("labels__action_failed_to_perform")
        })
      );
    }
  }, [dispatch, updateResult, t, createResult?.data?.CreateTask?.id, createResult.isError]);

  const onSubmit = useCallback(
    async (subtask: SubtasksForm) => {
      if (subtask.id) {
        await updateTaskAction({
          pmsPropertyId: selectedProperty?.propertyId ?? "",
          request: transformTaskFormToUpdateTaskRequest(subtask, undefined, taskId),
          taskId: subtask.id
        });
      } else {
        await createTaskAction({
          pmsPropertyId: selectedProperty?.propertyId ?? "",
          request: transformTaskFormToCreateTaskRequest(subtask, undefined, taskId)
        });
      }
    },
    [createTaskAction, selectedProperty?.propertyId, taskId, updateTaskAction]
  );

  const initialValues = useMemo(() => {
    return {
      subtasks: subtasks
    };
  }, [subtasks]);

  return (
    <FieldWithIcon icon={<Checklist />} alignTop>
      <Box ml={1.5} width="100%">
        <Formik
          initialValues={initialValues}
          onSubmit={() => {}}
          enableReinitialize
          validationSchema={subtasksFormValidation}
        >
          {(formik) => {
            return (
              <FieldArray
                name="subtasks"
                render={(arrayHelpers) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start"
                    }}
                  >
                    {formik.values?.subtasks.length > 0 &&
                      formik.values?.subtasks.map((subtask, index) => (
                        <Grid
                          container
                          key={`item-subtask-${subtask.id}-${index}`}
                          direction={isMobile ? "column" : "row"}
                          justifyContent="space-between"
                          sx={{ mb: 1 }}
                        >
                          <Grid item>
                            <Grid
                              container
                              alignItems="center"
                              width={isMobile ? "100%" : "auto"}
                              gap={1}
                            >
                              {formik.values.subtasks[index]?.id && (
                                <Grid item>
                                  <Checkbox
                                    id={`subtasks.[${index}].status`}
                                    name={`subtasks.[${index}].status`}
                                    checked={subtask.status === Status.Done}
                                    onChange={() => {
                                      formik.setFieldValue(
                                        `subtasks.[${index}].status`,
                                        subtask.status === Status.Done ? Status.Todo : Status.Done
                                      );
                                      onSubmit({
                                        ...formik.values.subtasks[index],
                                        status:
                                          subtask.status === Status.Done ? Status.Todo : Status.Done
                                      });
                                    }}
                                  />
                                </Grid>
                              )}
                              <Grid item display="flex" flexDirection="column">
                                <OutlinedInput
                                  id={`subtasks.[${index}].title`}
                                  name={`subtasks.[${index}].title`}
                                  sx={{
                                    height: "32px",
                                    borderRadius: theme.spacing(0.75),
                                    width: isMobile ? "auto" : theme.spacing(36),
                                    ".MuiOutlinedInput-notchedOutline": {
                                      border:
                                        formik.values.subtasks[index].title !==
                                          subtasks[index]?.title ||
                                        formik.values.subtasks[index].isEdit
                                          ? "auto"
                                          : "none"
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                      border:
                                        formik.values.subtasks[index].title !==
                                          subtasks[index]?.title ||
                                        formik.values.subtasks[index].isEdit
                                          ? "auto"
                                          : "none"
                                    }
                                  }}
                                  value={subtask.title}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      `subtasks.[${index}].title`,
                                      e.target.value
                                    )
                                  }
                                  onKeyDown={async (e) => {
                                    formik.handleSubmit();
                                    if (
                                      e.key === "Enter" &&
                                      formik.touched.subtasks?.[index]?.title &&
                                      //@ts-ignore
                                      !formik.errors.subtasks?.[index]?.title
                                    ) {
                                      e.preventDefault();
                                      formik.setFieldTouched(`subtasks.[${index}].title`);
                                      await onSubmit(formik.values.subtasks[index]);
                                      formik.setFieldValue(`subtasks.[${index}].isEdit`, false);
                                    }
                                  }}
                                  notched
                                  fullWidth
                                  error={
                                    formik.touched.subtasks?.[index]?.title &&
                                    //@ts-ignore
                                    formik.errors.subtasks?.[index]?.title
                                  }
                                  disabled={!formik.values.subtasks[index].isEdit}
                                  autoFocus
                                />
                                {formik.touched.subtasks?.[index]?.title &&
                                  //@ts-ignore
                                  formik.errors.subtasks?.[index]?.title && (
                                    <Caption sx={{ color: theme.palette.error.main }}>
                                      {/*@ts-ignore*/}
                                      {t(`${formik.errors.subtasks?.[index]?.title}`)}
                                    </Caption>
                                  )}
                              </Grid>
                              <SubtasksDueDate
                                index={index}
                                subtask={subtask}
                                setFieldValue={formik.setFieldValue}
                                disabled={!formik.values.subtasks[index].isEdit}
                              />
                              {!isMobile && (
                                <Divider
                                  orientation="vertical"
                                  sx={{
                                    color: theme.palette.text.secondary,
                                    height: theme.spacing(3),
                                    mt: 0.5
                                  }}
                                />
                              )}
                              <SubtasksAssignee
                                subtask={subtask}
                                setFieldValue={formik.setFieldValue}
                                index={index}
                                disabled={!formik.values.subtasks[index].isEdit}
                              />
                            </Grid>
                          </Grid>

                          <Grid item justifySelf="flex-end" textAlign="right">
                            {formik.values.subtasks[index].isEdit ? (
                              <Grid
                                container
                                alignItems="center"
                                justifyContent={isMobile ? "flex-end" : "center"}
                              >
                                <Button
                                  variant="ghost"
                                  size="medium"
                                  startIcon={<Check />}
                                  onClick={async () => {
                                    // @ts-ignore
                                    if (formik.errors.subtasks?.[index]?.title) {
                                      formik.setFieldTouched(`subtasks.[${index}].title`);
                                      return;
                                    }
                                    if (taskId) {
                                      await onSubmit(formik.values.subtasks[index]);
                                    } else {
                                      setSubtasksOnCreate?.(formik.values.subtasks);
                                    }
                                    formik.setFieldValue(`subtasks.[${index}].isEdit`, false);
                                  }}
                                >
                                  {t("labels__save")}
                                </Button>

                                <IconButton
                                  onClick={() => {
                                    if (initialValues.subtasks[index]?.id) {
                                      formik.setFieldValue(
                                        `subtasks.[${index}]`,
                                        initialValues.subtasks[index]
                                      );
                                      formik.setFieldValue(`subtasks.[${index}].isEdit`, false);
                                    } else {
                                      arrayHelpers.remove(index);
                                    }
                                  }}
                                >
                                  <CloseOutlined
                                    sx={{
                                      color: theme.palette.text.secondary,
                                      cursor: "pointer"
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                            ) : (
                              <Button
                                variant="ghost"
                                size="medium"
                                startIcon={<EditIcon />}
                                onClick={async () => {
                                  formik.setFieldValue(`subtasks.[${index}].isEdit`, true);
                                }}
                                disabled={formik.values.subtasks.some((subtask) => subtask.isEdit)}
                              >
                                {t("labels__edit")}
                              </Button>
                            )}
                          </Grid>
                          {isMobile && <Divider sx={{ my: 1 }} />}
                        </Grid>
                      ))}
                    {
                      <Button
                        variant="ghost"
                        size="medium"
                        onClick={() => {
                          arrayHelpers.push(emptySubtask(taskId));
                        }}
                        disabled={formik.values.subtasks.some((subtask) => subtask.isEdit)}
                        sx={{ ml: -1.5 }}
                      >
                        {t("buttons__add_subtask")}
                      </Button>
                    }
                  </Box>
                )}
              />
            );
          }}
        </Formik>
      </Box>
    </FieldWithIcon>
  );
};
