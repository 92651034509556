import type { FC, PropsWithChildren } from "react";
import { useAuth } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";

interface RoleBasedGuardProps {
  roles: string[];
}

const RoleBasedGuard: FC<PropsWithChildren<RoleBasedGuardProps>> = ({ roles, children }) => {
  const { t } = useTranslationWrapper();
  const { hasRole } = useAuth();

  if (!roles.some((role) => hasRole(role))) {
    return <>{t("labels__not_accessible_with_your_role")}</>;
  }
  return <>{children}</>;
};

export default RoleBasedGuard;
