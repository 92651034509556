import { JSONApiResponse } from "@likemagic-tech/sv-magic-library";
import { ApiKeyApiClient } from "./base/api-key-api-client";

const BASE_URL = "/api/guest-journey-service/forecast";

class ForecastApiClient extends ApiKeyApiClient {
  async fetchDeparturesAndCleaningsForecast(
    startDate: string,
    endDate: string,
    propertyId: string,
    init?: RequestInit
  ): Promise<any> {
    const response = await this.fetchApi(
      `${BASE_URL}/departures-and-cleanings?&propertyId=${propertyId}&startDate=${startDate}&endDate=${endDate}`,
      init
    );

    return new JSONApiResponse<any>(response).value();
  }
}

export const ForecastApi = new ForecastApiClient();
