import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC } from "react";
import { ChatItemContentBaseProps } from "./domain/chat-item-content-base-props";

interface ChatItemContentWhatsappProps extends ChatItemContentBaseProps {}

export const ChatItemContentWhatsapp: FC<ChatItemContentWhatsappProps> = ({
  content,
  isLoggedUser
}) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        color: isLoggedUser ? palette.background.paper : palette.primary.main
      }}
    >
      <Paragraph whiteSpace="pre-wrap">{content}</Paragraph>
    </Box>
  );
};
