import { FC, PropsWithChildren, useEffect } from "react";
import { useProperty } from "../../hooks/use-property";
import { isStatusFailed, isStatusSuccess } from "../../domain/EntityStateStatus";

export const DataPreloader: FC<PropsWithChildren> = ({ children }) => {
  const { status } = useProperty();
  useEffect(() => {
    if (isStatusFailed(status)) {
      console.error("Fetch properties failed.");
    }
  }, [status]);

  return isStatusSuccess(status) ? <>{children}</> : null;
};
