import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useEffect } from "react";
import {
  fetchSupportedSyncStates,
  selectSupportedSyncStates
} from "src/slices/supported-sync-states";
import { useDispatch, useSelector } from "src/store";
import { DoorType } from "../api/door-delegate";
import { useGetSupportSyncStatesQuery } from "../graphql/queries/GetSupportSyncStates.generated";
import { transformSyncStatesToCommon } from "../graphql/transform/transform-sync-states";

export const useFetchSupportedSyncStates = (propertyId: string): DoorType[] => {
  const dispatch = useDispatch();
  const { isRESTVersion } = useApiVersion();
  const allDoorTypesV1 = useSelector(selectSupportedSyncStates);

  useEffect(() => {
    if (propertyId && isRESTVersion) {
      dispatch(fetchSupportedSyncStates({ propertyId: propertyId }));
    }
  }, [dispatch, isRESTVersion, propertyId]);

  const { data } = useGetSupportSyncStatesQuery(
    {
      filter: { pmsId: propertyId }
    },
    { skip: isRESTVersion }
  );

  const dataV2 = data?.GetProperty?.supportedSyncStates?.map(transformSyncStatesToCommon) ?? [];

  return (isRESTVersion ? allDoorTypesV1 : dataV2).filter((elem) => elem !== DoorType.NONE);
};
