import { lazy, Suspense } from "react";
import { Outlet, RouteObject } from "react-router";
import { DashboardLayout } from "./components/dashboard/dashboard-layout";
import LoadingScreen from "./components/loading-screen";
import { UnitsPage } from "./pages/unit/units.page";
import { DirtyUnitsPage } from "./pages/dirty-units/dirty-units.page";
import { BreakfastPage } from "./pages/breakfast/breakfast.page";
import Home from "./icons/Home";
import RoleBasedGuard from "./components/role-based-guard";
import {
  BREAKFAST_OPERATOR_ROLE,
  HOST_ROLE,
  HOUSEKEEPER_ROLE
} from "@likemagic-tech/sv-magic-library";
import ReleaseNotesPage from "./pages/release-notes.page";
import { BoxesPage } from "./pages/boxes.page";
import Arrival from "./pages/arrival/arrival";
import { HousekeepingPage } from "./pages/housekeeping/housekeeping.page";
import InHotel from "./pages/in-hotel/in-hotel";
import Departure from "./pages/departure/departure";

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Dashboard pages

const Reservations = Loadable(lazy(() => import("./pages/reservations.page")));
const Messaging = Loadable(lazy(() => import("./pages/messaging.page")));
const Tasks = Loadable(lazy(() => import("./pages/tasks.page")));
const Profiles = Loadable(lazy(() => import("./pages/profiles/profiles.page")));
const IdentityList = Loadable(lazy(() => import("./pages/identity/identity-list")));
const Overview = Loadable(lazy(() => import("./pages/dashboard/overview")));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import("./pages/authorization-required")));
const NotFound = Loadable(lazy(() => import("./pages/not-found")));
const ServerError = Loadable(lazy(() => import("./pages/server-error")));

const commonHomeAndErrors = {
  path: "*",
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <Home />
    },
    {
      path: "401",
      element: <AuthorizationRequired />
    },
    {
      path: "404",
      element: <NotFound />
    },
    {
      path: "500",
      element: <ServerError />
    },
    {
      path: "*",
      element: <NotFound />
    }
  ]
};

const commonPages = [
  {
    path: "breakfast",
    element: (
      <RoleBasedGuard roles={[HOST_ROLE, BREAKFAST_OPERATOR_ROLE]}>
        <BreakfastPage />
      </RoleBasedGuard>
    )
  },
  {
    path: "units",
    element: (
      <RoleBasedGuard roles={[HOST_ROLE, HOUSEKEEPER_ROLE]}>
        <UnitsPage />
      </RoleBasedGuard>
    )
  },
  {
    path: "dirty-units",
    element: (
      <RoleBasedGuard roles={[HOUSEKEEPER_ROLE]}>
        <DirtyUnitsPage />
      </RoleBasedGuard>
    )
  },
  {
    path: "housekeeping",
    element: (
      <RoleBasedGuard roles={[HOUSEKEEPER_ROLE]}>
        <HousekeepingPage />
      </RoleBasedGuard>
    )
  },
  {
    path: "reservations",
    element: (
      <RoleBasedGuard roles={[HOST_ROLE]}>
        <Reservations />
      </RoleBasedGuard>
    )
  },
  {
    path: "arrival",
    element: (
      <RoleBasedGuard roles={[HOST_ROLE]}>
        <Arrival />
      </RoleBasedGuard>
    )
  },
  {
    path: "departure",
    element: (
      <RoleBasedGuard roles={[HOST_ROLE]}>
        <Departure />
      </RoleBasedGuard>
    )
  },
  {
    path: "in-hotel",
    element: (
      <RoleBasedGuard roles={[HOST_ROLE]}>
        <InHotel />
      </RoleBasedGuard>
    )
  },
  {
    path: "release-notes",
    element: <ReleaseNotesPage />
  },
  {
    path: "messaging",
    element: <Messaging />
  },
  {
    path: "profiles",
    element: <Profiles />
  }
];

export const routesV1: Partial<RouteObject>[] = [
  {
    path: "",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Overview />
      },
      {
        path: "boxes",
        element: (
          <RoleBasedGuard roles={[HOST_ROLE, HOUSEKEEPER_ROLE]}>
            <BoxesPage />
          </RoleBasedGuard>
        )
      },
      {
        path: "identities",
        element: (
          <RoleBasedGuard roles={[HOST_ROLE]}>
            <IdentityList />
          </RoleBasedGuard>
        )
      },
      {
        path: "tasks",
        element: (
          <RoleBasedGuard roles={[HOST_ROLE, HOUSEKEEPER_ROLE]}>
            <Tasks />
          </RoleBasedGuard>
        )
      },
      ...commonPages
    ]
  },
  commonHomeAndErrors
];

export const routesV2: Partial<RouteObject>[] = [
  {
    path: "",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Overview />
      },
      {
        path: "tasks",
        element: (
          <RoleBasedGuard roles={[HOST_ROLE, HOUSEKEEPER_ROLE]}>
            <Tasks />
          </RoleBasedGuard>
        )
      },
      ...commonPages
    ]
  },
  commonHomeAndErrors
];
