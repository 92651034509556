import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC } from "react";
import { ChatItemContentBaseProps } from "./domain/chat-item-content-base-props";
import DOMPurify from "dompurify";

interface ChatItemContentTextProps extends ChatItemContentBaseProps {}

export const ChatItemContentText: FC<ChatItemContentTextProps> = ({ content, isLoggedUser }) => {
  const { palette } = useTheme();
  const sanitizedHtmlContent = DOMPurify.sanitize(content);

  return (
    <Box>
      <Paragraph
        dangerouslySetInnerHTML={{
          __html: sanitizedHtmlContent
        }}
        sx={{
          color: isLoggedUser ? palette.background.paper : palette.primary.main
        }}
      />
    </Box>
  );
};
