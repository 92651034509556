import { MaintenanceInformationProps } from "src/components/maintenance-modal/maintenance-modal";

export interface UnitAttribute {
  id: string;
  name: string;
}
export interface Unit {
  maintenanceSlots?: MaintenanceInformationProps[];
  attributes: UnitAttribute[] | null;
  id: string;
  name: string;
  unitGroupId: string;
  status: UnitStatus;
  privacyMode: boolean;
  privacyModeLastChangeTimestamp: string;
}

export interface UnitStatus {
  occupied: boolean;
  condition: UnitCondition;
}

export enum UnitCondition {
  CLEAN = "Clean",
  CLEAN_TO_BE_INSPECTED = "CleanToBeInspected",
  DIRTY = "Dirty",
  UNKNOWN = "Unknown"
}
