import * as React from "react";
import { FC } from "react";
import { TableCell, TableRow } from "@mui/material";
import ChevronRight from "../../../../icons/ChevronRight";
import { ReservationsForProfile } from "../../types/profile";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { formatDateTime } from "src/utils/timezoned-date";
import { Paragraph } from "@likemagic-tech/sv-magic-library";

interface ProfileDrawerTableRowProps {
  item: ReservationsForProfile;
  onSelect: (reservationId: string) => void;
}
export const ProfileDrawerBookingsTableRow: FC<ProfileDrawerTableRowProps> = ({
  item,
  onSelect
}) => {
  const { t } = useTranslationWrapper();

  return (
    <TableRow
      hover
      key={item.pmsId}
      sx={{ cursor: "pointer" }}
      onClick={() => onSelect(item.pmsId)}
    >
      <TableCell />
      <TableCell>
        <Paragraph>{item.displayId}</Paragraph>
      </TableCell>
      <TableCell>{item?.property.name}</TableCell>
      <TableCell>{t(`labels__conversation__status__${item.status}`)}</TableCell>
      <TableCell>
        {item.property.timezone
          ? formatDateTime(item.arrival, item.property.timezone)
          : formatDateTime(item.arrival)}
      </TableCell>
      <TableCell>
        {item.property.timezone
          ? formatDateTime(item.departure, item.property.timezone)
          : formatDateTime(item.departure)}
      </TableCell>
      <TableCell>
        {item.channel
          ? t(`labels__booking_channel_${item.channel.toUpperCase()}`)
          : t("labels__booking_channel_UNKNOW")}
      </TableCell>
      <TableCell>{item.unit?.name}</TableCell>
      <TableCell>
        <ChevronRight />
      </TableCell>
      <TableCell />
    </TableRow>
  );
};
