import { api } from "../BackofficeGetUpsellOffers.generated";

export const getUserProfilesEnhanced = api.enhanceEndpoints({
  endpoints: {
    BackofficeGetUpsellOffers: {
      providesTags: ["UpsellReservation"]
    }
  }
});

export const { useBackofficeGetUpsellOffersQuery: useBackofficeGetUpsellOffersQueryEnhanced } =
  getUserProfilesEnhanced;
