import { api } from "../delete-note.generated";

const getUserProfileByIdEnhanced = api.enhanceEndpoints({
  endpoints: {
    DeleteNote: {
      invalidatesTags: (result, error, arg) => {
        return [{ type: "UserProfileNotes", id: arg.pmsProfileNoteId }];
      }
    }
  }
});

export const { useDeleteNoteMutation: useDeleteNoteMutationEnhanced } = getUserProfileByIdEnhanced;
