import { Heading3 } from "@likemagic-tech/sv-magic-library";
import { Grid, IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FC } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";

interface DialogTitleModalProps {
  title: string;
  onClose: () => void;
}

export const DialogTitleModal: FC<DialogTitleModalProps> = ({ title, onClose }) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={11}>
        <Heading3 sx={{ color: theme.palette.text.primary }}>{t(title)}</Heading3>
      </Grid>
      <Grid item xs={1} display="flex" justifyContent="end">
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="medium" />
        </IconButton>
      </Grid>
    </Grid>
  );
};
