import { Box } from "@mui/material";
import { FC } from "react";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Task } from "../domain/task";
import { Unit } from "../../../domain/Unit";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { useReservationDetailsId } from "../../reservations/reservation-details/reservation-details/use-reservation-details-id";

interface TaskLinksProp {
  task: Task;
  units: Unit[] | undefined;
}

export const TaskLinks: FC<TaskLinksProp> = ({ task, units }) => {
  const { t } = useTranslationWrapper();
  const { setSelectId } = useReservationDetailsId();

  const unitId = task.links?.pmsUnitIds?.[0] ?? "";
  const displayableReservationId = task.links?.reservations?.[0]?.displayableReservationId ?? "";
  const displayablePmsReservationId = task.links?.reservations?.[0]?.pmsReservationId ?? "";

  const displayableDepartingReservationId =
    task.links?.departingReservation?.displayableReservationId ?? "";
  const displayableDepartingPmsReservationId =
    task.links?.departingReservation?.pmsReservationId ?? "";

  const displayableArrivingReservationId =
    task.links?.arrivingReservation?.displayableReservationId ?? "";
  const displayableArrivingPmsReservationId =
    task.links?.arrivingReservation?.pmsReservationId ?? "";

  const findUnitNumber = (unitId: string) => {
    return units?.find((unit) => unit.id === unitId)?.name;
  };

  const handleOnReservationIdClick = (reservationId: string) => {
    setSelectId(reservationId);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" gap={0.5}>
      {unitId && (
        <Box display="flex" gap={0.5}>
          <ParagraphSmall color="text.secondary">{t("labels__unit")}:</ParagraphSmall>
          {findUnitNumber(unitId)}
        </Box>
      )}
      <Box display="flex" alignItems="flex-start">
        {(displayableReservationId ||
          displayableDepartingReservationId ||
          displayableArrivingReservationId) && (
          <ParagraphSmall color="text.secondary" mr={0.5}>
            {t("labels__res_ID")}:
          </ParagraphSmall>
        )}
        {displayableReservationId && (
          <>
            <ParagraphSmall
              sx={{ "&:hover": { textDecoration: "underline", color: "info.main" } }}
              onClick={(e) => {
                e.stopPropagation();
                handleOnReservationIdClick(displayablePmsReservationId);
              }}
            >
              {displayableReservationId}
            </ParagraphSmall>
            {displayableDepartingReservationId || displayableArrivingReservationId ? ", " : ""}
          </>
        )}
        {displayableDepartingReservationId && (
          <>
            <ParagraphSmall
              onClick={(e) => {
                e.stopPropagation();
                handleOnReservationIdClick(displayableDepartingPmsReservationId);
              }}
              sx={{ "&:hover": { textDecoration: "underline", color: "info.main" } }}
            >
              {displayableDepartingReservationId}
            </ParagraphSmall>
            {displayableArrivingReservationId ? "," : ""}
          </>
        )}
        {displayableArrivingReservationId && (
          <ParagraphSmall
            onClick={(e) => {
              e.stopPropagation();
              handleOnReservationIdClick(displayableArrivingPmsReservationId);
            }}
            sx={{ "&:hover": { textDecoration: "underline", color: "info.main" } }}
          >
            {displayableArrivingReservationId}
          </ParagraphSmall>
        )}
      </Box>
    </Box>
  );
};
