import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import { EntityStateStatus, isStatusLoading } from "../domain/EntityStateStatus";
import { MessagingUploadApi } from "../api/messinging-upload.api";
import { RootState } from "../store";
import { FileUpload } from "src/domain/file-upload";

export interface MessagingUploadSliceItem {
  localIdentifier: string;
  fileUpload?: FileUpload;
  status: EntityStateStatus;
  preview: string;
}

export const messagingUploadAdapter = createEntityAdapter<MessagingUploadSliceItem>({
  selectId: (model) => model.localIdentifier
});

const initialState = messagingUploadAdapter.getInitialState();

export const uploadMessageAttachment = createAsyncThunk<
  FileUpload,
  { file: File; localIdentifier: string; preview: string },
  { rejectValue: { reason: string } }
>("messaging-upload/upload-file", async ({ file }, thunkAPI) => {
  const keycloak: any = (thunkAPI.extra as any).keycloak;
  return MessagingUploadApi.uploadFileToReservation({
    file,
    authToken: keycloak?.token
  });
});
export const fetchMessageAttachment = createAsyncThunk<
  string,
  { url: string },
  { rejectValue: { reason: string } }
>("messaging-attachment/fetch-file", async ({ url }, thunkAPI) => {
  const keycloak: any = (thunkAPI.extra as any).keycloak;
  return MessagingUploadApi.fetchAttachment({
    url,
    authToken: keycloak?.token
  });
});

export const messagingAttachmentSlice = createSlice({
  name: "messaging-attachment",
  initialState,
  reducers: {
    clearMessagingUploadState: (state) => {
      messagingUploadAdapter.removeAll(state);
    },
    removeMessagingUpload: (state, action: PayloadAction<string>) => {
      messagingUploadAdapter.removeOne(state, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadMessageAttachment.pending, (state, action) => {
        messagingUploadAdapter.addOne(state, {
          localIdentifier: action.meta.arg.localIdentifier,
          status: EntityStateStatus.LOADING,
          preview: action.meta.arg.preview
        });
      })
      .addCase(uploadMessageAttachment.fulfilled, (state, action) => {
        messagingUploadAdapter.updateOne(state, {
          id: action.meta.arg.localIdentifier,
          changes: {
            localIdentifier: action.meta.arg.localIdentifier,
            status: EntityStateStatus.SUCCEEDED,
            fileUpload: action.payload
          }
        });
      })
      .addCase(uploadMessageAttachment.rejected, (state, action) => {
        messagingUploadAdapter.updateOne(state, {
          id: action.meta.arg.localIdentifier,
          changes: {
            localIdentifier: action.meta.arg.localIdentifier,
            status: EntityStateStatus.FAILED
          }
        });
      });
  }
});

export const { clearMessagingUploadState, removeMessagingUpload } =
  messagingAttachmentSlice.actions;

export const { selectAll: selectAllUploadedAttachments } =
  messagingUploadAdapter.getSelectors<RootState>((state) => state[messagingAttachmentSlice.name]);

export const selectUploadIds = createSelector(
  selectAllUploadedAttachments,
  (res) => res.map((item) => item.fileUpload?.id).filter((item) => !!item) as Array<string>
);

export const selectFileUploads = createSelector(
  selectAllUploadedAttachments,
  (res) => res.map((item) => item.fileUpload).filter((item) => !!item) as Array<FileUpload>
);

export const selectAttachmentIsLoading = createSelector(selectAllUploadedAttachments, (res) =>
  res.some((item) => isStatusLoading(item.status))
);
