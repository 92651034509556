import { useCallback, useEffect } from "react";
import { useDispatch } from "../../../store";
import { useIsMobile } from "../../../hooks/use-is-mobile";
import { setIsOpenSideNavigation } from "../../../slices/dashboard-sidebar";
import { DrawerDetails } from "src/components/drawer-details/drawer-details";
import { useProfileDrawer } from "./use-profile-drawer";
import { Box, Fade } from "@mui/material";
import { ProfileDrawerHeader } from "./profile-drawer-header";
import { useGetUserProfileByIdEnhanced } from "src/graphql/queries/enhanced-queries/user-profile-by-id-enhanced";
import { Params } from "../../../hooks/use-select-id";

export const ProfileDrawer = () => {
  const { profileGuestIdentityId, closeDrawer } = useProfileDrawer();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const setOpenSideNavigation = useCallback(
    (openSidebar: boolean) => {
      dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (profileGuestIdentityId || isMobile) {
      setOpenSideNavigation(false);
    }
  }, [profileGuestIdentityId, setOpenSideNavigation, isMobile]);

  const { data: userProfile } = useGetUserProfileByIdEnhanced(
    {
      userProfileId: Number(profileGuestIdentityId)
    },
    { skip: !profileGuestIdentityId }
  );

  return (
    <DrawerDetails
      isOpen={!!profileGuestIdentityId}
      onClose={closeDrawer}
      drawerId={Params.PROFILE_ID_SEARCH_PARAM_NAME}
    >
      <Box sx={{ overflowY: "scroll" }}>
        <Fade in>
          <Box p={2}>
            <ProfileDrawerHeader profile={userProfile?.GetUserProfileById} />
          </Box>
        </Fade>
      </Box>
    </DrawerDetails>
  );
};
