import { FC } from "react";
import { SvgIcon } from "@mui/material";

export const PaymentLink: FC = () => (
  <SvgIcon
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="Accounting-Invoice-Mail--Streamline-Ultimate"
  >
    <desc>{"Accounting Invoice Mail Streamline Icon: https://streamlinehq.com"}</desc>
    <path
      d="M19.5 12.88V2.25A1.5 1.5 0 0 0 18 0.75H6a1.5 1.5 0 0 0 -1.5 1.5v10.63"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M21.284 11.465a0.75 0.75 0 0 1 1.216 0.587v9.7a1.5 1.5 0 0 1 -1.5 1.5H3a1.5 1.5 0 0 1 -1.5 -1.5v-9.7a0.75 0.75 0 0 1 1.216 -0.587l7.431 5.894a3 3 0 0 0 3.706 0Z"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="m15.808 15.808 2.942 2.942"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M8.192 15.808 5.25 18.75"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M12.2 10.094a2.224 2.224 0 0 0 1.858 0.875c1.139 0 2.063 -0.693 2.063 -1.547s-0.921 -1.546 -2.059 -1.546S12 7.183 12 6.328s0.924 -1.547 2.062 -1.547a2.221 2.221 0 0 1 1.858 0.875"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="m14.062 10.969 0 1.031"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="m14.062 3.75 0 1.031"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="m7.5 4.5 1.5 0"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="m7.5 7.5 1.5 0"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="m7.5 10.5 1.5 0"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </SvgIcon>
);
