import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type AddServicesToReservationMutationVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
  services: Array<Types.ServiceOrderRequest> | Types.ServiceOrderRequest;
}>;

export type AddServicesToReservationMutation = {
  __typename?: "Mutation";
  BackofficeAddServicesToReservation?: Array<{
    __typename?: "ServiceOrder";
    pmsId?: string | null;
  } | null> | null;
};

export const AddServicesToReservationDocument = `
    mutation AddServicesToReservation($pmsReservationId: String!, $services: [ServiceOrderRequest!]!) {
  BackofficeAddServicesToReservation(
    pmsReservationId: $pmsReservationId
    services: $services
  ) {
    pmsId
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    AddServicesToReservation: build.mutation<
      AddServicesToReservationMutation,
      AddServicesToReservationMutationVariables
    >({
      query: (variables) => ({ document: AddServicesToReservationDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useAddServicesToReservationMutation } = injectedRtkApi;
