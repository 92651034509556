import * as React from "react";
import { FC, ReactNode } from "react";
import { Grid } from "@mui/material";
import { Button } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";

interface ManualWizardStepItemNavigationProps {
  children?: ReactNode;
  onNextStep?: () => void;
  onPreviousStep?: () => void;
  disableNext?: boolean;
  disablePrevious?: boolean;
  nextLabel?: string;
  backLabel?: string;
}

export const ManualWizardStepItemNavigation: FC<ManualWizardStepItemNavigationProps> = ({
  onPreviousStep,
  onNextStep,
  disableNext = false,
  disablePrevious = false,
  nextLabel,
  backLabel,
  children
}) => {
  const { t } = useTranslationWrapper();

  return (
    <Grid container justifyContent="flex-end" mt={0} spacing={3}>
      <Grid item minWidth={140}>
        {onPreviousStep && (
          <Button variant="secondary" onClick={onPreviousStep} fullWidth disabled={disablePrevious}>
            {backLabel ?? t("buttons__back")}
          </Button>
        )}
      </Grid>
      {children}
      <Grid item minWidth={140}>
        {onNextStep && (
          <Button
            variant="primary"
            onClick={onNextStep}
            fullWidth
            disabled={disableNext}
            type="submit"
          >
            {nextLabel ?? t("buttons__next")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
