import { FC, useRef, useState } from "react";
import { Box, Button, ButtonBase, Divider, Popover, Typography } from "@mui/material";
import { useAuth } from "@likemagic-tech/sv-magic-library";
import { UserAvatar } from "../user-avatar";
import { useUserInfo } from "../../hooks/use-user-info";
import { clearUserInfoSlice } from "../../slices/keycloak.slice";
import { useDispatch } from "../../store";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useNavigateWithPropertyId } from "../../hooks/use-navigate-with-propertyId";

const AccountPopover: FC = () => {
  const { t } = useTranslationWrapper();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const auth = useAuth();
  const { userInfo } = useUserInfo();
  const navigate = useNavigateWithPropertyId();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      dispatch(clearUserInfoSlice());
      await auth.logout();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex"
        }}
      >
        <UserAvatar
          lettersToDisplay={userInfo?.name?.[0]}
          sx={{
            height: 32,
            width: 32
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom"
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {userInfo?.name}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
            {t("labels__logout")}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
