import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type PerformSecondScreenActionMutationVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
  deviceId: Types.Scalars["String"]["input"];
  eventType: Types.SecondScreenEventType;
}>;

export type PerformSecondScreenActionMutation = {
  __typename?: "Mutation";
  BackofficePerformSecondScreenAction?: boolean | null;
};

export const PerformSecondScreenActionDocument = `
    mutation PerformSecondScreenAction($pmsReservationId: String!, $deviceId: String!, $eventType: SecondScreenEventType!) {
  BackofficePerformSecondScreenAction(
    pmsReservationId: $pmsReservationId
    deviceId: $deviceId
    eventType: $eventType
  )
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    PerformSecondScreenAction: build.mutation<
      PerformSecondScreenActionMutation,
      PerformSecondScreenActionMutationVariables
    >({
      query: (variables) => ({ document: PerformSecondScreenActionDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { usePerformSecondScreenActionMutation } = injectedRtkApi;
