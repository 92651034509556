import { AllowedActionEnum, ReservationTableDto } from "../../domain/reservation-table-dto";
import { MutableRefObject, ReactElement } from "react";

export interface ReservationActionsMenuProps {
  reservation: ReservationTableDto;
  anchorRef: MutableRefObject<SVGSVGElement | null> | null;
  onClose?: () => void;
  iconVertical?: boolean;
}

export type MenuItem = {
  label: string;
  key: string;
  icon?: ReactElement;
  action?: AllowedActionEnum;
};

export enum SectionName {
  UNIT = "UNIT",
  RESERVATION = "RESERVATION",
  GUEST_JOURNEY = "GUEST_JOURNEY",
  GUEST = "GUEST"
}

export enum FolioManagementCheckoutModalResult {
  GO_TO_FOLIO = "GO_TO_FOLIO",
  COMPLETE = "COMPLETE"
}
