import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type DeleteNoteMutationVariables = Types.Exact<{
  pmsProfileNoteId: Types.Scalars["String"]["input"];
}>;

export type DeleteNoteMutation = { __typename?: "Mutation"; DeleteNote: boolean };

export const DeleteNoteDocument = `
    mutation DeleteNote($pmsProfileNoteId: String!) {
  DeleteNote(pmsProfileNoteId: $pmsProfileNoteId)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    DeleteNote: build.mutation<DeleteNoteMutation, DeleteNoteMutationVariables>({
      query: (variables) => ({ document: DeleteNoteDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useDeleteNoteMutation } = injectedRtkApi;
