import { api } from "../GetAdditionalTasks.generated";

const getAdditionalTasksEnhanced = api.enhanceEndpoints({
  endpoints: {
    GetAdditionalTasks: {
      providesTags: () => ["AdditionalTasks"]
    }
  }
});

export const { useGetAdditionalTasksQuery: useGetAdditionalTasksQueryEnhanced } =
  getAdditionalTasksEnhanced;
