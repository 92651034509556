import { CommunicationChannel, Select, TenantContext } from "@likemagic-tech/sv-magic-library";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useTheme
} from "@mui/material";
import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import {
  AllowedActionEnum,
  ReservationTableDto,
  SentNotifications
} from "src/domain/reservation-table-dto";
import { openBanner } from "src/slices/banner.slice";
import { useDispatch } from "src/store";
import { Form, Formik } from "formik";
import { useFormValidations } from "src/hooks/use-form-validation";
import { DialogTitleModal } from "src/components/submit-modal/dialog-title";
import { DialogActionModal } from "src/components/submit-modal/dialog-actions";
import { DialogModal } from "src/components/submit-modal/dialog";
import { usePerformReservationAction } from "src/hooks/use-reservation-actions";
import { getI18nSelectedLanguage } from "../../../../../../../utils/timezoned-date";
import { getTranslatedLanguageName } from "../../../../../../../utils/language";

export interface NotificationActionsModalProps {
  reservation?: ReservationTableDto;
  onClose: () => void;
  notification: SentNotifications;
}

export const NotificationResendModal: FC<NotificationActionsModalProps> = ({
  reservation,
  onClose,
  notification
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const [performAction, result] = usePerformReservationAction();
  const dispatch = useDispatch();
  const language = getI18nSelectedLanguage();

  const { notificationPersonalFieldsValidations } = useFormValidations();

  const { allowedLanguages, enabledCommunicationChannels } = useContext(TenantContext);

  const languageOptions = useMemo(
    () =>
      allowedLanguages?.map((value) => ({
        value: value.toUpperCase(),
        label: getTranslatedLanguageName({ code: value, locale: language })
      })),
    [allowedLanguages, language]
  );
  const channelOptions = useMemo(
    () =>
      enabledCommunicationChannels.map((value) => ({
        value: value,
        label: t(`labels__guest__communication__channel__${value}`)
      })),
    [t, enabledCommunicationChannels]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      result.reset();

      handleClose();
    }
  }, [result, dispatch, handleClose, t]);

  const handleSubmit = useCallback(
    (values: any) => {
      const payload = {
        template: notification.template,
        platform: values.channel.value,
        language: values.language.value,
        email: values.email,
        phoneNumber: values.phone,
        magicId: notification.magicId
      };
      if (values.channel.value === CommunicationChannel.EMAIL) {
        delete payload.phoneNumber;
      } else {
        delete payload.email;
      }
      performAction({
        reservationId: reservation?.id ?? "",
        action: AllowedActionEnum.RESEND_NOTIFICATION,
        payload
      });
    },
    [notification.template, notification.magicId, reservation?.id, performAction]
  );

  return (
    <Formik
      initialValues={{
        email: notification.channel === CommunicationChannel.EMAIL ? notification.recipient : "",
        phone:
          notification.channel === CommunicationChannel.WHATSAPP ||
          notification.channel === CommunicationChannel.SMS
            ? notification.recipient
            : "",
        channel: {
          value: notification.channel,
          label: t(`labels__guest__communication__channel__${notification.channel}`)
        },
        language: {
          value: notification.language,
          label: getTranslatedLanguageName({
            code: notification.language,
            locale: language
          })
        }
      }}
      onSubmit={handleSubmit}
      validationSchema={notificationPersonalFieldsValidations}
    >
      {(formik) => (
        <DialogModal handleClose={handleClose} isOpen>
          <Grid container p={3} flexDirection="column">
            <DialogTitle sx={{ flex: "1 1 0", padding: "0" }}>
              <DialogTitleModal title={t("labels__tabs__notification__resend")} onClose={onClose} />
            </DialogTitle>
            <Form noValidate>
              <DialogContent sx={{ padding: "0" }}>
                <Box sx={{ marginTop: theme.spacing(2) }}>
                  <Select
                    options={languageOptions ?? []}
                    onChange={formik.handleChange}
                    value={formik.values.language.value}
                    label={t("labels__notification__modal__language")}
                    id="language.value"
                    name="language.value"
                    variant="outlined"
                  />
                </Box>
                <Box sx={{ marginTop: theme.spacing(2) }}>
                  <Select
                    options={channelOptions}
                    onChange={(e) => {
                      formik.values.email = "";
                      formik.values.phone = "";
                      formik.handleChange(e);
                    }}
                    value={formik.values.channel.value}
                    label={t("labels__notification__modal__channel")}
                    id="channel.value"
                    name="channel.value"
                    variant="outlined"
                  />
                </Box>
                <Box sx={{ marginTop: theme.spacing(2) }}>
                  {CommunicationChannel.EMAIL === formik.values.channel.value && (
                    <TextField
                      required
                      label={t("labels__email")}
                      fullWidth
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors?.email)}
                      id="email"
                      name="email"
                    />
                  )}
                  {(CommunicationChannel.WHATSAPP === formik.values.channel.value ||
                    CommunicationChannel.SMS === formik.values.channel.value) && (
                    <TextField
                      required
                      label={t("labels__phone_number")}
                      fullWidth
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors?.phone)}
                      id="phone"
                      name="phone"
                    />
                  )}
                </Box>
              </DialogContent>
              <DialogActions sx={{ flex: "1 1 0", px: "0" }}>
                <DialogActionModal title={t("labels__notification__resend")} />
              </DialogActions>
            </Form>
          </Grid>
        </DialogModal>
      )}
    </Formik>
  );
};
