import { Unit } from "./Unit";
import { Service } from "./service";

export interface UnitToBeCleaned extends Unit {
  priority?: Priority;
  arrival?: string;
  contractualCheckInTime?: string;
  departure?: string;
  expectedDeparture?: string;
  adults?: number;
  services?: Service[];
  children?: number;
  floorNumber?: number | null;
  privacyMode: boolean;
}

export enum Priority {
  MAX = "MAX",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW"
}
