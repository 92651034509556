import {
  Caption,
  Checkbox,
  Chip,
  Heading3,
  Paragraph,
  ParagraphBold,
  ParagraphSmall,
  ParagraphSmallBold,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { Box, Grid, Paper } from "@mui/material";
import React, { FC, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import PersonInfo from "./person-info";
import { GuestPreferences } from "src/domain/reservation-table-dto";
import {
  GuestType,
  guestTypeLabelsMap
} from "../overview/rows-for-table-guest-overview/rows-for-table-guest-overview";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { IdCheckStatus } from "src/domain/reservation";
import { DisplayPreference } from "../overview/rows-for-table-guest-overview/display-preference";
import { useUnits } from "src/hooks/use-unit-by-id";
import { GuestNote, PersonGender } from "src/domain/person";
import { MembershipAccount } from "../../../../../graphql/generated/graphql";
import { ProfileActions } from "src/features/profiles/profile-drawer/profile-actions";
import {
  genderPronounsLabel,
  GuestInsightInfoTypes
} from "./reservation-details-guest-insight-utils";

interface PersonInfoType {
  name: string;
  role: GuestType;
  gender?: PersonGender;
  recurring?: boolean;
  isBlacklisted: boolean;
  id: number;
  account?: boolean;
  guestNotes?: GuestNote[];
  preferencesText?: string;
  preferences?: GuestPreferences;
  contact: Array<any>;
  legal?: Array<string>;
  privateAddress?: Array<string>;
  billingAddress?: Array<string>;
  verified?: IdCheckStatus;
  emergencyEvacuationHelpNeeded?: boolean;
  enrolledInLoyaltyProgram?: boolean;
  frequentFlyerPrograms?: MembershipAccount[];
  licensePlate?: string;
}

interface PersonCardProps {
  personInfo: PersonInfoType;
  propertyId: string;
}

const PersonCard: FC<PersonCardProps> = ({ personInfo, propertyId }) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const { data: units } = useUnits({
    propertyId
  });
  const { features } = usePropertyConfig({
    propertyId
  });

  const preferencesNullSafe = useMemo(() => personInfo.preferences || {}, [personInfo.preferences]);

  const PreferencesComponent = useMemo(() => {
    return (
      <>
        {Object.keys(preferencesNullSafe).length > 0 ? (
          Object.keys(preferencesNullSafe).map((preference) => (
            <DisplayPreference
              preference={preference}
              propertyId={propertyId}
              preferences={preferencesNullSafe}
              key={preference}
              units={units ?? []}
            />
          ))
        ) : (
          <Grid item paddingTop={1}>
            <Paragraph sx={{ color: theme.palette.text.primary }}>
              {t("labels__no__preferences__stored")}
            </Paragraph>
          </Grid>
        )}
      </>
    );
  }, [preferencesNullSafe, propertyId, t, theme.palette.text.primary, units]);

  const PreferencesTextComponent = useMemo(() => {
    return (
      <>
        {personInfo.preferencesText && (
          <Paragraph sx={{ color: theme.palette.text.primary, whiteSpace: "pre-wrap" }}>
            {personInfo.preferencesText}
          </Paragraph>
        )}
        {personInfo.guestNotes &&
          personInfo?.guestNotes?.map((note) => (
            <Grid item xs={11} py={1} key={note.pmsId}>
              <Paper
                sx={{
                  p: 2,
                  border: `${theme.spacing(0.25)} solid ${theme.palette.grey[200]}`
                }}
                elevation={0}
              >
                <Grid container direction="column">
                  <Grid item pb={1}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {note.title && (
                        <Grid item>
                          <ParagraphBold>{note.title}</ParagraphBold>
                        </Grid>
                      )}
                      <Grid item>
                        <Chip
                          label={t(`labels__notes_category_${note.pmsProfileNoteCategoryId}`)}
                          size="large"
                          color="info"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Paragraph>{note.text}</Paragraph>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        {(!personInfo?.guestNotes || personInfo?.guestNotes?.length === 0) &&
          !personInfo.preferencesText && (
            <Paragraph sx={{ color: theme.palette.text.primary, whiteSpace: "pre-wrap" }}>
              {t("labels__no__preferences_text__stored")}
            </Paragraph>
          )}
      </>
    );
  }, [personInfo, theme, t]);

  return (
    <Paper elevation={0}>
      <Grid container p={3}>
        <Grid container>
          <Grid container>
            <Grid item xs={11} display="flex" alignItems="center">
              {personInfo?.gender && (
                <ParagraphSmallBold color={theme.palette.text.primary} paddingRight={1}>
                  {genderPronounsLabel(personInfo.gender, t)}
                </ParagraphSmallBold>
              )}
              <Heading3 sx={{ color: theme.palette.secondary.main, paddingRight: 3 }}>
                {personInfo.name}
              </Heading3>
              <Chip
                color="default"
                size="small"
                label={t(`${guestTypeLabelsMap[personInfo.role]}`)}
                sx={{ marginRight: 1 }}
              />
              {(personInfo.role === GuestType.SAME_GUEST ||
                personInfo.role === GuestType.PRIMARY_GUEST) && (
                <Chip
                  size="small"
                  color="info"
                  label={
                    personInfo.recurring
                      ? t("labels__reservation__details__overview__guests__recurring")
                      : t("labels__reservation__details__overview__guests__new")
                  }
                  sx={{ marginRight: 1 }}
                />
              )}
              {personInfo?.isBlacklisted && (
                <Chip size="small" color="error" label={t("labels__profile_blacklisted")} />
              )}
            </Grid>
            {personInfo.id > 0 && (
              <Grid item xs={1}>
                <Grid container justifyContent="end">
                  <ProfileActions
                    isBlacklisted={personInfo?.isBlacklisted}
                    profileId={personInfo.id}
                    menuPlacement="bottom-start"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container paddingTop={3} rowSpacing={1}>
            {(personInfo?.contact || features?.tfeFeatureEnabled) && (
              <Grid
                item
                xs={12}
                md={6}
                paddingBottom={personInfo.role !== GuestType.TRAVEL_BUDDY ? 4 : 0}
              >
                {personInfo?.contact && (
                  <PersonInfo
                    guestInsightInfoType={GuestInsightInfoTypes.CONTACT}
                    title={t("labels__guest__insight__CONTACT")}
                    //edit
                    show={personInfo.contact.length > 0}
                    info={personInfo.contact}
                  />
                )}

                <Grid container gap={2} mt={2} direction="column">
                  {features?.licensePlateCheckinWizardEnabled && personInfo.licensePlate && (
                    <Box sx={{ display: "flex", gap: 0.5 }}>
                      <Caption sx={{ display: "block" }}>{t("labels__license_plate")}:</Caption>
                      <ParagraphSmall>{personInfo.licensePlate}</ParagraphSmall>
                    </Box>
                  )}
                  {features?.tfeFeatureEnabled && !!personInfo.frequentFlyerPrograms?.length && (
                    <Grid container width="100%">
                      <Grid xs={12} item flexDirection="column">
                        <Caption sx={{ display: "block" }}>
                          {`${t("labels__frequent_flyer_program")} & ${t(
                            "labels__membership_number"
                          )}:`}
                        </Caption>
                        {personInfo.frequentFlyerPrograms?.map((program) => {
                          return (
                            <ParagraphSmall>
                              {program?.membershipTypeCode}: {program.membershipId}
                            </ParagraphSmall>
                          );
                        })}
                      </Grid>
                    </Grid>
                  )}
                  {features?.tfeFeatureEnabled && features.loyaltyProgramEnabled && (
                    <Grid item display="flex" alignItems="center">
                      <Checkbox
                        id={"enrolledInLoyaltyProgram"}
                        checked={!!personInfo.enrolledInLoyaltyProgram}
                        onChange={() => {}}
                        disabled
                        title={t("labels__enrolled_loyalty_program")}
                      />
                    </Grid>
                  )}
                  {features?.tfeFeatureEnabled && (
                    <Grid item display="flex" alignItems="center">
                      <Checkbox
                        id={"emergencyEvacuationHelpNeeded"}
                        checked={!!personInfo.emergencyEvacuationHelpNeeded}
                        onChange={() => {}}
                        disabled
                        title={t("labels__special_assistance")}
                        text={t("labels__require_special_assistance")}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {personInfo?.legal && (
              <Grid item xs={12} md={6} paddingBottom={4}>
                <PersonInfo
                  guestInsightInfoType={GuestInsightInfoTypes.LEGAL_DATA}
                  title={t("labels__guest__insight__LEGAL_DATA")}
                  //edit
                  show={
                    (personInfo.role === GuestType.PRIMARY_GUEST ||
                      personInfo.role === GuestType.SAME_GUEST) &&
                    personInfo.legal?.length > 0
                  }
                  info={personInfo.legal}
                  verified={
                    personInfo.role === GuestType.PRIMARY_GUEST ||
                    personInfo.role === GuestType.SAME_GUEST
                      ? personInfo.verified
                      : undefined
                  }
                />
              </Grid>
            )}
            {personInfo?.privateAddress && (
              <Grid item xs={6} paddingBottom={4}>
                <PersonInfo
                  guestInsightInfoType={GuestInsightInfoTypes.PRIVATE_ADDRESS}
                  title={t("labels__guest__insight__PRIVATE_ADDRESS")}
                  //edit
                  show={
                    personInfo.role !== GuestType.TRAVEL_BUDDY &&
                    personInfo.privateAddress?.length > 0
                  }
                  info={personInfo.privateAddress}
                />
              </Grid>
            )}
            {personInfo?.billingAddress && (
              <Grid item xs={6} paddingBottom={4}>
                <PersonInfo
                  guestInsightInfoType={GuestInsightInfoTypes.BILLING_ADDRESS}
                  title={t("labels__guest__insight__BILLING_ADDRESS")}
                  //edit
                  show={
                    personInfo.role !== GuestType.TRAVEL_BUDDY &&
                    personInfo.billingAddress?.length > 0
                  }
                  info={personInfo?.billingAddress}
                />
              </Grid>
            )}
            <Grid item xs={6} paddingBottom={personInfo.role !== GuestType.TRAVEL_BUDDY ? 4 : 0}>
              <PersonInfo
                guestInsightInfoType={GuestInsightInfoTypes.PREFERENCES}
                title={t("labels__guest__insight__PREFERENCES")}
                //edit
                //add
                show={personInfo.role !== GuestType.TRAVEL_BUDDY}
                info={PreferencesComponent}
              />
            </Grid>
            <Grid item xs={6}>
              <PersonInfo
                guestInsightInfoType={GuestInsightInfoTypes.NOTES}
                title={t("labels__guest__insight__NOTES")}
                //edit
                //add
                show={personInfo.role !== GuestType.TRAVEL_BUDDY}
                info={PreferencesTextComponent}
              />
            </Grid>
            {personInfo.account !== null && personInfo.account !== undefined && (
              <Grid item xs={6}>
                <PersonInfo
                  guestInsightInfoType={GuestInsightInfoTypes.ACCOUNT}
                  title={t("labels__guest__insight__ACCOUNT")}
                  //edit
                  show={
                    personInfo.role !== GuestType.TRAVEL_BUDDY &&
                    personInfo.account !== null &&
                    personInfo.account !== undefined
                  }
                  info={personInfo.account ? t("labels__yes") : t("labels__no")}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PersonCard;
