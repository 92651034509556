import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type CreatePaymentLinkMutationVariables = Types.Exact<{
  paymentLinkRequest?: Types.InputMaybe<Types.PaymentLinkRequest>;
}>;

export type CreatePaymentLinkMutation = {
  __typename?: "Mutation";
  CreatePaymentLink?: { __typename?: "PaymentLinkResponse"; url: string } | null;
};

export const CreatePaymentLinkDocument = `
    mutation CreatePaymentLink($paymentLinkRequest: PaymentLinkRequest) {
  CreatePaymentLink(paymentLinkRequest: $paymentLinkRequest) {
    url
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    CreatePaymentLink: build.mutation<
      CreatePaymentLinkMutation,
      CreatePaymentLinkMutationVariables | void
    >({
      query: (variables) => ({ document: CreatePaymentLinkDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useCreatePaymentLinkMutation } = injectedRtkApi;
