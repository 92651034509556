import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import { ReportsApi } from "../api/report.api";
import { Report } from "../domain/report";
import { PerformedServiceApi } from "../api/performed-service.api";
import { PerformedServicePostPayload } from "../domain/performed-service";

function getReportIdFromReport(report: Report) {
  return report.reservationId + "_" + report.serviceId;
}
function getReportIdFromPerformedServicePostPayload(report: PerformedServicePostPayload) {
  return report.reservationId + "_" + report.serviceId;
}
export const dashboardBreakfastAdapter = createEntityAdapter<Report>({
  selectId: (model) => getReportIdFromReport(model)
});

const initialState = dashboardBreakfastAdapter.getInitialState<{
  status: EntityStateStatus;
}>({
  status: EntityStateStatus.IDLE
});

export const fetchBreakfast = createAsyncThunk<
  Array<Report>,
  { date: string; propertyId: string },
  { state: RootState; rejectValue: { reason: string } }
>(
  "dashboardBreakfast/fetchBreakfast",
  async (arg, thunkAPI) => {
    if (arg.propertyId && arg.date) {
      return ReportsApi.fetchBreakfastReport(arg.date, arg.propertyId, {
        signal: thunkAPI.signal
      });
    } else {
      return Promise.resolve([]);
    }
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const status = thunkAPI.getState().dashboardBreakfast.status;
      // don't load if it's already loading
      if (status === EntityStateStatus.LOADING) {
        return false;
      }
    }
  }
);

export const markAsPerformed = createAsyncThunk<
  any,
  { reports: PerformedServicePostPayload[] },
  { state: RootState; rejectValue: { reason: string } }
>("service/markAsPerformed", async (arg, thunkAPI) => {
  return PerformedServiceApi.markAsPerformed(arg.reports, {
    signal: thunkAPI.signal
  });
});
export const markAsUnperformed = createAsyncThunk<
  any,
  { reports: PerformedServicePostPayload[] },
  { state: RootState; rejectValue: { reason: string } }
>("service/markAsUnperformed", async (arg, thunkAPI) => {
  return PerformedServiceApi.markAsUnperformed(arg.reports, {
    signal: thunkAPI.signal
  });
});

const slice = createSlice({
  name: "dashboardBreakfast",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBreakfast.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchBreakfast.fulfilled, (state, action) => {
        dashboardBreakfastAdapter.setAll(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchBreakfast.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.status === EntityStateStatus.LOADING) {
            state.status = EntityStateStatus.IDLE;
          }
          return;
        }
        state.status = EntityStateStatus.FAILED;
      })
      .addCase(markAsPerformed.fulfilled, (state, action) => {
        action.meta.arg.reports.forEach((report) => {
          const entityId = getReportIdFromPerformedServicePostPayload(report);
          const entity = state.entities[entityId];
          const updateEntity = { ...entity };
          updateEntity.performed = true;

          dashboardBreakfastAdapter.updateOne(state, {
            id: entityId,
            changes: updateEntity
          });
        });
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(markAsUnperformed.fulfilled, (state, action) => {
        action.meta.arg.reports.forEach((report) => {
          const entityId = getReportIdFromPerformedServicePostPayload(report);
          const entity = state.entities[entityId];
          const updateEntity = { ...entity };
          updateEntity.performed = false;

          dashboardBreakfastAdapter.updateOne(state, {
            id: entityId,
            changes: updateEntity
          });
        });
        state.status = EntityStateStatus.SUCCEEDED;
      });
  }
});

export const { reducer } = slice;

export const { selectAll: selectBreakfast } = dashboardBreakfastAdapter.getSelectors<RootState>(
  (state) => state.dashboardBreakfast
);
