import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useAvailableTemplatesForSendCustomNotificationQuery } from "../../../../../../../graphql/queries/AvailableTemplatesForSendCustomNotification.generated";
import { NotificationType } from "../../../../../../../graphql/generated/graphql";
import { useGetNotificationTemplateTypeQuery } from "../../../../../../../store/endpoints/reservations.endpoints";

export type NotificationTemplateType = NotificationType;

export const useGetNotificationTemplate = ({
  reservationId
}: {
  reservationId: string;
}): Array<NotificationTemplateType> => {
  const { isRESTVersion, isGraphQlVersion } = useApiVersion();

  const { data: dataV2 } = useAvailableTemplatesForSendCustomNotificationQuery(
    {
      pmsReservationId: reservationId
    },
    {
      skip: isRESTVersion
    }
  );

  const { data: dataV1 } = useGetNotificationTemplateTypeQuery(
    {
      reservationId
    },
    {
      skip: isGraphQlVersion
    }
  );

  const templatesV2 = dataV2?.AvailableTemplatesForSendCustomNotification ?? [];
  const templatesV1 = dataV1 ?? [];

  return isRESTVersion ? templatesV1 : templatesV2;
};
