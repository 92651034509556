import { useLazyGetUserProfilesQuery } from "src/graphql/queries/GetUserProfiles.generated";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useLazyGetProfilesQuery } from "src/store/endpoints/user-profile.endpoints";
import { PageableRequestParams } from "src/types/pageable";
import { transformUserProfileV2ToCommon } from "../graphql/transform/transform-user-profile";
import { useCallback, useMemo } from "react";
import { UserProfile } from "src/domain/user-profile";

export const useFetchProfilesLazy = () => {
  const { isRESTVersion } = useApiVersion();

  const [getUserProfilesV2, resultUserProfilesV2] = useLazyGetUserProfilesQuery();
  const [getUserProfilesV1, resultUserProfilesV1] = useLazyGetProfilesQuery();

  const functionCall = useCallback(
    ({
      propertyId,
      pageable,
      text
    }: {
      propertyId: string;
      pageable: PageableRequestParams;
      text: string;
    }): Promise<UserProfile[]> => {
      return isRESTVersion
        ? getUserProfilesV1({
            propertyId: propertyId ?? "",
            pageable: { pageNumber: pageable.pageNumber, pageSize: pageable.pageSize },
            payload: { freeTextSearch: text }
          }).unwrap()
        : getUserProfilesV2({
            filter: {
              filters: {
                freeText: [`eq,${text}`]
              },
              pageable: {
                pageNumber: pageable.pageNumber,
                pageSize: pageable.pageSize
              }
            }
          })
            .unwrap()
            .then((val) => val.GetUserProfiles.content.map(transformUserProfileV2ToCommon));
    },
    [isRESTVersion, getUserProfilesV1, getUserProfilesV2]
  );

  const result = useMemo(
    () =>
      isRESTVersion
        ? resultUserProfilesV1?.data
        : resultUserProfilesV2?.data?.GetUserProfiles?.content.map(transformUserProfileV2ToCommon),
    [isRESTVersion, resultUserProfilesV1?.data, resultUserProfilesV2?.data]
  );
  return { functionCall, result: result ?? [] };
};
