import { FC } from "react";
import { SvgIcon } from "@mui/material";

export const PaymentTerminal: FC = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="Credit-Card-Payment--Streamline-Ultimate"
  >
    <desc>{"Credit Card Payment Streamline Icon: https://streamlinehq.com"}</desc>
    <g id="Credit-Card-Payment--Streamline-Ultimate">
      <g>
        <path
          d="M17.25 18.75 19.5 21l2.25 -2.25"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path
          d="m19.5 21 0 -5.25"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
      </g>
      <g>
        <path
          d="M15.75 0.75h6s1.5 0 1.5 1.5v9s0 1.5 -1.5 1.5h-6s-1.5 0 -1.5 -1.5v-9s0 -1.5 1.5 -1.5"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path
          d="m17.25 12.75 0 -12"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path
          d="m20.25 3.75 0 1.5"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
      </g>
      <g>
        <path
          d="M1.5 4.66a0.89 0.89 0 0 0 -0.75 1v15.63a1.78 1.78 0 0 0 1.5 2h10.5a1.78 1.78 0 0 0 1.5 -2v-5.54"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path
          d="m0.75 12.75 10.25 0"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path
          d="m3.75 16.75 0.75 0"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path
          d="m3.75 19.75 0.75 0"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path
          d="m7.25 16.75 0.75 0"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path
          d="m7.25 19.75 0.75 0"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path
          d="m11 16.75 0 2.95"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path
          d="m4.5 8.5 0 -7.75L7.75 2.5 11 0.75l0 7.75"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <path
          d="M0.75 8.5 11 8.5"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
      </g>
    </g>
  </SvgIcon>
);
