import React, { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Chip, ChipProps } from "@likemagic-tech/sv-magic-library";
import {
  BaseTableFiltersProps,
  FilterMapInterface,
  FilterObjectType,
  serializeIdOfFilter
} from "../table/filters/table-filters";
import { FilterOperator } from "../../types/filters/filters";
import DoneIcon from "@mui/icons-material/Done";
import { grey } from "@mui/material/colors";

interface QuickFilterCheckboxProps extends BaseTableFiltersProps {
  condition: string;
  filter: { name: string; type?: FilterObjectType; operator?: FilterOperator };
  filterType?: FilterObjectType;
  label: string;
  count: number;
  value?: string;
  filterMap: FilterMapInterface;
  mapColors?: (condition: string) => ChipProps["color"];
  onFilterMapChange: (filterMap: FilterMapInterface) => void;
  isSingleValue?: boolean;
}

const wrapIfBigNumber = (number: number) => {
  if (number < 1000) return number;
  return "999+";
};

export const QuickFilterCheckbox: FC<QuickFilterCheckboxProps> = ({
  condition,
  filterMap,
  filter,
  label,
  value,
  count,
  mapColors,
  onFilterMapChange,
  isSingleValue
}) => {
  const theme = useTheme();

  const filterId = serializeIdOfFilter({
    type: filter?.type ?? FilterObjectType.ADDITIONAL,
    operator: filter?.operator ?? FilterOperator.In,
    name: filter?.name ?? "condition"
  });
  const filterConditionItem = useMemo(() => filterMap[filterId], [filterId, filterMap]);

  const isOptionSelected = useMemo(() => {
    return filterConditionItem?.value?.includes(value ?? condition) || false;
  }, [condition, filterConditionItem?.value, value]);

  const toggleCheckbox = () => {
    const valueList = filterConditionItem?.value?.split(",") || [];

    const singleValue = value ?? condition;

    const multipleValues = valueList
      .filter((item) => item !== "")
      .concat(singleValue)
      .join(",");

    const removeValue = valueList.filter((item) => item !== "" && item !== singleValue).join(",");

    const singleOrMultipleValue = isSingleValue ? singleValue : multipleValues;

    const result = !isOptionSelected ? singleOrMultipleValue : removeValue;

    onFilterMapChange({
      ...filterMap,
      [filterId]: {
        value: result,
        type: filter?.type ?? FilterObjectType.ADDITIONAL,
        operator: filter?.operator ?? FilterOperator.In
      }
    });
  };

  return (
    <Chip
      label={label}
      onClick={() => {
        toggleCheckbox();
      }}
      rightIcon={
        isOptionSelected ? (
          <Box
            sx={{
              "&.MuiChip-deleteIcon": {
                minWidth: 16,
                height: 16,
                display: "flex",
                color:
                  mapColors && mapColors(condition)
                    ? (theme.palette[mapColors(condition) as keyof typeof theme.palette] as any)
                        ?.dark
                    : "white"
              }
            }}
          >
            <DoneIcon sx={{ width: 16, height: 16 }} />
          </Box>
        ) : undefined
      }
      leftIcon={
        <Box
          sx={{
            width: "auto",
            background:
              mapColors && mapColors(condition)
                ? (theme.palette[mapColors(condition) as keyof typeof theme.palette] as any)?.dark
                : grey[700],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "3px",
            lineHeight: "18px",
            color: "white !important",
            "&.MuiChip-icon": {
              height: theme.spacing(2.5),
              padding: theme.spacing(0.25, 0.5),
              lineHeight: "18px",
              width: "auto",
              color: "white",
              minWidth: "20px"
            }
          }}
        >
          {wrapIfBigNumber(count)}
        </Box>
      }
      onRightIconClick={
        isOptionSelected
          ? () => {
              toggleCheckbox();
            }
          : undefined
      }
      variant={isOptionSelected ? "filled" : "outlined"}
      color={(mapColors && mapColors(condition)) ?? "primary"}
      sx={{
        height: theme.spacing(4),
        "&:hover": {
          backgroundColor:
            mapColors && mapColors(condition)
              ? (theme.palette[mapColors(condition) as keyof typeof theme.palette] as any)?.light
              : grey[700]
        }
      }}
    />
  );
};
