import React, { FC } from "react";
import { FieldWithIcon } from "./field-with-icon";
import { GroupOutlined } from "@mui/icons-material";
import { Box, Divider, useTheme } from "@mui/material";
import { useGetActors } from "../../use-get-actors";
import {
  AutocompleteComponent,
  AutocompleteOption
} from "../../../../components/autocomplete-component";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { useIsMobile } from "../../../../hooks/use-is-mobile";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";

interface AssignProps {
  setFieldValue: (field: string, value: any) => void;
  assigneeGroup?: { id: string; label: string };
  assignee?: { id: string; label: string };
}

export const Assign: FC<AssignProps> = ({ setFieldValue, assigneeGroup, assignee }) => {
  const theme = useTheme();
  const { actorGroupOptions, actorOptions } = useGetActors();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();

  return (
    <FieldWithIcon icon={<GroupOutlined />}>
      <Box sx={{ flex: 1, ml: 2, minWidth: isMobile ? "calc(100% - 16px)" : "auto" }}>
        <AutocompleteComponent
          options={actorGroupOptions as AutocompleteOption[]}
          onSelected={(value) => setFieldValue("assigneeGroup", value)}
          value={assigneeGroup ?? null}
          inputVariant="standard"
          disableClearable={false}
          placeholder={assigneeGroup?.id ? undefined : t("labels__assignee_group")}
          label=""
          inputFontSize={14}
          inputStartAdornment={
            assigneeGroup?.id ? (
              <ParagraphSmall sx={{ color: theme.palette.text.secondary, mr: 0.5 }}>
                {t("labels__assignee_group")}:
              </ParagraphSmall>
            ) : (
              <></>
            )
          }
        />
      </Box>

      {!isMobile && (
        <Divider
          orientation="vertical"
          sx={{ color: theme.palette.text.secondary, height: theme.spacing(3) }}
        />
      )}

      <Box sx={{ flex: 1, ml: 2, minWidth: isMobile ? "calc(100% - 16px)" : "auto" }}>
        <AutocompleteComponent
          options={actorOptions as AutocompleteOption[]}
          onSelected={(value) => setFieldValue("assignee", value)}
          value={assignee ?? null}
          inputVariant="standard"
          disableClearable={false}
          placeholder={t("labels__assignee")}
          label=""
          inputFontSize={14}
          inputStartAdornment={
            assignee?.id ? (
              <ParagraphSmall sx={{ color: theme.palette.text.secondary, mr: 0.5 }}>
                {t("labels__assignee")}:
              </ParagraphSmall>
            ) : (
              <></>
            )
          }
        />
      </Box>
    </FieldWithIcon>
  );
};
