import * as Types from "../generated/graphql";

import { tasksApi } from "../tasks.api";
export type UpdateTaskMutationVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
  request: Types.UpdateTaskRequest;
  taskId: Types.Scalars["Int"]["input"];
}>;

export type UpdateTaskMutation = {
  __typename?: "Mutation";
  UpdateTask: { __typename?: "TaskDto"; id: number };
};

export const UpdateTaskDocument = `
    mutation UpdateTask($pmsPropertyId: String!, $request: UpdateTaskRequest!, $taskId: Int!) {
  UpdateTask(pmsPropertyId: $pmsPropertyId, request: $request, taskId: $taskId) {
    id
  }
}
    `;

const injectedRtkApi = tasksApi.injectEndpoints({
  endpoints: (build) => ({
    UpdateTask: build.mutation<UpdateTaskMutation, UpdateTaskMutationVariables>({
      query: (variables) => ({ document: UpdateTaskDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useUpdateTaskMutation } = injectedRtkApi;
