import { api } from "../UpdateTask.generated";

type TasksRelatedToReservationType = "Tasks-Related-To-Reservations";
export const getUpdateTaskEnhanced = api.enhanceEndpoints({
  endpoints: {
    UpdateTask: {
      invalidatesTags: (result, error, arg) => [
        ...(arg.request?.links?.pmsReservationIds?.map((reservationId) => ({
          id: reservationId,
          type: "Tasks-Related-To-Reservations" as TasksRelatedToReservationType
        })) ?? []),
        "Tasks",
        { id: arg.request.parentId ?? 0, type: "Tasks" },
        { id: result?.UpdateTask.id, type: "Tasks" },

        // Housekeeping
        "AdditionalTasks",
        "HousekeepingTabCounts",
        "HousekeepingCards",
        "LeftoverTasks"
      ]
    }
  }
});

export const { useUpdateTaskMutation: useUpdateTaskMutationEnhanced } = getUpdateTaskEnhanced;
