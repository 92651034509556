import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useAddPreferenceToProfileMutationEnhanced } from "src/graphql/mutations/enhanced-mutations/add-preference-to-profile-enhanced";

interface AddAddPreferenceToProfileProps {
  preferenceId: string;
  profileId: number;
}

export const useAddPreferenceToProfile = () => {
  const { isRESTVersion } = useApiVersion();
  const [addPreferencesMutation, resultAddToProfile] = useAddPreferenceToProfileMutationEnhanced();

  const addPreferencesToProfileV1 = () => {
    return Promise.reject("not yet implemented");
  };

  const addPreferenceToProfileV2 = ({
    preferenceId,
    profileId
  }: AddAddPreferenceToProfileProps) => {
    return addPreferencesMutation({
      pmsPreferenceId: preferenceId,
      userProfileId: profileId
    });
  };

  const functionCall = isRESTVersion ? addPreferencesToProfileV1 : addPreferenceToProfileV2;
  const result = isRESTVersion ? [] : resultAddToProfile;

  return [functionCall, result] as [typeof addPreferenceToProfileV2, typeof resultAddToProfile];
};
