import * as Types from "../generated/graphql";

import { messagingApi } from "../messaging.api";
export type StreamUnreadMessagesStatSubscriptionVariables = Types.Exact<{
  propertyIds: Array<Types.Scalars["ID"]["input"]> | Types.Scalars["ID"]["input"];
}>;

export type StreamUnreadMessagesStatSubscription = {
  __typename?: "Subscription";
  StreamUnreadMessagesStat: {
    __typename?: "UnreadMessagesStat";
    numberOfUnreadMessagesInResolved: number;
    numberOfUnreadMessagesInAssignedToMe: number;
    numberOfUnreadMessagesInInbox: number;
    totalNumberOfUnreadMessages: number;
  };
};

export const StreamUnreadMessagesStatDocument = `
    subscription StreamUnreadMessagesStat($propertyIds: [ID!]!) {
  StreamUnreadMessagesStat(pmsPropertyIds: $propertyIds) {
    numberOfUnreadMessagesInResolved
    numberOfUnreadMessagesInAssignedToMe
    numberOfUnreadMessagesInInbox
    totalNumberOfUnreadMessages
  }
}
    `;

const injectedRtkApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({})
});

export { injectedRtkApi as api };
export const {} = injectedRtkApi;
