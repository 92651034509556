import React, { FC } from "react";
import { MessagingUploadSliceItem } from "../../../slices/messaging-attachment.slice";
import { Box, CircularProgress, IconButton, useTheme } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { isStatusLoading } from "../../../domain/EntityStateStatus";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

export const UploadItem: FC<{
  uploadItem: MessagingUploadSliceItem;
  removeFile: (id: string) => void;
}> = ({ uploadItem, removeFile }) => {
  const { spacing } = useTheme();

  return (
    <Box position="relative">
      <IconButton
        sx={{
          position: "absolute",
          top: spacing(-0.5),
          right: spacing(-2)
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          removeFile(uploadItem.localIdentifier);
        }}
      >
        <CancelIcon />
      </IconButton>
      <div style={{ height: 35, width: 35 }}>
        {isStatusLoading(uploadItem.status) && (
          <Box position="absolute" width="100%" height={40} textAlign="center">
            <CircularProgress size={35} />
          </Box>
        )}
        {uploadItem.preview ? (
          <img
            style={{ objectFit: "cover" }}
            height="100%"
            width="100%"
            src={uploadItem.preview}
            alt={uploadItem.localIdentifier}
          />
        ) : (
          <InsertDriveFileOutlinedIcon />
        )}
      </div>
    </Box>
  );
};
