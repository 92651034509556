import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Selector
} from "@reduxjs/toolkit";
import { EntityStateStatus, isStatusLoading } from "../domain/EntityStateStatus";
import { UnitApi } from "../api/unit.api";
import { UnitToBeCleaned } from "../domain/UnitToBeCleaned";
import { RootState } from "../store";

export const unitAdapter = createEntityAdapter<UnitToBeCleaned>({
  selectId: (model) => model.id
});
interface CleaningState {
  status: EntityStateStatus;
  statusForBulkPatch: EntityStateStatus;
}
const initialState = unitAdapter.getInitialState<CleaningState>({
  status: EntityStateStatus.IDLE,
  statusForBulkPatch: EntityStateStatus.IDLE
});

export const fetchUnitsToBeCleaned = createAsyncThunk<
  Array<UnitToBeCleaned>,
  { propertyId: string },
  { state: RootState; rejectValue: { reason: string } }
>(
  "cleaning/fetchUnitsToBeCleaned",
  async (arg, thunkAPI) => {
    return UnitApi.fetchUnitsToBeCleaned(arg.propertyId, {
      signal: thunkAPI.signal
    });
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const status = thunkAPI.getState().cleaning.status;
      // don't load if it's already loading
      if (status === EntityStateStatus.LOADING) {
        return false;
      }
    }
  }
);

export const patchUnit = createAsyncThunk<
  any,
  { unitId: string; patches: any },
  { state: RootState; rejectValue: { reason: string } }
>("cleaning/patchUnit", async (arg, thunkAPI) => {
  return UnitApi.updateUnit(arg.unitId, arg.patches, {
    signal: thunkAPI.signal
  });
});

export const updateStateForUnits = createAsyncThunk<
  any,
  { unitIds: string[]; patches: any },
  { state: RootState; rejectValue: { reason: string } }
>("cleaning/patchUnits", async (arg, thunkAPI) => {
  return UnitApi.updateUnits(arg.unitIds, arg.patches, {
    signal: thunkAPI.signal
  });
});

const slice = createSlice({
  name: "cleaning",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnitsToBeCleaned.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchUnitsToBeCleaned.fulfilled, (state, action) => {
        unitAdapter.setAll(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchUnitsToBeCleaned.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.status === EntityStateStatus.LOADING) {
            state.status = EntityStateStatus.IDLE;
          }
          return;
        }
        state.status = EntityStateStatus.FAILED;
      })
      .addCase(patchUnit.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(patchUnit.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      })
      .addCase(patchUnit.fulfilled, (state) => {
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(updateStateForUnits.pending, (state) => {
        state.statusForBulkPatch = EntityStateStatus.LOADING;
      })
      .addCase(updateStateForUnits.rejected, (state) => {
        state.statusForBulkPatch = EntityStateStatus.FAILED;
      })
      .addCase(updateStateForUnits.fulfilled, (state, action) => {
        action.meta.arg.unitIds.forEach((unitId) => {
          const entity = state.entities[unitId];
          if (entity) {
            entity.status.condition = action.meta.arg.patches[0].value;
          }
        });
        state.statusForBulkPatch = EntityStateStatus.SUCCEEDED;
      });
  }
});

export const { reducer } = slice;

export const { selectAll: selectAllUnitsToBeCleaned } = unitAdapter.getSelectors<RootState>(
  (state) => state.cleaning
);

const selectSelf: Selector<RootState, CleaningState> = (state: RootState) => state[slice.name];

export const selectIsUnitsUpdating = createSelector(selectSelf, (res) =>
  isStatusLoading(res.statusForBulkPatch)
);
