import { useCallback } from "react";
import { getHousekeepingCardsEnhanced } from "../graphql-tasks/query/enhanced-queries/get-housekeeping-cards-enhanced";
import { useDispatch } from "../store";

export const useInvalidateHousekeepingCards = () => {
  const dispatch = useDispatch();

  const invalidateHousekeepingCards = useCallback(() => {
    return setTimeout(() => {
      dispatch(getHousekeepingCardsEnhanced.util.invalidateTags(["HousekeepingCards"]));
    }, 1000);
  }, [dispatch]);

  return {
    invalidateHousekeepingCards: invalidateHousekeepingCards
  };
};
