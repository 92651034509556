/**
 * https://stackoverflow.com/a/1349426/13234705
 * @param length
 */
function makeRandomString(length: number) {
  const result = [];
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join("");
}

// logic for obfuscation for env key:
// finding first "empty key slot" on window object based on length and longer than 35
// then we generate random string for our env key
const keysLengthSet = new Set(Object.keys(window).map((key) => key.length));
const freeSlot =
  Array.from(keysLengthSet)
    .sort((a, b) => a - b)
    .find((len) => len > 35)! + 1;

const envKey = makeRandomString(freeSlot);

interface Env {
  ENV_STAGE: string;
}

Object.defineProperty(window, envKey, {
  value: {
    ENV_STAGE: process.env.REACT_APP_ENV_STAGE
  },
  writable: false,
  enumerable: false,
  configurable: false
});

export const env = window[envKey as keyof typeof window] as Env;
