import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useTheme
} from "@mui/material";
import { Heading2, Paragraph } from "@likemagic-tech/sv-magic-library";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import {
  AutocompleteComponent,
  AutocompleteOption
} from "../../../../components/autocomplete-component";
import { ReservationTableDto } from "../../../../domain/reservation-table-dto";
import { useUnits } from "../../../../hooks/use-unit-by-id";
import { openBanner } from "../../../../slices/banner.slice";
import { useDispatch } from "../../../../store";
import { useUnitGroups } from "../../../../hooks/use-unit-group-by-id";
import { PreferencesActorSelectionComponent } from "./guest-preferences-actor-selection";
import { PreferenceTypes } from "src/domain/reservation";
import { useGuestPreference } from "../../../../hooks/use-guest-preference";
import { useProfileDrawer } from "src/features/profiles/profile-drawer/use-profile-drawer";
import { useAddPreferenceToProfile } from "src/hooks/use-add-preference-to-profile";
import { useAddPreferenceInReservation } from "src/hooks/use-add-preference-in-reservation";
import { guestSpecificUnits } from "../reservation-details-tabs/guest-insights/reservation-details-guest-insight-utils";

interface UserPreferenceModalProps {
  isOpen: boolean;
  onClose: () => void;
  propertyId: string;
  reservation?: ReservationTableDto;
  userFullName?: string;
}

export const GuestPreferencesModal: FC<UserPreferenceModalProps> = ({
  propertyId,
  isOpen,
  onClose,
  reservation,
  userFullName
}) => {
  const dispatch = useDispatch();
  const { data: units } = useUnits({
    propertyId: propertyId
  });
  const unitsGroups = useUnitGroups(propertyId);
  const { t } = useTranslationWrapper();
  const { palette, spacing } = useTheme();
  const [selectedCategory, setSelectedCategory] = useState<AutocompleteOption | null>(null);
  const [selectedPreference, setSelectedPreference] = useState<AutocompleteOption | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<AutocompleteOption | null>(null);
  const [selectedActor, setSelectedActor] = useState<AutocompleteOption | null>(null);
  const data = useGuestPreference({ propertyId });
  const { profileGuestIdentityId } = useProfileDrawer();
  const [addPreferenceToProfile, resultAddToProfile] = useAddPreferenceToProfile();
  const [addPreferenceInReservation, resultAddToReservation] = useAddPreferenceInReservation();

  const handleClose = useCallback(() => {
    setSelectedActor(null);
    setSelectedUnit(null);
    setSelectedPreference(null);
    setSelectedCategory(null);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (resultAddToReservation.isSuccess || resultAddToProfile.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      if (reservation) {
        resultAddToReservation.reset();
      } else {
        resultAddToProfile.reset();
      }
      handleClose();
    }
  }, [resultAddToReservation, dispatch, handleClose, t, resultAddToProfile, reservation]);

  const categoryOptions = useMemo(
    () =>
      data
        ? Object.keys(data).map((key) => ({
            id: key,
            label: t(`labels__preference_category_${key}`)
          }))
        : [],
    [t, data]
  );

  const preferenceOptions = useMemo(
    () =>
      selectedCategory && data && data[selectedCategory.id as keyof typeof data]
        ? data[selectedCategory.id as keyof typeof data].map((value) => ({
            id: value,
            label: t(`labels__preference_type_${value}`, { unitName: "" })
          }))
        : [],
    [data, selectedCategory, t]
  );

  const addPreference = useCallback(() => {
    if (reservation) {
      let value;

      if (selectedPreference?.id === PreferenceTypes.SPECIFIC_UNIT) {
        const uniqueUnits: { unitId: string; propertyId: string }[] = Object.values(
          guestSpecificUnits(reservation, selectedActor?.id ?? "").reduce(
            (units, item) => {
              units[item.unitId] = item;
              return units;
            },
            {} as Record<string, { unitId: string; propertyId: string }>
          )
        );

        if (selectedUnit?.id && !uniqueUnits.some((unit) => unit?.unitId === selectedUnit.id)) {
          uniqueUnits.push({
            unitId: selectedUnit.id,
            propertyId
          });
        }

        value = uniqueUnits;
      } else {
        value = selectedUnit?.id;
      }

      const payload = {
        actor: selectedActor?.id ?? "",
        patches: [
          {
            path: `/${selectedPreference?.id ?? ""}`,
            op: "add",
            value
          }
        ]
      };

      addPreferenceInReservation({
        reservationId: reservation.id,
        payload
      });
    } else {
      addPreferenceToProfile({
        preferenceId: selectedPreference?.id ?? "",
        profileId: Number(profileGuestIdentityId)
      });
    }
  }, [
    addPreferenceToProfile,
    addPreferenceInReservation,
    reservation,
    selectedPreference?.id,
    selectedActor?.id,
    profileGuestIdentityId,
    selectedUnit?.id,
    propertyId
  ]);

  const unitOptions = useMemo(() => {
    if (!units) return [];

    return units
      .map((unit) => {
        const unitGroupLabel = unitsGroups?.find((item) => item.unitGroupId === unit.unitGroupId)
          ?.name;
        const label = unitGroupLabel ? `${unitGroupLabel} ${unit.name}` : unit.name;

        return { id: unit.id, label };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [units, unitsGroups]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }
      }}
    >
      <Grid container p={3} flexDirection="column">
        <DialogTitle sx={{ flex: "1 1 0", padding: "0" }}>
          <Grid container direction="row">
            <Grid item xs={11}>
              <Heading2 color="text.primary">
                {t("labels__tabs_comments_add_reservation_preference")}
              </Heading2>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="end">
              <IconButton onClick={handleClose}>
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ padding: "0" }}>
          <Box sx={{ marginTop: spacing(2) }}>
            {reservation ? (
              <PreferencesActorSelectionComponent
                selectedActor={selectedActor}
                setSelectedActor={setSelectedActor}
                reservation={reservation}
              />
            ) : (
              <>{userFullName}</>
            )}
          </Box>
          {(selectedActor || !reservation) && (
            <Box sx={{ marginTop: spacing(2) }}>
              <AutocompleteComponent
                options={categoryOptions}
                onSelected={(value) => {
                  setSelectedCategory(value);
                  setSelectedPreference(null);
                  setSelectedUnit(null);
                }}
                value={selectedCategory}
                label={t("labels__preference_category")}
              />
            </Box>
          )}
          {selectedCategory && (
            <Box sx={{ marginTop: spacing(2) }}>
              <AutocompleteComponent
                options={preferenceOptions}
                onSelected={(value) => {
                  setSelectedPreference(value);
                  setSelectedUnit(null);
                }}
                value={selectedPreference}
                label={t(`labels__preference_category_${selectedCategory.id}`)}
              />
            </Box>
          )}
          {selectedPreference?.id === PreferenceTypes.SPECIFIC_UNIT && (
            <Box sx={{ marginTop: spacing(2) }}>
              <AutocompleteComponent
                options={unitOptions}
                onSelected={(inputValue) => {
                  setSelectedUnit(inputValue);
                }}
                value={selectedUnit}
                label={t("labels__unit")}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ flex: "1 1 0", px: "0" }}>
          <Button
            variant="primary"
            onClick={() => addPreference()}
            disabled={!selectedPreference}
            fullWidth
            sx={{ marginTop: spacing(5) }}
          >
            <Paragraph
              sx={{
                color: palette.background.paper,
                textTransform: "none"
              }}
            >
              {t("labels__add")}
            </Paragraph>
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};
