import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useAddToBlacklistMutationEnhanced } from "src/graphql/mutations/enhanced-mutations/add-to-blacklist-enhanced";
import { useUpdateBlacklistProfileMutation } from "src/store/endpoints/user-profile.endpoints";

interface AddProfileToBlacklistProps {
  profileId: number;
  reservationId?: string;
}

export const useBlacklistProfile = () => {
  const { isRESTVersion } = useApiVersion();
  const [addToBlacklist, resultAddToBlacklist] = useAddToBlacklistMutationEnhanced();
  const [updateBlacklistProfile, resultV1] = useUpdateBlacklistProfileMutation();

  const addToBlacklistV1 = ({ profileId, reservationId }: AddProfileToBlacklistProps) => {
    return updateBlacklistProfile({
      profileId: profileId,
      patches: { patches: [{ op: "replace", path: "/blacklisted", value: true }] },
      reservationId
    });
  };

  const addToBlacklistV2 = ({ profileId }: AddProfileToBlacklistProps) => {
    return addToBlacklist({
      userProfileId: profileId
    });
  };

  const functionCall = isRESTVersion ? addToBlacklistV1 : addToBlacklistV2;
  const result = isRESTVersion ? resultV1 : resultAddToBlacklist;

  return [functionCall, result] as
    | [typeof addToBlacklistV1, typeof resultV1]
    | [typeof addToBlacklistV2, typeof resultAddToBlacklist];
};
