import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetUserProfilesQueryVariables = Types.Exact<{
  filter: Types.GenericFilter;
}>;

export type GetUserProfilesQuery = {
  __typename?: "Query";
  GetUserProfiles: {
    __typename?: "UserProfilesPage";
    totalElements: number;
    content: Array<{
      __typename?: "UserProfile";
      id: number;
      numberOfReservations: number;
      isRecurring: boolean;
      guestIdentityId?: number | null;
      title?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userAccountUuid?: string | null;
      preferredLanguage?: string | null;
      nationality?: string | null;
      vipCode?: string | null;
      phone?: string | null;
      email?: string | null;
      blacklisted: boolean;
      preferredCommunicationChannel?: Types.CommunicationChannel | null;
      preferences: Array<{
        __typename?: "Preference";
        pmsId: string;
        pmsPreferenceCategoryId: string;
        name: string;
        description?: string | null;
        pmsPropertyId?: string | null;
      }>;
    }>;
  };
};

export const GetUserProfilesDocument = `
    query GetUserProfiles($filter: GenericFilter!) {
  GetUserProfiles(filter: $filter) {
    content {
      id
      numberOfReservations
      isRecurring
      guestIdentityId
      title
      firstName
      lastName
      userAccountUuid
      preferredLanguage
      nationality
      vipCode
      phone
      email
      blacklisted
      preferredCommunicationChannel
      preferences {
        pmsId
        pmsPreferenceCategoryId
        name
        description
        pmsPropertyId
      }
    }
    totalElements
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetUserProfiles: build.query<GetUserProfilesQuery, GetUserProfilesQueryVariables>({
      query: (variables) => ({ document: GetUserProfilesDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetUserProfilesQuery, useLazyGetUserProfilesQuery } = injectedRtkApi;
