import Paper from "@mui/material/Paper";
import { Grid, useTheme } from "@mui/material";
import {
  EmptyState,
  Heading2,
  Paragraph,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { FC } from "react";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Profile } from "../../types/profile";
import { formatDate } from "src/utils/timezoned-date";
import { useProperty } from "src/hooks/use-property";
import { regionNames } from "../../../reservations/reservation-details/reservation-details-tabs/guest-insights/reservation-details-guest-insight-utils";

interface ProfileDrawerCardProps {
  profileInformation: Profile | undefined | null;
}

export const ProfileDrawerPersonalInformationCard: FC<ProfileDrawerCardProps> = ({
  profileInformation
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const { selectedProperty } = useProperty();
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });

  return (
    <Grid item xs={12}>
      <Paper elevation={0}>
        <Grid container gap={3} p={3}>
          <Grid container justifyContent="space-between" alignItems="top">
            <Grid item>
              <Heading2>{t(`labels__profiles_personal_information`)}</Heading2>
            </Grid>
            {/* Let's add the icon whenever we can edit */}
            {/* <Grid item>
              <IconButton>
                <Edit />
              </IconButton>
            </Grid> */}
          </Grid>
          {profileInformation ? (
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={4}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_title")}
                    </Paragraph>
                  </Grid>
                  <Grid item xs={8}>
                    <Paragraph>
                      {profileInformation.title
                        ? t(`labels__pronouns__${profileInformation.title}`)
                        : ""}
                    </Paragraph>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={4}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_firstName")}
                    </Paragraph>
                  </Grid>
                  <Grid item xs={8}>
                    <Paragraph>{profileInformation.firstName}</Paragraph>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={4}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_lastName")}
                    </Paragraph>
                  </Grid>
                  <Grid item xs={8}>
                    <Paragraph>{profileInformation.lastName}</Paragraph>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={4}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_phoneNumber")}
                    </Paragraph>
                  </Grid>
                  <Grid item xs={8}>
                    <Paragraph>
                      <Paragraph>{profileInformation.phone}</Paragraph>
                    </Paragraph>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={4}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_emailAddress")}
                    </Paragraph>
                  </Grid>
                  <Grid item xs={8}>
                    <Paragraph>{profileInformation.email}</Paragraph>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={4}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_nationality")}
                    </Paragraph>
                  </Grid>
                  <Grid item xs={8}>
                    {profileInformation?.nationality && (
                      <Paragraph>{regionNames.of(profileInformation?.nationality)}</Paragraph>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={4}>
                    <Paragraph sx={{ color: theme.palette.grey[600] }}>
                      {t("labels__profiles_dateOfBirth")}
                    </Paragraph>
                  </Grid>
                  <Grid item xs={8}>
                    <Paragraph>
                      {formatDate(
                        profileInformation.birthdate,
                        selectedProperty?.details?.timeZone ?? ""
                      )}
                    </Paragraph>
                  </Grid>
                </Grid>
              </Grid>
              {features?.licensePlateCheckinWizardEnabled && (
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={4}>
                      <Paragraph sx={{ color: theme.palette.grey[600] }}>
                        {t("labels__profiles_license_plate")}
                      </Paragraph>
                    </Grid>
                    <Grid item xs={8}>
                      <Paragraph>{profileInformation.licensePlate}</Paragraph>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <EmptyState title="No information" />
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};
