export interface Report {
  reservationId: string;
  unitId: string;
  unitGroup: string;
  count: number;
  adults: number;
  children: number;
  performed: boolean;
  date: Date;
  serviceName: string;
  serviceId: string;
  primaryGuestFirstName: string;
  primaryGuestLastName: string;
}

export enum ReportStatus {
  BOOKED = "BOOKED",
  OBTAINED = "OBTAINED"
}
