import React, { FC, ReactElement, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { assignPreviewToFiles, FileWithPreview } from "src/utils/file-utils";

interface TaskUploadProps {
  onChange: (image: File[] | null) => void;
  disabled?: boolean;
  showPreview: boolean;
  inputComponent: ReactElement;
}

// same limit as in BE (just a bit less, to be safe)
const maxFileSize = 7900000;

export const AttachmentsInput: FC<TaskUploadProps> = ({ onChange, disabled, inputComponent }) => {
  const [filesWithPreview, setFilesWithPreview] = useState<FileWithPreview[] | null>([]);

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      if (!acceptedFiles.length) {
        return;
      }

      if (filesWithPreview) {
        // Make sure to revoke the data uris to avoid memory leaks
        filesWithPreview.forEach((fileWithPreview) => {
          URL.revokeObjectURL(fileWithPreview.preview);
        });
      }

      onChange([...acceptedFiles]);
      setFilesWithPreview(assignPreviewToFiles(acceptedFiles));
    },
    [filesWithPreview, onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled,
    useFsAccessApi: false,
    onDrop: onDrop,
    maxSize: maxFileSize,
    onDropRejected: (fileRejections) => {
      if (fileRejections.length > 0 && fileRejections[0].errors.length > 0) {
        console.warn("File upload failed");
      }
    }
  });

  return (
    <section
      {...getRootProps()}
      style={{
        border: isDragActive ? "1px dashed black" : "none",
        margin: isDragActive ? "-1px" : "0"
      }}
    >
      <input {...getInputProps()} />
      {inputComponent}
    </section>
  );
};
