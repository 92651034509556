import { ApiError, ApiErrorJson } from "@likemagic-tech/sv-magic-library";

export const handleSliceError = (
  e: unknown | ApiError,
  rejectWithValue: (value: ApiErrorJson) => any // RejectWithValue
) => {
  if (e instanceof ApiError) {
    return rejectWithValue(e.toJSON());
  }
  return rejectWithValue(e as ApiErrorJson);
};
