import { api } from "../update-registration-card-guests.generated";

const getUpdateRegistrationCardGuestEnhanced = api.enhanceEndpoints({
  endpoints: {
    UpdateRegistrationCardGuests: {
      invalidatesTags: ["ReservationDetails"]
    }
  }
});

export const {
  useUpdateRegistrationCardGuestsMutation: useUpdateRegistrationCardGuestsMutationEnhanced
} = getUpdateRegistrationCardGuestEnhanced;
