import { FC, useCallback, useContext, useMemo } from "react";
import { Box, Card, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Report, ReportStatus } from "../../domain/report";
import { useDispatch, useSelector } from "../../store";
import { markAsPerformed, markAsUnperformed } from "../../slices/dashboard-breakfast";
import { formatDate, pickerDateSelector } from "../../slices/date-picker";
import {
  Heading3,
  ParagraphSmall,
  ParagraphSmallBold,
  TenantContext
} from "@likemagic-tech/sv-magic-library";
import { getUnitType } from "src/utils/tenant-unit-type";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { PerformedServicePostPayload } from "../../domain/performed-service";
import GroupIcon from "@mui/icons-material/Group";
import { useTheme } from "@mui/material/styles";
import { useProperty } from "../../hooks/use-property";

interface BreakfastCardProps {
  report: Report;
  propertyId: string;
}

export const BreakfastCard: FC<BreakfastCardProps> = ({ report, propertyId }) => {
  const dispatch = useDispatch();
  const { theme: selectedTheme } = useContext(TenantContext);
  const theme = useTheme();
  const property = useProperty();
  const { t } = useTranslationWrapper();
  const date = useSelector(pickerDateSelector);

  // Plurals for i18n don't work in our version of TS https://github.com/i18next/i18next/issues/1683
  const displayOccupantsLabel = useMemo(() => {
    return `${report.adults} ${
      /*@ts-ignore*/ t("labels__adult", {
        count: report.adults
      })
    }${
      report.children
        ? ", " +
          report.children +
          " " +
          /*@ts-ignore*/
          t("labels__kid", { count: report.children })
        : ""
    }`;
  }, [t, report]);

  const performAction = useCallback(() => {
    const reportToBeSubmitted: PerformedServicePostPayload = {
      reservationId: report.reservationId,
      serviceId: report.serviceId,
      serviceDate: formatDate(new Date(date), property.selectedProperty?.details.timeZone),
      propertyId
    };

    if (report.performed) {
      dispatch(markAsUnperformed({ reports: [reportToBeSubmitted] }));
    } else {
      dispatch(markAsPerformed({ reports: [reportToBeSubmitted] }));
    }
  }, [dispatch, report, propertyId, date, property.selectedProperty?.details.timeZone]);

  return (
    <Grid item key={report.reservationId} md={4} xs={12}>
      <Card sx={{ py: 3.5, px: 3 }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex"
          }}
        >
          <Grid container direction="row">
            <Heading3>
              {report.unitId
                ? getUnitType(selectedTheme) + " " + report.unitId
                : t("labels__card_breakfast_unit_not_assigned", {
                    reservationId: report.reservationId
                  })}
            </Heading3>
          </Grid>
        </Box>

        {(report.primaryGuestLastName || report.primaryGuestFirstName) && (
          <Box>
            <ParagraphSmall color="text.secondary">
              {[report.primaryGuestFirstName, report.primaryGuestLastName].join(" ")}
            </ParagraphSmall>
          </Box>
        )}

        <Grid container direction="row" alignItems="center">
          <GroupIcon sx={{ color: theme.palette.text.secondary }} fontSize="small" />
          <ParagraphSmall color="text.secondary">{displayOccupantsLabel}</ParagraphSmall>
        </Grid>
        <Box mt={2}>
          <ParagraphSmallBold>
            {report.serviceName + (report.count > 1 ? " x " + report.count : "")}
          </ParagraphSmallBold>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex"
          }}
          pt={2}
        >
          <ToggleButtonGroup
            fullWidth
            exclusive
            aria-label="Platform"
            value={report.performed ? ReportStatus.OBTAINED : ReportStatus.BOOKED}
            onChange={performAction}
          >
            <ToggleButton value={ReportStatus.BOOKED} color="error">
              {t("labels__performed_service_" + ReportStatus.BOOKED)}
            </ToggleButton>
            <ToggleButton value={ReportStatus.OBTAINED} color="success">
              {t("labels__performed_service_" + ReportStatus.OBTAINED)}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Card>
    </Grid>
  );
};
