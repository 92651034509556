import React, { FC, useCallback, useMemo, useState } from "react";
import { DialogModal } from "../../components/submit-modal/dialog";
import { Box, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useUpdateConversationMutationEnhanced } from "../../graphql-messaging/mutations/enhanced-mutations/update-conversation-enhanced";
import { ConversationDetails } from "../domain/conversation";
import { useProperty } from "../../hooks/use-property";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { DialogActionModal } from "../../components/submit-modal/dialog-actions";
import { DialogTitleModal } from "../../components/submit-modal/dialog-title";
import { Select } from "@likemagic-tech/sv-magic-library";

interface EditPropertyModalProps {
  isOpen: boolean;
  onClose: () => void;
  conversation: ConversationDetails;
}

export const EditPropertyModal: FC<EditPropertyModalProps> = ({
  isOpen,
  onClose,
  conversation
}) => {
  const [selectedProperty, setSelectedProperty] = useState<string>(conversation.pmsPropertyId);

  const [updateConversation] = useUpdateConversationMutationEnhanced();
  const { allProperties, getProperty } = useProperty();
  const { t } = useTranslationWrapper();

  const changePropertyAction = useCallback(() => {
    updateConversation({
      conversation: {
        conversationId: conversation.conversationId,
        pmsPropertyId: selectedProperty
      }
    });
    onClose();
  }, [conversation, updateConversation, selectedProperty, onClose]);

  const propertiesOptions = useMemo(() => {
    const options: { label: string; value: string; disabled?: boolean }[] = [];

    options.push({ label: t("labels__no_property"), value: "ACCOUNT", disabled: true });
    allProperties?.map((property) =>
      options.push({
        label: property?.details?.name,
        value: property?.propertyId
      })
    );

    return options;
  }, [allProperties, t]);

  return (
    <DialogModal handleClose={onClose} isOpen={isOpen}>
      <Grid container p={3} flexDirection="column">
        <DialogTitle
          sx={{
            flex: "1 1 0",
            padding: "0",
            justifyContent: "space-between"
          }}
        >
          <DialogTitleModal title={t("labels__edit_property")} onClose={onClose} />
        </DialogTitle>
        <DialogContent sx={{ padding: "0" }}>
          <Box sx={{ marginTop: "24px" }}>
            <Select
              title={"labels__edit_property"}
              options={propertiesOptions}
              value={selectedProperty}
              label={t("labels__edit_property")}
              renderValue={(selectedProperty: string) => {
                if (selectedProperty === "ACCOUNT") {
                  return t("labels__no_property");
                } else {
                  return getProperty(selectedProperty)?.details?.name;
                }
              }}
              id="channel.value"
              name="channel.value"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setSelectedProperty(e.target.value);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ flex: "1 1 0", px: "0" }}>
          <DialogActionModal title={t("labels__save")} handleSubmit={changePropertyAction} />
        </DialogActions>
      </Grid>
    </DialogModal>
  );
};
