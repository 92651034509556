import { Grid, useTheme } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { EmailOutlined } from "@mui/icons-material";
import { Heading1, Paragraph, ParagraphSmallBold } from "@likemagic-tech/sv-magic-library";
import { FC, SyntheticEvent, useState } from "react";
import { ProfileTabs } from "../profile-constants";
import { ProfileDrawerDetails } from "./profile-drawer-details";
import { ProfileDrawerBookings } from "./profile-drawer-bookings";
import { ProfileDrawerTabs } from "./profile-drawer-tabs";
import { ProfileDrawerTransactions } from "./profile-drawer-transactions";
import { ProfileDrawerNotes } from "./profile-drawer-notes";
import { ProfileDrawerMessages } from "./profile-drawer-messages";
import { useIsMobile } from "../../../hooks/use-is-mobile";
import { Profile } from "../types/profile";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { Notification } from "src/components/notification";
import { ProfileActions } from "./profile-actions";
import { CrownSimpleIcon } from "src/icons/CrownSimpleIcon";

interface ProfileDrawerHeaderProps {
  profile?: Profile | null;
}

export const ProfileDrawerHeader: FC<ProfileDrawerHeaderProps> = ({ profile }) => {
  const [value, setValue] = useState<ProfileTabs>(ProfileTabs.DETAILS);
  const isMobile = useIsMobile();
  const theme = useTheme();

  const { t } = useTranslationWrapper();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue as ProfileTabs);
  };

  return (
    <Grid container gap={3}>
      <Grid container gap={2}>
        <Grid item>
          <Grid container direction="row" spacing={3} alignItems="center">
            <Grid item>
              <Heading1>
                {profile?.firstName} {profile?.lastName}
              </Heading1>
            </Grid>
            {profile?.vipCode && (
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <CrownSimpleIcon sx={{ position: "relative", top: theme.spacing(0.25) }} />
                  <ParagraphSmallBold sx={{ color: theme.palette.success.dark }}>
                    {t("labels__VIP")}
                  </ParagraphSmallBold>
                </Grid>
              </Grid>
            )}
            <Grid item>
              <ProfileActions
                profileId={profile?.id ?? 0}
                isBlacklisted={profile?.blacklisted ?? false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container gap={2}>
          {profile?.phone && (
            <Grid item>
              <Grid container gap={1}>
                <WhatsAppIcon />
                <Paragraph>{profile.phone}</Paragraph>
              </Grid>
            </Grid>
          )}
          {!isMobile && profile?.phone && (
            <Grid item>
              <Paragraph>|</Paragraph>
            </Grid>
          )}
          <Grid item>
            {profile?.email && (
              <Grid container gap={1}>
                <EmailOutlined />
                <Paragraph>{profile.email}</Paragraph>
              </Grid>
            )}
          </Grid>
        </Grid>
        {profile?.blacklisted && (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Notification
                fullWidth
                type="error"
                title={t("labels__profile_has_been_blacklisted")}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <ProfileDrawerTabs value={value} handleChange={handleChange} />
        {value === ProfileTabs.DETAILS && <ProfileDrawerDetails profile={profile} />}
        {value === ProfileTabs.RESERVATIONS && <ProfileDrawerBookings profileId={profile?.id} />}
        {value === ProfileTabs.TRANSACTIONS && (
          <ProfileDrawerTransactions profileId={profile?.id} />
        )}
        {value === ProfileTabs.NOTES && <ProfileDrawerNotes notes={profile?.notes ?? []} />}
        {value === ProfileTabs.MESSAGES && (
          <ProfileDrawerMessages guestIdentityId={profile?.guestIdentityId} />
        )}
      </Grid>
    </Grid>
  );
};
