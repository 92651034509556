import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Unit } from "../domain/Unit";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import { ReportsApi } from "../api/report.api";

export const scheduledCleaningAdapter = createEntityAdapter<Unit>({
  selectId: (model) => model.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const initialState = scheduledCleaningAdapter.getInitialState<{
  status: EntityStateStatus;
}>({
  status: EntityStateStatus.IDLE
});

export const fetchScheduledCleaningReport = createAsyncThunk<
  Array<any>,
  { propertyId: string; date: string },
  { state: RootState; rejectValue: { reason: string } }
>("scheduled-cleanings/fetchAll", async (arg, thunkAPI) => {
  return ReportsApi.fetchScheduledCleaningReport(arg.date, arg.propertyId, {
    signal: thunkAPI.signal
  });
});
const slice = createSlice({
  name: "scheduledCleanings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchScheduledCleaningReport.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchScheduledCleaningReport.fulfilled, (state, action) => {
        scheduledCleaningAdapter.setAll(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchScheduledCleaningReport.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.status === EntityStateStatus.LOADING) {
            state.status = EntityStateStatus.IDLE;
          }
          return;
        }
        state.status = EntityStateStatus.FAILED;
      });
  }
});

export const { reducer } = slice;

export const { selectAll: selectScheduledCleaning } =
  scheduledCleaningAdapter.getSelectors<RootState>((state) => state.scheduledCleaning);
