import { api } from "../GetHousekeepingTabCounts.generated";

const getHousekeepingTabCountsEnhanced = api.enhanceEndpoints({
  endpoints: {
    GetHousekeepingTabCounts: {
      providesTags: ["HousekeepingTabCounts"]
    }
  }
});

export const { useGetHousekeepingTabCountsQuery: useGetHousekeepingTabCountsQueryEnhanced } =
  getHousekeepingTabCountsEnhanced;
