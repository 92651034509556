import { Divider, Grid, useTheme } from "@mui/material";
import {
  EmptyState,
  EmptyStateSize,
  ParagraphBold,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import { colorSchemePriority } from "src/features/tasks/task-row";
import React, { FC } from "react";
import { useTaskModal } from "src/features/tasks/use-task-select-id";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useProperty } from "src/hooks/use-property";
import { DisplayTaskIcon } from "src/features/tasks/components/display-task-icon";
import { isBefore } from "date-fns";
import { Task } from "../../../../../tasks/domain/task";
import { Status } from "../../../../../../graphql-tasks/generated/graphql";
import { TaskLinks } from "../../../../../tasks/components/task-links";
import { useUnits } from "../../../../../../hooks/use-unit-by-id";
import { formatDateTime } from "../../../../../../utils/timezoned-date";

interface CardTasksInfoProps {
  tasks: Task[];
}

export const CardTasksInfo: FC<CardTasksInfoProps> = ({ tasks }) => {
  const { spacing, palette } = useTheme();
  const { openEditTask } = useTaskModal({});
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const { data: units } = useUnits({
    propertyId: selectedProperty?.propertyId ?? ""
  });

  const taskDateOverdue = (date: string) => {
    if (date) {
      const currentDate = new Date();
      const taskDate = new Date(date);

      return isBefore(taskDate, currentDate);
    }
  };

  return (
    <>
      {tasks.length > 0 ? (
        tasks.map((task) => (
          <React.Fragment key={task.id}>
            <Grid container direction="column">
              <Grid
                container
                py={2}
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    task.status !== Status.Done && taskDateOverdue(task?.due)
                      ? palette.error.light
                      : "inherit"
                }}
                onClick={() => openEditTask(task.id.toString())}
              >
                <Grid item xs={2} paddingLeft={1}>
                  <DisplayTaskIcon status={task.status} />
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="column">
                    <Grid item>
                      <ParagraphBold>{task.title}</ParagraphBold>
                    </Grid>
                    <Grid item>
                      <TaskLinks task={task} units={units} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <ParagraphSmall>
                    {task.due && formatDateTime(task.due, selectedProperty?.details.timeZone)}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={2}>
                  <Grid container justifyContent="flex-end" paddingRight={1}>
                    <EmojiFlagsIcon
                      sx={{
                        color: `${colorSchemePriority(task.priority)}.dark`,
                        bgcolor: `${colorSchemePriority(task.priority)}.light`,
                        borderRadius: spacing(1)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
          </React.Fragment>
        ))
      ) : (
        <EmptyState title={t("labels__no__tasks__in__reservation")} size={EmptyStateSize.REGULAR} />
      )}
    </>
  );
};
