import React, { FC } from "react";
import { Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Heading4 } from "@likemagic-tech/sv-magic-library";
import { formatPriceToString } from "../../../utils/price";
import { useTheme } from "@mui/material/styles";
import { Price } from "../../../domain/price";

interface TotalPricePreviewProps {
  price: Price;
  label: string;
}

export const TotalPricePreview: FC<TotalPricePreviewProps> = ({ price, label }) => {
  const { shape } = useTheme();

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{ background: grey[100], borderRadius: (shape.borderRadius as number) * 0.25 }}
      p={2.5}
    >
      <Grid item>
        <Heading4>{label}</Heading4>
      </Grid>
      <Grid item>
        <Heading4>{formatPriceToString(price)}</Heading4>
      </Grid>
    </Grid>
  );
};
