import { Box, Dialog as MuiDialog, DialogActions, DialogContent, IconButton } from "@mui/material";
import { ClassNameMap, WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import { makeStyles } from "tss-react/mui";
import withStyles from "@mui/styles/withStyles";
import { Theme } from "@mui/material/styles";
import React, { FC, ReactNode } from "react";

import { Button, Heading4 } from "@likemagic-tech/sv-magic-library";
import CloseIcon from "@mui/icons-material/Close";

export interface DialogProps {
  open: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  content: ReactNode;
  buttonLabel: string;
  buttonDisabled?: boolean;
  title?: string;
  disableBackdropClick?: boolean;
  classNameActions?: string;
  classNameContent?: string;
  classesDialogPaper?: ClassNameMap;
}

const useStylesPaper = makeStyles()(({ spacing, shape }: Theme) => ({
  paper: {
    padding: spacing(1),
    borderRadius: (shape.borderRadius as number) * 2.5
  }
}));

const useStyles = makeStyles()(({ spacing }: Theme) => ({
  content: {
    padding: spacing(2),
    fontSize: 17
  }
}));

const dialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    heading: {
      padding: theme.spacing(1, 5, 1, 0)
    }
  });

export interface DialogTitleProps extends WithStyles<typeof dialogTitleStyles> {
  children: ReactNode;
  onClose?: () => void;
}

const DialogTitle = withStyles(dialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Heading4 className={classes.root} {...other}>
      {children}
      {onClose ? (
        <>
          <Box component="span" ml={4} />
          <IconButton className={classes.closeButton} onClick={() => onClose()} size="large">
            <CloseIcon />
          </IconButton>
        </>
      ) : null}
    </Heading4>
  );
});

export const Dialog: FC<DialogProps> = ({
  onConfirm,
  onDismiss,
  open,
  content,
  buttonLabel,
  buttonDisabled,
  title,
  classNameContent,
  classNameActions,
  classesDialogPaper
}) => {
  const { classes } = useStyles();
  const { classes: classesPaper } = useStylesPaper();
  return (
    <MuiDialog
      onClose={onDismiss}
      open={open}
      classes={classesDialogPaper ?? classesPaper}
      maxWidth="md"
    >
      <DialogTitle onClose={onDismiss}>{title}</DialogTitle>
      <DialogContent className={`${classes.content} ${classNameContent}`}>{content}</DialogContent>
      <DialogActions className={classNameActions}>
        <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center" py={1}>
          <Button variant="primary" onClick={onConfirm} fullWidth disabled={buttonDisabled}>
            {buttonLabel}
          </Button>
        </Box>
      </DialogActions>
    </MuiDialog>
  );
};
