import * as Types from "../generated/graphql";

import { tasksApi } from "../tasks.api";
export type ForecastQueryVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
}>;

export type ForecastQuery = {
  __typename?: "Query";
  Forecast: Array<{
    __typename?: "ForecastOutput";
    date: any;
    departureCleaningCount: number;
    stayoverCleaningCount: number;
    serviceTaskCounts: any;
    total: number;
  }>;
};

export const ForecastDocument = `
    query Forecast($pmsPropertyId: String!) {
  Forecast(pmsPropertyId: $pmsPropertyId) {
    date
    departureCleaningCount
    stayoverCleaningCount
    serviceTaskCounts
    total
  }
}
    `;

const injectedRtkApi = tasksApi.injectEndpoints({
  endpoints: (build) => ({
    Forecast: build.query<ForecastQuery, ForecastQueryVariables>({
      query: (variables) => ({ document: ForecastDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useForecastQuery, useLazyForecastQuery } = injectedRtkApi;
