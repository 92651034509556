import { BaseApiClient, JSONApiResponse, VoidApiResponse } from "@likemagic-tech/sv-magic-library";
import { AvailableDoor } from "../domain/available-door";

const BASE_URL = "/api/guest-journey-service";

class DoorDelegateApiClient extends BaseApiClient {
  async postDoorAccess(reservationId: string, doorType: DoorType, init?: RequestInit) {
    const response = await this.fetchApi(
      `${BASE_URL}/door-access-delegate/${reservationId}/door-access?status=${doorType}`,
      {
        ...init,
        method: "POST"
      }
    );
    return response.ok;
  }

  async availableDoors(propertyId: String, init?: RequestInit) {
    const response = await this.fetchApi(
      `${BASE_URL}/door-access-delegate/admin/${propertyId}/available-doors`,
      init
    );
    return new JSONApiResponse<AvailableDoor[]>(response).value();
  }
  async supportedSyncStates(propertyId: String, init?: RequestInit) {
    const response = await this.fetchApi(
      `${BASE_URL}/door-access-delegate/admin/${propertyId}/supported-sync-states`,
      init
    );
    return new JSONApiResponse<DoorType[]>(response).value();
  }

  async postOpenDoor(propertyId: String, doorId: string) {
    const response = await this.fetchApi(
      `${BASE_URL}/door-access-delegate/admin/${propertyId}/open/${doorId}`,
      {
        method: "POST"
      }
    );
    return new VoidApiResponse(response).value();
  }

  async encodeKey(reservationId: String, encoderId: string, init?: RequestInit) {
    const response = await this.fetch(
      `${BASE_URL}/door-access-delegate/${reservationId}/encode-key/${encoderId}`,
      {
        ...init,
        method: "POST"
      }
    );
    return new VoidApiResponse(response).value();
  }
}

export enum DoorType {
  NONE = "NONE",
  PUBLIC = "PUBLIC",
  PUBLIC_INHOUSE = "PUBLIC_INHOUSE",
  PUBLIC_AND_PRIVATE = "PUBLIC_AND_PRIVATE"
}

export const DoorDelegateApi = new DoorDelegateApiClient();
