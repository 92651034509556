import { VoidApiResponse } from "@likemagic-tech/sv-magic-library";
import { ApiKeyApiClient } from "./base/api-key-api-client";
import { PerformedServicePostPayload } from "../domain/performed-service";

const BASE_URL = "/api/guest-journey-service/performed-service";

class PerformedServiceApiClient extends ApiKeyApiClient {
  async markAsPerformed(reports: PerformedServicePostPayload[], init?: RequestInit) {
    const response = await this.fetchApi(`${BASE_URL}/perform`, {
      ...init,
      method: "POST",
      body: JSON.stringify(reports)
    });
    return new VoidApiResponse(response).value();
  }
  async markAsUnperformed(reports: PerformedServicePostPayload[], init?: RequestInit) {
    const response = await this.fetchApi(`${BASE_URL}/unperform`, {
      ...init,
      method: "POST",
      body: JSON.stringify(reports)
    });
    return new VoidApiResponse(response).value();
  }
}

export const PerformedServiceApi = new PerformedServiceApiClient();
