import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type MoveOrderItemsMutationVariables = Types.Exact<{
  moveOrderItemsInput: Types.MoveOrderItemsInput;
}>;

export type MoveOrderItemsMutation = { __typename?: "Mutation"; MoveOrderItems?: boolean | null };

export const MoveOrderItemsDocument = `
    mutation MoveOrderItems($moveOrderItemsInput: MoveOrderItemsInput!) {
  MoveOrderItems(moveOrderItemsInput: $moveOrderItemsInput)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    MoveOrderItems: build.mutation<MoveOrderItemsMutation, MoveOrderItemsMutationVariables>({
      query: (variables) => ({ document: MoveOrderItemsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useMoveOrderItemsMutation } = injectedRtkApi;
