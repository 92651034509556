import { Grid, useTheme } from "@mui/material";
import { Button, Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { Check, WarningAmberOutlined } from "@mui/icons-material";
import { HousekeepingTaskComponent } from "../housekeeping-task-component";
import { HousekeepingTaskType } from "../../../features/tasks/domain/task";
import { HousekeepingCardType } from "../housekeeping-card/housekeeping-card";
import { TaskType } from "../../../graphql-tasks/generated/graphql";
import { ModalWithPropActions } from "../../../components/submit-modal/modal-with-prop-actions";

interface HousekeepingModalProps {
  housekeepingCard: HousekeepingCardType;
  handleUpdateHousekeepingCard: (values: HousekeepingTaskType) => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  handleOnMarkAsDone: () => void;
}
export const HousekeepingModal: FC<HousekeepingModalProps> = ({
  housekeepingCard,
  handleUpdateHousekeepingCard,
  isModalOpen,
  setIsModalOpen,
  handleOnMarkAsDone
}) => {
  const { t } = useTranslationWrapper();
  const { spacing, palette } = useTheme();

  const leftoverTasks = useMemo(() => {
    return housekeepingCard?.unit?.housekeepingTasks?.filter(
      (task) => task.type === TaskType.AutomatedTask || task.type === TaskType.ManualTask
    );
  }, [housekeepingCard]);

  return (
    <ModalWithPropActions
      isOpen={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      title={`${t("labels__unit")} ${housekeepingCard?.unit?.name ?? ""}`}
      content={
        <Grid container gap={spacing(3)}>
          <Grid container>
            <Paragraph>{t("labels__housekeeping_modal_unfinished_tasks")}</Paragraph>
          </Grid>
          <Grid container gap={2}>
            <Grid container justifyContent="space-between" alignItems="baseline" gap={1}>
              <Grid container>
                <ParagraphSmall>{t("labels__additional_tasks")}</ParagraphSmall>
              </Grid>
              <Grid container gap={1}>
                {leftoverTasks?.map((task) => (
                  <HousekeepingTaskComponent
                    key={task.id}
                    task={task}
                    handleUpdateHousekeepingCard={handleUpdateHousekeepingCard}
                    enableEditTaskDescription
                  />
                ))}
              </Grid>
            </Grid>
            {!!leftoverTasks?.length && (
              <Grid
                container
                sx={{ backgroundColor: palette.warning.light, color: palette.warning.dark }}
                justifyContent="space-between"
                py={spacing(1)}
                px={spacing(1.5)}
                borderRadius={spacing(1)}
                alignItems="center"
              >
                <ParagraphSmall color={palette.warning.dark}>
                  {t("labels__these_will_become_leftover_tasks")}
                </ParagraphSmall>
                <WarningAmberOutlined />
              </Grid>
            )}
            <Grid container gap={2}>
              <Button
                variant="secondary"
                fullWidth
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                {t("labels__cancel")}
              </Button>
              <Button
                variant="primary"
                color="success"
                fullWidth
                startIcon={<Check />}
                onClick={handleOnMarkAsDone}
              >
                {t("buttons__mark_as_done")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};
