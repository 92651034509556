import { createAsyncThunk, createSelector, createSlice, Selector } from "@reduxjs/toolkit";
import { ApiErrorJson } from "@likemagic-tech/sv-magic-library";
import { EntityStateStatus, isStatusLoading } from "src/domain/EntityStateStatus";
import { RootState } from "../store";
import { UserInfoApi } from "../api/user-info.api";

type UserInfoState = {
  userInfoLanguageUpdate: EntityStateStatus;
};

const initialState: UserInfoState = {
  userInfoLanguageUpdate: EntityStateStatus.IDLE
};

export const updateUserInfoLanguage = createAsyncThunk<
  any,
  { lang: string },
  { state: RootState; rejectValue: ApiErrorJson }
>("userInfo/updateLanguage", async (arg, thunkAPI) => {
  return await UserInfoApi.updateLanguage(arg.lang, {
    signal: thunkAPI.signal
  });
});

export const userInfoSLice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateUserInfoLanguage.pending, (state) => {
      state.userInfoLanguageUpdate = EntityStateStatus.LOADING;
    });
    builder.addCase(updateUserInfoLanguage.fulfilled, (state) => {
      state.userInfoLanguageUpdate = EntityStateStatus.SUCCEEDED;
    });
    builder.addCase(updateUserInfoLanguage.rejected, (state) => {
      state.userInfoLanguageUpdate = EntityStateStatus.FAILED;
    });
  }
});

export const selectUserInfoSlice: Selector<RootState, UserInfoState> = (state: RootState) =>
  state[userInfoSLice.name];

export const selectIsUserInfoLanguageUpdating = createSelector(selectUserInfoSlice, (state) =>
  isStatusLoading(state.userInfoLanguageUpdate)
);
