import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetProfileNoteCategoriesQueryVariables = Types.Exact<{
  pmsPropertyIds?: Types.InputMaybe<
    Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]
  >;
}>;

export type GetProfileNoteCategoriesQuery = {
  __typename?: "Query";
  GetProfileNoteCategories: Array<{
    __typename?: "ProfileNoteCategory";
    pmsId: string;
    name?: string | null;
  }>;
};

export const GetProfileNoteCategoriesDocument = `
    query GetProfileNoteCategories($pmsPropertyIds: [String!]) {
  GetProfileNoteCategories(pmsPropertyIds: $pmsPropertyIds) {
    pmsId
    name
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetProfileNoteCategories: build.query<
      GetProfileNoteCategoriesQuery,
      GetProfileNoteCategoriesQueryVariables | void
    >({
      query: (variables) => ({ document: GetProfileNoteCategoriesDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetProfileNoteCategoriesQuery, useLazyGetProfileNoteCategoriesQuery } =
  injectedRtkApi;
