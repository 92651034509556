import { BaseApiClient, VoidApiResponse } from "@likemagic-tech/sv-magic-library";

const BASE_URL = "/api/guest-journey-service/magic";

class MagicApiClient extends BaseApiClient {
  async openDoor(doorId: string, magicId: string, magicToken: string, init?: RequestInit) {
    const response = await this.fetch(BASE_URL + `/${magicId}/doors/${doorId}/open`, {
      ...init,
      method: "POST",
      headers: {
        "sk-magic-token": magicToken
      }
    });
    return new VoidApiResponse(response).value();
  }
}

export const MagicApi = new MagicApiClient();
