import { Box, Grid, useTheme } from "@mui/material";
import { AssignmentIndOutlined, Check, Edit } from "@mui/icons-material";
import { Button, Chip, ParagraphBold, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import * as React from "react";
import { FC, useCallback } from "react";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { HousekeepingTableType } from "./housekeeping-task-table";
import BedroomParentOutlinedIcon from "@mui/icons-material/BedroomParentOutlined";
import { Status } from "../../graphql-tasks/generated/graphql";
import { HousekeepingTaskType, TaskPriority } from "../../features/tasks/domain/task";
import { colorSchemePriority } from "../../features/tasks/task-row";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import { useTaskModal } from "../../features/tasks/use-task-select-id";
import { convert } from "html-to-text";

interface HousekeepingTaskCardProps {
  housekeepingTableType: HousekeepingTableType;
  task: HousekeepingTaskType;
  handleUpdate: (values: HousekeepingTaskType) => Promise<void>;
}
export const HousekeepingTaskCard: FC<HousekeepingTaskCardProps> = ({
  housekeepingTableType,
  task,
  handleUpdate
}) => {
  const { palette, spacing } = useTheme();
  const { t } = useTranslationWrapper();
  const { openEditTask } = useTaskModal({});

  const priorityChipDisplay = (priority: TaskPriority) => {
    const color = colorSchemePriority(priority);
    const accentChipProps = {
      backgroundColor: palette.accent.light,
      color: palette.accent.dark,
      "& .MuiChip-icon": {
        color: palette.accent.dark
      }
    };

    return (
      <Chip
        size="small"
        leftIcon={<EmojiFlagsIcon />}
        sx={priority === TaskPriority.Urgent ? accentChipProps : {}}
        color={color}
        label={t(`labels__additional_filter_${priority}`)}
      />
    );
  };

  const handleOnMarkAsDone = useCallback(() => {
    const { parent, ...rest } = task;

    handleUpdate({
      ...rest,
      status: Status.Done
    });
  }, [task, handleUpdate]);

  return (
    <Grid
      container
      p={spacing(2)}
      gap={spacing(1.5)}
      borderBottom={`1px solid ${palette.grey[300]}`}
    >
      <Grid container gap={spacing(1)} alignItems="center">
        <AssignmentIndOutlined />
        <ParagraphSmall>{t("labels__housekeeping")}</ParagraphSmall>
      </Grid>
      <Grid container flexDirection="column">
        <ParagraphBold>{task.title}</ParagraphBold>
        <ParagraphSmall>{convert(task.description ?? "")}</ParagraphSmall>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center">
        {housekeepingTableType === HousekeepingTableType.ADDITIONAL ? (
          priorityChipDisplay(task.priority)
        ) : (
          <Box display="flex" gap={spacing(0.5)} alignItems="center">
            <BedroomParentOutlinedIcon />
            <ParagraphSmall>
              {task?.links?.units?.[0]?.name}, {t("labels__floor")} {task?.links?.units?.[0]?.floor}
            </ParagraphSmall>
          </Box>
        )}
        <Button
          size="small"
          variant="ghost"
          sx={{ gap: spacing(1) }}
          onClick={() => openEditTask(String(task.id))}
        >
          <Edit />
          <ParagraphSmall>{t("labels__edit")}</ParagraphSmall>
        </Button>
      </Grid>
      <Grid container>
        <Button fullWidth color="success" sx={{ gap: spacing(1) }} onClick={handleOnMarkAsDone}>
          <Check />
          {t("buttons__mark_as_done")}
        </Button>
      </Grid>
    </Grid>
  );
};
