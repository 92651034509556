import { FC, useEffect } from "react";

import { AppDetailsContent } from "../utils/meta-update";
import { useLocation } from "react-router-dom";
import { getStartUrl } from "../utils/routing";

/**
 * This component requires <link rel="manifest" id="manifest-link"/> to be in index.html
 */
export const Manifest: FC = () => {
  const content = AppDetailsContent;

  const { pathname } = useLocation();
  let startUrl = getStartUrl(pathname);

  useEffect(() => {
    const manifestLink = document.getElementById("manifest-link");

    const myDynamicManifest = {
      short_name: content?.SHORT_NAME,
      name: content?.NAME,
      icons: content?.ICONS,
      display: "standalone",
      start_url: startUrl,
      theme_color: "#000000",
      background_color: "#ffffff"
    };

    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);

    manifestLink?.setAttribute("href", manifestURL);
  }, [content, startUrl]);

  return null;
};
