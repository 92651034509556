import { api } from "../UpdateConversation.generated";

const getConversationDetailsEnhanced = api.enhanceEndpoints({
  addTagTypes: ["ConversationDetails"],
  endpoints: {
    UpdateConversation: {
      invalidatesTags: (result, error, arg) => [
        { id: arg.conversation.conversationId, type: "ConversationDetails" }
      ]
    }
  }
});

export const { useUpdateConversationMutation: useUpdateConversationMutationEnhanced } =
  getConversationDetailsEnhanced;
