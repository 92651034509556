import React, { FC, useCallback, useMemo } from "react";
import { PaymentComponentProps } from "../payment-component";
import { useSelector } from "../../../../store";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Alert, Box, CircularProgress, useTheme } from "@mui/material";
import { Button, ParagraphSmall, Select } from "@likemagic-tech/sv-magic-library";
import { PricePicker } from "../../../../components/price-picker/price-picker";
import { Form, Formik } from "formik";
import { useFormValidations } from "../../../../hooks/use-form-validation";
import { InfoIcon } from "../../../../icons/info.icon";
import { selectLastSelectedTerminalId } from "../../../../slices/recently-used-devices.slice";
import { useTerminalPayment } from "../hooks/use-terminal-payment";

export const PaymentTerminalComponent: FC<PaymentComponentProps> = ({
  paymentOption,
  onSuccess,
  magicId,
  onFailed,
  price,
  propertyId,
  folioMetadata,
  closeModal
}) => {
  const { t } = useTranslationWrapper();
  const { isLoading, isCancelAvailable, initiateTerminalPayment } = useTerminalPayment();
  const { terminalPaymentValidations } = useFormValidations();
  const theme = useTheme();
  const lastSelectedSecondScreenId = useSelector(selectLastSelectedTerminalId);

  const listOfTerminalIds = useMemo(
    () =>
      paymentOption?.__typename === "TerminalPaymentMethod"
        ? paymentOption?.availableTerminals?.map((item) => ({
            label: item?.name ?? "",
            value: item?.id ?? ""
          })) ?? []
        : [],
    [paymentOption]
  );

  const terminalIdMap = useMemo(
    () =>
      listOfTerminalIds.reduce((acc: Record<string, { label: string; value: string }>, item) => {
        acc[item.value] = item;
        return acc;
      }, {}),
    [listOfTerminalIds]
  );

  const initialValues = useMemo(() => {
    return {
      paymentTerminalId: lastSelectedSecondScreenId ?? undefined,
      price: {
        ...price,
        amount: price.amount / 100
      }
    };
  }, [lastSelectedSecondScreenId, price]);

  const handleSubmit = useCallback(
    (values: { paymentTerminalId?: string; price: { amount: number; currency: string } }) => {
      if (propertyId && values.paymentTerminalId) {
        initiateTerminalPayment(
          {
            selectedTerminal: terminalIdMap[values!.paymentTerminalId],
            price: values.price,
            magicId,
            propertyId,
            folioMetadata
          },
          onSuccess,
          onFailed,
          closeModal
        );
      } else {
        onFailed();
      }
    },
    [
      propertyId,
      magicId,
      folioMetadata,
      terminalIdMap,
      onFailed,
      onSuccess,
      closeModal,
      initiateTerminalPayment
    ]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={terminalPaymentValidations}
    >
      {(formik) => (
        <Form id="terminal-payment">
          {isLoading && (
            <Box sx={{ position: "absolute", zIndex: 10, left: 0, right: 0 }}>
              <div style={{ margin: "auto", width: 56 }}>
                <CircularProgress size={88} thickness={5} />
              </div>
            </Box>
          )}
          <Box py={1}>
            <Select
              id="paymentTerminalId"
              name="paymentTerminalId"
              variant="outlined"
              options={listOfTerminalIds}
              value={formik.values.paymentTerminalId}
              disabled={isLoading}
              label={t("labels__terminal_id")}
              onChange={formik.handleChange}
              error={formik.touched.paymentTerminalId ? !!formik.errors.paymentTerminalId : false}
            />
          </Box>
          <Box py={1}>
            <PricePicker
              prefix="price"
              value={formik.values.price}
              onChange={formik.handleChange}
              disabled={isLoading}
            />
          </Box>
          {formik.values.price.amount < 0 && (
            <Alert
              icon={<InfoIcon sx={{ color: theme.palette.info.main }} />}
              sx={{
                alignItems: "center",
                backgroundColor: theme.palette.info.light,
                mb: 2,
                mt: 1
              }}
            >
              <ParagraphSmall>{t("labels_make_unreferenced_negative_payment")}</ParagraphSmall>
            </Alert>
          )}
          <Box textAlign="center" mt={1}>
            {isLoading ? (
              <Button
                sx={{ width: "50%" }}
                type="submit"
                variant="primary"
                disabled={isCancelAvailable}
              >
                {t("buttons__cancel")}
              </Button>
            ) : (
              <Button
                sx={{ width: "50%" }}
                type="submit"
                variant="primary"
                disabled={!formik.values.paymentTerminalId}
              >
                {t("buttons__choose")}
              </Button>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
