import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useSetPreferredCleaningDaysMutation } from "src/store/endpoints/reservation-table.endpoints";

interface SetPreferredCleaningDaysProps {
  reservationId: string;
  payload: any;
}

export const useSetPreferredCleaningDays = () => {
  const { isRESTVersion } = useApiVersion();
  const [setPreferredCleaningDays, resultV1] = useSetPreferredCleaningDaysMutation();

  const setPreferredCleaningDaysV1 = ({
    reservationId,
    payload
  }: SetPreferredCleaningDaysProps) => {
    return setPreferredCleaningDays({ reservationId, payload });
  };

  const setPreferredCleaningDaysV2 = () => {
    return Promise.reject("not yet implemented");
  };

  const functionCall = isRESTVersion ? setPreferredCleaningDaysV1 : setPreferredCleaningDaysV2;
  const result = isRESTVersion ? resultV1 : [];

  return [functionCall, result] as [typeof setPreferredCleaningDaysV1, typeof resultV1];
};
