import React, { FC } from "react";
import { Grid } from "@mui/material";
import { Input, NativeSelect, useCountries } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { SelectProps } from "@likemagic-tech/sv-magic-library/dist/components/select/select.types";
import { getI18nSelectedLanguage } from "../../../../utils/timezoned-date";
import { useProperty } from "../../../../hooks/use-property";

interface ManualWizardAddressFormProps {
  handleChange: (e: any) => void;
  handleBlur: (e: any) => void;
  values: any;
  errors: any;
  touched: any;
  genderOptions: SelectProps["options"];
  disabled?: boolean;
}
export const ManualWizardAddressForm: FC<ManualWizardAddressFormProps> = ({
  handleChange,
  handleBlur,
  touched,
  errors,
  values,
  genderOptions,
  disabled
}) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const { sortedCountries } = useCountries({ propertyId: selectedProperty?.propertyId });

  return (
    <>
      <Grid item xs={12} md={6}>
        <Input
          id={"mainGuest.addressLine1"}
          name={"mainGuest.addressLine1"}
          value={values.mainGuest.addressLine1 || ""}
          type="address"
          label={t("labels__wizard_address_line_1")}
          error={touched.mainGuest?.addressLine1 && errors.mainGuest?.addressLine1}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          autoComplete="nope"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          id={"mainGuest.addressLine2"}
          name={"mainGuest.addressLine2"}
          value={values.mainGuest.addressLine2 || ""}
          type="address"
          label={t("labels__wizard_address_line_2")}
          error={touched.mainGuest?.addressLine2 && errors.mainGuest?.addressLine2}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          autoComplete="nope"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          id={"mainGuest.city"}
          name={"mainGuest.city"}
          value={values.mainGuest.city || ""}
          label={t("labels__wizard_suburb")}
          error={touched.mainGuest?.city && errors.mainGuest?.city}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          autoComplete="nope"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          id={"mainGuest.region"}
          name={"mainGuest.region"}
          value={values.mainGuest.region || ""}
          label={t("labels__wizard_state")}
          error={touched.mainGuest?.region && errors.mainGuest?.region}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          autoComplete="nope"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          id={"mainGuest.postcode"}
          name={"mainGuest.postcode"}
          value={values.mainGuest.postcode || ""}
          type="number"
          label={t("labels__wizard_postcode")}
          error={touched.mainGuest?.postcode && errors.mainGuest?.postcode}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          autoComplete="nope"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NativeSelect
          items={sortedCountries(getI18nSelectedLanguage())}
          id={"mainGuest.country"}
          name={"mainGuest.country"}
          value={values.mainGuest.country || ""}
          label={t("labels__wizard_country")}
          error={touched.mainGuest?.country && errors.mainGuest?.country}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          disabled={disabled}
        />
      </Grid>
    </>
  );
};
