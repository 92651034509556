import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function CheckboxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
      <mask id="mask0_18866_110183" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_18866_110183)">
        <path
          d="M7.1627 13.4992L2.8877 9.22422L3.95645 8.15547L7.1627 11.3617L14.0439 4.48047L15.1127 5.54922L7.1627 13.4992Z"
          fill="#077050"
        />
      </g>
    </SvgIcon>
  );
}
