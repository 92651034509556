import { FC, PropsWithChildren } from "react";
import { Grid } from "@mui/material";

export const ProfileDrawerCards: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Grid container pt={3} gap={3}>
      {children}
    </Grid>
  );
};
