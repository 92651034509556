import { FC, PropsWithChildren, useContext } from "react";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { Provider as ReduxProvider } from "react-redux/es/exports";
import { setupStore } from "../../store";
import { PersistGate } from "redux-persist/integration/react";

export const InjectContextTenantIntoRedux: FC<PropsWithChildren> = ({ children }) => {
  const { apiKey, tenantId, keycloak } = useContext(TenantContext);

  const store = setupStore({ apiKey: apiKey ?? "", tenantId, keycloak }, {});

  return (
    <ReduxProvider store={store.store}>
      <PersistGate persistor={store.persistor}>{children}</PersistGate>
    </ReduxProvider>
  );
};
