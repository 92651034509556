import { FC, useEffect, useState } from "react";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTheme } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { ChatItemContentV2BaseProps } from "./domain/chat-item-content-v2-base-props";
import { fetchMessageAttachment } from "../../../slices/messaging-attachment.slice";
import { useDispatch } from "../../../store";

interface ChatItemContentProps extends ChatItemContentV2BaseProps {}

export const ChatItemContentV2Image: FC<ChatItemContentProps> = ({
  content,
  isLoggedUser,
  mediaUrl
}) => {
  const { palette } = useTheme();
  const [birdAttachment, setBirdAttachment] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (mediaUrl) {
      dispatch(fetchMessageAttachment({ url: mediaUrl }))
        .unwrap()
        .then((blobString: string) => setBirdAttachment(blobString));
    }
  }, [mediaUrl, dispatch]);

  return (
    <>
      {mediaUrl && !birdAttachment ? (
        <CircularProgress />
      ) : (
        <img
          alt={content?.text ? content?.text : content?.images[0].altText}
          width="100%"
          src={birdAttachment}
        />
      )}
      <Paragraph
        sx={{
          color: isLoggedUser ? palette.background.paper : palette.primary.main
        }}
      >
        {content?.text ? content?.text : content?.images[0].altText}
      </Paragraph>
    </>
  );
};
