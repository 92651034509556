import { DoorProvider } from "@likemagic-tech/sv-magic-library";

const doorProvidersWithAdminDoorsAvailable = [
  DoorProvider.SALTO,
  DoorProvider.SALTO_SPACE_MOBILE_KEY,
  DoorProvider.SALTO_SPACE_ONLINE,
  DoorProvider.OFFLINE_PIN,
  DoorProvider.GLUTZ,
  DoorProvider.BURGWACHTER,
  DoorProvider.BURGWACHTER_LEGACY,
  DoorProvider.NUKI
];

const doorProvidersWithRemoteKeysAvailable = [
  DoorProvider.SALTO,
  DoorProvider.SALTO_SPACE_ONLINE,
  DoorProvider.FOUR_SUITES,
  DoorProvider.HAFELE,
  DoorProvider.OFFLINE_PIN,
  DoorProvider.GLUTZ,
  DoorProvider.BURGWACHTER,
  DoorProvider.BURGWACHTER_LEGACY,
  DoorProvider.NUKI
];

export const areRemoteKeysAvailable = (doorProvider: DoorProvider | undefined) =>
  doorProvidersWithRemoteKeysAvailable.some((item) => item === doorProvider);

export const areAdminKeysAvailable = (doorProvider: DoorProvider | undefined) =>
  doorProvidersWithAdminDoorsAvailable.some((item) => item === doorProvider);
