import { Grid, Skeleton } from "@mui/material";
import React, { FC } from "react";
import { AlwaysScrollToBottom } from "../../components/always-scroll-to-bottom/always-scroll-to-bottom";
import { useIsMobile } from "../../hooks/use-is-mobile";

interface ChatItemPlaceholderProps {
  conversationId: string | undefined;
}
export const ChatItemPlaceholder: FC<ChatItemPlaceholderProps> = ({ conversationId }) => {
  const isMobile = useIsMobile();

  return (
    <Grid container pt={1} direction={"row-reverse"}>
      {!isMobile && (
        <Grid item display={"flex"} justifyContent={"end"} pl={1}>
          <Skeleton
            variant="circular"
            sx={{
              height: 40,
              width: 40,
              p: 2
            }}
          />
        </Grid>
      )}
      <Grid item md={7} lg={9} xl={7} xs={11}>
        <Skeleton variant="rectangular" height={150} sx={{ borderRadius: 1 }}></Skeleton>
      </Grid>
      <Grid item xs={11} pt={1}>
        <Grid container alignItems="center" justifyContent={"end"}>
          <Skeleton variant={"text"} width={270} height={35} />
          {!isMobile && <Grid item xs={1}></Grid>}
        </Grid>
      </Grid>
      <AlwaysScrollToBottom id={conversationId ?? ""} />
    </Grid>
  );
};
