import { grey } from "@mui/material/colors";
import { ThemeOptions } from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    accent: true;
    primary: true;
    secondary: true;
    success: true;
    warning: true;
    error: true;
    disabled: true;
  }
}

declare module "@mui/material/Badge" {
  interface BadgePropsVariantOverrides {
    secondary: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    accent: Palette["primary"];
  }

  interface PaletteOptions {
    accent?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    accent: true;
  }
}

declare module "@mui/material/IconButton" {
  interface ButtonPropsColorOverrides {
    accent: true;
  }
}
export const buttonGeneralStyle = {
  height: 48,
  fontSize: 14,
  fontWeight: 700,
  boxShadow:
    "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;"
};

const defaultOpacity = 0.38;

export const overrideStyleComponents: (themeName: string) => ThemeOptions = () => ({
  palette: {
    accent: {
      main: "#0000DC",
      dark: "#0000DC",
      light: "#EBEBFF"
    }
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          borderRadius: theme.spacing(2),
          margin: theme.spacing(1)
        })
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: grey[300]
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(2)
        })
      }
    },
    MuiSpeedDial: {
      styleOverrides: {
        fab: ({ theme }) => ({
          borderRadius: theme.spacing(2.5),
          backgroundColor: theme.palette.accent.main
        })
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          borderRadius: theme.spacing(2),
          boxShadow: theme.shadows[2]
        })
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }) => ({
          borderRadius: theme.spacing(2),
          boxShadow: theme.shadows[2]
        })
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(2),
          "&.Mui-disabled": { background: theme.palette.grey["200"] }
        })
      }
    },

    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1)
        })
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": { opacity: defaultOpacity }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1),
          borderRadius: theme.spacing(1)
        }),
        bar: ({ theme }) => ({
          borderRadius: theme.spacing(1)
        }),
        bar2Buffer: {
          backgroundColor: grey.A200
        }
      }
    },
    MuiBadge: {
      variants: [
        {
          props: { variant: "secondary" },
          style: ({ theme }) => ({
            "& .BaseBadge-badge": {
              color: theme.palette.accent.main,
              border: `1px solid ${theme.palette.accent.main}`,
              textAlign: "center",
              backgroundColor: theme.palette.common.white
            }
          })
        }
      ],
      styleOverrides: {
        badge: ({ theme }) => ({
          padding: 2,
          height: 24,
          width: 24,
          borderRadius: 24,
          zIndex: 1051,
          backgroundColor: theme.palette.accent.main,
          color: theme.palette.common.white,
          top: 4,
          right: 4
        })
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: grey[300]
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary
        })
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": { opacity: defaultOpacity }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: `0px 0px 1px 1px ${theme.palette.grey[300]}`
        })
      }
    }
  }
});
