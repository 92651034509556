import { HeadCell } from "../../components/table/enhanced-table-head";
import { Conversation } from "../domain/conversation";
import { useIsMobile } from "../../hooks/use-is-mobile";

export const useConversationsHeadCells = () => {
  const headCells: HeadCell<Conversation>[] = [
    {
      // INFO: This will sort by platform on BE, we do not need it on FE
      // @ts-ignore
      id: "platform",
      label: "labels__channel"
    },
    {
      id: "conversationId",
      label: "labels__message",
      // INFO: Agreed with Cyril that this is the only thing that should not be sortable, as it is confusing.
      disableSort: true
    },
    {
      id: "pmsPropertyId",
      label: "labels__property"
    },
    {
      id: "assignee",
      label: "labels__assignee"
    },
    {
      id: "status",
      label: "labels__status"
    },
    {
      id: "lastReceivedMessage",
      label: "labels__lastMessage"
    },
    // INFO: This is a column for messaging actions
    {
      id: "__typename",
      label: "",
      disableSort: true
    }
  ];

  const mobileHeadCells: HeadCell<Conversation>[] = [
    {
      // INFO: Here I disabled sorting so we have more space on mobile
      // @ts-ignore
      id: "platform",
      label: "labels__channel",
      disableSort: true
    },
    {
      id: "conversationId",
      label: "labels__message",
      // INFO: Agreed with Cyril that this is the only thing that should not be sortable, as it is confusing.
      disableSort: true
    },
    {
      id: "lastReceivedMessage",
      label: "labels__lastMessage"
    }
  ];

  return useIsMobile() ? mobileHeadCells : headCells;
};
