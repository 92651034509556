import { FC, useCallback, useContext, useRef } from "react";
import { Box } from "@mui/material";

import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { useProperty } from "../../hooks/use-property";
import { SupportIcon } from "../../icons/SupportIcon";

export const SupportPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { urls } = useContext(TenantContext);
  const { selectedProperty } = useProperty();
  const onClick = useCallback(() => {
    window.open(
      urls.magicSupportUrl.replace("{{property}}", selectedProperty?.details?.name ?? ""),
      "_blank"
    );
  }, [urls, selectedProperty?.details?.name]);
  if (!urls.magicSupportUrl || !selectedProperty?.details?.name) {
    return <></>;
  }
  return (
    <Box
      onClick={onClick}
      ref={anchorRef}
      sx={{
        alignItems: "center",
        display: "flex",
        color: "common.white"
      }}
    >
      <SupportIcon />
    </Box>
  );
};
