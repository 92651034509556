import { Caption, ParagraphBold, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Grid, IconButton, Tooltip, useTheme } from "@mui/material";
import { FC, useState } from "react";
import { formatDateTime, getI18nSelectedLanguage } from "src/utils/timezoned-date";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { DisplayNotificationContact, DisplayResendButton } from "./notification-card-info-utils";
import { NotificationResendModal } from "./notification-resend-modal";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { AllowedActionEnum, SentNotifications } from "src/domain/reservation-table-dto";
import { ReservationDetailsDTO } from "../../../../../../../store/endpoints/reservation-table.endpoints";
import { useConversationSelectId } from "../../../../../../../messaging/use-conversation-select-id";
import { useProperty } from "src/hooks/use-property";
import { getTranslatedLanguageName } from "../../../../../../../utils/language";

export const NotificationCardInfo: FC<{
  notificationInfo: SentNotifications;
  reservation?: ReservationDetailsDTO;
}> = ({ notificationInfo, reservation }) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useIsMobile();
  const { openDrawer } = useConversationSelectId();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();

  const handleOpenConversation = (conversationId: string, messageId: string) => {
    openDrawer(conversationId, messageId);
  };

  const handleOpen = () => {
    setOpenModal(true);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const isResendNotificationAllowed = reservation?.allowedActions?.includes(
    AllowedActionEnum.RESEND_NOTIFICATION
  );

  return (
    <>
      <Grid
        container
        p={3}
        alignItems={isMobile ? "baseline" : "center"}
        direction={isMobile ? "column" : "row"}
      >
        <Grid item xs={3} paddingBottom={isMobile ? 2 : 0}>
          <ParagraphBold>
            {t(`labels__notification__template__${notificationInfo.template}`)}
          </ParagraphBold>
        </Grid>
        <Grid item xs={isMobile ? 12 : 3} paddingRight={2} width="100%">
          <Grid container>
            <Grid item xs={12}>
              <DisplayNotificationContact
                channel={notificationInfo.channel}
                recipient={notificationInfo.recipient}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={isMobile ? 3 : 1}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <Tooltip
                title={getTranslatedLanguageName({
                  code: notificationInfo.language,
                  locale: language
                })}
              >
                <div>
                  <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                    {notificationInfo.language}
                  </ParagraphSmall>
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <IconButton
                onClick={() => {
                  handleOpenConversation(notificationInfo.conversationId, notificationInfo.id);
                }}
              >
                <Tooltip
                  title={t(
                    notificationInfo.contentLink
                      ? "labels__notification__tab__link"
                      : "labels__notification__tab__link_unavailable"
                  )}
                >
                  <ForwardToInboxIcon
                    color={notificationInfo.contentLink ? "primary" : "disabled"}
                  />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={isMobile ? 3 : 5} width="100%">
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <ParagraphSmall>
                {formatDateTime(notificationInfo.timestamp, selectedProperty?.details.timeZone)}
              </ParagraphSmall>
            </Grid>
            <Grid item xs={isMobile ? 0 : 2}>
              {!isMobile && (
                <Grid container justifyContent="center">
                  <Tooltip
                    title={t("labels__notification__status", {
                      status: t(`labels__notification__status__${notificationInfo.status}`)
                    })}
                  >
                    <div>
                      <Caption sx={{ color: theme.palette.text.primary }}>
                        {t(`labels__notification__status__${notificationInfo.status}`)}
                      </Caption>
                    </div>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            {isResendNotificationAllowed && (
              <Grid item xs={isMobile ? 8 : 6}>
                <Grid container justifyContent="flex-end">
                  <DisplayResendButton handleOpen={handleOpen} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {openModal && (
        <NotificationResendModal
          reservation={reservation}
          onClose={handleClose}
          notification={notificationInfo}
        />
      )}
    </>
  );
};
