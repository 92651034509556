import { Grid } from "@mui/material";
import { Button, Heading2 } from "@likemagic-tech/sv-magic-library";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { FC } from "react";

/**
 * Returns the header of which card
 * @param title: string with the title of the card
 */

interface CardHeaderInterface {
  title: string;
  viewAllInfo: () => void;
  extraAction?: JSX.Element;
}

export const CardHeader: FC<CardHeaderInterface> = ({ title, viewAllInfo, extraAction }) => {
  const { t } = useTranslationWrapper();

  return (
    <Grid p={3} container direction="row" alignItems="center" justifyContent="space-between">
      <Grid item>
        <Heading2>{title}</Heading2>
      </Grid>
      <Grid item>{extraAction}</Grid>
      <Grid item>
        <Button
          variant="ghost"
          size="large"
          onClick={viewAllInfo}
          endIcon={<ChevronRightIcon fontSize="small" />}
        >
          {t("labels__reservation__details__overview__view_all")}
        </Button>
      </Grid>
    </Grid>
  );
};
