import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useSendCustomNotificationMutation } from "src/graphql/mutations/send-custom-notification.generated";
import { usePerformReservationAction } from "./use-reservation-actions";
import { AllowedActionEnum } from "src/domain/reservation-table-dto";

interface SendCustomNotificationProps {
  reservationId: string;
  payload: any;
}

export const useSendCustomNotification = () => {
  const { isRESTVersion } = useApiVersion();
  const [sendCustomNotificationMutation, result] = useSendCustomNotificationMutation();
  const [performAction, resultAction] = usePerformReservationAction();

  const sendCustomNotificationV1 = ({ reservationId, payload }: SendCustomNotificationProps) => {
    performAction({
      reservationId: reservationId,
      action: AllowedActionEnum.SEND_CUSTOM_NOTIFICATION,
      payload
    });
  };

  const sendCustomNotificationV2 = ({ reservationId, payload }: SendCustomNotificationProps) => {
    sendCustomNotificationMutation({
      pmsReservationId: reservationId,
      payload
    });
  };

  const functionCall = isRESTVersion ? sendCustomNotificationV1 : sendCustomNotificationV2;
  const resultFunction = isRESTVersion ? resultAction : result;

  return [functionCall, resultFunction] as
    | [typeof sendCustomNotificationV2, typeof result]
    | [typeof sendCustomNotificationV1, typeof resultAction];
};
