import { api } from "../add-to-blacklist.generated";

const getUserProfileByIdEnhanced = api.enhanceEndpoints({
  endpoints: {
    AddToBlacklist: {
      invalidatesTags: (result, error, arg) => [
        { type: "UserProfile" as any, id: arg.userProfileId },
        "UserProfile"
      ]
    }
  }
});

export const { useAddToBlacklistMutation: useAddToBlacklistMutationEnhanced } =
  getUserProfileByIdEnhanced;
