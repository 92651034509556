import { PmsType, TenantContext } from "@likemagic-tech/sv-magic-library";
import { useCallback, useContext } from "react";
import { ReservationDetailsDTO } from "../store/endpoints/reservation-table.endpoints";

export const useOpenReservationPms = () => {
  const { urls, pms } = useContext(TenantContext);

  const openApaleoReservation = useCallback(
    (reservation: ReservationDetailsDTO) => {
      window.open(
        `${urls?.apaleoUrl}/${reservation.propertyId}/reservations/${reservation?.id}/actions`
      );
    },
    [urls?.apaleoUrl]
  );

  const openMewsReservation = useCallback(
    (reservation: ReservationDetailsDTO) => {
      window.open(`${urls?.pmsUrl}/Commander/Reservation/Detail/${reservation?.id}`);
    },
    [urls?.pmsUrl]
  );

  const openOHIPReservation = useCallback(
    (reservation: ReservationDetailsDTO) => {
      window.open(
        `${urls?.pmsUrl}/bookmarks/reservation?resvId=${reservation?.id}&TPRESORT=${reservation?.propertyId}`
      );
    },
    [urls?.pmsUrl]
  );

  const openReservation = (reservation: ReservationDetailsDTO) => {
    switch (pms) {
      case PmsType.APALEO:
        return openApaleoReservation(reservation);
      case PmsType.MEWS:
        return openMewsReservation(reservation);
      case PmsType.OHIP:
        return openOHIPReservation(reservation);
    }
  };

  return openReservation;
};
