import { useCallback, useEffect } from "react";
import {
  departuresAndCleaningsForecastSelectors,
  fetchDeparturesAndCleaningsForecast
} from "../../slices/departures-and-cleanings-forecast";
import { useDispatch } from "../../store";
import { formatDateForBE, useApiVersion } from "@likemagic-tech/sv-magic-library";
import { DeparturesAndCleanings } from "../../domain/departures-and-cleanings-forecast";
import { useSelector } from "react-redux";

export const useFetchForecast = ({
  propertyId,
  startDate,
  endDate
}: {
  propertyId?: string;
  startDate: Date;
  endDate: Date;
}) => {
  const dispatch = useDispatch();
  const { isRESTVersion } = useApiVersion();

  const forecastV1 = useSelector(departuresAndCleaningsForecastSelectors.selectEntities);
  const departuresAndCleaningsForecast: DeparturesAndCleanings = isRESTVersion
    ? (forecastV1 as DeparturesAndCleanings)
    : {};

  const fetchForecast = useCallback(() => {
    if (propertyId && isRESTVersion) {
      dispatch(
        fetchDeparturesAndCleaningsForecast({
          startDate: formatDateForBE(startDate),
          endDate: formatDateForBE(endDate),
          propertyId: propertyId
        })
      );
    }
  }, [dispatch, propertyId, startDate, endDate, isRESTVersion]);

  useEffect(() => {
    fetchForecast();
  }, [fetchForecast]);

  return { fetchForecast, departuresAndCleaningsForecast };
};
