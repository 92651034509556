import React, { FC, useState } from "react";
import { Grid } from "@mui/material";
import { Select } from "@likemagic-tech/sv-magic-library";
import { PaymentComponent, PaymentComponentProps } from "../payment-component";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { PaymentOption } from "../domain/payment-option";

export const PaymentPreAuthorizationComponent: FC<PaymentComponentProps> = (props) => {
  const paymentOptions = [PaymentOption.TerminalPayment, PaymentOption.StoredPaymentMethod];
  const [paymentOption, setPaymentOption] = useState<PaymentOption | undefined>(undefined);
  const { t } = useTranslationWrapper();

  return (
    <Grid container>
      <Grid item xs={12} mb={2}>
        <Select
          variant="outlined"
          id="paymentOption"
          name="paymentOption"
          options={paymentOptions.map((item) => ({
            label: t(`labels__payment_${item}`),
            value: item
          }))}
          label={t("labels__payment_option")}
          value={paymentOption}
          onChange={(event) => {
            setPaymentOption(event.target.value as PaymentOption);
          }}
        />
      </Grid>
      <Grid item xs={12} mb={2}>
        <PaymentComponent {...props} isPreAuthorisation />
      </Grid>
    </Grid>
  );
};
