import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function MoneyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="18" height="12" viewBox="0 0 18 12">
      <path
        d="M10.5 6.75C9.875 6.75 9.34375 6.53125 8.90625 6.09375C8.46875 5.65625 8.25 5.125 8.25 4.5C8.25 3.875 8.46875 3.34375 8.90625 2.90625C9.34375 2.46875 9.875 2.25 10.5 2.25C11.125 2.25 11.6562 2.46875 12.0938 2.90625C12.5312 3.34375 12.75 3.875 12.75 4.5C12.75 5.125 12.5312 5.65625 12.0938 6.09375C11.6562 6.53125 11.125 6.75 10.5 6.75ZM5.25 9C4.8375 9 4.48438 8.85313 4.19063 8.55938C3.89688 8.26562 3.75 7.9125 3.75 7.5V1.5C3.75 1.0875 3.89688 0.734375 4.19063 0.440625C4.48438 0.146875 4.8375 0 5.25 0H15.75C16.1625 0 16.5156 0.146875 16.8094 0.440625C17.1031 0.734375 17.25 1.0875 17.25 1.5V7.5C17.25 7.9125 17.1031 8.26562 16.8094 8.55938C16.5156 8.85313 16.1625 9 15.75 9H5.25ZM6.75 7.5H14.25C14.25 7.0875 14.3969 6.73438 14.6906 6.44063C14.9844 6.14688 15.3375 6 15.75 6V3C15.3375 3 14.9844 2.85313 14.6906 2.55938C14.3969 2.26562 14.25 1.9125 14.25 1.5H6.75C6.75 1.9125 6.60313 2.26562 6.30937 2.55938C6.01562 2.85313 5.6625 3 5.25 3V6C5.6625 6 6.01562 6.14688 6.30937 6.44063C6.60313 6.73438 6.75 7.0875 6.75 7.5ZM15 12H2.25C1.8375 12 1.48438 11.8531 1.19063 11.5594C0.896875 11.2656 0.75 10.9125 0.75 10.5V2.25H2.25V10.5H15V12Z"
        fill="#E57700"
      />
    </SvgIcon>
  );
}
