import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  useTheme
} from "@mui/material";
import React, { FC, useCallback, useContext } from "react";
import { Heading3, TenantContext } from "@likemagic-tech/sv-magic-library";
import Download from "../../../icons/Download";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PaymentStatus, PaymentStatusFlag } from "./payment-status.flag";
import { ReservationDetailsDTO } from "../../../store/endpoints/reservation-table.endpoints";
import { PropertiesApi } from "src/api/properties.api";
import { saveAs } from "file-saver";
import { useProperty } from "src/hooks/use-property";
import { isFolioClosed } from "./payment-utils";
import { FolioItemDetails } from "./folio-item-details";

interface PaymentFoliosPreviewProps {
  reservation: ReservationDetailsDTO;
  propertyId: string;
}
export const PaymentFoliosPreview: FC<PaymentFoliosPreviewProps> = ({
  reservation,
  propertyId
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const { keycloak } = useContext(TenantContext);

  const { selectedProperty } = useProperty();

  const downloadFolio = useCallback(
    async (
      pmsPropertyId: string,
      pmsFolioId: string,
      pmsReservationId: string,
      isClosed: boolean
    ) => {
      const blob = await PropertiesApi.downloadFolioInvoice({
        pmsPropertyId,
        pmsFolioId,
        pmsReservationId,
        isClosed,
        authToken: keycloak.token
      });

      const file = new Blob([blob], {
        type: "application/octet-stream",
        endings: "native"
      });
      saveAs(file, `download-invoice-folio-${pmsFolioId}.pdf`);
    },
    [keycloak.token]
  );

  return (
    <Box>
      {reservation.folios.map((folio) => (
        <Accordion
          sx={{
            boxShadow: "none",
            borderRadius: 2,
            mb: 2,
            "&:before": {
              display: "none"
            }
          }}
          defaultExpanded={!isFolioClosed(folio)}
          key={folio.id}
          disableGutters
        >
          <AccordionSummary
            sx={{ px: 3, py: 1.5, border: "none" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="last-reservations"
            id="last-reservations"
          >
            <Grid container gap={2} alignItems="center">
              <Box display="flex" alignItems="center">
                <Heading3>{`${t(isFolioClosed(folio) ? "labels__invoice" : "labels__folio")} ${
                  folio.number
                }`}</Heading3>
              </Box>
              <PaymentStatusFlag
                status={
                  folio.balance.amount * -1 < 0
                    ? PaymentStatus.OPEN
                    : folio.balance.amount === 0
                      ? PaymentStatus.PAID
                      : PaymentStatus.BALANCE
                }
              />
              <IconButton
                sx={{ px: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadFolio(
                    propertyId,
                    isFolioClosed(folio) ? folio.id : folio.number,
                    reservation.id,
                    isFolioClosed(folio)
                  );
                }}
              >
                <Download sx={{ color: theme.palette.accent.main }} />
              </IconButton>
            </Grid>
          </AccordionSummary>

          <AccordionDetails sx={{ p: 3, pt: 0 }}>
            {selectedProperty?.propertyId && selectedProperty?.details.timeZone && (
              <FolioItemDetails
                folio={folio}
                magicId={reservation.magicId}
                propertyId={selectedProperty?.propertyId}
                propertyTimeZone={selectedProperty?.details.timeZone}
                folios={reservation.folios}
                reservation={reservation}
              />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
