import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type AvailableTemplatesForSendCustomNotificationQueryVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
}>;

export type AvailableTemplatesForSendCustomNotificationQuery = {
  __typename?: "Query";
  AvailableTemplatesForSendCustomNotification: Array<Types.NotificationType>;
};

export const AvailableTemplatesForSendCustomNotificationDocument = `
    query AvailableTemplatesForSendCustomNotification($pmsReservationId: String!) {
  AvailableTemplatesForSendCustomNotification(pmsReservationId: $pmsReservationId)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    AvailableTemplatesForSendCustomNotification: build.query<
      AvailableTemplatesForSendCustomNotificationQuery,
      AvailableTemplatesForSendCustomNotificationQueryVariables
    >({
      query: (variables) => ({
        document: AvailableTemplatesForSendCustomNotificationDocument,
        variables
      })
    })
  })
});

export { injectedRtkApi as api };
export const {
  useAvailableTemplatesForSendCustomNotificationQuery,
  useLazyAvailableTemplatesForSendCustomNotificationQuery
} = injectedRtkApi;
