import { useCallback } from "react";
import { FolioManagementCheckoutModalResult } from "../reservation-actions-menu.types";
import { useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { ReservationTableDto } from "../../../domain/reservation-table-dto";

interface UseHandleFolioManagementCheckoutModalProps {
  reservation: ReservationTableDto;
}

export const useHandleFolioManagementCheckoutModal = ({
  reservation
}: UseHandleFolioManagementCheckoutModalProps) => {
  const { t } = useTranslationWrapper();
  const { open: openGlobalModal } = useGlobalModal();

  const handleFolioManagementCheckoutModal = useCallback(() => {
    return openGlobalModal({
      modalProps: {
        title: t("labels__handle_folio_management_checkout_modal_title"),
        content: (
          <>
            {reservation?.canCheckout ? (
              <>{t("labels__handle_folio_management_checkout_modal_subtitle")}</>
            ) : (
              <>{t("labels__handle_folio_management_checkout_modal_subtitle_open_balance")}</>
            )}
          </>
        )
      },
      modalActions: [
        {
          variant: "primary",
          result: reservation?.canCheckout
            ? FolioManagementCheckoutModalResult.COMPLETE
            : FolioManagementCheckoutModalResult.GO_TO_FOLIO,
          label: reservation?.canCheckout
            ? t("buttons__complete_checkout")
            : t("buttons__go_to_folio_management")
        }
      ]
    });
  }, [openGlobalModal, reservation?.canCheckout, t]);

  return {
    handleFolioManagementCheckoutModal
  };
};
