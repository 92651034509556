import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type AddToBlacklistMutationVariables = Types.Exact<{
  userProfileId: Types.Scalars["Int"]["input"];
}>;

export type AddToBlacklistMutation = { __typename?: "Mutation"; AddToBlacklist: boolean };

export const AddToBlacklistDocument = `
    mutation AddToBlacklist($userProfileId: Int!) {
  AddToBlacklist(userProfileId: $userProfileId)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    AddToBlacklist: build.mutation<AddToBlacklistMutation, AddToBlacklistMutationVariables>({
      query: (variables) => ({ document: AddToBlacklistDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useAddToBlacklistMutation } = injectedRtkApi;
