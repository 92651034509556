import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LuggageLocker = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_1197_787)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 6.00049C7.83579 6.00049 7.5 6.33627 7.5 6.75049V14.2505C7.5 14.6647 7.83579 15.0005 8.25 15.0005H21.75C22.1642 15.0005 22.5 14.6647 22.5 14.2505V6.75049C22.5 6.33627 22.1642 6.00049 21.75 6.00049H8.25ZM6 6.75049C6 5.50785 7.00736 4.50049 8.25 4.50049H21.75C22.9926 4.50049 24 5.50785 24 6.75049V14.2505C24 15.4931 22.9926 16.5005 21.75 16.5005H8.25C7.00736 16.5005 6 15.4931 6 14.2505V6.75049Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.818 1.31851C12.6619 0.474594 13.8065 0.000488281 15 0.000488281C16.1935 0.000488281 17.3381 0.474594 18.182 1.31851C19.0259 2.16242 19.5 3.30701 19.5 4.50049V5.25049C19.5 5.6647 19.1642 6.00049 18.75 6.00049H11.25C10.8358 6.00049 10.5 5.6647 10.5 5.25049V4.50049C10.5 3.30701 10.9741 2.16242 11.818 1.31851ZM15 1.50049C14.2044 1.50049 13.4413 1.81656 12.8787 2.37917C12.3161 2.94178 12 3.70484 12 4.50049H18C18 3.70484 17.6839 2.94178 17.1213 2.37917C16.5587 1.81656 15.7956 1.50049 15 1.50049Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 4.50049C11.6642 4.50049 12 4.83627 12 5.25049V15.7505C12 16.1647 11.6642 16.5005 11.25 16.5005C10.8358 16.5005 10.5 16.1647 10.5 15.7505V5.25049C10.5 4.83627 10.8358 4.50049 11.25 4.50049Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 4.50049C19.1642 4.50049 19.5 4.83627 19.5 5.25049V15.7505C19.5 16.1647 19.1642 16.5005 18.75 16.5005C18.3358 16.5005 18 16.1647 18 15.7505V5.25049C18 4.83627 18.3358 4.50049 18.75 4.50049Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 18.0005C2.50736 18.0005 1.5 19.0078 1.5 20.2505C1.5 21.4931 2.50736 22.5005 3.75 22.5005C4.99264 22.5005 6 21.4931 6 20.2505C6 19.0078 4.99264 18.0005 3.75 18.0005ZM0 20.2505C0 18.1794 1.67893 16.5005 3.75 16.5005C5.82107 16.5005 7.5 18.1794 7.5 20.2505C7.5 22.3216 5.82107 24.0005 3.75 24.0005C1.67893 24.0005 0 22.3216 0 20.2505Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 20.2505C6 19.8363 6.33579 19.5005 6.75 19.5005H14.25C14.6642 19.5005 15 19.8363 15 20.2505V22.5005C15 22.9147 14.6642 23.2505 14.25 23.2505C13.8358 23.2505 13.5 22.9147 13.5 22.5005V21.0005H6.75C6.33579 21.0005 6 20.6647 6 20.2505Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 19.5005C11.6642 19.5005 12 19.8363 12 20.2505V21.7505C12 22.1647 11.6642 22.5005 11.25 22.5005C10.8358 22.5005 10.5 22.1647 10.5 21.7505V20.2505C10.5 19.8363 10.8358 19.5005 11.25 19.5005Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1197_787">
        <rect width="24" height="24.0002" fill="white" transform="translate(0 0.000244141)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
