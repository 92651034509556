import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type MovePaymentsMutationVariables = Types.Exact<{
  movePaymentsInput: Types.MovePaymentInput;
}>;

export type MovePaymentsMutation = { __typename?: "Mutation"; MovePayments?: boolean | null };

export const MovePaymentsDocument = `
    mutation MovePayments($movePaymentsInput: MovePaymentInput!) {
  MovePayments(movePaymentsInput: $movePaymentsInput)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    MovePayments: build.mutation<MovePaymentsMutation, MovePaymentsMutationVariables>({
      query: (variables) => ({ document: MovePaymentsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useMovePaymentsMutation } = injectedRtkApi;
