import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type BulkUpdateUnitStateMutationVariables = Types.Exact<{
  pmsUnitIds: Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"];
  state: Types.UnitState;
}>;

export type BulkUpdateUnitStateMutation = {
  __typename?: "Mutation";
  BulkUpdateUnitState?: boolean | null;
};

export const BulkUpdateUnitStateDocument = `
    mutation BulkUpdateUnitState($pmsUnitIds: [String!]!, $state: UnitState!) {
  BulkUpdateUnitState(pmsUnitIds: $pmsUnitIds, state: $state)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    BulkUpdateUnitState: build.mutation<
      BulkUpdateUnitStateMutation,
      BulkUpdateUnitStateMutationVariables
    >({
      query: (variables) => ({ document: BulkUpdateUnitStateDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useBulkUpdateUnitStateMutation } = injectedRtkApi;
