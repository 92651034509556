import { graphqlApi, GUEST_SERVICE_BASE_URL } from "../graphql.api";
import { createClient } from "../../graphql-messaging/subscribtion/subscribe.utils";
import {
  StreamSecondScreenEventsDocument,
  StreamSecondScreenEventsSubscription
} from "./StreamSecondScreenEvents.generated";
import { getAuthToken } from "@likemagic-tech/sv-magic-library";

interface StreamReservationOverviewDetailsSubscribeProps {
  apiKey?: string;
  authorization?: string;
  tenantId: string;
  pmsReservationId: string;
  handleResponse: (response: { errors?: Array<{ message: string }>; data: any }) => void;
}

export const streamSecondScreenEventsSubscribeApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    SubscribeToReservationOverviewDetails: build.query<
      any,
      StreamReservationOverviewDetailsSubscribeProps
    >({
      query: (arg) => ({
        document: StreamSecondScreenEventsDocument,
        variables: {
          pmsReservationId: arg.pmsReservationId
        }
      }),
      providesTags: [],
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, extra }) {
        const client = createClient(
          GUEST_SERVICE_BASE_URL,
          arg,
          // @ts-ignore
          await getAuthToken(extra?.keycloak as any)
        );

        try {
          await cacheDataLoaded;
          const onNext = (value: { data: StreamSecondScreenEventsSubscription }) => {
            arg.handleResponse(value);
          };

          new Promise<void>((resolve, reject) => {
            client.subscribe(
              {
                query: StreamSecondScreenEventsDocument,
                operationName: "StreamSecondScreenEvents",
                variables: { pmsReservationId: arg.pmsReservationId }
              },
              {
                next: onNext,
                error: reject,
                complete: resolve
              }
            );
          });
        } catch (e) {
          console.error("WS subscription for V2 failed", e);
          client.dispose();
        }
        await cacheEntryRemoved;
        client.dispose();
      }
    })
  }),

  overrideExisting: false
});
