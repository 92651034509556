import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetUnitGroupsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.UnitGroupFilter>;
}>;

export type GetUnitGroupsQuery = {
  __typename?: "Query";
  GetUnitGroups?: Array<{
    __typename?: "UnitGroup";
    pmsId?: string | null;
    name?: string | null;
    property?: { __typename?: "Property"; pmsId: string } | null;
  } | null> | null;
};

export const GetUnitGroupsDocument = `
    query GetUnitGroups($filter: UnitGroupFilter) {
  GetUnitGroups(filter: $filter) {
    pmsId
    name
    property {
      pmsId
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetUnitGroups: build.query<GetUnitGroupsQuery, GetUnitGroupsQueryVariables | void>({
      query: (variables) => ({ document: GetUnitGroupsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetUnitGroupsQuery, useLazyGetUnitGroupsQuery } = injectedRtkApi;
