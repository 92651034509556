import { SvgIcon, SvgIconProps } from "@mui/material";

export const CrownSimpleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2547 5.71624C19.0676 5.55216 18.8357 5.4477 18.5887 5.41625C18.3418 5.38479 18.0911 5.42777 17.8688 5.53967L14.2047 7.36624L11.0797 2.17171C10.9678 1.98584 10.8097 1.83208 10.6208 1.72534C10.4319 1.61859 10.2186 1.5625 10.0016 1.5625C9.78463 1.5625 9.57134 1.61859 9.38244 1.72534C9.19354 1.83208 9.03544 1.98584 8.92348 2.17171L5.79848 7.36624L2.13364 5.54046C1.91153 5.4295 1.66155 5.38676 1.41519 5.41762C1.16883 5.44848 0.937116 5.55156 0.749237 5.71388C0.561358 5.8762 0.425719 6.09049 0.359409 6.32975C0.293099 6.56902 0.299084 6.82255 0.376609 7.05842L3.26723 15.9139C3.31077 16.0473 3.3837 16.1692 3.48065 16.2707C3.5776 16.3722 3.69609 16.4506 3.82738 16.5001C3.95867 16.5497 4.09942 16.5692 4.23923 16.5571C4.37905 16.545 4.51437 16.5017 4.6352 16.4303C4.65395 16.4194 6.59302 15.3123 10 15.3123C13.4071 15.3123 15.3461 16.4209 15.361 16.4295C15.4818 16.5018 15.6174 16.5459 15.7576 16.5587C15.8979 16.5714 16.0392 16.5523 16.1711 16.5029C16.3029 16.4536 16.422 16.3751 16.5194 16.2734C16.6168 16.1717 16.69 16.0493 16.7336 15.9155L19.6243 7.06467C19.7041 6.82868 19.7115 6.57422 19.6457 6.33396C19.5798 6.0937 19.4437 5.87859 19.2547 5.71624ZM15.2758 14.3373C14.2329 13.9373 12.4375 13.4373 10 13.4373C7.56255 13.4373 5.76723 13.9389 4.72427 14.3389L2.61489 7.87717L5.4688 9.29671C5.75243 9.43667 6.0782 9.4653 6.3819 9.37694C6.68559 9.28858 6.94516 9.08965 7.10942 8.81936L10 4.01546L12.8907 8.82014C13.0549 9.09043 13.3146 9.28932 13.6183 9.37754C13.922 9.46577 14.2478 9.43693 14.5313 9.29671L17.3844 7.87483L15.2758 14.3373Z"
        fill="#067647"
      />
    </svg>
  </SvgIcon>
);
