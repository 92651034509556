import { FC } from "react";
import { ChatItemContentBaseProps } from "./domain/chat-item-content-base-props";

interface ChatItemContentAudioProps extends ChatItemContentBaseProps {}

export const ChatItemContentAudio: FC<ChatItemContentAudioProps> = ({ content }) => {
  return (
    <audio controls style={{ width: "100%" }}>
      <source src={`${content.url}`} type="audio/ogg" />
    </audio>
  );
};
