import { DragEventHandler } from "react";
import { FileRejection, useDropzone } from "react-dropzone";

// same limit as in BE (just a bit less, to be safe)
const maxFileSize = 10000000;
export const useChatUploadFile = ({
  onDropAccepted,
  onDragEnter,
  onDragLeave,
  onDropRejected,
  allowZipUpload
}: {
  onDropAccepted: (acceptedFiles: Array<File>) => void;
  onDragEnter?: DragEventHandler<HTMLElement>;
  onDragLeave?: DragEventHandler<HTMLElement>;
  onDropRejected?: (fileRejections: FileRejection[]) => void;
  allowZipUpload: boolean;
}) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/svg+xml": [".svg"],
      "application/pdf": [".pdf"],
      ...(allowZipUpload && { "application/zip": [".zip", ".rar"] })
    },
    maxSize: maxFileSize,
    maxFiles: 10,
    onDropAccepted,
    onDragEnter,
    onDragLeave,
    onDropRejected
  });

  return { getRootProps, getInputProps, open, isDragActive };
};
