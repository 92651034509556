import sessionStorage from "redux-persist/lib/storage/session";
import storage from "redux-persist/lib/storage";
import { paymentSlice } from "../slices/payment.slice";
import { secondScreenSlice } from "../slices/second-screen.slice";
import { drawerStackSlice } from "../slices/drawer-stack.slice";

const defaultTimeout = 500;

export const paymentSlicePersistConfig = {
  timeout: defaultTimeout,
  key: paymentSlice.name,
  storage: sessionStorage,
  blacklist: ["status", "paymentLink"]
};

export const recentlyUsedDevicesSlicePersistConfig = {
  timeout: defaultTimeout,
  key: secondScreenSlice.name,
  storage: storage,
  blacklist: ["status", "selectedSecondScreenId"]
};

export const drawerStackSlicePersistConfig = {
  timeout: defaultTimeout,
  key: drawerStackSlice.name,
  storage: sessionStorage
};
