import * as Types from "../generated/graphql";

import { MessageFragmentDoc } from "../fragments/Message.generated";
import { ActorFragmentDoc } from "../fragments/Actor.generated";
import { messagingApi } from "../messaging.api";
export type StreamMessageSubscriptionVariables = Types.Exact<{
  conversationId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type StreamMessageSubscription = {
  __typename?: "Subscription";
  StreamMessage: Array<{
    __typename?: "Message";
    conversationId: string;
    messageBirdVersion?: string | null;
    messageId: string;
    content: any;
    direction: Types.Direction;
    read: boolean;
    createdAt: any;
    status: Types.MessageStatus;
    to?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorGroupId?: string | null;
      displayName?: string | null;
    } | null;
    from?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorGroupId?: string | null;
      displayName?: string | null;
    } | null;
    channel: { __typename?: "Channel"; platform: Types.Platform; name?: string | null };
  }>;
};

export const StreamMessageDocument = `
    subscription StreamMessage($conversationId: String) {
  StreamMessage(
    filter: {conversationId: $conversationId, pageable: {pageNumber: 0, pageSize: 1000, sort: "createdAt,asc"}}
  ) {
    ...Message
  }
}
    ${MessageFragmentDoc}
${ActorFragmentDoc}`;

const injectedRtkApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({})
});

export { injectedRtkApi as api };
export const {} = injectedRtkApi;
