export enum ProfileTabs {
  DETAILS = "DETAILS",
  RESERVATIONS = "RESERVATIONS",
  TRANSACTIONS = "TRANSACTIONS",
  NOTES = "NOTES",
  MESSAGES = "MESSAGES"
}
export const ProfileTabsList = [
  ProfileTabs.DETAILS,
  ProfileTabs.RESERVATIONS,
  ProfileTabs.TRANSACTIONS,
  ProfileTabs.NOTES,
  ProfileTabs.MESSAGES
];
