import { useMemo } from "react";
import { Params, useSelectId } from "../../hooks/use-select-id";
import { useSearchParams } from "react-router-dom";

interface UseTaskModalProps {
  type?: string;
  typeId?: string;
}

export const useTaskModal = ({ type, typeId }: UseTaskModalProps) => {
  const { selectedId, setSelectId } = useSelectId({
    fieldName: Params.TASK_ID_SEARCH_PARAM_NAME
  });

  const [searchParams] = useSearchParams();

  const selectedReservationId = useMemo(() => {
    const searchParamsObj = new URLSearchParams(searchParams);
    return searchParamsObj.get(typeId ?? "");
  }, [searchParams, typeId]);

  const selectedLinkType = useMemo(() => {
    const searchParamsObj = new URLSearchParams(searchParams);
    return searchParamsObj.get(type ?? "");
  }, [searchParams, type]);

  return {
    taskId: selectedId ?? undefined,
    openNewTask: () => setSelectId(Params.CREATE_TASK_PARAM),
    openNewTaskWithParam: (id: string, type: string) =>
      setSelectId(
        Params.CREATE_TASK_PARAM,
        new URLSearchParams({
          [Params.TASK_LINK_TYPE_URL_PARAM]: type,
          [Params.TASK_LINK_ID_URL_PARAM]: id
        })
      ),
    openEditTask: (taskId: string) => setSelectId(taskId),
    closeModal: () =>
      setSelectId(
        undefined,
        new URLSearchParams({
          [Params.TASK_LINK_TYPE_URL_PARAM]: "",
          [Params.TASK_LINK_ID_URL_PARAM]: ""
        })
      ),
    selectedId: selectedReservationId,
    selectedLinkType: selectedLinkType
  };
};
