import { JSONApiResponse, VoidApiResponse } from "@likemagic-tech/sv-magic-library";
import { ApiKeyApiClient } from "./base/api-key-api-client";
import { BoxItem, BoxState } from "../slices/box.slice";

const BASE_URL = "/api/guest-journey-service/hafele-box";

class BoxApiClient extends ApiKeyApiClient {
  async fetchBoxes(propertyId: string, init?: RequestInit): Promise<Array<BoxItem>> {
    const response = await this.fetchApi(BASE_URL.concat(`/admin/${propertyId}/boxes`), init);
    return new JSONApiResponse<Array<BoxItem>>(response).value();
  }

  async updateBoxState(
    propertyId: string,
    boxId: string,
    boxState: BoxState,
    init?: RequestInit
  ): Promise<void> {
    const response = await this.fetchApi(
      BASE_URL.concat(`/admin/${propertyId}/${boxId}/state-safe/${boxState}`),
      { ...init, method: "POST" }
    );
    return new VoidApiResponse(response).value();
  }
}

export const BoxApi = new BoxApiClient();
