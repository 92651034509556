import { Property } from "../../domain/property";
import { GetAllPropertiesQuery } from "../generated/graphql";

export const transformPropertyV2ToCommonProperty = (
  arg: GetAllPropertiesQuery | undefined
): Property[] =>
  arg?.GetAllProperties
    ? arg?.GetAllProperties?.map((property) => {
        return {
          propertyId: property?.pmsId ?? "",
          originalPropertyId: property?.pmsId ?? "",
          details: {
            id: property?.pmsId ?? "",
            name: property?.name ?? "",
            companyName: "",
            currencyCode: property?.defaultCurrency ?? "",
            description: "",
            timeZone: property?.timezone ?? ""
          },
          language: "en"
        };
      })
    : [];
