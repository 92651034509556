import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetSupportSyncStatesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PropertyFilter>;
}>;

export type GetSupportSyncStatesQuery = {
  __typename?: "Query";
  GetProperty?: {
    __typename?: "Property";
    supportedSyncStates?: Array<Types.DoorAccessState | null> | null;
    availableDoors?: Array<{
      __typename?: "AvailableDoorDto";
      unitId?: string | null;
      id?: string | null;
      title?: string | null;
      lockAccessibility?: Types.DoorLockAccessibility | null;
      batteryLevel?: Types.BatteryLevel | null;
      privacyMode?: boolean | null;
      privacyModeLastChangeTimestamp?: any | null;
      pin?: string | null;
      sortPriority?: number | null;
    } | null> | null;
  } | null;
};

export const GetSupportSyncStatesDocument = `
    query GetSupportSyncStates($filter: PropertyFilter) {
  GetProperty(filter: $filter) {
    supportedSyncStates
    availableDoors {
      unitId
      id
      title
      lockAccessibility
      batteryLevel
      privacyMode
      privacyModeLastChangeTimestamp
      pin
      sortPriority
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetSupportSyncStates: build.query<
      GetSupportSyncStatesQuery,
      GetSupportSyncStatesQueryVariables | void
    >({
      query: (variables) => ({ document: GetSupportSyncStatesDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetSupportSyncStatesQuery, useLazyGetSupportSyncStatesQuery } = injectedRtkApi;
