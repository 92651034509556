import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { AllowedActionEnum } from "src/domain/reservation-table-dto";
import { usePerformActionMutation } from "src/graphql/mutations/perform-action.generated";
import {
  transformCommonPayloadToV2,
  transformV2AllowedActionsToV2AllowedActions
} from "src/graphql/transform/transform-reservation-table-overview";
import { usePerformReservationActionMutation } from "src/store/endpoints/reservation-table.endpoints";
import { useCallback, useMemo } from "react";
import { ReservationCheckInOutAuthor } from "../graphql/generated/graphql";

export interface ReservationActionPayload {
  unitId?: string;
  unitGroupId?: string;
  pin?: string;
  encoderId?: string;
  reservationComment?: string;
  actor?: string;
  text?: string;
  patches?: Array<any>;
  status?: any;
  template?: string;
  platform?: string;
  language?: string;
  email?: string;
  phoneNumber?: string;
  magicId?: string;
  author?: ReservationCheckInOutAuthor;
}

export const usePerformReservationAction = () => {
  const { isRESTVersion } = useApiVersion();
  const [performActionMutationV1, resultV1] = usePerformReservationActionMutation();
  const [performActionMutationV2, resultV2] = usePerformActionMutation();
  const performActionV1 = useCallback(
    ({
      reservationId,
      action,
      payload
    }: {
      reservationId: string;
      action: AllowedActionEnum;
      payload?: ReservationActionPayload;
    }) =>
      performActionMutationV1({
        reservationId,
        payload,
        action
      }).unwrap(),
    [performActionMutationV1]
  );

  const performActionV2 = useCallback(
    ({
      reservationId,
      action,
      payload
    }: {
      reservationId: string;
      action: AllowedActionEnum;
      payload?: ReservationActionPayload;
    }) =>
      performActionMutationV2({
        pmsReservationId: reservationId,
        reservationAction: transformV2AllowedActionsToV2AllowedActions(action),
        payload: transformCommonPayloadToV2(action, payload)
      }).unwrap(),
    [performActionMutationV2]
  );

  const functionCall = useMemo(
    () => (isRESTVersion ? performActionV1 : performActionV2),
    [isRESTVersion, performActionV1, performActionV2]
  );

  const result = useMemo(
    () => (isRESTVersion ? resultV1 : resultV2),
    [resultV2, resultV1, isRESTVersion]
  );

  return [functionCall, result] as
    | [typeof performActionV1, typeof resultV1]
    | [typeof performActionV2, typeof resultV2];
};
