import { createApi, defaultSerializeQueryArgs } from "@reduxjs/toolkit/query/react";
import { magicBaseQuery } from "./base-query";

const GUEST_JOURNEY_SERVICE_BASE_URL = "/api/guest-journey-service";
export const magicApi = createApi({
  reducerPath: "api",
  baseQuery: magicBaseQuery({ baseUrl: GUEST_JOURNEY_SERVICE_BASE_URL }),
  tagTypes: ["Reservations", "ReservationDetails", "Units"],
  serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
    const args = { ...queryArgs };
    delete args["skipSpinner" as keyof typeof args];

    return defaultSerializeQueryArgs({
      endpointName,
      queryArgs: args,
      endpointDefinition
    });
  },
  endpoints: () => ({})
});
