import type { FC } from "react";
import React from "react";
import { Grid, Skeleton, Typography } from "@mui/material";

interface LoaderOrNotUploadedProps {
  isFileLoading: boolean;
  notUploadedLabel: string;
}

export const LoaderOrNotUploaded: FC<LoaderOrNotUploadedProps> = ({
  isFileLoading,
  notUploadedLabel
}) => {
  return isFileLoading ? (
    <Skeleton variant="rectangular" height={216} width={250} />
  ) : (
    <Grid justifyContent="center" alignContent="center" textAlign="center">
      <Typography>{notUploadedLabel}</Typography>
    </Grid>
  );
};
