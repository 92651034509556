import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { FolioPreview } from "../../../../store/endpoints/reservation-table.endpoints";
import { MovePaymentsModal } from "../modals/move-payments-modal";
import { ActionMenu } from "../../../../components/action-menu/action-menu";

export interface MultiplePaymentsActionsMenuProps {
  reservationId: string;
  folios: Array<FolioPreview>;
  paymentPmsIds: string[];
  folioId: string;
  isFolioClosed: boolean | false;
  folioMetadata: Object | null;
  disabled?: boolean;
  onSuccess: () => void;
}

export const MultiplePaymentsActionsMenu: FC<MultiplePaymentsActionsMenuProps> = ({
  reservationId,
  paymentPmsIds,
  folioId,
  isFolioClosed,
  folioMetadata,
  folios,
  disabled,
  onSuccess
}) => {
  const { t } = useTranslationWrapper();

  const [movePaymentModalOpened, setMovePaymentModalOpened] = useState(false);

  const onMoveOptionSelect = useCallback(() => {
    setMovePaymentModalOpened(true);
  }, []);

  const menuItems = useMemo(() => {
    return isFolioClosed ? [] : [{ label: t("labels__move"), id: "move" }];
  }, [isFolioClosed, t]);

  const handleAction = useCallback(
    (selectedOptionId: string) => {
      switch (selectedOptionId) {
        case "move":
          onMoveOptionSelect();
          break;
      }
    },
    [onMoveOptionSelect]
  );

  return (
    <>
      <ActionMenu items={menuItems} handleAction={handleAction} disabled={disabled} />
      <MovePaymentsModal
        isOpen={movePaymentModalOpened}
        reservationId={reservationId}
        folios={folios}
        onClose={() => {
          setMovePaymentModalOpened(false);
        }}
        paymentPmsIds={paymentPmsIds}
        folioId={folioId}
        folioMetadata={folioMetadata}
        onSuccess={onSuccess}
      />
    </>
  );
};
