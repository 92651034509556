import { FC, useEffect, useState } from "react";
import { AlwaysScrollToBottom } from "../always-scroll-to-bottom/always-scroll-to-bottom";
import { Message } from "../../messaging/domain/message";

export const ChatScrollToBottom: FC<{
  messages: Message[];
  selectedMessageId: string | null;
}> = ({ messages, selectedMessageId }) => {
  const [messageId, setMessageId] = useState<string | null>(null);

  useEffect(() => {
    if (!messages.find((m) => m?.messageId === selectedMessageId)) {
      const latestMessage = [...messages].pop();
      setMessageId(latestMessage?.messageId ?? "");
    }
  }, [messages, selectedMessageId]);

  return messageId != null ? <AlwaysScrollToBottom id={messageId ?? ""} /> : null;
};
