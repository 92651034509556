import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BatteryLowIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 8V16H6L6 8H15ZM4 7.33V9H2L2 15H4V16.67C4 17.0227 4.14012 17.361 4.38955 17.6105C4.63897 17.8599 4.97726 18 5.33 18H20.67C21.4 18 22 17.4 22 16.67V7.33C22 6.97726 21.8599 6.63897 21.6105 6.38955C21.361 6.14012 21.0227 6 20.67 6L5.33 6C4.6 6 4 6.6 4 7.33Z" />
    </svg>
  </SvgIcon>
);
