import { SubtasksForm, TaskFormValues } from "./task-modal";
import {
  CreateTaskRequest,
  Status,
  UpdateTaskRequest
} from "../../../graphql-tasks/generated/graphql";
import { Task, TaskPriority, TaskStatus } from "../domain/task";
import { combineTimeAndDate } from "@likemagic-tech/sv-magic-library";

const parseDueDateTime = ({
  dueDate,
  dueTime
}: {
  dueDate?: string;
  dueTime?: string;
}): Date | undefined => {
  if (dueDate && !dueTime) {
    return new Date(dueDate);
  } else if (dueDate && dueTime) {
    return combineTimeAndDate(new Date(dueDate), new Date(dueTime));
  } else if (!dueDate && dueTime) {
    return new Date(dueTime);
  }
  return undefined;
};
export const transformTaskFormToCreateTaskRequest = (
  taskForm: TaskFormValues | SubtasksForm,
  task?: Task,
  parentId?: number
): CreateTaskRequest => {
  const filteredValues: Partial<TaskFormValues> = Object.fromEntries(
    Object.entries(taskForm).filter(([, value]) => value != null && value !== "")
  );

  return {
    assigneeGroupId: parseInt(filteredValues.assigneeGroup?.id ?? "") ?? undefined,
    assigneeId: parseInt(filteredValues.assignee?.id ?? "") ?? undefined,
    attachments: filteredValues.attachments?.map((item) => item.uuid),
    description: filteredValues.description ?? "",
    due: parseDueDateTime({
      dueDate: filteredValues.dueDate,
      dueTime: filteredValues.dueTime
    }),
    links: {
      pmsUnitIds: filteredValues.linkedUnitId ? [filteredValues.linkedUnitId] : [],
      pmsReservationIds: filteredValues.linkedPmsReservationId
        ? [filteredValues.linkedPmsReservationId]
        : [],
      pmsArrivingReservationId: task?.links?.pmsArrivingReservationId ?? null,
      pmsDepartingReservationId: task?.links?.pmsDepartingReservationId ?? null
    },
    parentId: parentId ?? undefined,
    priority: filteredValues.priority ?? TaskPriority.Low,
    status: filteredValues.status ?? TaskStatus.Todo,
    title: filteredValues.title ?? ""
  };
};
export const transformTaskFormToUpdateTaskRequest = (
  taskForm: TaskFormValues | SubtasksForm,
  task?: Task,
  parentId?: number
): UpdateTaskRequest => {
  const filteredValues: Partial<TaskFormValues> = Object.fromEntries(
    Object.entries(taskForm).filter(([, value]) => value != null && value !== "")
  );

  return {
    assigneeGroupId: parseInt(filteredValues.assigneeGroup?.id ?? "") ?? 0,
    assigneeId: parseInt(filteredValues.assignee?.id ?? "") ?? 0,
    attachments: filteredValues.attachments?.map((item) => item.uuid),
    description: filteredValues.description ?? "",
    due: parseDueDateTime({
      dueDate: filteredValues.dueDate,
      dueTime: filteredValues.dueTime
    }),
    links: {
      pmsUnitIds: filteredValues.linkedUnitId ? [filteredValues.linkedUnitId] : [],
      pmsReservationIds: filteredValues.linkedPmsReservationId
        ? [filteredValues.linkedPmsReservationId]
        : [],
      pmsArrivingReservationId: task?.links?.pmsArrivingReservationId ?? null,
      pmsDepartingReservationId: task?.links?.pmsDepartingReservationId ?? null
    },
    parentId: parentId ?? undefined,
    priority: filteredValues.priority ?? TaskPriority.Low,
    status: filteredValues.status ?? TaskStatus.Todo,
    title: filteredValues.title ?? ""
  };
};

export const transformSubtask = (subtask: Task): SubtasksForm => {
  return {
    title: subtask.title,
    status: subtask.status,
    dueDate: subtask.due,
    parentId: subtask.parentId ?? undefined,
    id: subtask.id,
    assignee: subtask?.assignee
      ? {
          id: subtask.assignee.actorId ?? "",
          label: subtask.assignee.displayName ?? ""
        }
      : undefined,
    isEdit: false
  };
};

export const transformTask = (task: Task, reservationId: string): TaskFormValues => {
  return {
    title: task?.title ?? "",
    status: task?.status ?? Status.Todo,
    description: task?.description ?? "",
    id: task?.id ?? 0,
    attachments: task?.attachments ?? [],
    dueDate: task?.due ?? "",
    dueTime: task?.due ?? "",
    assigneeGroup: task?.assigneeGroup
      ? {
          id: task.assigneeGroup.actorId ?? "",
          label: task.assigneeGroup.displayName ?? ""
        }
      : undefined,
    assignee: task?.assignee
      ? {
          id: task.assignee.actorId ?? "",
          label: task.assignee.displayName ?? ""
        }
      : undefined,
    priority: task?.priority ?? TaskPriority.Medium,
    linkedUnitId: task?.links?.pmsUnitIds?.[0] ?? "",
    linkedPmsReservationId: task?.links?.reservations?.[0]?.pmsReservationId ?? reservationId ?? "",
    linkedDisplayableReservationId:
      task?.links?.reservations?.[0]?.displayableReservationId ?? reservationId ?? "",
    linkedPmsArrivingReservationId: task?.links?.arrivingReservation?.pmsReservationId ?? "",
    linkedDisplayableArrivingReservationId:
      task?.links?.arrivingReservation?.displayableReservationId ?? "",
    linkedPmsDepartingReservationId: task?.links?.departingReservation?.pmsReservationId ?? "",
    linkedDisplayableDepartingReservationId:
      task?.links?.departingReservation?.displayableReservationId ?? ""
  };
};
