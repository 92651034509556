import { unitEndpoints, useGetUnitsQueryV1 } from "../store/endpoints/unit.endpoints";
import { Unit } from "../domain/Unit";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useGetUnitsQuery } from "src/graphql/queries/GetUnits.generated";
import { transformUnitsV2ToCommonUnits } from "src/graphql/transform/transform-units";
import { useCallback } from "react";
import { useDispatch } from "src/store";
import { getUnitsEnhancedApi } from "../graphql/queries/enhanced-queries/get-units-enhanced";
import { useGetAssignableUnitsQuery } from "src/graphql/queries/GetAssignableUnits.generated";

interface UnitsByUnitGroupIdInterface {
  unitGroupId: string;
  propertyId: string;
  reservationId: string;
  skipV2Call: boolean;
}

interface UnitsInterface {
  propertyId: string;
  refetchOnMountOrArgChange?: boolean;
}

export const useUnitsByUnitGroupId = ({
  unitGroupId,
  propertyId,
  reservationId,
  skipV2Call
}: UnitsByUnitGroupIdInterface): Unit[] => {
  const { isRESTVersion, isGraphQlVersion } = useApiVersion();

  const { data: unitsByGroupIdV1 } = useGetUnitsQueryV1(
    { propertyId },
    { skip: !propertyId || isGraphQlVersion }
  );

  const unitsByGroupIdV2 = useGetAssignableUnitsQuery(
    {
      pmsReservationId: reservationId,
      pmsUnitGroupId: unitGroupId
    },
    {
      skip: !reservationId || isRESTVersion || skipV2Call
    }
  );

  const units = isRESTVersion
    ? unitsByGroupIdV1
    : unitsByGroupIdV2.data?.GetAssignableUnits.map(transformUnitsV2ToCommonUnits);

  return (
    units
      ?.filter((unit) => unit.unitGroupId === unitGroupId)
      .sort((a, b) => a.name.localeCompare(b.name)) ?? []
  );
};

export const useUnits = ({ propertyId, refetchOnMountOrArgChange = false }: UnitsInterface) => {
  const { isRESTVersion, isGraphQlVersion } = useApiVersion();
  const dispatch = useDispatch();
  const { data: units } = useGetUnitsQueryV1(
    { propertyId },
    { skip: !propertyId || isGraphQlVersion, refetchOnMountOrArgChange }
  );

  const dispatchFetchAllUnits = useCallback(
    () =>
      dispatch(
        isRESTVersion
          ? unitEndpoints.util.invalidateTags(["Units"])
          : getUnitsEnhancedApi.util.invalidateTags(["Units"])
      ),
    [dispatch, isRESTVersion]
  );

  const { data: dataV2 } = useGetUnitsQuery(
    {
      filter: {
        pmsPropertyId: propertyId
      }
    },
    {
      skip: isRESTVersion || !propertyId,
      refetchOnMountOrArgChange
    }
  );

  return {
    data: isRESTVersion ? units : dataV2?.GetUnits?.map(transformUnitsV2ToCommonUnits),
    fetchAction: dispatchFetchAllUnits
  };
};
