import { JSONApiResponse } from "@likemagic-tech/sv-magic-library";
import { ApiKeyApiClient } from "./base/api-key-api-client";
import { Reservation } from "../domain/reservation";
import { TableDtoSortingParams } from "../store/endpoints/reservation-table.endpoints";
import { ReservationTableDto } from "../domain/reservation-table-dto";
import { transformObjToQueryParams } from "../utils/filters";

const BASE_URL = "/api/guest-journey-service/reservations";

class ReservationApiClient extends ApiKeyApiClient {
  async fetchArrivalsByDateAndPropertyId(
    date: string,
    propertyId: string,
    init?: RequestInit
  ): Promise<Reservation[]> {
    const response = await this.fetchApi(
      `${BASE_URL}/arrivals?date=${date}&propertyId=${propertyId}`,
      init
    );
    return new JSONApiResponse<Reservation[]>(response).value();
  }
  async fetchDeparturesByDateAndPropertyId(
    date: string,
    propertyId: string,
    init?: RequestInit
  ): Promise<Reservation[]> {
    const response = await this.fetchApi(
      `${BASE_URL}/departures?date=${date}&propertyId=${propertyId}`,
      init
    );
    return new JSONApiResponse<Reservation[]>(response).value();
  }

  async fetchInHotelByDateAndPropertyId(
    date: string,
    propertyId: string,
    init?: RequestInit
  ): Promise<Reservation[]> {
    const response = await this.fetchApi(
      `${BASE_URL}/in-hotel?date=${date}&propertyId=${propertyId}`,
      init
    );
    return new JSONApiResponse<Reservation[]>(response).value();
  }

  async downloadCSVInHotelByDateAndPropertyId(
    date: string,
    propertyId: string,
    init?: RequestInit
  ): Promise<Blob> {
    const response = await this.fetchApi(
      `${BASE_URL}/in-hotel/csv?date=${date}&propertyId=${propertyId}`,
      init
    );

    return response.blob();
  }

  async downloadXLSXArrivalsByDateAndPropertyId(
    date: string,
    propertyId: string,
    init?: RequestInit
  ): Promise<Blob> {
    const response = await this.fetchApi(
      `${BASE_URL}/arrivals/xlsx?date=${date}&propertyId=${propertyId}`,
      init
    );

    return response.blob();
  }

  async downloadCSVArrivalsByDateAndPropertyId(
    date: string,
    propertyId: string,
    init?: RequestInit
  ): Promise<Blob> {
    const response = await this.fetchApi(
      `${BASE_URL}/arrivals/csv?date=${date}&propertyId=${propertyId}`,
      init
    );

    return response.blob();
  }

  async downloadXLSXDeparturesByDateAndPropertyId(
    date: string,
    propertyId: string,
    init?: RequestInit
  ): Promise<Blob> {
    const response = await this.fetchApi(
      `${BASE_URL}/departures/xlsx?date=${date}&propertyId=${propertyId}`,
      init
    );

    return response.blob();
  }

  async downloadCSVDeparturesByDateAndPropertyId(
    date: string,
    propertyId: string,
    init?: RequestInit
  ): Promise<Blob> {
    const response = await this.fetchApi(
      `${BASE_URL}/departures/csv?date=${date}&propertyId=${propertyId}`,
      init
    );

    return response.blob();
  }

  async downloadXLSXInHotelByDateAndPropertyId(
    date: string,
    propertyId: string,
    init?: RequestInit
  ): Promise<Blob> {
    const response = await this.fetchApi(
      `${BASE_URL}/in-hotel/xlsx?date=${date}&propertyId=${propertyId}`,
      init
    );

    return response.blob();
  }

  async downloadXLSXReservationsForTable(
    arg: TableDtoSortingParams<ReservationTableDto>,
    init?: RequestInit
  ): Promise<Blob> {
    const response = await this.fetchApi(
      `/api/guest-journey-service/backoffice/${
        arg.propertyId
      }/reservations/export?${transformObjToQueryParams<ReservationTableDto>(arg)}`,
      init
    );
    return response.blob();
  }
}

export const ReservationApi = new ReservationApiClient();
