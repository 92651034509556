import { MenuItem, Select, Tooltip, useTheme } from "@mui/material";
import React, { FC, useMemo } from "react";
import { Button, Chip } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { SortedTaskPriorities, TaskPriority } from "../../domain/task";
import { FieldWithIcon } from "./field-with-icon";
import { FlagOutlined } from "@mui/icons-material";
import { InfoIcon } from "../../../../icons/info.icon";

interface PriorityShowInterface {
  status: TaskPriority | null;
  background?: boolean;
}

const PriorityShow: FC<PriorityShowInterface> = ({ status, background }) => {
  const { t } = useTranslationWrapper();
  const { palette } = useTheme();

  return (
    <Button
      variant="ghost"
      size="medium"
      sx={{
        backgroundColor: status && background ? statusColor(status, "light") : palette.common.white,
        color: statusColor(status, "dark")
      }}
    >
      {status ? t(`labels__task_priority_${status}`) : t("labels__task_priority")}
    </Button>
  );
};

export const statusColor = (status: TaskPriority | null | undefined, type: string) => {
  switch (status) {
    case TaskPriority.Low:
      return `success.${type}`;
    case TaskPriority.Medium:
      return `warning.${type}`;
    case TaskPriority.High:
      return `error.${type}`;
    case TaskPriority.Urgent:
      return `accent.${type}`;
    default:
      return "black";
  }
};

interface PrioritySelectProps {
  value: TaskPriority | null;
  onChange: (value: string) => void;
  pmsReservationIds?: string[] | null;
  departingReservationId?: string | null;
  arrivingReservationId?: string | null;
}

export const PrioritySelect: FC<PrioritySelectProps> = ({
  value,
  onChange,
  pmsReservationIds,
  departingReservationId,
  arrivingReservationId
}) => {
  const { t } = useTranslationWrapper();

  const hasReservationLink = useMemo(
    () =>
      !!pmsReservationIds?.length ||
      !!departingReservationId?.length ||
      !!arrivingReservationId?.length,
    [pmsReservationIds, departingReservationId, arrivingReservationId]
  );

  return (
    <FieldWithIcon icon={<FlagOutlined />}>
      <Select
        id={"priority"}
        data-stop-propagation
        value={value}
        renderValue={(s) => <PriorityShow status={s as TaskPriority} />}
        displayEmpty
        IconComponent={() => null}
        onChange={(e) => onChange(e.target.value ?? "")}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          paddingRight: 0,
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
            paddingRight: 0
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
            paddingRight: 0
          },
          ".MuiSelect-select": {
            padding: 0,
            paddingRight: "0!important"
          }
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          }
        }}
      >
        {SortedTaskPriorities.map((status) =>
          status === TaskPriority.Urgent && !hasReservationLink ? (
            <Tooltip
              title={t("labels__task_not_linked_to_reservation")}
              placement="right"
              key={`priority-` + status}
            >
              <span>
                <MenuItem disabled data-stop-propagation value={`${status}`}>
                  {t(`labels__task_priority_${status}`)}
                </MenuItem>
              </span>
            </Tooltip>
          ) : (
            <MenuItem data-stop-propagation value={`${status}`} key={`priority-` + status}>
              {t(`labels__task_priority_${status}`)}
            </MenuItem>
          )
        )}
      </Select>
      {value === TaskPriority.Urgent && (
        <Chip
          leftIcon={<InfoIcon />}
          label={t("labels__task_urgent_additional_info")}
          color="info"
          sx={{ maxWidth: "90%" }}
        />
      )}
    </FieldWithIcon>
  );
};
