import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { QueryCacheKey } from "@reduxjs/toolkit/dist/query/core/apiState";
import { useCallback, useContext, useMemo } from "react";
import {
  EntityStateStatus,
  isStatusFailed,
  isStatusLoading,
  isStatusSuccess
} from "src/domain/EntityStateStatus";
import { Price } from "src/domain/price";
import { TerminalPaymentSubscription } from "src/graphql/generated/graphql";
import { useCancelTerminalPaymentMutation } from "src/graphql/mutations/cancel-terminal-payment.generated";
import { terminalPaymentSubscribeApi } from "src/graphql/subscribtion/terminal-payment-subscribe";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { openBanner } from "src/slices/banner.slice";
import {
  selectTerminalPayment,
  setTerminalPayment,
  setTerminalPaymentModal,
  unsetTerminalPayment
} from "src/slices/payment.slice";
import { setLastSelectedTerminalId } from "src/slices/recently-used-devices.slice";
import { useDispatch, useSelector } from "src/store";
import { parsePricePickerAmountToAmountInCents } from "src/utils/price";

interface InitiateTerminalPayment {
  price: { amount: number; currency: string };
  magicId: string;
  propertyId: string;
  folioMetadata: any;
  selectedTerminal: { label: string; value: string };
}

interface SubscribeActionPayload {
  magicId: string;
  price: Price;
  terminalId: string;
  pmsPropertyId: string;
  folioMetadata?: any;
  handleResponse: (response?: TerminalPaymentSubscription) => void;
}

export const useTerminalPayment = () => {
  const dispatch = useDispatch();
  const { t } = useTranslationWrapper();
  const [cancelAction] = useCancelTerminalPaymentMutation();
  const terminalPayment = useSelector(selectTerminalPayment);
  const { tenantId, apiKey, keycloak } = useContext(TenantContext);
  const authToken = keycloak.token;

  const subscribeAction = useCallback(
    ({
      magicId,
      terminalId,
      pmsPropertyId,
      folioMetadata,
      price,
      handleResponse
    }: SubscribeActionPayload) =>
      dispatch(
        terminalPaymentSubscribeApi.endpoints.SubscribeToTerminalPayment.initiate(
          {
            tenantId,
            apiKey,
            authorization: authToken,
            paymentRequest: {
              magicId,
              amount: price,
              terminalId,
              shopperReference: magicId,
              pmsPropertyId,
              targetFolioMetadata: folioMetadata
            },
            handleResponse,
            // @ts-ignore
            skipSpinner: true
          },
          { forceRefetch: true }
        )
      ),
    [dispatch, tenantId, apiKey, authToken]
  );

  const unsubscribeAction = useCallback(
    (queryCacheKey: QueryCacheKey) => {
      dispatch(
        terminalPaymentSubscribeApi.internalActions.removeQueryResult({
          queryCacheKey: queryCacheKey
        })
      );
    },
    [dispatch]
  );

  const resetTerminalPayment = useCallback(() => {
    unsubscribeAction(terminalPayment.promiseRef?.queryCacheKey as QueryCacheKey);

    dispatch(unsetTerminalPayment());
  }, [unsubscribeAction, dispatch, terminalPayment.promiseRef]);

  const handleResponse = useCallback(
    (onSuccess: () => void, onFailed: () => void) => {
      return (data?: TerminalPaymentSubscription) => {
        dispatch(
          setTerminalPayment({
            serviceId: data?.TerminalPayment?.serviceId ?? undefined,
            transactionId: data?.TerminalPayment?.transactionId ?? undefined
          })
        );
        if (data?.TerminalPayment) {
          if (data?.TerminalPayment?.success === true) {
            dispatch(setTerminalPayment({ paymentStatus: EntityStateStatus.SUCCEEDED }));
            resetTerminalPayment();
            onSuccess();
          } else if (data?.TerminalPayment?.success === false) {
            dispatch(setTerminalPayment({ paymentStatus: EntityStateStatus.FAILED }));
            resetTerminalPayment();
            onFailed();
            dispatch(
              openBanner({
                type: "error",
                errorId: data?.TerminalPayment?.errorReason ?? undefined,
                title: t("labels__action_failed_to_perform")
              })
            );
          }
        }
      };
    },
    [t, dispatch, resetTerminalPayment]
  );

  const initiateTerminalPayment = useCallback(
    (
      { price, magicId, propertyId, folioMetadata, selectedTerminal }: InitiateTerminalPayment,
      onSuccess: () => void,
      onFailed: () => void,
      onInitiatingModalClose: () => void
    ) => {
      dispatch(
        setTerminalPayment({
          price,
          propertyId,
          selectedTerminal
        })
      );

      dispatch(setLastSelectedTerminalId(selectedTerminal.value));
      try {
        const promise = subscribeAction({
          terminalId: selectedTerminal.value,
          magicId: magicId,
          pmsPropertyId: propertyId,
          handleResponse: handleResponse(onSuccess, onFailed),
          price: {
            amount: parsePricePickerAmountToAmountInCents(price.amount),
            currency: price.currency
          },
          folioMetadata: folioMetadata
        });
        onInitiatingModalClose();
        dispatch(
          setTerminalPayment({
            promiseRef: promise,
            openModal: true,
            paymentStatus: EntityStateStatus.LOADING
          })
        );
      } catch (e) {
        console.warn(e);
        dispatch(setTerminalPaymentModal(false));
        onFailed();
      }
    },
    [dispatch, subscribeAction, handleResponse]
  );

  const handleCancelPayment = useCallback(
    async (values: { paymentTerminalId?: string; price: { amount: number; currency: string } }) => {
      if (
        terminalPayment.propertyId &&
        values.paymentTerminalId &&
        terminalPayment.serviceId &&
        terminalPayment.transactionId
      ) {
        const data = await cancelAction({
          cancelTerminalPaymentRequest: {
            pmsPropertyId: terminalPayment.propertyId,
            terminalId: values.paymentTerminalId,
            serviceId: terminalPayment.serviceId,
            transactionId: terminalPayment.transactionId
          }
        }).unwrap();
        if (data.CancelTerminalPayment) {
          dispatch(setTerminalPayment({ paymentStatus: EntityStateStatus.IDLE }));
        }
        resetTerminalPayment();
      }
    },
    [
      terminalPayment.propertyId,
      terminalPayment.serviceId,
      terminalPayment.transactionId,
      dispatch,
      cancelAction,
      resetTerminalPayment
    ]
  );

  const isCancelAvailable = useMemo(() => {
    return !(
      terminalPayment.propertyId &&
      terminalPayment.serviceId &&
      terminalPayment.transactionId
    );
  }, [terminalPayment.propertyId, terminalPayment.serviceId, terminalPayment.transactionId]);

  const isLoading = isStatusLoading(terminalPayment.paymentStatus);
  const isSuccess = isStatusSuccess(terminalPayment.paymentStatus);
  const isFailed = isStatusFailed(terminalPayment.paymentStatus);

  return {
    isLoading,
    isSuccess,
    isFailed,
    initiateTerminalPayment,
    isCancelAvailable,
    handleCancelPayment,
    resetTerminalPayment,
    propertyId: terminalPayment.propertyId,
    price: terminalPayment.price,
    openModal: terminalPayment.openModal,
    selectedTerminal: terminalPayment.selectedTerminal
  };
};
