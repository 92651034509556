import { createApi, defaultSerializeQueryArgs } from "@reduxjs/toolkit/query/react";
import { magicBaseQuery } from "./base-query";
import { BlobResponse } from "./task-management-files.api";

const GUEST_SERVICE_FILES_BASE_URL = "/api/guest-service/files/backoffice";

export const guestServiceFilesApi = createApi({
  reducerPath: "guest-service-files",
  baseQuery: magicBaseQuery({ baseUrl: GUEST_SERVICE_FILES_BASE_URL }),
  tagTypes: ["Files"],
  serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
    const args = { ...queryArgs };
    delete args["skipSpinner" as keyof typeof args];
    return defaultSerializeQueryArgs({
      endpointName,
      queryArgs: args,
      endpointDefinition
    });
  },
  endpoints: (builder) => ({
    getFile: builder.query<BlobResponse, { propertyId: string; fileName: string }>({
      query: (arg) => ({
        headers: { method: "GET" },
        url: `/${arg.propertyId}/${arg.fileName}`,
        isBlob: true,
        cache: "no-cache"
      })
    })
  })
});

export const { useLazyGetFileQuery } = guestServiceFilesApi;
