import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetToBeCleanedTodayQueryVariables = Types.Exact<{
  propertyPmsId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type GetToBeCleanedTodayQuery = {
  __typename?: "Query";
  GetProperty?: {
    __typename?: "Property";
    pmsId: string;
    toBeCleanedToday?: Array<{
      __typename?: "CleaningDTO";
      pmsUnitId: string;
      arrivalPmsReservationId?: string | null;
      departurePmsReservationId?: string | null;
      servicePmsReservationId?: string | null;
      pmsUnitGroupIds?: Array<string | null> | null;
      name: string;
      occupied: boolean;
      condition: Types.UnitState;
      priority: Types.Priority;
      floorNumber?: number | null;
      adults?: number | null;
      children?: number | null;
      arrival?: any | null;
      contractualCheckInTime?: any | null;
      departure?: any | null;
      expectedDeparture?: any | null;
      services?: Array<{
        __typename?: "BackofficeService";
        name?: string | null;
        code?: Types.ServiceCodeEnum | null;
        quantity?: number | null;
        price?: {
          __typename?: "Price";
          currency?: string | null;
          grossPrice?: any | null;
          grossPriceInCents?: number | null;
          netPrice?: any | null;
          netPriceInCents?: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export const GetToBeCleanedTodayDocument = `
    query GetToBeCleanedToday($propertyPmsId: String) {
  GetProperty(filter: {pmsId: $propertyPmsId}) {
    pmsId
    toBeCleanedToday {
      pmsUnitId
      arrivalPmsReservationId
      departurePmsReservationId
      servicePmsReservationId
      pmsUnitGroupIds
      name
      occupied
      condition
      priority
      floorNumber
      adults
      children
      arrival
      contractualCheckInTime
      departure
      expectedDeparture
      services {
        name
        code
        quantity
        price {
          currency
          grossPrice
          grossPriceInCents
          netPrice
          netPriceInCents
        }
      }
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetToBeCleanedToday: build.query<
      GetToBeCleanedTodayQuery,
      GetToBeCleanedTodayQueryVariables | void
    >({
      query: (variables) => ({ document: GetToBeCleanedTodayDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetToBeCleanedTodayQuery, useLazyGetToBeCleanedTodayQuery } = injectedRtkApi;
