import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type AddArbitraryOrderItemsMutationVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
  addArbitraryOrderItemsInput:
    | Array<Types.AddArbitraryOrderItemInput>
    | Types.AddArbitraryOrderItemInput;
}>;

export type AddArbitraryOrderItemsMutation = {
  __typename?: "Mutation";
  BackofficeAddArbitraryOrderItems?: boolean | null;
};

export const AddArbitraryOrderItemsDocument = `
    mutation AddArbitraryOrderItems($pmsReservationId: String!, $addArbitraryOrderItemsInput: [AddArbitraryOrderItemInput!]!) {
  BackofficeAddArbitraryOrderItems(
    pmsReservationId: $pmsReservationId
    addArbitraryOrderItemsInput: $addArbitraryOrderItemsInput
  )
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    AddArbitraryOrderItems: build.mutation<
      AddArbitraryOrderItemsMutation,
      AddArbitraryOrderItemsMutationVariables
    >({
      query: (variables) => ({ document: AddArbitraryOrderItemsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useAddArbitraryOrderItemsMutation } = injectedRtkApi;
