import { useFetchAvailableDoors } from "./use-fetch-available-doors";
import { useUnits } from "./use-unit-by-id";
import { UnitDashboardDTO } from "../domain/UnitDashboardDTO";

export const useUnitsWithAvailableDoors = (propertyId: string) => {
  const { data: allUnits } = useUnits({
    propertyId,
    refetchOnMountOrArgChange: true
  });

  const { allAvailableDoors: availableDoors } = useFetchAvailableDoors(propertyId);

  const filteredAllUnits = allUnits?.map((value) => ({
    ...value
  }));

  const result: UnitDashboardDTO[] | undefined = filteredAllUnits?.map((unit) => {
    const availableDoor = availableDoors.find((door) => door.unitId === unit.id);
    return availableDoor
      ? {
          ...unit,
          // Using both because in V1 we get the privacyMode value from the door, in V2 we get it from the unit
          privacyMode: availableDoor.privacyMode || unit.privacyMode,
          privacyModeLastChangeTimestamp: availableDoor.privacyModeLastChangeTimestamp,
          door: {
            batteryLevel: availableDoor.batteryLevel,
            title: availableDoor.title,
            pin: availableDoor.pin,
            id: availableDoor.id
          }
        }
      : unit;
  });

  return result;
};
