import { useCallback } from "react";
import { StreamBackofficeSecondScreenEventsSubscription } from "../../domain/manual-checkin";
import { openBanner } from "../../slices/banner.slice";
import { useDispatch } from "../../store";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";

interface UseHandleShareToDeviceResponseProps {
  onStartSharing: () => void;
  onDeviceNotAvailable: () => void;
  onConfirm: () => void;
}

export const useHandleShareToDeviceResponse = ({
  onStartSharing,
  onDeviceNotAvailable,
  onConfirm
}: UseHandleShareToDeviceResponseProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslationWrapper();

  const handleResponse = useCallback(
    (response: {
      errors?: Array<{ message: string }>;
      data: StreamBackofficeSecondScreenEventsSubscription;
    }) => {
      const payload = response.data.StreamBackofficeSecondScreenEvents?.payload;

      if (payload?.__typename === "VerifyBillAck" && payload?.payload?.accepted) {
        onStartSharing();
      }

      if (payload?.__typename === "VerifyAndSignAck" && payload?.payload?.accepted) {
        onStartSharing();
      }

      if (
        (payload?.__typename === "VerifyBillAck" || payload?.__typename === "VerifyAndSignAck") &&
        !payload?.payload?.accepted
      ) {
        dispatch(
          openBanner({
            type: "error",
            title: t("labels__selected_device_is_not_available")
          })
        );
        onDeviceNotAvailable();
      }

      if (payload?.__typename === "BillingConfirmed") {
        dispatch(
          openBanner({
            type: "success",
            title: t("labels__billing_confirmed_successfully")
          })
        );
        onConfirm();
      }

      if (payload?.__typename === "Signed") {
        dispatch(
          openBanner({
            type: "success",
            title: t("labels__signed_successfully")
          })
        );
        onConfirm();
      }
    },
    [onStartSharing, dispatch, t, onDeviceNotAvailable, onConfirm]
  );

  return { handleResponse };
};
