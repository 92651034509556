import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState: { paymentLink: string | undefined } = {
  paymentLink: undefined
};

export const sendPaymentLinkModalSlice = createSlice({
  name: "sendPaymentLinkModal",
  initialState,
  reducers: {
    setPaymentLink(state, action: PayloadAction<string | undefined>) {
      state.paymentLink = action.payload;
    }
  }
});

export const { setPaymentLink } = sendPaymentLinkModalSlice.actions;
export const { reducer } = sendPaymentLinkModalSlice;

export const paymentLinkSelector = (state: RootState) => state.sendPaymentLinkModal.paymentLink;
