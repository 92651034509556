import { api } from "../GetAdditionalServicesAvailability.generated";

export const getAdditionalServicesAvailabilityEnhanced = api.enhanceEndpoints({
  endpoints: {
    GetAdditionalServicesAvailability: { providesTags: ["AdditionalServicesAvailability"] }
  }
});

export const {
  useGetAdditionalServicesAvailabilityQuery: useGetAdditionalServicesAvailabilityQueryEnhanced
} = getAdditionalServicesAvailabilityEnhanced;
