import { createSlice, PayloadAction, Selector } from "@reduxjs/toolkit";
import { ApiError } from "@likemagic-tech/sv-magic-library";
import { RootState } from "../store";

const DEFAULT_TIMEOUT_FOR_BANNER = 5000;

export interface BannerSliceState {
  title: string;
  subtitle?: string;
  errorId?: string;
  isOpen: boolean;
  type: "error" | "success";
  timeout?: number;
}

const initialState: BannerSliceState = {
  isOpen: false,
  type: "error",
  title: ""
};
//V1 skip banner handler
const skipBannerForActionWithPrefix = (actionType: string) =>
  ["payment/"].some((prefix) => actionType.startsWith(prefix));

//V2 skip banner handler
const skipBannerForEndpointName = (actionType?: string) =>
  ["AdminOpenDoor", "OpenDoor"].some((prefix) => actionType?.startsWith(prefix));

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    openBanner: (state, action: PayloadAction<Omit<BannerSliceState, "isOpen">>) => {
      state.isOpen = true;
      state.title = action.payload.title;
      state.subtitle = action.payload.subtitle;
      state.type = action.payload.type;
      // In case it's not passed we use the default one. (4 secs).
      state.errorId = action.payload.errorId;
      state.timeout = action.payload.timeout || DEFAULT_TIMEOUT_FOR_BANNER;
    },
    closeBanner: (state) => {
      state.isOpen = false;
      state.timeout = undefined;
      state.errorId = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type.endsWith("/rejected") &&
        !skipBannerForActionWithPrefix(action.type) &&
        !skipBannerForEndpointName(action.meta?.arg?.endpointName),
      (state, action) => {
        const error: ApiError = action?.payload;

        if (error?.status >= 400) {
          state.isOpen = true;
          state.title = error.message;
          state.subtitle = "";
          //  setting the errorId so the title will be resolved from prismic in the Notification component itself
          state.errorId = error.id;
          state.type = "error";
          state.timeout = DEFAULT_TIMEOUT_FOR_BANNER;
        }
      }
    );
  }
});

export const { openBanner, closeBanner } = bannerSlice.actions;

export const selectBannerState: Selector<RootState, BannerSliceState> = (state: RootState) =>
  state[bannerSlice.name];
