import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type AddPreferenceToProfileMutationVariables = Types.Exact<{
  userProfileId: Types.Scalars["Int"]["input"];
  pmsPreferenceId: Types.Scalars["String"]["input"];
}>;

export type AddPreferenceToProfileMutation = {
  __typename?: "Mutation";
  AddPreferenceToProfile: {
    __typename?: "UserProfile";
    id: number;
    userAccountUuid?: string | null;
  };
};

export const AddPreferenceToProfileDocument = `
    mutation AddPreferenceToProfile($userProfileId: Int!, $pmsPreferenceId: String!) {
  AddPreferenceToProfile(
    userProfileId: $userProfileId
    pmsPreferenceId: $pmsPreferenceId
  ) {
    id
    userAccountUuid
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    AddPreferenceToProfile: build.mutation<
      AddPreferenceToProfileMutation,
      AddPreferenceToProfileMutationVariables
    >({
      query: (variables) => ({ document: AddPreferenceToProfileDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useAddPreferenceToProfileMutation } = injectedRtkApi;
