import { BulkActionType, UnitActionType } from "../pages/dirty-units/dirty-units-actions";
import { UnitCondition } from "../domain/Unit";
import { useCallback, useEffect, useState } from "react";
import { useUpdateUnits } from "./use-update-units";
import { useUnitsWithAvailableDoors } from "./use-units-with-available-doors";
import { useDispatch } from "../store";
import { useAddMaintenanceSlot } from "./use-maintenance";
import { useProperty } from "./use-property";
import { useFetchUnitsToBeCleaned } from "./use-fetch-units-to-be-cleaned";
import { openBanner } from "../slices/banner.slice";
import { useTranslationWrapper } from "./use-translation-wrapper";
import { useTaskModal } from "../features/tasks/use-task-select-id";

export const useUnitBulkActions = () => {
  const { selectedProperty } = useProperty();

  const { t } = useTranslationWrapper();

  const { fetchAction } = useFetchUnitsToBeCleaned({
    propertyId: selectedProperty?.propertyId ?? ""
  });

  const [maintenanceModalOpen, setMaintenanceModalOpen] = useState(false);

  const [addMaintenance, addResult] = useAddMaintenanceSlot();

  const dispatch = useDispatch();

  const { openNewTask } = useTaskModal({});

  const units = useUnitsWithAvailableDoors(selectedProperty?.propertyId ?? "");

  const [UpdateUnits] = useUpdateUnits();

  const refreshUnits = useCallback(() => {
    if (!selectedProperty?.propertyId) {
      return;
    }

    return fetchAction();
  }, [fetchAction, selectedProperty?.propertyId]);

  const bulkUpdate = async (condition: UnitCondition) => {
    if (units?.filter((value) => value.status.condition !== condition).length === 0) {
      // no need to make a BE call in case there are zero elements
      return;
    }

    const unitIds = units
      ?.filter((unit) => unit.status.condition !== condition)
      ?.map((unit) => unit.id);

    await UpdateUnits({
      unitIds: unitIds ?? [],
      condition
    });
  };
  const bulkAction = async (selectedItem: string) => {
    switch (selectedItem) {
      case BulkActionType.ALL_TO_CLEAN:
        await bulkUpdate(UnitCondition.CLEAN);
        break;
      case BulkActionType.ALL_TO_DIRTY:
        await bulkUpdate(UnitCondition.DIRTY);
        break;
      case BulkActionType.ALL_TO_CLEAN_TO_BE_INSPECTED:
        await bulkUpdate(UnitCondition.CLEAN_TO_BE_INSPECTED);
        break;
      case UnitActionType.MAINTENANCE:
        setMaintenanceModalOpen(true);
        return;
      case UnitActionType.CREATE_TASK:
        return openNewTask();
    }
    refreshUnits();
  };

  useEffect(() => {
    if (addResult?.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      addResult.reset();
      fetchAction();
      setMaintenanceModalOpen(false);
    }
  }, [dispatch, t, addResult, fetchAction]);

  return {
    bulkAction,
    maintenanceModalOpen,
    addMaintenance,
    setMaintenanceModalOpen
  };
};
