import React, { FC, useEffect, useMemo } from "react";
import { useTheme } from "@mui/material/styles";

export const AppDetailsContent = {
  DESCRIPTION: "LIKE MAGIC - Backoffice",
  NAME: "LIKE MAGIC - Backoffice",
  SHORT_NAME: "LIKE MAGIC - Backoffice",
  ICONS: [
    {
      src: `${window.location.origin}/likemagic/logo192.png`,
      type: "image/png",
      sizes: "192x192"
    },
    {
      src: `${window.location.origin}/likemagic/favicon.png`,
      sizes: "64x64 32x32 24x24 16x16",
      type: "image/x-icon"
    },
    {
      src: `${window.location.origin}/likemagic/logo512.png`,
      type: "image/png",
      sizes: "512x512"
    },
    {
      src: `${window.location.origin}/likemagic/maskable_icon.png`,
      type: "image/png",
      sizes: "192x192",
      purpose: "maskable"
    }
  ]
};

export const MetaUpdate: FC = () => {
  const { palette } = useTheme();

  const pageDetails = useMemo(
    () => ({
      name: AppDetailsContent?.NAME,
      description: AppDetailsContent?.SHORT_NAME,
      appName: AppDetailsContent?.NAME,
      title: AppDetailsContent?.NAME,
      image: AppDetailsContent.ICONS[0]?.src,
      themeColor: palette.primary.main
    }),
    [palette.primary.main]
  );

  useEffect(() => {
    const ogTitle = document.createElement("meta");
    const ogDescription = document.createElement("meta");
    const description = document.createElement("meta");
    const appName = document.createElement("meta");
    const themeColor = document.createElement("meta");
    const ogImage = document.createElement("meta");

    if (pageDetails?.name) {
      ogTitle.setAttribute("property", "og:title");
      ogTitle.content = pageDetails?.name;
      document.getElementsByTagName("head")[0].appendChild(ogTitle);
    }

    if (pageDetails?.description) {
      ogDescription.setAttribute("property", "og:description");
      ogDescription.content = pageDetails?.description;
      document.getElementsByTagName("head")[0].appendChild(ogDescription);
    }

    if (pageDetails?.description) {
      description.setAttribute("property", "description");
      description.content = pageDetails?.description;
      document.getElementsByTagName("head")[0].appendChild(description);
    }

    if (pageDetails?.themeColor) {
      themeColor.setAttribute("property", "theme-color");
      themeColor.content = pageDetails?.themeColor;
      document.getElementsByTagName("head")[0].appendChild(themeColor);
    }

    if (pageDetails?.appName) {
      appName.setAttribute("property", "application-name");
      appName.content = pageDetails?.appName;
      document.getElementsByTagName("head")[0].appendChild(appName);
    }
    if (pageDetails?.image) {
      ogImage.setAttribute("property", "og:image");
      ogImage.content = pageDetails?.image;
      document.getElementsByTagName("head")[0].appendChild(ogImage);
    }

    return () => {
      for (const metaElement of [
        ogTitle,
        ogDescription,
        themeColor,
        appName,
        description,
        ogImage
      ]) {
        metaElement?.parentNode?.removeChild(metaElement);
      }
    };
  }, [pageDetails]);

  return <> </>;
};
