import { api } from "../remove-preference-from-profile.generated";

const getUserProfileByIdEnhanced = api.enhanceEndpoints({
  endpoints: {
    RemovePreferenceFromProfile: {
      invalidatesTags: (result, error, arg) => [
        { type: "UserProfile" as any, id: arg.userProfileId },
        "UserProfile"
      ]
    }
  }
});

export const {
  useRemovePreferenceFromProfileMutation: useRemovePreferenceFromProfileMutationEnhanced
} = getUserProfileByIdEnhanced;
