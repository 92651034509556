import { OrderItemTransactionType } from "../generated/graphql";
import { transformFullPrice } from "./transform-utils";
import { OrderItemTransactionTypeResponse } from "../../domain/order-item-transaction-type";

export const transformOrderItemTransactionType = (
  param: OrderItemTransactionType,
  defaultCurrency?: string
): OrderItemTransactionTypeResponse => {
  // TODO - hotfix while defaultPrice is not available from the API
  const price = {
    ...param.defaultPrice,
    currency: param.defaultPrice?.currency ?? defaultCurrency
  };

  return {
    pmsId: param.pmsId ?? "",
    transactionTypeCode: param?.transactionTypeCode ?? "",
    name: param?.name ?? "",
    price: transformFullPrice(price)
  };
};
