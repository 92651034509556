import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

export const MESSAGE_BASE_URL = "/api/unified-messaging/graphql";
export const MessagingGraphqlClient = new GraphQLClient(MESSAGE_BASE_URL);

export const messagingApi = createApi({
  reducerPath: "graphql-messaging",
  baseQuery: graphqlRequestBaseQuery({
    client: MessagingGraphqlClient,
    prepareHeaders: async (headers, api) => {
      // @ts-ignore
      const keycloak: any | undefined = api?.extra?.keycloak;
      if (keycloak?.authenticated && keycloak?.isTokenExpired()) {
        await keycloak.updateToken(5);
      }

      if (keycloak?.authenticated && keycloak?.token) {
        headers.delete("Authorization");
        headers.set("Authorization", `Bearer ${keycloak.token}`);
      }

      return headers;
    },
    customErrors: ({ name, stack, response }) => {
      const { errorCode = 500 } = response?.errors?.length ? response?.errors[0]?.extensions : {};
      const { message = "" } = response?.errors?.length ? response?.errors[0] : {};
      return {
        name,
        message,
        status: handleErrorStatus(errorCode),
        stack
      };
    }
  }),
  tagTypes: ["ConversationDetails"],
  endpoints: () => ({})
});

const handleErrorStatus = (errorCode: string) => {
  switch (errorCode) {
    case "errors_unauthorized":
      return 401;
    default:
      return 500;
  }
};
