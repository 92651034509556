import { useNavigate } from "react-router-dom";
import { useProperty } from "./use-property";
import { Params } from "./use-select-id";

export const useNavigateWithPropertyId = () => {
  const navigate = useNavigate();
  const { selectedProperty, selectedPropertyIds } = useProperty();

  return (path: string, params?: URLSearchParams) => {
    const searchParamWithPropertyId = new URLSearchParams(params);

    if (selectedPropertyIds) {
      searchParamWithPropertyId.set(Params.SELECTED_PROPERTY_ID, selectedPropertyIds.join(","));
    } else if (selectedProperty?.propertyId) {
      searchParamWithPropertyId.set(Params.SELECTED_PROPERTY_ID, selectedProperty?.propertyId);
    }
    navigate({
      pathname: path,
      search: "?" + searchParamWithPropertyId
    });
  };
};
