import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetOrderItemTransactionTypesQueryVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
}>;

export type GetOrderItemTransactionTypesQuery = {
  __typename?: "Query";
  GetOrderItemTransactionTypes: Array<{
    __typename?: "OrderItemTransactionType";
    pmsId?: string | null;
    name?: string | null;
    transactionTypeCode?: string | null;
    defaultPrice?: {
      __typename?: "GrossPrice";
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      currency?: string | null;
    } | null;
  }>;
};

export const GetOrderItemTransactionTypesDocument = `
    query GetOrderItemTransactionTypes($pmsPropertyId: String!) {
  GetOrderItemTransactionTypes(pmsPropertyId: $pmsPropertyId) {
    pmsId
    name
    defaultPrice {
      grossPrice
      grossPriceInCents
      currency
    }
    transactionTypeCode
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetOrderItemTransactionTypes: build.query<
      GetOrderItemTransactionTypesQuery,
      GetOrderItemTransactionTypesQueryVariables
    >({
      query: (variables) => ({ document: GetOrderItemTransactionTypesDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetOrderItemTransactionTypesQuery, useLazyGetOrderItemTransactionTypesQuery } =
  injectedRtkApi;
