import { JSONApiResponse } from "@likemagic-tech/sv-magic-library";
import { ApiKeyApiClient } from "./base/api-key-api-client";
import { Property } from "../domain/property";

const BASE_URL = "/api/guest-journey-service/properties";

class PropertyApiClient extends ApiKeyApiClient {
  async fetchProperties(init?: RequestInit): Promise<Property[]> {
    const response = await this.fetchApi(BASE_URL, init);
    return new JSONApiResponse<Property[]>(response).value();
  }
}

export const PropertyApi = new PropertyApiClient();
