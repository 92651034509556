import * as yup from "yup";
import { StringSchema } from "yup";
import parsePhoneNumber from "libphonenumber-js/mobile";

yup.addMethod<StringSchema>(yup.string, "phone", function (message: string) {
  return this.test({
    message,
    name: "phone",
    exclusive: true,
    params: {},
    test(value) {
      if (!value) {
        return true;
      }
      const phoneNumber = parsePhoneNumber(value);
      return Boolean(phoneNumber?.isValid() && phoneNumber?.getType() === "MOBILE");
    }
  });
});

export default yup;
