import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetReservationsPageQueryVariables = Types.Exact<{
  filter: Types.ReservationsPageFilter;
}>;

export type GetReservationsPageQuery = {
  __typename?: "Query";
  GetReservationsPage: {
    __typename?: "ReservationsPage";
    totalElements: number;
    content: Array<{
      __typename?: "Reservation";
      displayId: string;
      id: number;
      arrival: any;
      departure: any;
      magicId: string;
      status: Types.ReservationStatus;
      pmsId: string;
      channel?: string | null;
      unit?: { __typename?: "Unit"; name?: string | null } | null;
      property: { __typename?: "Property"; name?: string | null; timezone?: string | null };
      primaryGuest?: {
        __typename?: "Person";
        userProfileId?: number | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
  };
};

export const GetReservationsPageDocument = `
    query GetReservationsPage($filter: ReservationsPageFilter!) {
  GetReservationsPage(filter: $filter) {
    totalElements
    content {
      displayId
      id
      arrival
      departure
      magicId
      status
      pmsId
      unit {
        name
      }
      property {
        name
        timezone
      }
      channel
      primaryGuest {
        userProfileId
        firstName
        lastName
      }
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetReservationsPage: build.query<GetReservationsPageQuery, GetReservationsPageQueryVariables>({
      query: (variables) => ({ document: GetReservationsPageDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetReservationsPageQuery, useLazyGetReservationsPageQuery } = injectedRtkApi;
