import { useGetActors } from "../features/tasks/use-get-actors";
import { useUserInfo } from "./use-user-info";

export const useCurrentActor = () => {
  const { data: actorsData } = useGetActors();
  const actors = actorsData?.GetActors;
  const { userInfo } = useUserInfo();

  const currentActorId =
    actors?.find((item) => item?.keycloakUUID === userInfo?.sub)?.actorId ?? "";

  return {
    currentActorId
  };
};
