import { ManualCheckinStepsEnum } from "../features/reservations/domain/enums";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Params } from "./use-select-id";
import { usePropertyConfig } from "@likemagic-tech/sv-magic-library";

export const useManualCheckinSteps = ({ propertyId }: { propertyId?: string }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { features } = usePropertyConfig({
    propertyId
  });

  const computedSteps = useMemo(() => {
    const steps = Object.values(ManualCheckinStepsEnum);

    return steps.filter((item) =>
      item === ManualCheckinStepsEnum.UPSELL_UNIT_GROUP ? features?.upsellUnitGroupEnabled : true
    );
  }, [features?.upsellUnitGroupEnabled]);

  const currentStep = useMemo(() => {
    return searchParams.get(Params.MANUAL_CHECKIN_STEP);
  }, [searchParams]);

  const goToStep = (step: ManualCheckinStepsEnum) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(Params.MANUAL_CHECKIN_STEP, step);

    setSearchParams(newSearchParams);
  };

  const nextStep = (skipPage?: ManualCheckinStepsEnum) => {
    const currentStepIndex = computedSteps.findIndex((step) => step === currentStep);
    const skipPageIndex = computedSteps.findIndex((step) => step === skipPage);
    const nextPageIndex = currentStepIndex + 1;
    const nextStep =
      computedSteps[nextPageIndex === skipPageIndex ? nextPageIndex + 1 : nextPageIndex];

    goToStep(nextStep);
  };

  const previousStep = (skipPage?: ManualCheckinStepsEnum) => {
    const currentStepIndex = computedSteps.findIndex((step) => step === currentStep);
    const skipPageIndex = computedSteps.findIndex((step) => step === skipPage);
    const prevPageIndex = currentStepIndex - 1;
    const previousStep =
      computedSteps[prevPageIndex === skipPageIndex ? prevPageIndex + -1 : prevPageIndex];

    goToStep(previousStep);
  };

  return {
    steps: computedSteps,
    currentStep,
    nextStep,
    previousStep,
    goToStep
  };
};
