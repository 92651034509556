import * as React from "react";
import { FC } from "react";
import { ProfileDrawerCards } from "./profile-drawer-cards/profile-drawer-cards";
import { ProfileDrawerPersonalInformationCard } from "./profile-drawer-cards/profile-drawer-personal-information-card";
import { ProfileDrawerPreferencesCard } from "./profile-drawer-cards/profile-drawer-preferences-card";
import { ProfileDrawerAddressInformationCard } from "./profile-drawer-cards/profile-drawer-address-information-card";
import { Profile } from "../types/profile";

interface ProfileDrawerDetailsProps {
  profile?: Profile | null;
}

export const ProfileDrawerDetails: FC<ProfileDrawerDetailsProps> = ({ profile }) => {
  return (
    <ProfileDrawerCards>
      <ProfileDrawerPersonalInformationCard profileInformation={profile} />
      <ProfileDrawerAddressInformationCard addressInformation={profile?.address} />
      <ProfileDrawerPreferencesCard
        preferences={profile?.preferences}
        userFullName={`${profile?.firstName} ${profile?.lastName}`}
      />
    </ProfileDrawerCards>
  );
};
