import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type BackofficeAdjustOrderItemMutationVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
  adjustOrderItemInput?: Types.InputMaybe<Types.AdjustOrderItemInput>;
}>;

export type BackofficeAdjustOrderItemMutation = {
  __typename?: "Mutation";
  BackofficeAdjustOrderItem?: Array<{ __typename?: "OrderItem"; pmsId: string } | null> | null;
};

export const BackofficeAdjustOrderItemDocument = `
    mutation BackofficeAdjustOrderItem($pmsPropertyId: String!, $adjustOrderItemInput: AdjustOrderItemInput) {
  BackofficeAdjustOrderItem(
    pmsPropertyId: $pmsPropertyId
    adjustOrderItemInput: $adjustOrderItemInput
  ) {
    pmsId
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    BackofficeAdjustOrderItem: build.mutation<
      BackofficeAdjustOrderItemMutation,
      BackofficeAdjustOrderItemMutationVariables
    >({
      query: (variables) => ({ document: BackofficeAdjustOrderItemDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useBackofficeAdjustOrderItemMutation } = injectedRtkApi;
