import * as Types from "../generated/graphql";

import { ActorFragmentDoc } from "../fragments/Actor.generated";
import { messagingApi } from "../messaging.api";
export type StreamConversationSubscriptionVariables = Types.Exact<{
  filter: Types.ConversationFilter;
}>;

export type StreamConversationSubscription = {
  __typename?: "Subscription";
  StreamConversation: {
    __typename?: "ConversationsPage";
    totalElements: number;
    content: Array<{
      __typename?: "Conversation";
      conversationId: string;
      lastReceivedMessage?: any | null;
      messageBirdVersion?: string | null;
      status: Types.ConversationStatus;
      numberOfUnreadMessages: number;
      updatedAt?: string | null;
      pmsPropertyId: string;
      assignee?: {
        __typename?: "Actor";
        actorId?: string | null;
        actorGroupId?: string | null;
        displayName?: string | null;
      } | null;
      guest: {
        __typename?: "Actor";
        actorId?: string | null;
        actorGroupId?: string | null;
        displayName?: string | null;
      };
      lastMessage: Array<{
        __typename?: "Message";
        messageId: string;
        messageBirdVersion?: string | null;
        content: any;
        updatedAt: any;
        channel: { __typename?: "Channel"; platform: Types.Platform };
        from?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorGroupId?: string | null;
          displayName?: string | null;
        } | null;
      } | null>;
    }>;
  };
};

export const StreamConversationDocument = `
    subscription StreamConversation($filter: ConversationFilter!) {
  StreamConversation(filter: $filter) {
    content {
      assignee {
        ...Actor
      }
      conversationId
      lastReceivedMessage
      messageBirdVersion
      guest {
        ...Actor
      }
      status
      numberOfUnreadMessages
      updatedAt
      pmsPropertyId
      lastMessage: messages(
        filter: {pageable: {pageNumber: 0, pageSize: 1, sort: "createdAt,desc"}}
      ) {
        channel {
          platform
        }
        messageId
        messageBirdVersion
        content
        updatedAt
        from {
          ...Actor
        }
      }
    }
    totalElements
  }
}
    ${ActorFragmentDoc}`;

const injectedRtkApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({})
});

export { injectedRtkApi as api };
export const {} = injectedRtkApi;
