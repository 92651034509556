export const ALL_COUNTRIES = "ALL";

export const isDisabledIdentificationForCountry = (
  disabledCountries: string[] | undefined,
  country?: string
) => {
  if (disabledCountries?.includes(ALL_COUNTRIES)) {
    return true;
  }
  return !!country && disabledCountries?.includes(country);
};
