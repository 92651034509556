import { api } from "../add-services-to-reservation.generated";

const getAddServicesToReservationEnhanced = api.enhanceEndpoints({
  endpoints: {
    AddServicesToReservation: {
      invalidatesTags: ["AdditionalServicesAvailability"]
    }
  }
});

export const { useAddServicesToReservationMutation: useAddServicesToReservationMutationEnhanced } =
  getAddServicesToReservationEnhanced;
