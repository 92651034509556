import { Accordion, AccordionDetails, AccordionSummary, Box, Grid } from "@mui/material";
import { FC } from "react";
import { Heading3 } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReservationDetailsDTO } from "../../../store/endpoints/reservation-table.endpoints";
import { emptyPrice } from "src/domain/price";
import { FolioItemDetails } from "./folio-item-details";
import { useProperty } from "../../../hooks/use-property";

interface PaymentFoliosPreviewProps {
  nextStep: () => void;
  reservation: ReservationDetailsDTO;
  propertyId: string;
}
export const PaymentFoliosEmptyPreview: FC<PaymentFoliosPreviewProps> = ({
  reservation,
  propertyId
}) => {
  const { t } = useTranslationWrapper();
  const { getProperty } = useProperty();
  const property = getProperty(propertyId);

  return (
    <Box>
      <Accordion
        sx={{
          boxShadow: "none",
          borderRadius: 2,
          mb: 2,
          "&:before": {
            display: "none"
          }
        }}
        defaultExpanded={false}
        disableGutters
      >
        <AccordionSummary
          sx={{ px: 3, py: 1.5, border: "none" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="last-reservations"
          id="last-reservations"
        >
          <Grid container gap={2} alignItems="center">
            <Box display="flex" alignItems="center">
              <Heading3>{`${t("labels__new_folio")}`}</Heading3>
            </Box>
            {/* <Box width="15%" onClick={(e) => e.stopPropagation()}>
              <NativeSelect
                id={"payer"}
                name={"payer"}
                value={"Guest"}
                variant="standard"
                disabled
                items={[
                  { label: "Guest", value: "Guest" },
                  { label: "Company", value: "Company" }
                ]}
                label={t("labels__payer")}
                onChange={() => {}}
              />
            </Box>
            <Box width="20%" onClick={(e) => e.stopPropagation()}>
              <NativeSelect
                id={"billingAddress"}
                name={"billingAddress"}
                value={"Guest"}
                variant="standard"
                disabled
                items={[
                  { label: "Guest", value: "Guest" },
                  { label: "Company", value: "Company" }
                ]}
                label={t("labels__billing_address")}
                onChange={() => {}}
              />
            </Box> */}
          </Grid>
        </AccordionSummary>

        <AccordionDetails sx={{ p: 3, pt: 0 }}>
          {property?.details?.timeZone && (
            <FolioItemDetails
              folio={{
                id: "",
                number: "",
                charges: [],
                payments: [],
                balance: emptyPrice(property?.details?.currencyCode),
                closed: false,
                metadata: {},
                pendingPayments: [],
                createdAt: ""
              }}
              magicId={reservation.magicId}
              propertyId={propertyId}
              propertyTimeZone={property?.details?.timeZone}
              folios={reservation.folios}
              reservation={reservation}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
