import { Divider, Grid, useTheme } from "@mui/material";
import { LocalizationDatePicker } from "../../components/language-selector/localization-date-picker";
import { DatePicker } from "../../components/datepicker/date-picker";
import * as React from "react";
import { FC, useCallback, useMemo } from "react";
import { Property } from "../../domain/property";
import { groupBy } from "lodash";
import { HousekeepingCardsFloor, HousekeepingCardsTypeEnum } from "./housekeeping-cards-floor";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { HousekeepingConfirmation } from "./housekeeping-confirmation";
import { HousekeepingCards } from "../../features/tasks/domain/task";
import {
  FilterMapInterface,
  FilterObjectType,
  serializeIdOfFilter
} from "../../components/table/filters/table-filters";
import { FilterOperator } from "../../types/filters/filters";
import { QuickFilterCheckbox } from "../../components/quick-filter-checkbox/quick-filter-checkbox";
import { useSearchFilterParams } from "../../components/table/hooks/use-search-filter-params";
import { TaskType } from "../../graphql-tasks/generated/graphql";
import { useCurrentActor } from "../../hooks/use-current-actor";
import { housekeepingFilterParamSelector } from "../../utils/housekeeping";
import { HousekeepingFilters } from "../../domain/housekeeping-filters";

interface UnitsToCleanProps {
  selectedProperty?: Property;
  housekeepingCards: HousekeepingCards;
  isLoading: boolean;
}

const assignedToMeFilter = serializeIdOfFilter({
  type: FilterObjectType.AUTOCOMPLETE,
  operator: FilterOperator.Equality,
  name: housekeepingFilterParamSelector(HousekeepingFilters.ASSIGNED_TO_ME)
});

const assignedToTeamFilter = serializeIdOfFilter({
  type: FilterObjectType.AUTOCOMPLETE,
  operator: FilterOperator.Equality,
  name: housekeepingFilterParamSelector(HousekeepingFilters.ASSIGNED_TO_TEAM)
});

export const HousekeepingUnitsToClean: FC<UnitsToCleanProps> = ({
  selectedProperty,
  housekeepingCards,
  isLoading
}) => {
  const units = groupBy(housekeepingCards.units ?? [], "floor");
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const { currentActorId } = useCurrentActor();

  const { filterMap, setFilterMap } = useSearchFilterParams();

  const onFiltersChange = useCallback(
    (filters: FilterMapInterface) => {
      setFilterMap(filters);
    },
    [setFilterMap]
  );

  const currentActorTasks = useMemo(() => {
    return groupBy(
      housekeepingCards.units?.filter(
        (unit) =>
          unit?.housekeepingTasks?.find(
            (task) =>
              (task.type === TaskType.DepartureCleaning ||
                task.type === TaskType.StayoverCleaning) &&
              task.assignee?.actorId === currentActorId
          )
      ),
      "floor"
    );
  }, [housekeepingCards.units, currentActorId]);

  const teamTasks = useMemo(() => {
    return groupBy(
      housekeepingCards.units?.filter(
        (unit) =>
          unit?.housekeepingTasks?.find(
            (task) =>
              (task.type === TaskType.DepartureCleaning ||
                task.type === TaskType.StayoverCleaning) &&
              task.assignee === null
          )
      ),
      "floor"
    );
  }, [housekeepingCards.units]);

  const filteredUnits = useMemo(() => {
    const isActorTasksFilterActive = !!filterMap[assignedToMeFilter]?.value;
    const isTeamTasksFilterActive = !!filterMap[assignedToTeamFilter]?.value;

    switch (true) {
      case isActorTasksFilterActive && isTeamTasksFilterActive:
        return units;
      case isActorTasksFilterActive:
        return currentActorTasks;
      case isTeamTasksFilterActive:
        return teamTasks;
      default:
        return units;
    }
  }, [filterMap, currentActorTasks, teamTasks, units]);

  return (
    <Grid container>
      <Grid container alignItems="center" spacing={2}>
        <Grid item display="flex" gap={1}>
          <QuickFilterCheckbox
            key={HousekeepingFilters.ASSIGNED_TO_ME}
            isSingleValue
            condition={HousekeepingFilters.ASSIGNED_TO_ME}
            value={currentActorId}
            count={Object.values(currentActorTasks).flatMap((unit) => unit).length}
            filterMap={filterMap}
            filterType={FilterObjectType.AUTOCOMPLETE}
            filter={{
              name: housekeepingFilterParamSelector(HousekeepingFilters.ASSIGNED_TO_ME) ?? "",
              type: FilterObjectType.AUTOCOMPLETE,
              operator: FilterOperator.Equality
            }}
            label={t("labels__my_assignments")}
            onFilterMapChange={onFiltersChange}
          />
          <QuickFilterCheckbox
            key={HousekeepingFilters.ASSIGNED_TO_TEAM}
            isSingleValue
            condition={HousekeepingFilters.ASSIGNED_TO_TEAM}
            count={Object.values(teamTasks).flatMap((unit) => unit).length}
            filterMap={filterMap}
            filterType={FilterObjectType.AUTOCOMPLETE}
            filter={{
              name: housekeepingFilterParamSelector(HousekeepingFilters.ASSIGNED_TO_TEAM) ?? "",
              type: FilterObjectType.AUTOCOMPLETE,
              operator: FilterOperator.Equality
            }}
            label={t("labels__team_assignments")}
            onFilterMapChange={onFiltersChange}
          />
        </Grid>
        <Grid item>
          <Divider
            orientation="vertical"
            sx={{
              color: theme.palette.text.secondary,
              height: theme.spacing(3)
            }}
          />
        </Grid>
        <Grid item>
          <LocalizationDatePicker>
            <DatePicker timeZone={selectedProperty?.details.timeZone ?? ""} disablePast={true} />
          </LocalizationDatePicker>
        </Grid>
      </Grid>
      {!isLoading && (
        <Grid container pt={4}>
          {!Object.keys(filteredUnits).length && !housekeepingCards.reservations?.length && (
            <HousekeepingConfirmation label={t("labels__all_units_cleaned")} />
          )}
          {Object.keys(filteredUnits).map((key) => (
            <HousekeepingCardsFloor
              key={`cards-flor-${key}`}
              floorKey={key}
              units={filteredUnits[key]}
              housekeepingCardsType={HousekeepingCardsTypeEnum.UNITS}
              propertyId={selectedProperty?.propertyId ?? ""}
            />
          ))}
          {!!housekeepingCards?.reservations.length && (
            <HousekeepingCardsFloor
              reservations={housekeepingCards.reservations ?? []}
              housekeepingCardsType={HousekeepingCardsTypeEnum.RESERVATIONS}
              propertyId={selectedProperty?.propertyId ?? ""}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};
