import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetUnitsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.UnitFilter>;
}>;

export type GetUnitsQuery = {
  __typename?: "Query";
  GetUnits?: Array<{
    __typename?: "Unit";
    pmsId?: string | null;
    name?: string | null;
    pmsUnitGroupIds?: Array<string> | null;
    state?: Types.UnitState | null;
    occupied?: boolean | null;
    privacyMode?: boolean | null;
    privacyModeLastChangeTimestamp?: any | null;
    batteryLevel?: Types.BatteryLevel | null;
    property?: { __typename?: "Property"; pmsId: string } | null;
  } | null> | null;
};

export const GetUnitsDocument = `
    query GetUnits($filter: UnitFilter) {
  GetUnits(filter: $filter) {
    pmsId
    name
    pmsUnitGroupIds
    property {
      pmsId
    }
    state
    occupied
    privacyMode
    privacyModeLastChangeTimestamp
    batteryLevel
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetUnits: build.query<GetUnitsQuery, GetUnitsQueryVariables | void>({
      query: (variables) => ({ document: GetUnitsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetUnitsQuery, useLazyGetUnitsQuery } = injectedRtkApi;
