import { FC, PropsWithChildren } from "react";
import { overrideStyleComponents } from "./theme-override";
import { TenantThemeProvider } from "@likemagic-tech/sv-magic-library";

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <TenantThemeProvider
      overrideTheme="likemagic-backoffice-theme"
      overrideComponents={overrideStyleComponents}
    >
      {children}
    </TenantThemeProvider>
  );
};
