import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type PerformActionMutationVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
  reservationAction: Types.ReservationAction;
  payload?: Types.InputMaybe<Types.Scalars["Json"]["input"]>;
}>;

export type PerformActionMutation = { __typename?: "Mutation"; PerformAction?: boolean | null };

export const PerformActionDocument = `
    mutation PerformAction($pmsReservationId: String!, $reservationAction: ReservationAction!, $payload: Json) {
  PerformAction(
    pmsReservationId: $pmsReservationId
    reservationAction: $reservationAction
    payload: $payload
  )
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    PerformAction: build.mutation<PerformActionMutation, PerformActionMutationVariables>({
      query: (variables) => ({ document: PerformActionDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { usePerformActionMutation } = injectedRtkApi;
