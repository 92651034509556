import * as Types from "../generated/graphql";

import { HousekeepingTaskFragmentDoc } from "../fragment/HousekeepingTaskFragment.generated";
import { TaskFragmentDoc } from "../fragment/TaskFragment.generated";
import { ActorFragmentDoc } from "../fragment/Actor.generated";
import { tasksApi } from "../tasks.api";
export type GetHousekeepingCardsQueryVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
  date?: Types.InputMaybe<Types.Scalars["LocalDate"]["input"]>;
}>;

export type GetHousekeepingCardsQuery = {
  __typename?: "Query";
  GetHousekeepingCards: {
    __typename?: "HousekeepingCards";
    reservations: Array<{
      __typename?: "Reservation";
      adultsAmount?: number | null;
      arrival: any;
      childrenAges?: Array<number> | null;
      childrenAmount?: number | null;
      departure: any;
      displayableReservationId: string;
      pmsPropertyId: string;
      pmsReservationId: string;
      pmsUnitGroupId: string;
      pmsUnitId?: string | null;
      status?: Types.ReservationStatus | null;
      housekeepingTasks?: Array<{
        __typename?: "TaskDto";
        type: Types.TaskType;
        id: number;
        title: string;
        description?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        due?: any | null;
        status: Types.Status;
        priority: Types.Priority;
        parentId?: number | null;
        attachments?: Array<{
          __typename?: "Attachment";
          uuid: string;
          contentType: string;
          fileName: string;
          originalFileName: string;
        }> | null;
        assignee?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: Types.ActorType;
          displayName?: string | null;
        } | null;
        assigneeGroup?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: Types.ActorType;
          displayName?: string | null;
        } | null;
        reporter: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: Types.ActorType;
          displayName?: string | null;
        };
        links?: {
          __typename?: "Links";
          pmsReservationIds?: Array<string> | null;
          pmsDepartingReservationId?: string | null;
          pmsArrivingReservationId?: string | null;
          pmsUnitIds?: Array<string> | null;
          reservations?: Array<{
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
          }> | null;
          departingReservation?: {
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
            departure: any;
          } | null;
          arrivingReservation?: {
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
          } | null;
          units?: Array<{
            __typename?: "Unit";
            pmsUnitId: string;
            name: string;
            floor?: string | null;
          }> | null;
        } | null;
        subtasks?: Array<{
          __typename?: "TaskDto";
          id: number;
          title: string;
          parentId?: number | null;
        }> | null;
        parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
      }> | null;
    }>;
    units: Array<{
      __typename?: "Unit";
      floor?: string | null;
      name: string;
      occupied: boolean;
      pmsPropertyId: string;
      pmsUnitGroupId?: string | null;
      pmsUnitId: string;
      state?: Types.UnitState | null;
      unitGroupName?: string | null;
      housekeepingTasks?: Array<{
        __typename?: "TaskDto";
        type: Types.TaskType;
        id: number;
        title: string;
        description?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        due?: any | null;
        status: Types.Status;
        priority: Types.Priority;
        parentId?: number | null;
        attachments?: Array<{
          __typename?: "Attachment";
          uuid: string;
          contentType: string;
          fileName: string;
          originalFileName: string;
        }> | null;
        assignee?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: Types.ActorType;
          displayName?: string | null;
        } | null;
        assigneeGroup?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: Types.ActorType;
          displayName?: string | null;
        } | null;
        reporter: {
          __typename?: "Actor";
          actorId?: string | null;
          actorType: Types.ActorType;
          displayName?: string | null;
        };
        links?: {
          __typename?: "Links";
          pmsReservationIds?: Array<string> | null;
          pmsDepartingReservationId?: string | null;
          pmsArrivingReservationId?: string | null;
          pmsUnitIds?: Array<string> | null;
          reservations?: Array<{
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
          }> | null;
          departingReservation?: {
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
            departure: any;
          } | null;
          arrivingReservation?: {
            __typename?: "Reservation";
            displayableReservationId: string;
            pmsReservationId: string;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
          } | null;
          units?: Array<{
            __typename?: "Unit";
            pmsUnitId: string;
            name: string;
            floor?: string | null;
          }> | null;
        } | null;
        subtasks?: Array<{
          __typename?: "TaskDto";
          id: number;
          title: string;
          parentId?: number | null;
        }> | null;
        parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
      }> | null;
      maintenanceSlots?: Array<{
        __typename?: "MaintenanceSlot";
        id: string;
        description?: string | null;
        from: any;
        status: Types.MaintenanceSlotStatus;
        to: any;
        type: Types.MaintenanceSlotType;
      }> | null;
    }>;
  };
};

export const GetHousekeepingCardsDocument = `
    query GetHousekeepingCards($pmsPropertyId: String!, $date: LocalDate) {
  GetHousekeepingCards(pmsPropertyId: $pmsPropertyId, date: $date) {
    reservations {
      adultsAmount
      arrival
      childrenAges
      childrenAmount
      departure
      displayableReservationId
      housekeepingTasks(date: $date) {
        ...HousekeepingTask
      }
      pmsPropertyId
      pmsReservationId
      pmsUnitGroupId
      pmsUnitId
      status
    }
    units {
      floor
      housekeepingTasks(date: $date) {
        ...HousekeepingTask
      }
      maintenanceSlots {
        id
        description
        from
        status
        to
        type
      }
      name
      occupied
      pmsPropertyId
      pmsUnitGroupId
      pmsUnitId
      state
      unitGroupName
    }
  }
}
    ${HousekeepingTaskFragmentDoc}
${TaskFragmentDoc}
${ActorFragmentDoc}`;

const injectedRtkApi = tasksApi.injectEndpoints({
  endpoints: (build) => ({
    GetHousekeepingCards: build.query<
      GetHousekeepingCardsQuery,
      GetHousekeepingCardsQueryVariables
    >({
      query: (variables) => ({ document: GetHousekeepingCardsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetHousekeepingCardsQuery, useLazyGetHousekeepingCardsQuery } = injectedRtkApi;
