import { createApi, defaultSerializeQueryArgs } from "@reduxjs/toolkit/query/react";
import { CONTENT_TYPE_MULTIPART, magicBaseQuery } from "./base-query";
import { ExportActionType } from "../pages/housekeeping/housekeeping-forecast-actions";

export type UploadFileResponse = { uuid: string };
export interface BlobResponse {
  data: string;
  fileName: string;
}
type UploadFileArgument = { file: File; taskId?: number };
const TASK_MANAGEMENT_FILES_BASE_URL = "/api/task-management-service";
export const taskManagementFilesApi = createApi({
  reducerPath: "task-management-files",
  baseQuery: magicBaseQuery({ baseUrl: TASK_MANAGEMENT_FILES_BASE_URL }),
  tagTypes: ["Files"],
  serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
    const args = { ...queryArgs };
    delete args["skipSpinner" as keyof typeof args];

    return defaultSerializeQueryArgs({
      endpointName,
      queryArgs: args,
      endpointDefinition
    });
  },
  endpoints: (builder) => ({
    getFile: builder.query<BlobResponse, { uuid: string }>({
      query: (arg) => ({
        headers: { method: "GET" },
        url: `/files/${arg.uuid}`,
        isBlob: true,
        cache: "no-cache"
      })
    }),
    getForecastFile: builder.query<
      BlobResponse,
      { propertyId: string; exportType: ExportActionType }
    >({
      query: (arg) => ({
        headers: { method: "GET" },
        url: `/properties/${arg.propertyId}/forecast?exportType=${arg.exportType}`,
        isBlob: true,
        cache: "no-cache"
      })
    }),
    uploadFile: builder.mutation<UploadFileResponse, UploadFileArgument>({
      query: ({ file, taskId }) => {
        return {
          url: taskId ? `/files/?taskId=${taskId}` : "/files",
          headers: {
            method: "POST",
            "Content-Type": CONTENT_TYPE_MULTIPART
          },
          body: { file },
          isVoid: false
        };
      },
      invalidatesTags: ["Files"]
    })
  })
});

export const { useLazyGetForecastFileQuery, useLazyGetFileQuery, useUploadFileMutation } =
  taskManagementFilesApi;
