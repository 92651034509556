import React, { FC, useCallback, useMemo, useState } from "react";
import { FolioPreview } from "../../../../store/endpoints/reservation-table.endpoints";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { MoveChargesModal } from "../modals/move-charges-modal";
import { ActionMenu } from "../../../../components/action-menu/action-menu";
import { AdjustChargesModal } from "../modals/adjust-charges-modal";

export interface MultipleChargesActionsMenuProps {
  reservationId: string;
  propertyId: string;
  folios: Array<FolioPreview>;
  orderItemPmsIds: string[];
  folioId: string;
  isFolioClosed: boolean | false;
  folioMetadata: Object | null;
  disabled?: boolean;
  onSuccess: () => void;
}

export const MultipleChargesActionsMenu: FC<MultipleChargesActionsMenuProps> = ({
  reservationId,
  orderItemPmsIds,
  folioId,
  isFolioClosed,
  folioMetadata,
  folios,
  disabled,
  onSuccess,
  propertyId
}) => {
  const { t } = useTranslationWrapper();
  const [moveOrderItemModalOpened, setMoveOrderItemModalOpened] = useState(false);
  const [adjustOrderItemModalOpened, setAdjustOrderItemModalOpened] = useState<boolean>(false);

  const onMoveOptionSelect = useCallback(() => {
    setMoveOrderItemModalOpened(true);
  }, []);

  const onAdjustOptionSelect = useCallback(() => {
    setAdjustOrderItemModalOpened(true);
  }, []);

  const menuItems = useMemo(() => {
    return isFolioClosed
      ? []
      : [
          { label: t("labels__move"), id: "move" },
          {
            label: t("labels__adjust"),
            id: "adjust"
          }
        ];
  }, [isFolioClosed, t]);

  const handleAction = useCallback(
    (selectedOptionId: string) => {
      switch (selectedOptionId) {
        case "move":
          onMoveOptionSelect();
          break;
        case "adjust":
          onAdjustOptionSelect();
          break;
      }
    },
    [onMoveOptionSelect, onAdjustOptionSelect]
  );
  return (
    <>
      <ActionMenu items={menuItems} handleAction={handleAction} disabled={disabled} />
      <MoveChargesModal
        isOpen={moveOrderItemModalOpened}
        reservationId={reservationId}
        onClose={() => {
          setMoveOrderItemModalOpened(false);
        }}
        orderItemPmsIds={orderItemPmsIds}
        folioId={folioId}
        folioMetadata={folioMetadata}
        folios={folios}
        onSuccess={onSuccess}
      />
      {adjustOrderItemModalOpened && (
        <AdjustChargesModal
          isOpen
          reservationId={reservationId}
          propertyId={propertyId}
          onClose={() => {
            setAdjustOrderItemModalOpened(false);
          }}
          orderItemPmsIds={orderItemPmsIds}
        />
      )}
    </>
  );
};
