import { Box, Grid } from "@mui/material";
import {
  Button,
  combineTimeAndDate,
  NativeSelect,
  useCountries
} from "@likemagic-tech/sv-magic-library";
import React, { FC, useMemo } from "react";
import { Price } from "../../../../domain/price";
import { Form, Formik } from "formik";
import DateTimePicker from "../../../../components/maintenance-modal/date-time-picker/date-time-picker";
import { PricePicker } from "../../../../components/price-picker/price-picker";
import { PaymentComponentProps } from "../payment-component";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { useCreatePaymentLinkMutation } from "../../../../graphql/mutations/create-payment-link.generated";
import { zonedTimeToUtc } from "date-fns-tz";
import { getI18nSelectedLanguage } from "../../../../utils/timezoned-date";
import { useProperty } from "../../../../hooks/use-property";
import { useFormValidations } from "../../../../hooks/use-form-validation";
import { useDispatch } from "react-redux";
import { setPaymentLink } from "../../../../slices/send-payment-link-modal.slice";
import { openBanner } from "../../../../slices/banner.slice";

interface CreatePaymentLinkForm {
  country: string;
  expiryDate: Date | undefined;
  expiryTime: Date | undefined;
  price: Price;
}

export const PaymentSendPaymentLinkComponent: FC<PaymentComponentProps> = ({
  price,
  magicId,
  propertyId,
  folioMetadata,
  onSuccess,
  onFailed
}) => {
  const { t } = useTranslationWrapper();
  const [createPaymentLink] = useCreatePaymentLinkMutation();
  const { sortedCountries } = useCountries({ propertyId });
  const { selectedProperty } = useProperty();
  const { paymentLinkValidation } = useFormValidations();
  const dispatch = useDispatch();

  const initialValuesCreate: CreatePaymentLinkForm = {
    price: {
      ...price,
      amount: price.amount / 100
    },
    country: "AU",
    expiryDate: undefined,
    expiryTime: undefined
  };

  const handleSubmitCreate = async (values: CreatePaymentLinkForm) => {
    if (propertyId) {
      const response = await createPaymentLink({
        paymentLinkRequest: {
          amount: {
            amount: parseFloat(values.price.amount.toFixed(2)) * 100,
            currency: values.price.currency
          },
          expiresAt: !!values.expiryDate
            ? zonedTimeToUtc(
                combineTimeAndDate(values.expiryDate, values.expiryTime),
                selectedProperty?.details?.timeZone ?? "Europe/Zurich"
              )
            : undefined,
          email: "",
          magicId,
          pmsPropertyId: propertyId,
          shopperReference: magicId,
          targetFolioMetadata: folioMetadata ?? {}
        }
      }).unwrap();

      if (response?.CreatePaymentLink?.url) {
        onSuccess();
        dispatch(setPaymentLink(response.CreatePaymentLink.url));
      } else {
        onFailed();
        dispatch(
          openBanner({
            type: "error",
            title: t("labels__action_failed_to_perform")
          })
        );
      }
    }
  };

  const maxDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 70);
    return date;
  }, []);

  return (
    <>
      <Box>
        <Formik
          initialValues={initialValuesCreate}
          onSubmit={async (values, { validateForm }) => {
            validateForm(values).then(() => {
              handleSubmitCreate(values);
            });
          }}
          validationSchema={paymentLinkValidation}
        >
          {(formik) => (
            <Form id="send-payment-link">
              <Grid container justifyContent="center">
                <Grid item xs={12} mb={2}>
                  <NativeSelect
                    items={sortedCountries(getI18nSelectedLanguage())}
                    id={"country"}
                    name={"country"}
                    label={t("labels__wizard_country")}
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <DateTimePicker
                    minDate={new Date()}
                    maxDate={maxDate}
                    label={t("labels__expiry_date")}
                    date={formik.values.expiryDate}
                    time={formik.values.expiryTime}
                    setDate={(date: Date) => formik.setFieldValue("expiryDate", date)}
                    setTime={(value: Date) => formik.setFieldValue("expiryTime", value)}
                    dateError={
                      formik.touched.expiryDate &&
                      formik.errors.expiryDate &&
                      (formik.values.expiryDate instanceof Date ||
                        formik.values.expiryTime === undefined)
                        ? formik.errors.expiryDate
                        : undefined
                    }
                  />
                </Grid>

                <Grid item xs={12} mb={2}>
                  <PricePicker
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    prefix="price"
                    error={
                      formik.touched.price?.amount && formik.errors.price?.amount
                        ? formik.errors.price?.amount
                        : undefined
                    }
                  />
                </Grid>

                <Grid item xs={12} textAlign="center">
                  <Button sx={{ width: "50%" }} type="submit">
                    {t("buttons__get_payment_link")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};
