export const generateBlinkerAnimation = (
  percentToAppear: number,
  fadeEffectInPercent: number = 1,
  fadeIn: boolean = true
) => {
  const from = fadeIn ? 0 : 1;
  const to = fadeIn ? 1 : 0;
  return {
    "0%": { opacity: from },
    [`${percentToAppear}%`]: { opacity: from },
    [`${percentToAppear + fadeEffectInPercent}%`]: { opacity: to },
    "100%": { opacity: to }
  };
};
