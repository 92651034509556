import { BoxState } from "src/slices/box.slice";
import { BoxTabAll } from "./box-tabs";

export const boxesTabLabels = (
  tab: BoxState | BoxTabAll,
  t: (key: string, additionalInterpolationMap?: Record<string, string> | undefined) => string
) => {
  switch (tab) {
    case BoxTabAll.ALL:
      return t("labels__boxes_tab_ALL");
    case BoxState.AVAILABLE:
      return t("labels__boxes_tab_AVAILABLE");
    case BoxState.BOOKED:
      return t("labels__boxes_tab_BOOKED");
    case BoxState.RETURNED:
      return t("labels__boxes_tab_RETURNED");
    case BoxState.UNAVAILABLE:
      return t("labels__boxes_tab_UNAVAILABLE");
    case BoxState.UNDER_REVIEW:
      return t("labels__boxes_tab_UNDER_REVIEW");
  }
};

export const boxTabsBadgeColor = (tab: BoxState | BoxTabAll) => {
  switch (tab) {
    case BoxState.AVAILABLE:
      return "success";
    case BoxState.BOOKED:
      return "warning";
    case BoxState.RETURNED:
      return "info";
    case BoxState.UNAVAILABLE:
      return "error";
    case BoxState.UNDER_REVIEW:
      return "warning";
  }
};
