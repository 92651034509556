import { TenantContext, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { useContext } from "react";
import { PropertyFrontendConfig } from "@likemagic-tech/sv-magic-library/dist/api";

/**
 * /**
 *  * Returns proper configuration by priority
 *  *
 *  * 1. From properties by propertyId if reservation context is available
 *  *    or overridePropertyId (if exists) overrides propertyId - should be used only in case when reservation context is not available
 *  * 2. From properties by origin (property has configured baseUrl)
 *  * 3. From tenant config
 *
 * @param {string} overridePropertyId - Override property ID to be used in the configuration (optional). Should be used carefully
 *
 * @returns {PropertyFrontendConfig} The frontend configuration for the property.
 */
const useConfiguration: (overridePropertyId?: string) => PropertyFrontendConfig = (
  overridePropertyId
) => {
  return usePropertyConfig({ propertyId: overridePropertyId });
};

export const useFeatureFlags = (propertyId?: string) => {
  const config = useConfiguration(propertyId);
  const { features } = useContext(TenantContext);

  return config?.features! || features;
};

export const useSevenRoomsWidgetConfig = () => {
  const { sevenRoomsWidgetConfig } = useContext(TenantContext);

  return sevenRoomsWidgetConfig;
};
