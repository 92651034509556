import { magicApi } from "../magic.api";
import { NotificationTemplateType } from "../../features/reservations/reservation-details/reservation-details-tabs/overview/components/notifications/use-get-notification-template";

const reservationsEndpoints = magicApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationTemplateType: builder.query<
      Array<NotificationTemplateType>,
      { reservationId: string }
    >({
      query: (arg) => ({
        url: `/backoffice/reservations/${arg.reservationId}/available-templates-for-send-custom-notification`,
        headers: { method: "GET" }
      })
    })
  }),
  overrideExisting: false
});
export const { useGetNotificationTemplateTypeQuery } = reservationsEndpoints;
