import React, { FC, useMemo } from "react";
import {
  GuestPreferences,
  ReservationPreferencesCategory
} from "../../../../../../domain/reservation-table-dto";
import { GuestPreferencesMapper } from "../../../../../../store/endpoints/preferences.endpoints";
import { Grid, Tooltip } from "@mui/material";
import { Chip } from "@likemagic-tech/sv-magic-library";
import { DisplayPreference } from "./display-preference";
import { useUnits } from "src/hooks/use-unit-by-id";
import { TransFormPreferencesResult } from "src/graphql/transform/transform-reservation-details";

export const CategoryColorMapping = {
  [ReservationPreferencesCategory.PAYMENT]: "#FEEBFF",
  [ReservationPreferencesCategory.UNIT]: "#E6EDFA",
  [ReservationPreferencesCategory.SERVICE]: "#EBFFF8",
  [ReservationPreferencesCategory.CONSUMPTION]: "#FAECE6",
  [ReservationPreferencesCategory.GUEST_NOTE]: "#FFFFFF"
};

const MAX_PREFERENCES = 2;

export const getCategoryColor = (
  preference: string,
  mapper?: GuestPreferencesMapper | TransFormPreferencesResult
) => {
  if (mapper) {
    // this one doesn't come from BE because it's kind of a FE choice to add it in the UI to the other guest preferences
    const fullMapper = { ...mapper, GUEST_NOTE: ["GUEST_NOTE"] };
    const category = Object.keys(fullMapper).find((item) =>
      fullMapper[item as keyof typeof fullMapper].includes(preference)
    );
    return CategoryColorMapping[category as keyof typeof CategoryColorMapping];
  }
  return "transparent";
};

interface PreferencesInRowPreviewProps {
  preferences?: GuestPreferences;
  preferencesText?: string;
  propertyId: string;
}

export const PreferencesInRowPreview: FC<PreferencesInRowPreviewProps> = ({
  preferences,
  preferencesText,
  propertyId
}) => {
  const { data: units } = useUnits({
    propertyId
  });

  const preferencesNullSafe = useMemo(() => preferences || {}, [preferences]);

  const preferencesNumber = useMemo(() => {
    const specificUnit = preferences?.SPECIFIC_UNIT;
    let unitPreferencesNumber;

    if (Array.isArray(specificUnit)) {
      unitPreferencesNumber = specificUnit?.length;
    } else if (typeof specificUnit === "object" && specificUnit !== null) {
      unitPreferencesNumber = 1;
    } else {
      unitPreferencesNumber = 0;
    }

    // Not the cleanest solution, but since we are using three different sources for the preferences, we need to check all of them
    return (
      Math.max(
        0,
        unitPreferencesNumber + Object.keys(preferencesNullSafe).length - MAX_PREFERENCES
      ) + (preferencesText && preferencesText !== "" && preferencesText !== undefined ? 1 : 0)
    );
  }, [preferences, preferencesNullSafe, preferencesText]);

  const allItems = useMemo(() => {
    const allPreferences = preferencesText
      ? { ...preferencesNullSafe, GUEST_NOTE: {} }
      : preferencesNullSafe;

    return (
      <Grid container direction="row" justifyContent="flex-start" py={1}>
        {Object.keys(allPreferences).map((preference) => (
          <React.Fragment key={`${preference}-tooltip-container`}>
            <DisplayPreference
              preference={preference}
              propertyId={propertyId}
              preferencesText={preferencesText}
              preferences={preferences}
              key={preference}
              units={units || []}
            />
          </React.Fragment>
        ))}
      </Grid>
    );
  }, [preferencesText, preferencesNullSafe, propertyId, preferences, units]);

  return (
    <Grid container alignItems="center">
      <>
        {Object.keys(preferencesNullSafe)
          .map((preference, index) =>
            index < MAX_PREFERENCES ? (
              <DisplayPreference
                preference={preference}
                propertyId={propertyId}
                preferencesText={preferencesText}
                preferences={preferences}
                key={preference}
                units={units || []}
              />
            ) : (
              <DisplayPreference
                preference={preference}
                propertyId={propertyId}
                preferencesText={preferencesText}
                preferences={preferences}
                key={preference}
                units={units || []}
                hide
              />
            )
          )
          .filter((item) => !!item)}
      </>
      <>
        {preferencesNumber > 0 && (
          <Grid item my={1} mx={0.5}>
            <Tooltip placement="top-start" title={allItems}>
              <span>
                <Chip size="small" color="default" label={`+ ${preferencesNumber}`} />
              </span>
            </Tooltip>
          </Grid>
        )}
      </>
    </Grid>
  );
};
