import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { patchUnit } from "../slices/cleaning";
import { useBulkUpdateUnitStateMutation } from "../graphql/mutations/update-unit.generated";
import { useDispatch } from "../store";
import { mapCommonToUnitStateV2 } from "../graphql/transform/transform-units";

interface UpdateUnitInterface {
  unitId: string;
  condition: any;
}

export const useUpdateUnit = () => {
  const { isRESTVersion } = useApiVersion();
  const [updateUnitMutation] = useBulkUpdateUnitStateMutation();
  const dispatch = useDispatch();
  const setUpdateUnitV1 = ({ unitId, condition }: UpdateUnitInterface) => {
    return dispatch(
      patchUnit({
        unitId: unitId,
        patches: [
          {
            op: "replace",
            path: "/status/condition",
            value: condition
          }
        ]
      })
    );
  };

  const setUpdateUnitV2 = ({ unitId, condition }: UpdateUnitInterface) => {
    return updateUnitMutation({
      pmsUnitIds: [unitId],
      state: mapCommonToUnitStateV2(condition)
    });
  };

  const functionCall = isRESTVersion ? setUpdateUnitV1 : setUpdateUnitV2;

  return [functionCall];
};
