import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetMembershipTypesQueryVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
}>;

export type GetMembershipTypesQuery = {
  __typename?: "Query";
  GetMembershipTypes: Array<{
    __typename?: "MembershipType";
    code: string;
    name: string;
    membershipClass: Types.MemberShipClassEnum;
    pmsId: string;
  }>;
};

export const GetMembershipTypesDocument = `
    query getMembershipTypes($pmsPropertyId: String!) {
  GetMembershipTypes(pmsPropertyId: $pmsPropertyId) {
    code
    name
    membershipClass
    pmsId
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    getMembershipTypes: build.query<GetMembershipTypesQuery, GetMembershipTypesQueryVariables>({
      query: (variables) => ({ document: GetMembershipTypesDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetMembershipTypesQuery, useLazyGetMembershipTypesQuery } = injectedRtkApi;
