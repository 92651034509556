import React, { FC } from "react";
import { SubtasksForm } from "../task-modal";
import { Box, useTheme } from "@mui/material";
import {
  AutocompleteComponent,
  AutocompleteOption
} from "../../../../components/autocomplete-component";
import { useGetActors } from "../../use-get-actors";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";

interface SubtasksAssigneeProps {
  subtask: SubtasksForm;
  setFieldValue: (field: string, value: any) => void;
  index: number;
  disabled: boolean;
}

export const SubtasksAssignee: FC<SubtasksAssigneeProps> = ({
  subtask,
  setFieldValue,
  index,
  disabled
}) => {
  const theme = useTheme();
  const { actorOptions } = useGetActors();
  const { t } = useTranslationWrapper();

  return (
    <Box sx={{ minWidth: theme.spacing(24), pt: 0.5 }}>
      <AutocompleteComponent
        options={actorOptions as AutocompleteOption[]}
        onSelected={(value) => setFieldValue(`subtasks.[${index}].assignee`, value)}
        value={subtask.assignee ?? null}
        inputVariant="standard"
        disableClearable={false}
        placeholder={t("labels__assignee")}
        label=""
        inputFontSize={14}
        disabled={disabled}
      />
    </Box>
  );
};
