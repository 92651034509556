import * as React from "react";
import { FC, Fragment } from "react";
import { Box, Grid, Paper, useTheme } from "@mui/material";
import { ParagraphSmallBold } from "@likemagic-tech/sv-magic-library";
import { MonitoringStep } from "../monitoring/monitoring-step";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { makeStyles } from "tss-react/mui";
import { ReservationDetailsDTO } from "../../../../store/endpoints/reservation-table.endpoints";
import { MonitoringLegendItem } from "../monitoring/monitoring-legend-item";
import { useMonitoringSteps } from "../monitoring/use-monitoring-steps";
import { isPassed } from "../monitoring/monitoring-step.util";

const useStyles = makeStyles()((theme) => ({
  stepperWrapper: {
    display: "flex",
    width: "100%",
    overflowX: "auto",
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(-1),
    justifyContent: "space-between"
  },
  wrapperWithBorders: {
    display: "flex",
    width: "auto",
    flex: 1,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: "space-between",
    paddingInline: theme.spacing(2)
  }
}));

interface ReservationStatusStepperProps {
  reservation: ReservationDetailsDTO;
}

export const ReservationStatusStepper: FC<ReservationStatusStepperProps> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const { classes } = useStyles();
  const theme = useTheme();

  const { steps, currentStepId, flatSteps } = useMonitoringSteps(reservation);

  return (
    <Box my={2}>
      <Paper sx={{ borderRadius: 2 }} elevation={0}>
        <Grid container direction="row" alignItems="flex-start" flexDirection="column">
          <Box p={2} display="flex" justifyContent="space-between" width="100%">
            <ParagraphSmallBold>{t("labels__guest_journey")}</ParagraphSmallBold>
            <Box display="flex" gap={2.5}>
              <MonitoringLegendItem
                color={theme.palette.grey[600]}
                label={t("labels__monitoring_legend_EXTERNAL")}
              />
              <MonitoringLegendItem
                color={theme.palette.info.main}
                label={t("labels__monitoring_legend_GUEST")}
              />
              <MonitoringLegendItem
                color={theme.palette.warning.main}
                label={t("labels__monitoring_legend_PROPERTY")}
              />
            </Box>
          </Box>
          <Box className={classes.stepperWrapper}>
            <Box className={classes.wrapperWithBorders}>
              {steps.map((step, index) => {
                return (
                  <Fragment key={step.id}>
                    {step.preSteps?.map((preStep) => {
                      return (
                        <MonitoringStep
                          key={preStep.id}
                          id={preStep.id}
                          tooltip={{ text: preStep.tooltipText, dateTime: preStep.dateTime }}
                          isPreStepDone={preStep.isPreStepDone}
                          isCurrent={currentStepId === preStep.id}
                          isPassed={isPassed({ flatSteps, currentStepId, stepId: preStep.id })}
                        />
                      );
                    })}
                    <MonitoringStep
                      id={step.id}
                      author={step.author}
                      hasEmptySpaceAfter={step.hasEmptySpace}
                      tooltip={{ text: step.tooltipText, dateTime: step.dateTime }}
                      isCurrent={currentStepId === step.id}
                      isPassed={isPassed({ flatSteps, currentStepId, stepId: step.id })}
                      isParent
                      isLast={steps.length - 1 === index}
                    />
                  </Fragment>
                );
              })}
            </Box>
          </Box>
        </Grid>
      </Paper>
    </Box>
  );
};
