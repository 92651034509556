import { FC } from "react";
import { Box } from "@mui/material";
import { Caption } from "@likemagic-tech/sv-magic-library";

interface MonitoringLegendItemProps {
  label: string;
  color: string;
}

export const MonitoringLegendItem: FC<MonitoringLegendItemProps> = ({ label, color }) => {
  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Box sx={{ backgroundColor: color, borderRadius: 0.25, width: 10, height: 10 }} />
      <Caption>{label}</Caption>
    </Box>
  );
};
