import { FC, useEffect, useState } from "react";
import { Box, CircularProgress, Link, useTheme } from "@mui/material";
import { ChatItemContentV2BaseProps } from "./domain/chat-item-content-v2-base-props";
import { fetchMessageAttachment } from "../../../slices/messaging-attachment.slice";
import { useDispatch } from "../../../store";

interface ChatItemContentFileProps extends ChatItemContentV2BaseProps {}

export const ChatItemContentV2File: FC<ChatItemContentFileProps> = ({
  content,
  isLoggedUser,
  mediaUrl
}) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const [birdAttachment, setBirdAttachment] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (mediaUrl) {
      dispatch(fetchMessageAttachment({ url: mediaUrl }))
        .unwrap()
        .then((blobString: string) => setBirdAttachment(blobString));
    }
  }, [mediaUrl, dispatch]);

  return (
    <Box>
      {mediaUrl && !birdAttachment ? (
        <CircularProgress />
      ) : (
        <Link
          href={birdAttachment}
          download
          sx={{
            color: isLoggedUser ? palette.background.paper : palette.primary.main
          }}
        >
          {/*We need a default value if there is no filename in blob*/}
          {content.files[0].filename ?? "Untitled"}
        </Link>
      )}
    </Box>
  );
};
