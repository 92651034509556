import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type UpdateRegistrationCardGuestsMutationVariables = Types.Exact<{
  person: Types.UpdatePersonInput;
  pmsReservationId: Types.Scalars["String"]["input"];
  persons: Array<Types.SecondaryPersonInput> | Types.SecondaryPersonInput;
}>;

export type UpdateRegistrationCardGuestsMutation = {
  __typename?: "Mutation";
  BackofficeUpdatePrimaryGuest: { __typename?: "Person"; id: number };
  BackofficeUpdateSecondaryGuestDetails: Array<{ __typename?: "Person"; id: number }>;
};

export const UpdateRegistrationCardGuestsDocument = `
    mutation UpdateRegistrationCardGuests($person: UpdatePersonInput!, $pmsReservationId: String!, $persons: [SecondaryPersonInput!]!) {
  BackofficeUpdatePrimaryGuest(
    person: $person
    pmsReservationId: $pmsReservationId
  ) {
    id
  }
  BackofficeUpdateSecondaryGuestDetails(
    persons: $persons
    pmsReservationId: $pmsReservationId
  ) {
    id
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    UpdateRegistrationCardGuests: build.mutation<
      UpdateRegistrationCardGuestsMutation,
      UpdateRegistrationCardGuestsMutationVariables
    >({
      query: (variables) => ({ document: UpdateRegistrationCardGuestsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useUpdateRegistrationCardGuestsMutation } = injectedRtkApi;
