import { SectionName } from "./reservation-actions-menu.types";
import { AllowedActionEnum } from "../../domain/reservation-table-dto";

export const actionToSectionMapping = new Map<SectionName, AllowedActionEnum[]>([
  [
    SectionName.UNIT,
    [
      AllowedActionEnum.ASSIGN_UNIT,
      AllowedActionEnum.CHANGE_UNIT_CONDITION_TO_DIRTY,
      AllowedActionEnum.CHANGE_UNIT_CONDITION_TO_CLEAN,
      AllowedActionEnum.CHANGE_UNIT_CONDITION_TO_CLEAN_TO_BE_INSPECTED
    ]
  ],
  [
    SectionName.RESERVATION,
    [
      AllowedActionEnum.MANUAL_CHECKIN,
      AllowedActionEnum.VALIDATE_RESERVATION,
      AllowedActionEnum.CHANGE_STATUS_TO_IN_HOUSE,
      AllowedActionEnum.CHANGE_STATUS_TO_CHECKED_OUT,
      AllowedActionEnum.CHANGE_STATUS_TO_CANCELED,
      AllowedActionEnum.CHANGE_STATUS_TO_NO_SHOW,
      AllowedActionEnum.CHANGE_PIN_CONFIGURATION,
      AllowedActionEnum.CHANGE_RESERVATION_COMMENT
    ]
  ],
  [
    SectionName.GUEST_JOURNEY,
    [
      AllowedActionEnum.JUMP_TO_RESERVATION,
      AllowedActionEnum.JUMP_TO_BOOKING,
      AllowedActionEnum.COPY_GUESTFLOW_LINK,
      AllowedActionEnum.RESEND_GUESTFLOW_LINK,
      AllowedActionEnum.RESET_GUEST_FLOW,
      AllowedActionEnum.ENCODE_KEY
    ]
  ],
  [
    SectionName.GUEST,
    [
      AllowedActionEnum.CHANGE_ID_CHECK_STATUS,
      AllowedActionEnum.EDIT_PREFERENCES,
      AllowedActionEnum.EDIT_PREFERENCES_TEXT
    ]
  ]
]);
