import { useEffect } from "react";
import { useProperty } from "./use-property";
import { Params, useSelectId } from "./use-select-id";

export const usePropertySearchParams = () => {
  const { selectedProperty } = useProperty();
  const { selectedId, setSelectId } = useSelectId({
    fieldName: Params.SELECTED_PROPERTY_ID
  });
  useEffect(() => {
    if (selectedProperty && !selectedId) {
      setSelectId(selectedProperty.propertyId);
    }
  }, [selectedProperty, selectedId, setSelectId]);
};
