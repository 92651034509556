import { api } from "../add-note-to-profile.generated";

const getUserProfileByIdEnhanced = api.enhanceEndpoints({
  endpoints: {
    AddNoteToProfile: {
      invalidatesTags: (result, error, arg) => [
        { type: "UserProfile", id: arg.userProfileId },
        "UserProfile"
      ]
    }
  }
});

export const { useAddNoteToProfileMutation: useAddNoteToProfileMutationEnhanced } =
  getUserProfileByIdEnhanced;
