import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type AddNoteToProfileMutationVariables = Types.Exact<{
  userProfileId: Types.Scalars["Int"]["input"];
  note: Types.NoteInput;
}>;

export type AddNoteToProfileMutation = {
  __typename?: "Mutation";
  AddNoteToProfile: { __typename?: "ProfileNote"; pmsId: string };
};

export const AddNoteToProfileDocument = `
    mutation AddNoteToProfile($userProfileId: Int!, $note: NoteInput!) {
  AddNoteToProfile(userProfileId: $userProfileId, note: $note) {
    pmsId
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    AddNoteToProfile: build.mutation<AddNoteToProfileMutation, AddNoteToProfileMutationVariables>({
      query: (variables) => ({ document: AddNoteToProfileDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useAddNoteToProfileMutation } = injectedRtkApi;
