import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Selector
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import { ReservationApi } from "../api/reservation.api";
import { Reservation } from "../domain/reservation";
import { KeycloakSliceState } from "./keycloak.slice";
import { TableDtoSortingParams } from "../store/endpoints/reservation-table.endpoints";
import { ReservationTableDto } from "../domain/reservation-table-dto";
import { saveAs } from "file-saver";

export const dashboardArrivalAdapter = createEntityAdapter<Reservation>({
  selectId: (model) => model.id
});

const initialState: {
  status: EntityStateStatus;
} = {
  status: EntityStateStatus.IDLE
};

export const downloadReservationXLSX = createAsyncThunk<
  Blob,
  TableDtoSortingParams<ReservationTableDto>,
  { state: RootState; rejectValue: { reason: string } }
>("download/reservationXLSX", async (arg, thunkAPI) => {
  return ReservationApi.downloadXLSXReservationsForTable(arg, {
    signal: thunkAPI.signal
  });
});

export const downloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadReservationXLSX.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(downloadReservationXLSX.fulfilled, (state, action) => {
        saveAs(action.payload, "reservations.xlsx");
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(downloadReservationXLSX.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      });
  }
});

const selectSelf: Selector<RootState, KeycloakSliceState> = (state: RootState) =>
  state[downloadSlice.name];
export const selectIsDownloading = createSelector(
  selectSelf,
  (res) => res.status === EntityStateStatus.LOADING
);
