import { CommunicationChannel, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { grey } from "@mui/material/colors";
import { FC, useCallback } from "react";
import { UserProfile } from "src/domain/user-profile";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";

interface NotificationLabelForAutocompleteProps {
  profile: UserProfile[];
  channel: string;
}

export const NotificationLabelForAutocomplete: FC<NotificationLabelForAutocompleteProps> = ({
  profile,
  channel
}) => {
  const { t } = useTranslationWrapper();
  const labelToDisplay = useCallback(
    (info: string) => (info ? info : t("labels__not_defined")),
    [t]
  );

  return (
    <ParagraphSmall sx={{ color: grey[500] }}>{`(${
      channel === CommunicationChannel.EMAIL
        ? labelToDisplay(profile[0]?.person.email ?? "")
        : labelToDisplay(profile[0]?.person.phone ?? "")
    })`}</ParagraphSmall>
  );
};
