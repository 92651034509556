import { FC, useMemo } from "react";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { Chip } from "@likemagic-tech/sv-magic-library";
import { HousekeepingCardType } from "./housekeeping-card";
import { useFetchAvailableDoors } from "../../../hooks/use-fetch-available-doors";
import { useProperty } from "../../../hooks/use-property";

interface UnitStatusProps {
  housekeepingCard: HousekeepingCardType;
}
interface UnitStatusType {
  label: string;
  color: "success" | "error" | "default";
}

export const HousekeepingCardUnitStatus: FC<UnitStatusProps> = ({ housekeepingCard }) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const { filterPrivateKeysByUnitId } = useFetchAvailableDoors(selectedProperty?.propertyId ?? "");
  const doorData = filterPrivateKeysByUnitId(housekeepingCard?.unit?.pmsUnitId ?? "");

  const doNotDisturb: boolean = useMemo(() => {
    return doorData.some((door) => door.privacyMode);
  }, [doorData]);

  const occupiedLabel = useMemo(() => {
    return doNotDisturb ? t("labels__do_not_disturb") : t("labels__unit_occupied");
  }, [doNotDisturb, t]);

  const status = useMemo<UnitStatusType>(() => {
    switch (housekeepingCard?.unit?.occupied || doNotDisturb) {
      case true:
        return {
          label: occupiedLabel,
          color: "error"
        };
      case false:
        return {
          label: t("labels__unit_free"),
          color: "success"
        };
      default:
        return {
          label: t("labels__unit_occupied"),
          color: "error"
        };
    }
  }, [t, housekeepingCard, occupiedLabel, doNotDisturb]);

  return housekeepingCard?.unit ? <Chip label={status?.label} color={status?.color} /> : <></>;
};
