import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import { AvailableDoor } from "../domain/available-door";
import { DoorDelegateApi, DoorType } from "../api/door-delegate";
import { MagicApi } from "../api/magic.api";
import { handleSliceError } from "../utils/error-handling";

export const availableDoorAdapter = createEntityAdapter<AvailableDoor>({
  selectId: (model) => model.id,
  sortComparer: (a, b) => a.title.localeCompare(b.title)
});

export const syncDoors = createAsyncThunk<boolean, { reservationId: string; doorType: DoorType }>(
  "door-delegate/door-access",
  ({ reservationId, doorType }, thunkAPI) => {
    try {
      return DoorDelegateApi.postDoorAccess(reservationId, doorType);
    } catch (e) {
      return handleSliceError(e, thunkAPI.rejectWithValue);
    }
  }
);

const initialState = availableDoorAdapter.getInitialState<{
  status: EntityStateStatus;
  propertyId?: string;
}>({
  status: EntityStateStatus.IDLE,
  propertyId: undefined
});
export const openDoorByAdmin = createAsyncThunk<void, { propertyId: String; doorId: string }>(
  "door-delegate/open-door",
  ({ propertyId, doorId }, thunkAPI) => {
    try {
      return DoorDelegateApi.postOpenDoor(propertyId, doorId);
    } catch (e) {
      return handleSliceError(e, thunkAPI.rejectWithValue);
    }
  }
);

export const openDoor = createAsyncThunk<
  void,
  { magicToken: string; magicId: string; doorId: string },
  { state: RootState }
>(
  "portalSlice/openDoor",
  async (arg: { magicToken: string; magicId: string; doorId: string }, thunkAPI) => {
    try {
      return await MagicApi.openDoor(arg.doorId, arg.magicId, arg.magicToken, {
        signal: thunkAPI.signal
      });
    } catch (e) {
      return handleSliceError(e, thunkAPI.rejectWithValue);
    }
  }
);

export const fetchAvailableDoors = createAsyncThunk<
  Array<AvailableDoor>,
  { propertyId: string },
  { state: RootState }
>(
  "available-doors/fetchAll",
  async (arg, thunkAPI) => {
    try {
      return DoorDelegateApi.availableDoors(arg.propertyId, {
        signal: thunkAPI.signal
      });
    } catch (e) {
      return handleSliceError(e, thunkAPI.rejectWithValue);
    }
  },
  {
    condition(arg, thunkAPI) {
      const status = thunkAPI.getState().availableDoor.status;
      const lastPropertyId = thunkAPI.getState().availableDoor.propertyId;
      // don't load already loaded Properties

      if (
        (status === EntityStateStatus.LOADING || status === EntityStateStatus.SUCCEEDED) &&
        arg.propertyId === lastPropertyId
      ) {
        return false;
      }
    }
  }
);

const slice = createSlice({
  name: "availableDoors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableDoors.pending, (state, action) => {
        state.status = EntityStateStatus.LOADING;
        state.propertyId = action.meta.arg.propertyId;
      })
      .addCase(fetchAvailableDoors.fulfilled, (state, action) => {
        state.propertyId = action.meta.arg.propertyId;
        availableDoorAdapter.setAll(
          state,
          action.payload.map((item) => ({
            error: false,
            unlocked: false,
            ...item
          }))
        );
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchAvailableDoors.rejected, (state, action) => {
        state.propertyId = undefined;
        if (action.error.name === "AbortError") {
          if (state.status === EntityStateStatus.LOADING) {
            state.status = EntityStateStatus.IDLE;
          }
          return;
        }
        state.status = EntityStateStatus.FAILED;
      });
  }
});

export const { reducer } = slice;

export const { selectAll: selectAvailableDoors } = availableDoorAdapter.getSelectors<RootState>(
  (state) => state.availableDoor
);
