import { ChangeEvent, FC, MouseEvent, useMemo, useState } from "react";
import { ProfileDrawerCards } from "./profile-drawer-cards/profile-drawer-cards";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { ReservationsForProfile } from "../types/profile";
import { Grid, Paper, Table, TableBody, TableContainer, TablePagination } from "@mui/material";
import { EmptyState, EmptyStateSize, Heading4 } from "@likemagic-tech/sv-magic-library";
import { EnhancedTableHead, HeadCell } from "src/components/table/enhanced-table-head";
import { ReservationStatus } from "src/domain/reservation-status";
import { Order, OrderEnum } from "src/types/pageable";
import { ProfileDrawerBookingsTableRow } from "./profile-drawer-cards/profile-drawer-bookings-table-row";
import { useGetReservationsPageQuery } from "src/graphql/queries/GetReservationsPage.generated";
import { useProperty } from "src/hooks/use-property";
import { PropertyDetails } from "src/domain/property";
import { useReservationDetailsId } from "../../reservations/reservation-details/reservation-details/use-reservation-details-id";

interface ProfileDrawerBookingsProps {
  profileId?: number;
}

export interface HeadCellsForBookingDrawer {
  id: string;
  property: PropertyDetails;
  status: ReservationStatus;
  arrival: string;
  departure: string;
  channel: string;
  unit: string;
}

const headCells: HeadCell<ReservationsForProfile>[] = [
  {
    id: "id",
    label: "labels__reservationId",
    disableSort: true
  },
  { id: "property", label: "labels__property", disableSort: true },
  {
    id: "status",
    label: "labels__status"
  },
  {
    id: "arrival",
    label: "labels__arrival"
  },
  {
    id: "departure",
    label: "labels__departure"
  },
  {
    id: "channel",
    label: "labels__channel"
  },
  {
    id: "unit",
    label: "labels__unit"
  }
];

export const ProfileDrawerBookings: FC<ProfileDrawerBookingsProps> = ({ profileId }) => {
  const { t } = useTranslationWrapper();
  const { selectedPropertyIds } = useProperty();
  const [bookingsPage, setBookingsPage] = useState(0);
  const [bookingsRowsPerPage, setBookingsRowsPerPage] = useState(25);
  const [orderBookings, setOrderBookings] = useState<Order>(OrderEnum.DESC);
  const [orderByBookings, setOrderByBookings] = useState<keyof ReservationsForProfile>("arrival");

  const { selectedId, setSelectId } = useReservationDetailsId();

  const onRowSelect = (conversationId: string) => {
    const id = selectedId === conversationId ? undefined : conversationId;
    setSelectId(id);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setBookingsPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setBookingsRowsPerPage(parseInt(event.target.value, 10));
    setBookingsPage(0);
  };

  const bookingPageable = useMemo(
    () => ({
      pageNumber: bookingsPage,
      pageSize: bookingsRowsPerPage,
      sort: `${orderByBookings},${orderBookings}`
    }),
    [orderByBookings, orderBookings, bookingsRowsPerPage, bookingsPage]
  );

  const handleBookingRequestSort = (
    event: MouseEvent<unknown>,
    orderByBookings: keyof ReservationsForProfile
  ) => {
    setOrderBookings(orderBookings === OrderEnum.DESC ? OrderEnum.ASC : OrderEnum.DESC);
    setOrderByBookings(orderByBookings);
  };

  const { data: bookingsData } = useGetReservationsPageQuery(
    {
      filter: {
        pageable: bookingPageable,
        pmsPropertyIds: selectedPropertyIds,
        userProfileId: profileId
      }
    },
    { skip: !selectedPropertyIds?.length || !profileId }
  );

  const bookingRows = useMemo(
    () => bookingsData?.GetReservationsPage?.content,
    [bookingsData?.GetReservationsPage]
  );

  const bookingTotalElements = useMemo(
    () => bookingsData?.GetReservationsPage?.totalElements,
    [bookingsData?.GetReservationsPage]
  );

  return (
    <ProfileDrawerCards>
      <Grid item xs={12}>
        <Paper elevation={0}>
          <Grid container justifyContent="space-between">
            <Grid item alignItems="top" p={3}>
              <Heading4>{t("labels__profiles_reservations")}</Heading4>
            </Grid>
            {bookingRows && bookingRows?.length > 0 ? (
              <>
                <TableContainer>
                  <Table>
                    <EnhancedTableHead<ReservationsForProfile>
                      headCells={headCells}
                      order={orderBookings}
                      orderBy={orderByBookings}
                      onRequestSort={handleBookingRequestSort}
                    />
                    <TableBody>
                      {bookingRows?.map((bookingRow) => (
                        <ProfileDrawerBookingsTableRow
                          key={bookingRow.pmsId}
                          item={bookingRow}
                          onSelect={onRowSelect}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={bookingTotalElements ?? 0}
                  rowsPerPage={bookingsRowsPerPage}
                  page={bookingsPage}
                  labelRowsPerPage={t("labels__rows_per_page")}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${t("labels__of")} ${count}`
                  }
                />
              </>
            ) : (
              <EmptyState
                title={t("labels__profile_no_reservation_information")}
                size={EmptyStateSize.LARGE}
              />
            )}
          </Grid>
        </Paper>
      </Grid>
    </ProfileDrawerCards>
  );
};
