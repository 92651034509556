import { HasFlowState } from "./flow-state";
import { Guest } from "./guest";
import { MagicFile } from "./magic-file";
import { MagicObject } from "./magic-object";
import { Person } from "./person";
import { ReservationExtras } from "./reservation-extras";
import { ReservationStatus } from "./reservation-status";
import { TravelBuddy } from "./travel-buddy";
import { Unit } from "./Unit";

export enum IdCheckStatus {
  UNCONFIRMED = "UNCONFIRMED",
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
  REUPLOADED = "REUPLOADED"
}

export interface AccessibleDoor {
  id: number;
  title: string;
  is_general: boolean;
}

export enum PreferenceTypes {
  SPECIFIC_UNIT = "SPECIFIC_UNIT"
}

export interface Reservation extends MagicObject, HasFlowState {
  magicLink: string;
  userProfileReservationCount: number;
  accessibleDoors: AccessibleDoor[] | null;
  additionalGuests: Array<Guest>;
  extras: ReservationExtras | null;
  primaryGuest: Person;
  travelBuddy: Person;
  propertyId: string;
  status: ReservationStatus;
  arrival?: string;
  adults: number;
  childrenAges: number[];
  departure?: string;
  checkInTime?: string;
  checkOutTime?: string;
  cancellationTime?: string;
  unitCleanOnCheckin: boolean;
  comment?: string;
  guestComment?: string;
  bookerComment?: string;
  bookingComment?: string;
  channelCode: string;
  unit?: Unit;
  // Note: unitGroup.id is the *booked* unit group.
  // Usually we want to show the *assigned* unit group => unit.unitGroupId
  unitGroup: {
    id: string;
    maxPersons: number;
  };
  maxCompanions: number;
  files: MagicFile[];
  travelBuddies: Array<TravelBuddy>;
  idCheckStatus: IdCheckStatus;
  displayId?: string;
}
