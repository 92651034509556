import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type CancelTerminalPaymentMutationVariables = Types.Exact<{
  cancelTerminalPaymentRequest: Types.CancelTerminalPaymentRequest;
}>;

export type CancelTerminalPaymentMutation = {
  __typename?: "Mutation";
  CancelTerminalPayment?: boolean | null;
};

export const CancelTerminalPaymentDocument = `
    mutation CancelTerminalPayment($cancelTerminalPaymentRequest: CancelTerminalPaymentRequest!) {
  CancelTerminalPayment(
    cancelTerminalPaymentRequest: $cancelTerminalPaymentRequest
  )
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    CancelTerminalPayment: build.mutation<
      CancelTerminalPaymentMutation,
      CancelTerminalPaymentMutationVariables
    >({
      query: (variables) => ({ document: CancelTerminalPaymentDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useCancelTerminalPaymentMutation } = injectedRtkApi;
