import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import { PropertyApi } from "../api/property.api";
import { RootState } from "../store";
import { Property } from "../domain/property";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import { getI18nLanguage } from "../components/language-selector/language-selector";

export const propertiesAdapter = createEntityAdapter<Property>({
  selectId: (model) => model.propertyId + "_" + model.language
});

const initialState = propertiesAdapter.getInitialState<{
  status: EntityStateStatus;
  selectedProperty: string;
}>({
  status: EntityStateStatus.IDLE,
  selectedProperty: ""
});

export const fetchProperties = createAsyncThunk<Property[], undefined, { state: RootState }>(
  "properties/fetchProperties",
  async (arg, thunkApi) => {
    return await PropertyApi.fetchProperties({ signal: thunkApi.signal });
  },
  {
    condition(arg, thunkAPI): boolean | undefined {
      const status = thunkAPI.getState().property.status;
      // don't load already loaded Properties
      if (status === EntityStateStatus.LOADING || status === EntityStateStatus.SUCCEEDED) {
        return false;
      }
    }
  }
);

const propertiesSlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setSelectedProperty(state, action: PayloadAction<string>) {
      state.selectedProperty = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProperties.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(fetchProperties.fulfilled, (state, action) => {
        propertiesAdapter.addMany(state, action.payload);
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchProperties.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          if (state.status === EntityStateStatus.LOADING) {
            state.status = EntityStateStatus.IDLE;
          }
          return;
        }
        state.status = EntityStateStatus.FAILED;
      });
  }
});

export const { reducer } = propertiesSlice;
export const { setSelectedProperty } = propertiesSlice.actions;
const selectSelf = (state: RootState) => state[propertiesSlice.name];

export const { selectAll: selectAllProperties } = propertiesAdapter.getSelectors<RootState>(
  (state) => state.property
);

export const selectSelectedProperty = createSelector(selectSelf, (s) => s.selectedProperty);

export const selectPropertyStatus = createSelector(selectSelf, (s) => s.status);

export const selectPropertiesByLanguage = createSelector(selectAllProperties, (properties) =>
  properties.filter((p) => p.language === getI18nLanguage())
);
