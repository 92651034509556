import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BatteryNoneIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8 6.00014L4 16.7901V15.0001H2L2 9.00014H4V7.33014C4.00262 6.97821 4.14358 6.64144 4.39244 6.39258C4.6413 6.14372 4.97807 6.00275 5.33 6.00014H14.8ZM17.35 6.00014L2.73 20.6201L4 21.8901L7.89 18.0001H20.67C21.0219 17.9975 21.3587 17.8566 21.6076 17.6077C21.8564 17.3588 21.9974 17.0221 22 16.6701V7.33014C21.9974 6.97821 21.8564 6.64144 21.6076 6.39258C21.3587 6.14372 21.0219 6.00275 20.67 6.00014H19.89L22.73 3.16014L21.46 1.89014L17.35 6.00014Z"
        fill="black"
      />
    </svg>
  </SvgIcon>
);
