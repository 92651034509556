import { FC } from "react";
import { Box, Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export const PropertySelectorCheckbox: FC<{ checked: boolean }> = ({ checked }) => (
  <Checkbox
    icon={<Box sx={{ border: "2px solid black" }} width={24} height={24} borderRadius={0.5}></Box>}
    checkedIcon={
      <Box sx={{ border: "2px solid black" }} width={24} height={24} borderRadius={0.5}>
        <CheckIcon fontSize={"small"} />
      </Box>
    }
    checked={checked}
  />
);
