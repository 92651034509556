import { createSelector, createSlice, PayloadAction, Selector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EntityStateStatus } from "../domain/EntityStateStatus";

export interface SecondScreenState {
  selectedSecondScreenId?: string;
  status: EntityStateStatus;
}

const initialState: SecondScreenState = {
  selectedSecondScreenId: undefined,
  status: EntityStateStatus.IDLE
};
export const secondScreenSlice = createSlice({
  name: "secondScreen",
  initialState,
  reducers: {
    setSelectedSecondScreenId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedSecondScreenId = action.payload;
    },
    setSecondScreenStatus: (state, action: PayloadAction<EntityStateStatus>) => {
      state.status = action.payload;
    }
  }
});

export const { setSelectedSecondScreenId, setSecondScreenStatus } = secondScreenSlice.actions;

const selectSelf: Selector<RootState, SecondScreenState> = (state: RootState) =>
  state[secondScreenSlice.name];

export const selectSelectedSecondScreenId = createSelector(
  selectSelf,
  (res) => res.selectedSecondScreenId
);

export const selectSharingOnSecondScreenStatus = createSelector(selectSelf, (res) => res.status);
