import { ApiKeyApiClient } from "./base/api-key-api-client";

const BASE_URL = "/api/guest-service/properties";

class PropertiesApiClient extends ApiKeyApiClient {
  async downloadFolioInvoice(arg: any, init?: RequestInit) {
    const { pmsPropertyId, pmsFolioId, pmsReservationId, isClosed, authToken } = arg;
    const response = await this.fetchApi(
      `${BASE_URL}/${pmsPropertyId}/folios/${pmsFolioId}/pdf?pmsReservationId=${pmsReservationId}&isFolioWindow=${!isClosed}`,
      {
        ...init,
        method: "GET",
        headers: { Authorization: `Bearer ${authToken}` }
      }
    );
    return response.blob();
  }
}

export const PropertiesApi = new PropertiesApiClient();
