import { transformAdditionalServicesAvailability } from "../graphql/transform/transform-additional-services-availability";
import { AdditionalServicesAvailabilityResponse } from "../domain/additional-services-availability";
import { useGetAdditionalServicesAvailabilityQueryEnhanced } from "../graphql/queries/enhanced-queries/get-additional-services-availability-enhanced";

export const useAdditionalServicesAvailability = ({
  reservationId,
  skipApiCall
}: {
  reservationId: string;
  skipSpinner?: boolean;
  skipApiCall?: boolean;
}): { data: AdditionalServicesAvailabilityResponse[] | undefined; isLoading: boolean } => {
  const { data, isLoading } = useGetAdditionalServicesAvailabilityQueryEnhanced(
    {
      pmsReservationId: reservationId,
      // @ts-ignore
      skipSpinner: true
    },
    {
      skip: skipApiCall || !reservationId,
      refetchOnMountOrArgChange: true,
      forceRefetch: true
    }
  );

  return {
    data: data?.BackofficeGetAdditionalServicesAvailability?.map(
      transformAdditionalServicesAvailability
    ).filter((item) => item.available),
    isLoading
  };
};
